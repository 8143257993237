import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { getRequest } from '@admin/model/api';
import { UsersData, UsersPayload } from '@admin/model/users/user.types';
import { normalizeUsersInRoleData } from '@admin/model/users/users-in-role/users-in-role.helpers';
import { UserState } from '@admin/model/users/users-in-role/users-in-role.types';

export const getUsersInRoleFx = createEffect(async (payload: UsersPayload): Promise<UserState> => {
    try {
        const data = await getRequest<UsersData>('employees', payload);

        return normalizeUsersInRoleData(data);
    } catch (error) {
        logger(error);

        throw error;
    }
});
