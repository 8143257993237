import {
    CategoriesBindingChangeHistoryItemData,
    CategoriesBindingChangeHistory,
} from '@admin/model/categories/categories-binding-change-history/categories-binding-change-history.types';
import { normalizeDate } from '@admin/model/helpers';
import { ChangeHistoryData } from '@admin/model/model.types';

export const normalizeCategoriesBindingChangeHistoryData = (
    response: ChangeHistoryData<CategoriesBindingChangeHistoryItemData>,
): CategoriesBindingChangeHistory => ({
    ...response,
    createdAt: normalizeDate(response.createdAt) as Date,
    data: response.data.map((dataItem) => ({ ...dataItem, changedAt: normalizeDate(dataItem.changedAt) as Date })),
});
