import { reset } from 'patronum';
import {
    $clientDisableAccessAndPhoneDone,
    $clientDisableAccessAndPhoneError,
    resetClientDisableAccessAndPhoneErrorEvent,
} from '@admin/model/clients-and-profiles/client-disable-access-and-phone/client-disable-access-and-phone.store';
import { sample } from 'effector';
import { getClientAccountFx } from '@admin/model/clients-and-profiles/client-account/get-client-account.fx';
import { clientDisableAccessAndPhoneFx } from '@admin/model/clients-and-profiles/client-disable-access-and-phone/client-disable-access-and-phone.fx';

sample({
    clock: clientDisableAccessAndPhoneFx.doneData,
    target: $clientDisableAccessAndPhoneDone,
});

sample({
    clock: getClientAccountFx.finally,
    fn: () => false,
    target: $clientDisableAccessAndPhoneDone,
});

sample({
    clock: clientDisableAccessAndPhoneFx.failData,
    target: $clientDisableAccessAndPhoneError,
});

reset({ clock: resetClientDisableAccessAndPhoneErrorEvent, target: [$clientDisableAccessAndPhoneError] });
