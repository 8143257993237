import { showNoticeEvent } from '@admin/model/notices';
import { sample } from 'effector';
import { editOrderFx } from '@admin/model/orders/edit-order/edit-order.fx';
import { getSuccessMessage } from '@admin/model/orders/edit-order/edit-order.helpers';

sample({
    clock: editOrderFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
