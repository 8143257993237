import { combine, createEvent, createStore } from 'effector';
import { OrderDetailsData } from '@admin/model/orders/order/order.types';
import { getOrderFx } from '@admin/model/orders/order/get-order.fx';

export const resetOrderErrorEvent = createEvent();

export const $orderData = createStore<Nullable<OrderDetailsData>>(null);
export const $orderError = createStore<Nullable<unknown>>(null);

export const $order = combine({
    data: $orderData,
    error: $orderError.reset(resetOrderErrorEvent),
    isLoading: getOrderFx.pending,
});
