import React, { ComponentType, createElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { PagePath } from '@admin/pages/page-path';
import { PageName } from '@admin/pages/page-name';
import { PageRoute } from '@admin/common/pages/page-route.component';
import { PageComponent } from '@admin/pages/page-component';
import { Permission } from '@admin/model/users/user.types';

export interface RouterComponentProps {
    permissions: { [key in Permission]?: string };
}

function PageRouterComponent({ permissions }: RouterComponentProps) {
    const { t } = useTranslation();

    const getPermission = (permissionList?: Permission[]): boolean =>
        permissionList?.some((routePermission) => permissions[routePermission] !== undefined) ?? false;

    return (
        <Routes>
            <Route
                path={PagePath.HOME}
                element={
                    <PageRoute name={PageName.HOME} component={PageComponent.HOME} redirectTo={PageName.CATALOG} />
                }
            >
                <Route
                    path={PagePath.CATALOG}
                    element={
                        <PageRoute
                            name={PageName.CATALOG}
                            component={PageComponent.CATALOG}
                            redirectTo={PageName.CATEGORIES_EDITOR}
                        />
                    }
                >
                    <Route
                        path={PagePath.CATEGORIES_EDITOR}
                        element={
                            <PageRoute
                                name={PageName.CATEGORIES_EDITOR}
                                component={PageComponent.CATEGORIES_EDITOR}
                                redirectTo={PageName.CATEGORIES_MAIN}
                            />
                        }
                    >
                        <Route
                            path={PagePath.CATEGORIES_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.CATEGORIES_MAIN}
                                    component={PageComponent.CATEGORIES_MAIN}
                                    title={t('META.CATEGORIES_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.CATEGORY_CREATE}
                            element={
                                <PageRoute
                                    name={PageName.CATEGORY_CREATE}
                                    component={PageComponent.CATEGORY_CREATE}
                                    title={t('META.CATEGORIES_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.CategoryCreateDelete])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.CATEGORY_EDIT}
                            element={
                                <PageRoute
                                    name={PageName.CATEGORY_EDIT}
                                    component={PageComponent.CATEGORY_EDIT}
                                    title={t('META.CATEGORIES_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.CategoryEdit])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.CATEGORY_INFO}
                            element={
                                <PageRoute name={PageName.CATEGORY_INFO} component={PageComponent.CATEGORY_INFO} />
                            }
                        >
                            <Route
                                path={PagePath.CATEGORY_VIEW}
                                element={
                                    <PageRoute
                                        name={PageName.CATEGORY_VIEW}
                                        component={PageComponent.CATEGORY_VIEW}
                                        title={t('META.CATEGORIES_MAIN_PAGE')}
                                    />
                                }
                            />
                            <Route
                                path={PagePath.CATEGORY_CHANGE_HISTORY}
                                element={
                                    <PageRoute
                                        name={PageName.CATEGORY_CHANGE_HISTORY}
                                        component={PageComponent.CATEGORY_CHANGE_HISTORY}
                                        title={t('META.CATEGORIES_MAIN_PAGE')}
                                        hasPermission={getPermission([Permission.ViewLogHistory])}
                                    />
                                }
                            />
                        </Route>
                    </Route>

                    <Route
                        path={PagePath.CATEGORIES_BINDING}
                        element={
                            <PageRoute
                                name={PageName.CATEGORIES_BINDING}
                                component={PageComponent.CATEGORIES_BINDING}
                                redirectTo={PageName.CATEGORIES_BINDING_CATEGORIES}
                            />
                        }
                    >
                        <Route
                            path={PagePath.CATEGORIES_BINDING_CATEGORIES}
                            element={
                                <PageRoute
                                    name={PageName.CATEGORIES_BINDING_CATEGORIES}
                                    component={PageComponent.CATEGORIES_BINDING_CATEGORIES}
                                    title={t('META.CATEGORIES_BINDING_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.CATEGORIES_BINDING_CHANGE_HISTORY}
                            element={
                                <PageRoute
                                    name={PageName.CATEGORIES_BINDING_CHANGE_HISTORY}
                                    component={PageComponent.CATEGORIES_BINDING_CHANGE_HISTORY}
                                    title={t('META.CATEGORIES_BINDING_PAGE')}
                                    hasPermission={getPermission([Permission.ViewLogHistory])}
                                />
                            }
                        />
                    </Route>

                    <Route
                        path={PagePath.OFFERS}
                        element={
                            <PageRoute
                                name={PageName.OFFERS}
                                component={PageComponent.OFFERS}
                                redirectTo={PageName.OFFERS_MAIN}
                                hasPermission={getPermission([Permission.OffersRead])}
                            />
                        }
                    >
                        <Route
                            path={PagePath.OFFERS_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.OFFERS_MAIN}
                                    component={PageComponent.OFFERS_MAIN}
                                    title={t('META.OFFERS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.CREATE_OFFER}
                            element={
                                <PageRoute
                                    name={PageName.CREATE_OFFER}
                                    component={PageComponent.CREATE_OFFER}
                                    title={t('META.OFFERS_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.OffersEdit])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.OFFER_EDIT}
                            element={
                                <PageRoute
                                    name={PageName.OFFER_EDIT}
                                    component={PageComponent.OFFER_EDIT}
                                    title={t('META.OFFERS_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.OffersEdit])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.UPDATE_CHARACTERISTICS}
                            element={
                                <PageRoute
                                    name={PageName.UPDATE_CHARACTERISTICS}
                                    component={PageComponent.UPDATE_CHARACTERISTICS}
                                    title={t('META.OFFERS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.OFFER_INFO}
                            element={
                                <PageRoute
                                    name={PageName.OFFER_INFO}
                                    component={PageComponent.OFFER_INFO}
                                    hasPermission={getPermission([Permission.OffersRead])}
                                />
                            }
                        >
                            <Route
                                path={PagePath.OFFER_VIEW}
                                element={
                                    <PageRoute
                                        name={PageName.OFFER_VIEW}
                                        component={PageComponent.OFFER_VIEW}
                                        title={t('META.OFFERS_MAIN_PAGE')}
                                    />
                                }
                            />
                            <Route
                                path={PagePath.OFFER_COUPON_CODES}
                                element={
                                    <PageRoute
                                        name={PageName.OFFER_COUPON_CODES}
                                        component={PageComponent.OFFER_COUPON_CODES}
                                        title={t('META.OFFERS_MAIN_PAGE')}
                                        hasPermission={getPermission([Permission.PartnersObjectsCouponsRead])}
                                    />
                                }
                            />
                        </Route>
                        <Route
                            path={PagePath.OFFERS_UPLOADS}
                            element={
                                <PageRoute name={PageName.OFFERS_UPLOADS} component={PageComponent.OFFERS_UPLOADS} />
                            }
                        >
                            <Route
                                path={PagePath.OFFERS_UPLOAD_OFFERS}
                                element={
                                    <PageRoute
                                        name={PageName.OFFERS_UPLOAD_OFFERS}
                                        component={PageComponent.OFFERS_UPLOAD_OFFERS}
                                        title={t('META.OFFERS_MAIN_PAGE')}
                                        hasPermission={getPermission([Permission.OffersCatalogUpload])}
                                    />
                                }
                            />
                            <Route
                                path={PagePath.OFFERS_UPLOAD_COUPON_CODES}
                                element={
                                    <PageRoute
                                        name={PageName.OFFERS_UPLOAD_COUPON_CODES}
                                        component={PageComponent.OFFERS_UPLOAD_COUPON_CODES}
                                        title={t('META.OFFERS_MAIN_PAGE')}
                                        hasPermission={getPermission([Permission.PartnersObjectsCouponsEdit])}
                                    />
                                }
                            />
                        </Route>
                    </Route>

                    <Route
                        path={PagePath.PRODUCTS}
                        element={
                            <PageRoute
                                name={PageName.PRODUCTS}
                                component={PageComponent.PRODUCTS}
                                redirectTo={PageName.PRODUCTS_MAIN}
                                hasPermission={getPermission([Permission.ProductRead])}
                            />
                        }
                    >
                        <Route
                            path={PagePath.PRODUCTS_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.PRODUCTS_MAIN}
                                    component={PageComponent.PRODUCTS_MAIN}
                                    title={t('META.PRODUCTS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.PRODUCT_VIEW}
                            element={
                                <PageRoute
                                    name={PageName.PRODUCT_VIEW}
                                    component={PageComponent.PRODUCT_VIEW}
                                    hasPermission={getPermission([Permission.ProductRead])}
                                />
                            }
                        >
                            <Route
                                path={PagePath.PRODUCT_DETAILS_VIEW}
                                element={
                                    <PageRoute
                                        name={PageName.PRODUCT_DETAILS_VIEW}
                                        component={PageComponent.PRODUCT_DETAILS_VIEW}
                                        title={t('META.OFFERS_MAIN_PAGE')}
                                    />
                                }
                            />
                            <Route
                                path={PagePath.PRODUCT_OFFERS_VIEW}
                                element={
                                    <PageRoute
                                        name={PageName.PRODUCT_OFFERS_VIEW}
                                        component={PageComponent.PRODUCT_OFFERS_VIEW}
                                        title={t('META.OFFERS_MAIN_PAGE')}
                                    />
                                }
                            />
                        </Route>
                        <Route
                            path={PagePath.PRODUCT_EDIT}
                            element={
                                <PageRoute
                                    name={PageName.PRODUCT_EDIT}
                                    component={PageComponent.PRODUCT_EDIT}
                                    hasPermission={getPermission([Permission.ProductCardCreateEditDelete])}
                                />
                            }
                        >
                            <Route
                                path={PagePath.PRODUCT_DETAILS_EDIT}
                                element={
                                    <PageRoute
                                        name={PageName.PRODUCT_DETAILS_EDIT}
                                        component={PageComponent.PRODUCT_DETAILS_EDIT}
                                        title={t('META.OFFERS_MAIN_PAGE')}
                                    />
                                }
                            />
                            <Route
                                path={PagePath.PRODUCT_OFFERS_EDIT}
                                element={
                                    <PageRoute
                                        name={PageName.PRODUCT_OFFERS_EDIT}
                                        component={PageComponent.PRODUCT_OFFERS_EDIT}
                                        title={t('META.OFFERS_MAIN_PAGE')}
                                    />
                                }
                            />
                        </Route>
                        <Route
                            path={PagePath.PRODUCT_CREATE}
                            element={
                                <PageRoute
                                    name={PageName.PRODUCT_CREATE}
                                    component={PageComponent.PRODUCT_CREATE}
                                    hasPermission={getPermission([Permission.ProductCardCreateEditDelete])}
                                />
                            }
                        >
                            <Route
                                path={PagePath.PRODUCT_OFFERS_CREATE}
                                element={
                                    <PageRoute
                                        name={PageName.PRODUCT_OFFERS_CREATE}
                                        component={PageComponent.PRODUCT_OFFERS_CREATE}
                                        title={t('META.OFFERS_MAIN_PAGE')}
                                    />
                                }
                            />
                            <Route
                                path={PagePath.PRODUCT_DETAILS_CREATE}
                                element={
                                    <PageRoute
                                        name={PageName.PRODUCT_DETAILS_CREATE}
                                        component={PageComponent.PRODUCT_DETAILS_CREATE}
                                        title={t('META.OFFERS_MAIN_PAGE')}
                                    />
                                }
                            />
                        </Route>
                    </Route>

                    <Route
                        path={PagePath.CHARACTERISTICS}
                        element={
                            <PageRoute
                                name={PageName.CHARACTERISTICS}
                                component={PageComponent.CHARACTERISTICS}
                                redirectTo={PageName.CHARACTERISTICS_MAIN}
                            />
                        }
                    >
                        <Route
                            path={PagePath.CHARACTERISTICS_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.CHARACTERISTICS_MAIN}
                                    component={PageComponent.CHARACTERISTICS_MAIN}
                                    title={t('META.CHARACTERISTICS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.CHARACTERISTIC_CREATE}
                            element={
                                <PageRoute
                                    name={PageName.CHARACTERISTIC_CREATE}
                                    component={PageComponent.CHARACTERISTIC_CREATE}
                                    title={t('META.CHARACTERISTICS_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.ProductsCharacteristicsEdit])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.CHARACTERISTIC_EDIT}
                            element={
                                <PageRoute
                                    name={PageName.CHARACTERISTIC_EDIT}
                                    component={PageComponent.CHARACTERISTIC_EDIT}
                                    title={t('META.CHARACTERISTICS_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.ProductsCharacteristicsEdit])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.CHARACTERISTIC_VIEW}
                            element={
                                <PageRoute
                                    name={PageName.CHARACTERISTIC_VIEW}
                                    component={PageComponent.CHARACTERISTIC_VIEW}
                                    title={t('META.CHARACTERISTICS_MAIN_PAGE')}
                                />
                            }
                        />
                    </Route>

                    <Route
                        path={PagePath.COLLECTIONS}
                        element={
                            <PageRoute
                                name={PageName.COLLECTIONS}
                                component={PageComponent.COLLECTIONS}
                                redirectTo={PageName.COLLECTIONS_MAIN}
                                hasPermission={getPermission([Permission.CollectionsCategoryRead])}
                            />
                        }
                    >
                        <Route
                            path={PagePath.COLLECTIONS_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.COLLECTIONS_MAIN}
                                    component={PageComponent.COLLECTIONS_MAIN}
                                    title={t('META.COLLECTIONS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.COLLECTIONS_CREATE}
                            element={
                                <PageRoute
                                    name={PageName.COLLECTIONS_CREATE}
                                    component={PageComponent.COLLECTIONS_CREATE}
                                    title={t('META.COLLECTIONS_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.CollectionsCategoryCreateDelete])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.COLLECTIONS_VIEW}
                            element={
                                <PageRoute
                                    name={PageName.COLLECTIONS_VIEW}
                                    component={PageComponent.COLLECTIONS_VIEW}
                                    title={t('META.COLLECTIONS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.COLLECTIONS_EDIT}
                            element={
                                <PageRoute
                                    name={PageName.COLLECTIONS_EDIT}
                                    component={PageComponent.COLLECTIONS_EDIT}
                                    title={t('META.COLLECTIONS_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.CollectionsCategoryEdit])}
                                />
                            }
                        />
                    </Route>

                    <Route
                        path={PagePath.SUPPLIERS}
                        element={
                            <PageRoute
                                name={PageName.SUPPLIERS}
                                component={PageComponent.SUPPLIERS}
                                redirectTo={PageName.SUPPLIERS_MAIN}
                                hasPermission={getPermission([Permission.PartnersRead])}
                            />
                        }
                    >
                        <Route
                            path={PagePath.SUPPLIERS_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.SUPPLIERS_MAIN}
                                    component={PageComponent.SUPPLIERS_MAIN}
                                    title={t('META.SUPPLIERS_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.SUPPLIER_CREATE}
                            element={
                                <PageRoute
                                    name={PageName.SUPPLIER_CREATE}
                                    component={PageComponent.SUPPLIER_CREATE}
                                    title={t('META.SUPPLIERS_PAGE')}
                                    hasPermission={getPermission([Permission.PartnersCreateEdit])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.SUPPLIER_INFO}
                            element={
                                <PageRoute name={PageName.SUPPLIER_INFO} component={PageComponent.SUPPLIER_INFO} />
                            }
                        >
                            <Route
                                path={PagePath.SUPPLIER_EDIT}
                                element={
                                    <PageRoute
                                        name={PageName.SUPPLIER_EDIT}
                                        component={PageComponent.SUPPLIER_EDIT}
                                        title={t('META.SUPPLIERS_PAGE')}
                                        hasPermission={getPermission([
                                            Permission.PartnersCreateEdit,
                                            Permission.PartnersObjectsMatrixReadEdit,
                                        ])}
                                    />
                                }
                            />
                            <Route
                                path={PagePath.SUPPLIER_VIEW}
                                element={
                                    <PageRoute
                                        name={PageName.SUPPLIER_VIEW}
                                        component={PageComponent.SUPPLIER_VIEW}
                                        title={t('META.SUPPLIERS_PAGE')}
                                    />
                                }
                            />
                            <Route
                                path={PagePath.EVENT_SET}
                                element={
                                    <PageRoute
                                        name={PageName.EVENT_SET}
                                        component={PageComponent.EVENT_SET}
                                        title={t('META.SUPPLIERS_PAGE')}
                                        hasPermission={getPermission([
                                            Permission.NotificationConfigView,
                                            Permission.NotificationConfigEdit,
                                        ])}
                                    />
                                }
                            />
                            <Route
                                path={PagePath.DELIVERY}
                                element={
                                    <PageRoute
                                        name={PageName.DELIVERY}
                                        component={PageComponent.DELIVERY}
                                        hasPermission={getPermission([Permission.PartnersObjectsMatrixReadEdit])}
                                    />
                                }
                            >
                                <Route
                                    index={true}
                                    element={
                                        <PageRoute
                                            name={PageName.DELIVERY_COST_MATRIX}
                                            component={PageComponent.DELIVERY_COST_MATRIX}
                                            title={t('META.SUPPLIERS_PAGE')}
                                        />
                                    }
                                />
                                <Route
                                    path={PagePath.DELIVERY_MATRIX_CHANGE_HISTORY}
                                    element={
                                        <PageRoute
                                            name={PageName.DELIVERY_MATRIX_CHANGE_HISTORY}
                                            component={PageComponent.DELIVERY_MATRIX_CHANGE_HISTORY}
                                            title={t('META.SUPPLIERS_PAGE')}
                                            hasPermission={getPermission([Permission.ViewLogHistory])}
                                        />
                                    }
                                />
                                <Route
                                    path={PagePath.DELIVERY_MATRIX_UPLOAD_HISTORY}
                                    element={
                                        <PageRoute
                                            name={PageName.DELIVERY_MATRIX_UPLOAD_HISTORY}
                                            component={PageComponent.DELIVERY_MATRIX_UPLOAD_HISTORY}
                                            title={t('META.SUPPLIERS_PAGE')}
                                        />
                                    }
                                />
                            </Route>
                        </Route>
                    </Route>

                    <Route
                        path={PagePath.SUBSCRIPTIONS}
                        element={
                            <PageRoute
                                name={PageName.SUBSCRIPTIONS}
                                component={PageComponent.SUBSCRIPTIONS}
                                redirectTo={PageName.SUBSCRIPTIONS_MAIN}
                                hasPermission={getPermission([Permission.ClientSubscriptionsRead])}
                            />
                        }
                    >
                        <Route
                            path={PagePath.SUBSCRIPTIONS_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.SUBSCRIPTIONS_MAIN}
                                    component={PageComponent.SUBSCRIPTIONS_MAIN}
                                    title={t('META.SUBSCRIPTIONS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.SUBSCRIPTION_INFO}
                            element={
                                <PageRoute
                                    name={PageName.SUBSCRIPTION_INFO}
                                    component={PageComponent.SUBSCRIPTION_INFO}
                                    hasPermission={getPermission([Permission.ClientSubscriptionsRead])}
                                />
                            }
                        >
                            <Route
                                path={PagePath.SUBSCRIPTION_VIEW}
                                element={
                                    <PageRoute
                                        name={PageName.SUBSCRIPTION_VIEW}
                                        component={PageComponent.SUBSCRIPTION_VIEW}
                                        title={t('META.SUBSCRIPTIONS_MAIN_PAGE')}
                                    />
                                }
                            />
                            <Route
                                path={PagePath.SUBSCRIPTION_CHANGE_HISTORY}
                                element={
                                    <PageRoute
                                        name={PageName.SUBSCRIPTION_CHANGE_HISTORY}
                                        component={PageComponent.SUBSCRIPTION_CHANGE_HISTORY}
                                        title={t('META.SUBSCRIPTIONS_MAIN_PAGE')}
                                        hasPermission={getPermission([Permission.ViewLogHistory])}
                                    />
                                }
                            />
                        </Route>
                    </Route>
                </Route>

                <Route
                    path={PagePath.ORDERS_AND_PAYMENTS}
                    element={
                        <PageRoute
                            name={PageName.ORDERS_AND_PAYMENTS}
                            component={PageComponent.ORDERS_AND_PAYMENTS}
                            redirectTo={PageName.ORDERS}
                        />
                    }
                >
                    <Route
                        path={PagePath.ORDERS}
                        element={
                            <PageRoute
                                name={PageName.ORDERS}
                                component={PageComponent.ORDERS}
                                redirectTo={PageName.ORDERS_MAIN}
                                hasPermission={getPermission([Permission.PartnersObjectsOrdersRead])}
                            />
                        }
                    >
                        <Route
                            path={PagePath.ORDERS_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.ORDERS_MAIN}
                                    component={PageComponent.ORDERS_MAIN}
                                    title={t('META.ORDERS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.ORDER_INFO}
                            element={<PageRoute name={PageName.ORDER_INFO} component={PageComponent.ORDER_INFO} />}
                        >
                            <Route
                                path={PagePath.ORDER_VIEW}
                                element={
                                    <PageRoute
                                        name={PageName.ORDER_VIEW}
                                        component={PageComponent.ORDER_VIEW}
                                        title={t('META.ORDERS_MAIN_PAGE')}
                                    />
                                }
                            />
                            <Route
                                path={PagePath.ORDER_EDIT}
                                element={
                                    <PageRoute
                                        name={PageName.ORDER_EDIT}
                                        component={PageComponent.ORDER_EDIT}
                                        title={t('META.ORDERS_MAIN_PAGE')}
                                    />
                                }
                            />
                            <Route
                                path={PagePath.ORDER_PAYMENT}
                                element={
                                    <PageRoute
                                        name={PageName.ORDER_PAYMENT}
                                        component={PageComponent.ORDER_PAYMENT}
                                        title={t('META.ORDERS_MAIN_PAGE')}
                                    />
                                }
                            />
                            <Route
                                path={PagePath.ORDER_CHANGE_HISTORY}
                                element={
                                    <PageRoute
                                        name={PageName.ORDER_CHANGE_HISTORY}
                                        component={PageComponent.ORDER_CHANGE_HISTORY}
                                        title={t('META.ORDERS_MAIN_PAGE')}
                                    />
                                }
                            />
                            <Route
                                path={PagePath.ORDER_TRACKING}
                                element={
                                    <PageRoute
                                        name={PageName.ORDER_TRACKING}
                                        component={PageComponent.ORDER_TRACKING}
                                        title={t('META.ORDERS_MAIN_PAGE')}
                                    />
                                }
                            />
                        </Route>
                    </Route>
                    <Route
                        path={PagePath.PAYMENTS}
                        element={
                            <PageRoute
                                name={PageName.PAYMENTS}
                                component={PageComponent.PAYMENTS}
                                redirectTo={PageName.PAYMENTS_MAIN}
                                hasPermission={getPermission([Permission.PartnersObjectsOrdersRead])}
                            />
                        }
                    >
                        <Route
                            path={PagePath.PAYMENTS_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.PAYMENTS_MAIN}
                                    component={PageComponent.PAYMENTS_MAIN}
                                    title={t('META.PAYMENTS_MAIN_PAGE')}
                                />
                            }
                        />
                    </Route>
                </Route>

                <Route
                    path={PagePath.CLIENTS_AND_PROFILES}
                    element={
                        <PageRoute
                            name={PageName.CLIENTS_AND_PROFILES}
                            component={PageComponent.CLIENTS_AND_PROFILES}
                            redirectTo={PageName.CLIENTS_AND_PROFILES_SEARCH}
                            hasPermission={getPermission([Permission.ClientReadAccess])}
                        />
                    }
                >
                    <Route
                        path={PagePath.CLIENTS_AND_PROFILES_SEARCH}
                        element={
                            <PageRoute
                                name={PageName.CLIENTS_AND_PROFILES_SEARCH}
                                component={PageComponent.CLIENTS_AND_PROFILES_SEARCH}
                                title={t('META.CLIENTS_AND_PROFILES_SEARCH_PAGE')}
                            />
                        }
                    />
                    <Route
                        path={PagePath.CLIENTS_SEARCH_HASH}
                        element={
                            <PageRoute
                                name={PageName.CLIENTS_SEARCH_HASH}
                                component={PageComponent.CLIENTS_SEARCH_HASH}
                                title={t('META.CLIENTS_SEARCH_HASH_PAGE')}
                            />
                        }
                    />
                    <Route
                        path={PagePath.CLIENT}
                        element={<PageRoute name={PageName.CLIENT} component={PageComponent.CLIENT} />}
                    >
                        <Route
                            path={PagePath.CLIENT_ACCOUNT}
                            element={
                                <PageRoute
                                    name={PageName.CLIENT_ACCOUNT}
                                    component={PageComponent.CLIENT_ACCOUNT}
                                    title={t('META.CLIENT_ACCOUNT_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.CLIENT_EXPIRATIONS}
                            element={
                                <PageRoute
                                    name={PageName.CLIENT_EXPIRATIONS}
                                    component={PageComponent.CLIENT_EXPIRATIONS}
                                    title={t('META.CLIENT_EXPIRATIONS_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.CLIENT_EXPIRATION}
                            element={
                                <PageRoute
                                    name={PageName.CLIENT_EXPIRATION}
                                    component={PageComponent.CLIENT_EXPIRATION}
                                    title={t('META.CLIENT_EXPIRATIONS_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.CLIENT_AGREEMENTS}
                            element={
                                <PageRoute
                                    name={PageName.CLIENT_AGREEMENTS}
                                    component={PageComponent.CLIENT_AGREEMENTS}
                                    title={t('META.CLIENT_AGREEMENTS_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.CLIENT_AUTH_HISTORY}
                            element={
                                <PageRoute
                                    name={PageName.CLIENT_AUTH_HISTORY}
                                    component={PageComponent.CLIENT_AUTH_HISTORY}
                                    title={t('META.CLIENT_AUTH_HISTORY_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.CLIENT_TRANSACTIONS}
                            element={
                                <PageRoute
                                    name={PageName.CLIENT_TRANSACTIONS}
                                    component={PageComponent.CLIENT_TRANSACTIONS}
                                    title={t('META.CLIENT_TRANSACTIONS_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.CLIENT_SUBSCRIPTIONS}
                            element={
                                <PageRoute
                                    name={PageName.CLIENT_SUBSCRIPTIONS}
                                    component={PageComponent.CLIENT_SUBSCRIPTIONS}
                                    title={t('META.SUBSCRIPTIONS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.CLIENT_TRANSACTION_CREATE}
                            element={
                                <PageRoute
                                    name={PageName.CLIENT_TRANSACTION_CREATE}
                                    component={PageComponent.CLIENT_TRANSACTION_CREATE}
                                    title={t('META.CLIENT_TRANSACTIONS_PAGE')}
                                    hasPermission={getPermission([Permission.ClientTransactionsEdit])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.CLIENT_ORDERS}
                            element={
                                <PageRoute
                                    name={PageName.CLIENT_ORDERS}
                                    component={PageComponent.CLIENT_ORDERS}
                                    title={t('META.CLIENT_ORDERS_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.CLIENT_PROFILE}
                            element={
                                <PageRoute name={PageName.CLIENT_PROFILE} component={PageComponent.CLIENT_PROFILE} />
                            }
                        >
                            <Route
                                index
                                element={
                                    <PageRoute
                                        name={PageName.CLIENT_PROFILE_DETAILS}
                                        component={PageComponent.CLIENT_PROFILE_DETAILS}
                                        title={t('META.CLIENT_PROFILE_PAGE')}
                                    />
                                }
                            />
                        </Route>
                    </Route>
                </Route>

                <Route
                    path={PagePath.ACCOUNTS}
                    element={
                        <PageRoute
                            name={PageName.ACCOUNTS}
                            component={PageComponent.ACCOUNTS}
                            redirectTo={PageName.USERS_MAIN}
                        />
                    }
                >
                    <Route
                        path={PagePath.USERS}
                        element={
                            <PageRoute
                                name={PageName.USERS}
                                component={PageComponent.USERS}
                                redirectTo={PageName.USERS_MAIN}
                            />
                        }
                    >
                        <Route
                            path={PagePath.USERS_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.USERS_MAIN}
                                    component={PageComponent.USERS_MAIN}
                                    title={t('META.USERS_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.UsersCreateEdit])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.CREATE_USER}
                            element={
                                <PageRoute
                                    name={PageName.CREATE_USER}
                                    component={PageComponent.CREATE_USER}
                                    title={t('META.USERS_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.UsersCreateEdit])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.EDIT_USER}
                            element={
                                <PageRoute
                                    name={PageName.EDIT_USER}
                                    component={PageComponent.EDIT_USER}
                                    title={t('META.USERS_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.UsersCreateEdit])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.USER_INFO}
                            element={<PageRoute name={PageName.USER_INFO} component={PageComponent.USER_INFO} />}
                        >
                            <Route
                                path={PagePath.VIEW_USER}
                                element={
                                    <PageRoute
                                        name={PageName.VIEW_USER}
                                        component={PageComponent.VIEW_USER}
                                        title={t('META.USERS_MAIN_PAGE')}
                                    />
                                }
                            />
                            <Route
                                path={PagePath.USER_CHANGE_HISTORY}
                                element={
                                    <PageRoute
                                        name={PageName.USER_CHANGE_HISTORY}
                                        component={PageComponent.USER_CHANGE_HISTORY}
                                        title={t('META.USERS_MAIN_PAGE')}
                                        hasPermission={getPermission([Permission.ViewLogHistory])}
                                    />
                                }
                            />
                        </Route>
                    </Route>

                    <Route
                        path={PagePath.ROLES}
                        element={
                            <PageRoute
                                name={PageName.ROLES}
                                component={PageComponent.ROLES}
                                redirectTo={PageName.ROLES_MAIN}
                                hasPermission={getPermission([Permission.UsersCreateEdit])}
                            />
                        }
                    >
                        <Route
                            path={PagePath.ROLES_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.ROLES_MAIN}
                                    component={PageComponent.ROLES_MAIN}
                                    title={t('META.USERS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.CREATE_ROLE}
                            element={
                                <PageRoute
                                    name={PageName.CREATE_ROLE}
                                    component={PageComponent.CREATE_ROLE}
                                    title={t('META.USERS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.VIEW_ROLE}
                            element={
                                <PageRoute
                                    name={PageName.VIEW_ROLE}
                                    component={PageComponent.VIEW_ROLE}
                                    title={t('META.USERS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.EDIT_ROLE}
                            element={
                                <PageRoute
                                    name={PageName.EDIT_ROLE}
                                    component={PageComponent.EDIT_ROLE}
                                    title={t('META.USERS_MAIN_PAGE')}
                                />
                            }
                        />
                    </Route>
                </Route>

                <Route
                    path={PagePath.REPORTS}
                    element={
                        <PageRoute
                            name={PageName.REPORTS}
                            component={PageComponent.REPORTS}
                            redirectTo={PageName.REPORTS_MAIN}
                            hasPermission={getPermission([Permission.DwhAllReportsAccess, Permission.DwhReportsAccess])}
                        />
                    }
                >
                    <Route
                        path={PagePath.REPORTS_MAIN}
                        element={
                            <PageRoute
                                name={PageName.REPORTS_MAIN}
                                component={PageComponent.REPORTS_MAIN}
                                title={t('META.REPORTS_MAIN_PAGE')}
                            />
                        }
                    />
                </Route>

                <Route
                    path={PagePath.REQUESTS}
                    element={
                        <PageRoute
                            name={PageName.REQUESTS}
                            component={PageComponent.REQUESTS}
                            redirectTo={PageName.ALL_REQUESTS}
                        />
                    }
                >
                    <Route
                        path={PagePath.ALL_REQUESTS}
                        element={
                            <PageRoute
                                name={PageName.ALL_REQUESTS}
                                component={PageComponent.ALL_REQUESTS}
                                redirectTo={PageName.ALL_REQUESTS_MAIN}
                                hasPermission={getPermission([
                                    Permission.AllRequestsAccess,
                                    Permission.IndividualRequestsAccess,
                                ])}
                            />
                        }
                    >
                        <Route
                            path={PagePath.ALL_REQUESTS_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.ALL_REQUESTS_MAIN}
                                    component={PageComponent.ALL_REQUESTS_MAIN}
                                    title={t('META.ALL_REQUESTS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.ALL_REQUESTS_VIEW}
                            element={
                                <PageRoute
                                    name={PageName.ALL_REQUESTS_VIEW}
                                    component={PageComponent.ALL_REQUESTS_VIEW}
                                    title={t('META.ALL_REQUESTS_VIEW_PAGE')}
                                />
                            }
                        />
                    </Route>
                </Route>

                <Route
                    path={PagePath.SETTINGS}
                    element={
                        <PageRoute
                            name={PageName.SETTINGS}
                            component={PageComponent.SETTINGS}
                            redirectTo={PageName.NOTIFICATIONS}
                        />
                    }
                >
                    <Route
                        path={PagePath.NOTIFICATIONS}
                        element={
                            <PageRoute
                                name={PageName.NOTIFICATIONS}
                                component={PageComponent.NOTIFICATIONS}
                                redirectTo={PageName.NOTIFICATIONS_MAIN}
                                hasPermission={getPermission([Permission.NotificationConfigView])}
                            />
                        }
                    >
                        <Route
                            path={PagePath.NOTIFICATIONS_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.NOTIFICATIONS_MAIN}
                                    component={PageComponent.NOTIFICATIONS_MAIN}
                                    title={t('META.NOTIFICATIONS_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.NotificationConfigView])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.NOTIFICATION_CREATE}
                            element={
                                <PageRoute
                                    name={PageName.NOTIFICATION_CREATE}
                                    component={PageComponent.NOTIFICATION_CREATE}
                                    title={t('META.NOTIFICATIONS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.NOTIFICATION_EDIT}
                            element={
                                <PageRoute
                                    name={PageName.NOTIFICATION_EDIT}
                                    component={PageComponent.NOTIFICATION_EDIT}
                                    title={t('META.NOTIFICATIONS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.NOTIFICATION_INFO}
                            element={
                                <PageRoute
                                    name={PageName.NOTIFICATION_INFO}
                                    component={PageComponent.NOTIFICATION_INFO}
                                    hasPermission={getPermission([
                                        Permission.NotificationConfigEdit,
                                        Permission.NotificationConfigView,
                                    ])}
                                />
                            }
                        >
                            <Route
                                path={PagePath.NOTIFICATION_VIEW}
                                element={
                                    <PageRoute
                                        name={PageName.NOTIFICATION_VIEW}
                                        component={PageComponent.NOTIFICATION_VIEW}
                                        title={t('META.NOTIFICATIONS_MAIN_PAGE')}
                                    />
                                }
                            />
                            <Route
                                path={PagePath.NOTIFICATION_CHANGE_HISTORY}
                                element={
                                    <PageRoute
                                        name={PageName.NOTIFICATION_CHANGE_HISTORY}
                                        component={PageComponent.NOTIFICATION_CHANGE_HISTORY}
                                        title={t('META.NOTIFICATIONS_MAIN_PAGE')}
                                        hasPermission={getPermission([Permission.ViewLogHistory])}
                                    />
                                }
                            />
                        </Route>
                    </Route>

                    <Route
                        path={PagePath.LISTS}
                        element={
                            <PageRoute
                                name={PageName.LISTS}
                                component={PageComponent.LISTS}
                                redirectTo={PageName.LISTS_MAIN}
                                hasPermission={getPermission([Permission.ListCreateEditDelete])}
                            />
                        }
                    >
                        <Route
                            path={PagePath.LISTS_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.LISTS_MAIN}
                                    component={PageComponent.LISTS_MAIN}
                                    title={t('META.LISTS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.LIST_CREATE}
                            element={
                                <PageRoute
                                    name={PageName.LIST_CREATE}
                                    component={PageComponent.LIST_CREATE}
                                    title={t('META.LISTS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.LIST_EDIT}
                            element={
                                <PageRoute
                                    name={PageName.LIST_EDIT}
                                    component={PageComponent.LIST_EDIT}
                                    title={t('META.LISTS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.LIST_INFO}
                            element={<PageRoute name={PageName.LIST_INFO} component={PageComponent.LIST_INFO} />}
                        >
                            <Route
                                path={PagePath.LIST_VIEW}
                                element={
                                    <PageRoute
                                        name={PageName.LIST_VIEW}
                                        component={PageComponent.LIST_VIEW}
                                        title={t('META.LISTS_MAIN_PAGE')}
                                    />
                                }
                            />
                            <Route
                                path={PagePath.LIST_UPLOAD_HISTORY}
                                element={
                                    <PageRoute
                                        name={PageName.LIST_UPLOAD_HISTORY}
                                        component={PageComponent.LIST_UPLOAD_HISTORY}
                                        title={t('META.LISTS_MAIN_PAGE')}
                                        hasPermission={getPermission([Permission.ViewLogHistory])}
                                    />
                                }
                            />
                        </Route>
                    </Route>

                    <Route
                        path={PagePath.SHORT_LINKS}
                        element={
                            <PageRoute
                                name={PageName.SHORT_LINKS}
                                component={PageComponent.SHORT_LINKS}
                                redirectTo={PageName.SHORT_LINKS_MAIN}
                                hasPermission={getPermission([Permission.LinkWatch])}
                            />
                        }
                    >
                        <Route
                            path={PagePath.SHORT_LINKS_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.SHORT_LINKS_MAIN}
                                    component={PageComponent.SHORT_LINKS_MAIN}
                                    title={t('META.SHORT_LINKS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.SHORT_LINK_CREATE}
                            element={
                                <PageRoute
                                    name={PageName.SHORT_LINK_CREATE}
                                    component={PageComponent.SHORT_LINK_CREATE}
                                    title={t('META.SHORT_LINKS_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.LinkEdit])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.SHORT_LINK_EDIT}
                            element={
                                <PageRoute
                                    name={PageName.SHORT_LINK_EDIT}
                                    component={PageComponent.SHORT_LINK_EDIT}
                                    title={t('META.SHORT_LINKS_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.LinkEdit])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.SHORT_LINK_VIEW}
                            element={
                                <PageRoute
                                    name={PageName.SHORT_LINK_VIEW}
                                    component={PageComponent.SHORT_LINK_VIEW}
                                    title={t('META.SHORT_LINKS_MAIN_PAGE')}
                                />
                            }
                        />
                    </Route>

                    <Route
                        path={PagePath.SEGMENTS}
                        element={
                            <PageRoute
                                name={PageName.SEGMENTS}
                                component={PageComponent.SEGMENTS}
                                redirectTo={PageName.SEGMENTS_MAIN}
                                hasPermission={getPermission([Permission.ClientSegmentsUpload])}
                            />
                        }
                    >
                        <Route
                            path={PagePath.SEGMENTS_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.SEGMENTS_MAIN}
                                    component={PageComponent.SEGMENTS_MAIN}
                                    title={t('META.SEGMENTS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.SEGMENT_CREATE}
                            element={
                                <PageRoute
                                    name={PageName.SEGMENT_CREATE}
                                    component={PageComponent.SEGMENT_CREATE}
                                    title={t('META.SEGMENTS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.SEGMENT_EDIT}
                            element={
                                <PageRoute
                                    name={PageName.SEGMENT_EDIT}
                                    component={PageComponent.SEGMENT_EDIT}
                                    title={t('META.SEGMENTS_MAIN_PAGE')}
                                />
                            }
                        />
                        <Route
                            path={PagePath.SEGMENT_INFO}
                            element={<PageRoute name={PageName.SEGMENT_INFO} component={PageComponent.SEGMENT_INFO} />}
                        >
                            <Route
                                path={PagePath.SEGMENT_VIEW}
                                element={
                                    <PageRoute
                                        name={PageName.SEGMENT_VIEW}
                                        component={PageComponent.SEGMENT_VIEW}
                                        title={t('META.SEGMENTS_MAIN_PAGE')}
                                    />
                                }
                            />
                            <Route
                                path={PagePath.SEGMENT_UPLOAD_HISTORY}
                                element={
                                    <PageRoute
                                        name={PageName.SEGMENT_UPLOAD_HISTORY}
                                        component={PageComponent.SEGMENT_UPLOAD_HISTORY}
                                        title={t('META.SEGMENTS_MAIN_PAGE')}
                                        hasPermission={getPermission([Permission.ViewLogHistory])}
                                    />
                                }
                            />
                        </Route>
                    </Route>

                    <Route
                        path={PagePath.PAGE_BLOCKING}
                        element={
                            <PageRoute
                                name={PageName.PAGE_BLOCKING}
                                component={PageComponent.PAGE_BLOCKING}
                                redirectTo={PageName.PAGE_BLOCKING_VIEW}
                                hasPermission={getPermission([Permission.BlockingPagesView])}
                            />
                        }
                    >
                        <Route
                            path={PagePath.PAGE_BLOCKING_VIEW}
                            element={
                                <PageRoute
                                    name={PageName.PAGE_BLOCKING_VIEW}
                                    component={PageComponent.PAGE_BLOCKING_VIEW}
                                    title={t('META.PAGE_BLOCKING_VIEW_PAGE')}
                                />
                            }
                        />
                    </Route>
                </Route>

                <Route
                    path={PagePath.PARTNER_OFFERS}
                    element={
                        <PageRoute
                            name={PageName.PARTNER_OFFERS}
                            component={PageComponent.PARTNER_OFFERS}
                            redirectTo={PageName.PARTNER_OFFERS_MAIN}
                            hasPermission={getPermission([Permission.OfferPartnersRead])}
                        />
                    }
                >
                    <Route
                        path={PagePath.PARTNER_OFFERS_OFFERS}
                        element={
                            <PageRoute
                                name={PageName.PARTNER_OFFERS_OFFERS}
                                component={PageComponent.PARTNER_OFFERS_OFFERS}
                                redirectTo={PageName.PARTNER_OFFERS_MAIN}
                                hasPermission={getPermission([Permission.OfferPartnersRead])}
                            />
                        }
                    >
                        <Route
                            path={PagePath.PARTNER_OFFERS_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.PARTNER_OFFERS_MAIN}
                                    component={PageComponent.PARTNER_OFFERS_MAIN}
                                    title={t('META.PARTNER_OFFERS_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.OfferPartnersRead])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.PARTNER_OFFERS_CREATE}
                            element={
                                <PageRoute
                                    name={PageName.PARTNER_OFFERS_CREATE}
                                    component={PageComponent.PARTNER_OFFERS_CREATE}
                                    title={t('META.PARTNER_OFFERS_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.OfferPartnersCreateEdit])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.PARTNER_OFFERS_VIEW}
                            element={
                                <PageRoute
                                    name={PageName.PARTNER_OFFERS_VIEW}
                                    component={PageComponent.PARTNER_OFFERS_VIEW}
                                    title={t('META.PARTNER_OFFERS_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.OfferPartnersRead])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.PARTNER_OFFERS_EDIT}
                            element={
                                <PageRoute
                                    name={PageName.PARTNER_OFFERS_EDIT}
                                    component={PageComponent.PARTNER_OFFERS_EDIT}
                                    title={t('META.PARTNER_OFFERS_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.OfferPartnersCreateEdit])}
                                />
                            }
                        />
                    </Route>
                    <Route
                        path={PagePath.PARTNER_OFFERS_CATEGORIES}
                        element={
                            <PageRoute
                                name={PageName.PARTNER_OFFERS_CATEGORIES}
                                component={PageComponent.PARTNER_OFFERS_CATEGORIES}
                                redirectTo={PageName.PARTNER_OFFERS_CATEGORIES_MAIN}
                                hasPermission={getPermission([Permission.OfferPartnersRead])}
                            />
                        }
                    >
                        <Route
                            path={PagePath.PARTNER_OFFERS_CATEGORIES_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.PARTNER_OFFERS_CATEGORIES_MAIN}
                                    component={PageComponent.PARTNER_OFFERS_CATEGORIES_MAIN}
                                    title={t('META.PARTNER_OFFERS_CATEGORIES_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.OfferPartnersRead])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.PARTNER_OFFERS_CATEGORIES_CREATE}
                            element={
                                <PageRoute
                                    name={PageName.PARTNER_OFFERS_CATEGORIES_CREATE}
                                    component={PageComponent.PARTNER_OFFERS_CATEGORIES_CREATE}
                                    title={t('META.PARTNER_OFFERS_CATEGORIES_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.OfferPartnersCreateEdit])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.PARTNER_OFFERS_CATEGORIES_VIEW}
                            element={
                                <PageRoute
                                    name={PageName.PARTNER_OFFERS_CATEGORIES_VIEW}
                                    component={PageComponent.PARTNER_OFFERS_CATEGORIES_VIEW}
                                    title={t('META.PARTNER_OFFERS_CATEGORIES_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.OfferPartnersRead])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.PARTNER_OFFERS_CATEGORIES_EDIT}
                            element={
                                <PageRoute
                                    name={PageName.PARTNER_OFFERS_CATEGORIES_EDIT}
                                    component={PageComponent.PARTNER_OFFERS_CATEGORIES_EDIT}
                                    title={t('META.PARTNER_OFFERS_CATEGORIES_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.OfferPartnersCreateEdit])}
                                />
                            }
                        />
                    </Route>
                </Route>

                <Route
                    path={PagePath.ACCRUALS}
                    element={
                        <PageRoute
                            name={PageName.ACCRUALS}
                            component={PageComponent.ACCRUALS}
                            redirectTo={PageName.ACCRUALS_REQUESTS_MAIN}
                            hasPermission={getPermission([Permission.RequestsAccrualsRead])}
                        />
                    }
                >
                    <Route
                        path={PagePath.ACCRUALS_ACCRUALS}
                        element={
                            <PageRoute
                                name={PageName.ACCRUALS_ACCRUALS}
                                component={PageComponent.ACCRUALS_ACCRUALS}
                                redirectTo={PageName.ACCRUALS_ACCRUALS_MAIN}
                                hasPermission={getPermission([Permission.AccrualReadAccess])}
                            />
                        }
                    >
                        <Route
                            path={PagePath.ACCRUALS_ACCRUALS_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.ACCRUALS_ACCRUALS_MAIN}
                                    component={PageComponent.ACCRUALS_ACCRUALS_MAIN}
                                    title={t('META.ACCRUALS_ACCRUALS_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.AccrualReadAccess])}
                                />
                            }
                        />
                        <Route
                            path={PagePath.ACCRUALS_ACCRUALS_VIEW}
                            element={
                                <PageRoute
                                    name={PageName.ACCRUALS_ACCRUALS_VIEW}
                                    component={PageComponent.ACCRUALS_ACCRUALS_VIEW}
                                    title={t('META.ACCRUALS_ACCRUALS_MAIN_PAGE')}
                                    hasPermission={getPermission([Permission.AccrualReadAccess])}
                                />
                            }
                        />
                    </Route>
                    <Route
                        path={PagePath.ACCRUALS_REQUESTS}
                        element={
                            <PageRoute
                                name={PageName.ACCRUALS_REQUESTS}
                                component={PageComponent.ACCRUALS_REQUESTS}
                                redirectTo={PageName.ACCRUALS_REQUESTS_MAIN}
                                hasPermission={getPermission([
                                    Permission.RequestsAccrualsRead,
                                    Permission.RequestsAccrualsUpload,
                                ])}
                            />
                        }
                    >
                        <Route
                            path={PagePath.ACCRUALS_REQUESTS_MAIN}
                            element={
                                <PageRoute
                                    name={PageName.ACCRUALS_REQUESTS_MAIN}
                                    component={PageComponent.ACCRUALS_REQUESTS_MAIN}
                                    title={t('META.ACCRUALS_ACCRUALS_MAIN_PAGE')}
                                    hasPermission={getPermission([
                                        Permission.RequestsAccrualsRead,
                                        Permission.RequestsAccrualsUpload,
                                    ])}
                                />
                            }
                        />
                    </Route>
                </Route>

                <Route path={PagePath.NOT_FOUND} element={createElement(PageComponent.NOT_FOUND as ComponentType)} />
            </Route>
        </Routes>
    );
}

export const PageRouter = PageRouterComponent;
