import { sample } from 'effector';
import { updateCategoryVisibilityFx } from '@admin/model/categories/update-category-visibility/update-category-visibility.fx';
import { showNoticeEvent } from '@admin/model/notices';
import { getSuccessMessage } from '@admin/model/categories/update-category-visibility/update-category-visibility.helpers';

sample({
    clock: updateCategoryVisibilityFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
