import { logger } from '@admin/common/helpers/logger';
import { getRequest } from '@admin/model/api';
import { createEffect } from 'effector';
import { CostMatrixUploadErrorData } from '@admin/model/cost-matrix/cost-matrix-upload-errors/cost-matrix-upload-errors.types';

export const getCostMatrixUploadErrorsFx = createEffect(async (uploadId: string) => {
    try {
        return await getRequest<CostMatrixUploadErrorData>(`delivery/matrices/uploads/${uploadId}/errors`);
    } catch (error) {
        logger(error);

        throw error;
    }
});
