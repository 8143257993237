import { logger } from '@admin/common/helpers/logger';
import { setCommonAppError } from '@admin/model/error-notification';
import { putRequest } from '@admin/model/api';
import { createEffect } from 'effector';
import { MatrixItemStatusChangePayload } from '@admin/model/cost-matrix/update-matrix-item-status/update-matrix-item-status.types';
import { MatrixData } from '@admin/model/cost-matrix/cost-matrix.types';

export const updateMatrixItemStatusFx = createEffect(async (payload: MatrixItemStatusChangePayload) => {
    try {
        return await putRequest<MatrixData[], MatrixItemStatusChangePayload>(
            `delivery/matrices/items/batching`,
            payload,
        );
    } catch (error) {
        logger(error);

        setCommonAppError({ error });

        throw null;
    }
});
