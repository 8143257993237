import { combine, createStore } from 'effector';
import { loadRoleFx } from './role.fx';
import { Role } from '../roles.types';

export const $roleData = createStore<Nullable<Role>>(null);
export const $roleError = createStore<Nullable<unknown>>(null);

export const $role = combine({
    data: $roleData,
    error: $roleError,
    isLoading: loadRoleFx.pending,
});
