import {
    StyledButton,
    StyledModalBody,
    StyledModalWrapper,
    StyledTypography,
} from '@admin/components/modal/modal.styles';
import React, { memo, PropsWithChildren, ReactNode, useCallback } from 'react';
import { Button, Modal as DefaultModal, ModalProps, PaperPadding, Typography, TypographyVariant } from 'ui-kit';

type ModalWithoutOnClose = Omit<ModalProps, 'onClose'>;

export interface ModalInfoProps extends ModalWithoutOnClose {
    title: string;
    bodyText?: ReactNode;
    confirmButtonText: string;
    cancelButtonText?: string;
    disabled?: boolean;
    disabledConfirm?: boolean;
    disabledCancel?: boolean;
    className?: string;
    onDismiss?(): void;
    onCancel?(): void;
    onConfirm(): void;
}

const ModalComponent = ({
    title,
    bodyText,
    confirmButtonText,
    cancelButtonText,
    disabled,
    disabledConfirm,
    disabledCancel,
    isOpen,
    className,
    onConfirm,
    onCancel,
    onDismiss,
    children,
}: PropsWithChildren<ModalInfoProps>) => {
    const handleClose = useCallback(() => {
        if (onCancel !== undefined) {
            onCancel();

            return;
        }
        onConfirm?.();
    }, [onConfirm, onCancel]);

    return (
        <DefaultModal isOpen={isOpen} onClose={onDismiss || handleClose} className={className}>
            <StyledModalWrapper padding={PaperPadding.extraLarge}>
                <Typography variant={TypographyVariant.Heading5Night} element="h5">
                    {title}
                </Typography>
                <StyledModalBody>
                    {bodyText !== undefined ? (
                        <StyledTypography variant={TypographyVariant.Body2Night}>{bodyText}</StyledTypography>
                    ) : (
                        children
                    )}
                </StyledModalBody>
                <Button
                    onClick={onConfirm}
                    primary
                    responsive
                    disabled={disabled || disabledConfirm}
                    data-test-id="modal-confirm-button"
                >
                    {confirmButtonText}
                </Button>
                {cancelButtonText !== undefined && (
                    <StyledButton
                        disabled={disabled || disabledCancel}
                        onClick={onCancel}
                        responsive
                        data-test-id="modal-cancel-button"
                    >
                        {cancelButtonText}
                    </StyledButton>
                )}
            </StyledModalWrapper>
        </DefaultModal>
    );
};

export const Modal = memo(ModalComponent);
