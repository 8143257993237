import { ValidationError } from '@admin/common/validation';

export enum ListField {
    Id = 'id',
    Name = 'name',
    ListType = 'listType',
    HasUploadsError = 'hasUploadsError',
    LastUploadId = 'lastUploadId',
    UpdatedAt = 'updatedAt',
    File = 'file',
    FileName = 'fileName',
}

export interface ListData {
    [ListField.Id]: string;
    [ListField.Name]: string;
    [ListField.ListType]: string;
    [ListField.HasUploadsError]: boolean;
    [ListField.LastUploadId]: string;
    [ListField.UpdatedAt]: string;
    [ListField.FileName]: string;
}

export interface ListPayload {
    [ListField.Id]?: string;
    [ListField.Name]?: string;
    [ListField.ListType]?: string;
}

export interface List extends Omit<ListData, ListField.UpdatedAt> {
    [ListField.UpdatedAt]?: Date;
}

export enum ListValidationErrorCode {
    MissingRequiredField = 'MISSING_REQUIRED_FIELD',
    NameAlreadyTaken = 'NAME_ALREADY_TAKEN',
    ListFileSizeLimit = 'LIST_FILE_SIZE_LIMIT',
    ListFormatNotValid = 'LIST_FORMAT_NOT_VALID',
    ListStructureNotValid = 'LIST_STRUCTURE_NOT_VALID',
    SizeExceeded = 'SIZE_EXCEEDED',
    FileCantBeEmpty = 'FILE_CANT_BE_EMPTY',
}

export type ListValidationError = ValidationError<ListValidationErrorCode>;
