import { DictionaryBaseItem, DictionaryData } from '@admin/model/model.types';
import { NotificationsGroupsDictionary } from '@admin/model/notifications/notifications-groups-dictionary/notifications-groups-dictionary.types';

export const parseData = (NotificationsGroupsDictData: DictionaryData): NotificationsGroupsDictionary =>
    NotificationsGroupsDictData.dictionaryValueList.reduce<NotificationsGroupsDictionary>(
        (acc, NotificationsGroupsData) => {
            const parsed = JSON.parse(NotificationsGroupsData.value) as DictionaryBaseItem;
            acc.array.push(parsed);
            acc.dictionary[parsed.code] = parsed;
            return acc;
        },
        {
            array: [],
            dictionary: {},
        },
    );
