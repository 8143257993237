import { forward } from 'effector';
import { loadRolesFx } from './roles.fx';
import { $rolesData, $rolesError } from './roles.store';

forward({
    from: loadRolesFx.doneData,
    to: $rolesData,
});

forward({
    from: loadRolesFx.failData,
    to: $rolesError,
});
