import { getRequest, pollRequest, postRequest } from '@admin/model/api';
import { RequestWithPollingResponse } from '@admin/model/model.types';
import { ClientData } from '@admin/model';
import { logger } from '@admin/common/helpers/logger';
import { ValidationResponseError } from '@admin/common/validation';
import { createEffect } from 'effector';

export const resetClientPasswordFx = createEffect(async (clientId: string) => {
    try {
        const response = await postRequest<RequestWithPollingResponse, {}>(`clients/${clientId}/password`);
        await pollRequest<ClientData>(() => getRequest(`clients/processing/${response.processId}`));

        return true;
    } catch (error) {
        logger(error);

        throw (error as ValidationResponseError)?.data;
    }
});
