import { postRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { createEffect } from 'effector';
import { AccrualsRequestsResponse } from '@admin/model/accruals/accruals.types';
import { AccrualsRequestsPayload } from '@admin/pages/accruals/requests/requests.types';

export const getAccrualsRequestsFx = createEffect(async (payload: AccrualsRequestsPayload) => {
    try {
        const { data, totalCount }: AccrualsRequestsResponse<string> = await postRequest(
            'accruals/uploads/search',
            payload,
        );
        const accrualsRequests: AccrualsRequestsResponse<Date> = {
            data: data.map((upload) => ({
                ...upload,
                uploadAt: new Date(upload.uploadAt),
            })),
            totalCount,
        };

        return accrualsRequests;
    } catch (error) {
        logger(error);

        throw error;
    }
});
