import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { getRequest } from '@admin/model/api';
import { UsersData, UsersPayload } from '@admin/model/users/user.types';

export const getUsersListFx = createEffect(async (payload: UsersPayload) => {
    try {
        return await getRequest<UsersData>('employees', payload);
    } catch (error) {
        logger(error);

        throw error;
    }
});
