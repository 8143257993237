import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { getSuccessMessage } from '@admin/model/lists/update-list/update-list.helpers';
import { updateListFx } from '@admin/model/lists/update-list/update-list.fx';

sample({
    clock: updateListFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
