import { combine, createStore } from 'effector';
import { getTargetAudienceFx } from '@admin/model/target-audience/get-target-audience.fx';
import { TargetAudienceState } from '@admin/model/target-audience/target-audience.types';

const initialState: TargetAudienceState = { targetAudience: [], targetAudienceMap: {}, hasData: false };

export const $targetAudienceData = createStore<TargetAudienceState>(initialState);
export const $targetAudienceError = createStore<Nullable<unknown>>(null);

export const $targetAudience = combine({
    data: $targetAudienceData,
    error: $targetAudienceError,
    isLoading: getTargetAudienceFx.pending,
});
