import { sample } from 'effector';
import { getCategoriesListFx } from '@admin/model/categories/categories-list/get-categories-list.fx';
import { updateRootCategoryFx } from '@admin/model/categories/categories-list/update-root-category.fx';
import { updateChildCategoriesFx } from '@admin/model/categories/categories-list/update-child-categories.fx';
import {
    $isLoadAllCategoriesDoneData,
    $isLoadRootCategoriesDoneData,
    resetCategoriesListEvent,
    resetRootCategoriesLoadingDataEvent,
    $categoriesListError,
    $flatCategories,
    $categories,
    $areAllRowsExpanded,
    expandAllRowsEvent,
    collapseAllRowsEvent,
} from '@admin/model/categories/categories-list/categories-list.store';
import { reset } from 'patronum';
import { Category } from '@admin/model/categories/category/category.types';
import { updateCategoriesCurrencyFx } from '@admin/model/categories/update-categories-currency/update-categories-currency.fx';
import {
    getStateWithChildCategories,
    replaceCategory,
    getUpdatedRootCategoryState,
    getFilteredByCurrencyState,
} from '@admin/model/categories/categories-list/categories-list.helpers';
import { updateCategoryVisibilityFx } from '@admin/model/categories/update-category-visibility/update-category-visibility.fx';
import { updateCategoriesStatusFx } from '@admin/model/categories/update-categories-status/update-categories-status.fx';

sample({
    clock: getCategoriesListFx.doneData,
    fn: ({ categories }) => categories,
    target: $categories,
});

sample({
    clock: updateChildCategoriesFx.doneData,
    source: $categories,
    fn: (state, result) => getStateWithChildCategories(state, result),
    target: $categories,
});

sample({
    clock: updateRootCategoryFx.doneData,
    source: $categories,
    fn: (state, { data, supplierId, currenciesFilterValue }) =>
        getUpdatedRootCategoryState(state, data, supplierId, currenciesFilterValue),
    target: $categories,
});

sample({
    clock: updateCategoryVisibilityFx.doneData,
    source: $categories,
    fn: (state, result) => replaceCategory(state, result),
    target: $categories,
});

sample({
    clock: updateCategoriesCurrencyFx.doneData,
    source: $categories,
    fn: (state, { categoryIds, currenciesFilterValue, currenciesToDelete }) =>
        getFilteredByCurrencyState(state, categoryIds, currenciesFilterValue, currenciesToDelete),
    target: $categories,
});

sample({
    clock: updateCategoriesStatusFx.doneData,
    source: $categories,
    fn: (state, { categoryIds, showInactive }) =>
        showInactive === false ? state.filter((category) => category.id !== categoryIds[0]) : state,
    target: $categories,
});

sample({
    clock: getCategoriesListFx.doneData,
    fn: ({ categories }) => categories,
    target: $flatCategories,
});

sample({
    clock: updateChildCategoriesFx.doneData,
    source: $flatCategories,
    fn: (state, result) => {
        const flatNewCategories = result.reduce((acc, item) => {
            return [...acc, ...item.data];
        }, [] as Category[]);
        return state.concat(flatNewCategories);
    },
    target: $flatCategories,
});

sample({
    clock: updateCategoriesCurrencyFx.doneData,
    source: $flatCategories,
    fn: (state, { categoryIds, currenciesFilterValue, currenciesToDelete }) =>
        getFilteredByCurrencyState(state, categoryIds, currenciesFilterValue, currenciesToDelete),
    target: $flatCategories,
});

sample({
    clock: [getCategoriesListFx.failData, updateRootCategoryFx.failData, updateChildCategoriesFx.failData],
    target: $categoriesListError,
});

sample({
    clock: [getCategoriesListFx.done, updateRootCategoryFx.done, updateChildCategoriesFx.done],
    fn: () => null,
    target: $categoriesListError,
});

sample({
    clock: getCategoriesListFx.doneData,
    fn: ({ shouldExpandAll }) => Boolean(shouldExpandAll),
    target: $isLoadAllCategoriesDoneData,
});

sample({
    clock: getCategoriesListFx.done,
    fn: () => true,
    target: $isLoadRootCategoriesDoneData,
});

sample({
    clock: getCategoriesListFx.doneData,
    filter: ({ shouldExpandAll }) => Boolean(shouldExpandAll),
    target: [resetRootCategoriesLoadingDataEvent, expandAllRowsEvent],
});

sample({
    clock: expandAllRowsEvent,
    fn: () => true,
    target: $areAllRowsExpanded,
});

sample({
    clock: collapseAllRowsEvent,
    fn: () => false,
    target: $areAllRowsExpanded,
});

reset({
    clock: resetCategoriesListEvent,
    target: [$isLoadAllCategoriesDoneData, $flatCategories, $categories],
});

reset({
    clock: resetRootCategoriesLoadingDataEvent,
    target: [$isLoadRootCategoriesDoneData],
});
