import { forward, sample } from 'effector';
import { reset } from 'patronum';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { deleteSegmentFx } from '@admin/model/segments/delete-segment/delete-segment.fx';
import { getSuccessMessage } from '@admin/model/segments/delete-segment/delete-segment.helpers';
import {
    $isSegmentDeleted,
    resetDeleteSegmentDoneStatusEvent,
} from '@admin/model/segments/delete-segment/delete-segment.store';
import { getSegmentListFx } from '@admin/model';

sample({
    source: deleteSegmentFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});

sample({
    clock: deleteSegmentFx.done,
    fn: () => true,
    target: $isSegmentDeleted,
});

forward({
    from: deleteSegmentFx.done,
    to: getSegmentListFx,
});

reset({
    clock: resetDeleteSegmentDoneStatusEvent,
    target: $isSegmentDeleted,
});
