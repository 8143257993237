import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { DictionaryData } from '@admin/model/model.types';
import { parseData } from '@admin/model/clients-and-profiles/transaction-status/transaction-status.helpers';
import { ExpirationTransactionStatus } from '@admin/model/clients-and-profiles/expirations/expirations.types';

export const getTransactionStatusFx = createEffect(async () => {
    try {
        const transactionStatuses = await getRequest<DictionaryData>('dictionaries/transaction_status');
        return parseData<ExpirationTransactionStatus>(transactionStatuses);
    } catch (error) {
        logger(error);

        throw error;
    }
});
