import { DictionaryData } from '@admin/model/model.types';
import { SegmentsValue, SegmentWithValue } from '@admin/model/segments/segments.types';
import { normalizeDate } from '@admin/model/helpers';

const skipSegmentList = ['Prime', 'Mass', 'Light', 'Priv'];

export const parseData = (segmentsData: DictionaryData): SegmentWithValue[] =>
    segmentsData.dictionaryValueList
        .map((segment) => {
            const parsed = JSON.parse(segment.value) as SegmentsValue;

            return {
                ...parsed,
                key: segment.key,
                createdAt: normalizeDate(parsed.createdAt ?? null),
            };
        })
        .filter((segment) => !skipSegmentList.includes(segment.description));
