import { sample } from 'effector';
import { getOrderStatusDictionaryFx } from '@admin/model/orders/order-status-dictionary/get-order-status-dictionary.fx';
import {
    $orderStatusDictionaryData,
    $orderStatusDictionaryError,
} from '@admin/model/orders/order-status-dictionary/order-status-dictionary.store';
import { parseData } from '@admin/model/orders/orders.helpers';
import { OrderStatus } from '@admin/model/model.types';

sample({
    clock: getOrderStatusDictionaryFx.doneData,
    fn: (result) => parseData<OrderStatus>(result),
    target: $orderStatusDictionaryData,
});

sample({
    clock: getOrderStatusDictionaryFx.failData,
    target: $orderStatusDictionaryError,
});
