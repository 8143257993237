import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { mapAccrual } from '@admin/model/accruals/accruals.mappers';
import { AccrualData } from '@admin/model/accruals/accruals.types';

export const getAccrualFx = createEffect(async (id: string) => {
    try {
        const response = await getRequest<AccrualData>(`accruals/${id}`);
        if (response === null) return null;
        return mapAccrual(response);
    } catch (error) {
        logger(error);

        throw error;
    }
});
