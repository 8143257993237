import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import { createEffect } from 'effector';
import {
    CreateTrackNumberPayload,
    CreateTrackNumberValidationErrorCode,
} from '@admin/model/orders/create-track-number/create-track-number.types';
import { TrackNumber } from '@admin/model/orders/orders.types';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { setCommonAppError } from '@admin/model/error-notification';

export const createTrackNumberFx = createEffect(async ({ orderId, ...rest }: CreateTrackNumberPayload) => {
    try {
        await postRequest<{}, TrackNumber>(`orders/${orderId}/track`, rest);
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, CreateTrackNumberValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
