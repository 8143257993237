import { SupplierPartnerType } from '@admin/model/suppliers/suppliers.types';

export enum ViewFieldComponentFieldName {
    PublicWebsite = 'publicWebsite',
    RangeCoefficient = 'rangeCoefficient',
    PublicEmail = 'publicEmail',
    DownloadCatalogUrl = 'downloadCatalogURL',
    OrdersCheckUrl = 'ordersCheckUrl',
    OrdersConfirmUrl = 'ordersConfirmUrl',
    OrderStatusNotificationUrl = 'orderStatusNotificationUrl',
    BonusPaymentGateId = 'bonusPaymentGateId',
    CardPaymentGateId = 'cardPaymentGateId',
    AvailablePartCardPayment = 'availablePartCardPayment',
    DeliveryMatrixDownloadUrl = 'deliveryMatrixDownloadUrl',
    DeliveryFixedCost = 'deliveryFixedCost',
    DeliveryOptionsUrl = 'deliveryOptionsUrl',
    ReturnURL = 'returnURL',
    CancelOrderTimeLimit = 'cancelOrderTimeLimit',
    OfferFile = 'offerFile',
    PublicKey = 'publicKey',
    SetStatusAfterConfirmation = 'setStatusAfterConfirmation',
    Alias = 'alias',
    Fiscalization = 'fiscalization',
    FiscalizationGateId = 'fiscalizationGateId',
    SupplierINN = 'supplierINN',
    SupplierName = 'supplierName',
    FiscalizationType = 'fiscalizationType',
    Vat = 'vat',
    CertThumbprint = 'certThumbprint',
    PrivateKeyNameRS = 'rsSslPrivateKeyFileName',
    PublicKeyRS = 'rsSslPublicKey',
    PrivateKeyRS = 'rsSslPrivateKey',
}

export enum InputFieldName {
    PublicWebsite = 'publicWebsite',
    PublicEmail = 'publicEmail',
    DownloadCatalogUrl = 'downloadCatalogURL',
    OrdersCheckUrl = 'ordersCheckUrl',
    OrdersConfirmUrl = 'ordersConfirmUrl',
    OrderStatusNotificationUrl = 'orderStatusNotificationUrl',
    CardPaymentGateId = 'cardPaymentGateId',
    DeliveryMatrixDownloadUrl = 'deliveryMatrixDownloadUrl',
    DeliveryOptionsUrl = 'deliveryOptionsUrl',
    ReturnURL = 'returnURL',
    BonusPaymentGateId = 'bonusPaymentGateId',
    MobileBonusPaymentGateId = 'mobileBonusPaymentGateId',
    Alias = 'alias',
    FiscalizationGateId = 'fiscalizationGateId',
    SupplierName = 'supplierName',
    PrivateKeyNameRS = 'rsSslPrivateKeyFileName',
}

export enum NumberInputFieldName {
    CancelOrderTimeLimit = 'cancelOrderTimeLimit',
    AvailablePartCardPayment = 'availablePartCardPayment',
    RangeCoefficient = 'rangeCoefficient',
    DeliveryFixedCost = 'deliveryFixedCost',
    SupplierINN = 'supplierINN',
}

export enum LogoUploadFieldName {
    Logo = 'logo',
}

export enum FileUploadFieldName {
    OfferFile = 'offerFile',
    CertThumbprint = 'certThumbprint',
    PublicKey = 'publicKey',
    PublicKeyRS = 'rsSslPublicKey',
    PrivateKeyRS = 'rsSslPrivateKey',
}

export enum CheckboxGroupFieldName {
    CashbackCurrency = 'cashbackCurrency',
}

export enum InputsArrayFieldName {
    PublicPhones = 'publicPhones',
    ErrorEmail = 'errorEmail',
    OrdersReturnEmails = 'ordersReturnEmails',
    ContactNumbers = 'contactNumbers',
    OrdersReportEmails = 'ordersReportEmails',
    RetailPoints = 'retailPoints',
    LowCouponEmails = 'lowCouponEmails',
}

export enum RadioGroupFieldName {
    Confidence = 'confidence',
    DeliveryCalculationType = 'deliveryCalculationType',
    LevelAccessAPIPosOrderGateway = 'levelAccessAPIPosOrderGateway',
    ProgramLoyalty = 'programLoyalty',
    CancelOrdersActions = 'overdueOrdersAction',
}

export enum CheckboxFieldName {
    MandatoryOrderEmail = 'mandatoryOrderEmail',
    EnableReturn = 'enableReturn',
    DeliveryPartialPayment = 'deliveryPartialPayment',
}

export enum SelectFieldName {
    SetStatusAfterConfirmation = 'setStatusAfterConfirmation',
    FiscalizationType = 'fiscalizationType',
    Vat = 'vat',
    OtpProvider = 'otpProvider',
    PayAttribute = 'payAttribute',
    LineAttribute = 'lineAttribute',
    BankPaymentType = 'bankPaymentType',
    BonusCurrencyPayment = 'bonusCurrencyPayment',
}

export enum PaymentTypeSelectFieldName {
    BonusPaymentGate = 'bonusPaymentGate',
    ExtraPaymentGate = 'extraPaymentGate',
}

export enum TextAreaFieldName {
    DeliveryResultsMapping = 'deliveryResultsMapping',
}

export enum ToggleFieldName {
    Fiscalization = 'fiscalization',
    CancelOrders = 'cancellationAvailable',
    EnableCouponActivationURL = 'enableCouponActivationURL',
    ShowOrderPreview = 'showOrderPreview',
    DownloadCouponsAvailable = 'downloadCouponsAvailable',
}

export type ConstructorComponentFieldName =
    | InputFieldName
    | NumberInputFieldName
    | CheckboxGroupFieldName
    | RadioGroupFieldName
    | CheckboxFieldName
    | SelectFieldName
    | LogoUploadFieldName
    | FileUploadFieldName
    | InputsArrayFieldName
    | PaymentTypeSelectFieldName
    | ToggleFieldName;

export enum GroupName {
    Orders = 'orders',
    Common = 'common',
    ClientNotifications = 'clientNotifications',
    PartnerNotifications = 'partnerNotifications',
    Payment = 'payment',
    OnlinePartnerOptions = 'onlinePartnerOptions',
    Reward = 'reward',
    Delivery = 'delivery',
    SecuritySettings = 'securitySettings',
}

export enum PaymentGate {
    Uniteller = 'UNITELLER',
    Payture = 'PAYTURE',
    NoNeedPayment = 'NO_NEED_PAYMENT',
}

export enum CalculationType {
    FixedCost = 'FIXED_COST',
    DeliveryMatrixCalculation = 'DELIVERY_MATRIX',
    ExternalApi = 'EXTERNAL_API',
}

export enum LevelAccessAPIPosOrderGateway {
    Full = 'FULL',
    Limited = 'LIMITED',
}

export enum CancelOrdersAction {
    Cancel = 'CANCEL',
    Confirm = 'CONFIRM',
}

interface FormGroupConfig {
    group: GroupName;
    settings: ConstructorComponentFieldName[];
}

export type SupplierFormConfig = FormGroupConfig[];

export interface SupplierFormConfigsData {
    dictionaryValueList: {
        key: SupplierPartnerType;
        value: string;
    }[];
}

export interface ConfigItem {
    config: SupplierFormConfig;
    fields: ConfigFields;
}

type ConfigFields = {
    [key in ConstructorComponentFieldName]?: string;
};

export type SupplierFormConfigs = {
    [key in SupplierPartnerType]?: ConfigItem;
};
