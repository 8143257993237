import { logger } from '@admin/common/helpers/logger';
import { deleteRequest, putRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import {
    CategoriesVisibilityUpdatePayload,
    CategoryVisibilityValidationErrorCode,
} from '@admin/model/categories/update-category-visibility/update-category-visibility.types';
import { Category } from '@admin/model/categories/category/category.types';
import { createEffect } from 'effector';

export const updateCategoryVisibilityFx = createEffect(async ({ id, isHidden }: CategoriesVisibilityUpdatePayload) => {
    try {
        const method = isHidden ? putRequest : deleteRequest;

        const data = await method<Category, {}>(`catalog/categories/${id}/hidden`, {});

        return data;
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, CategoryVisibilityValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
