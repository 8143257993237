import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { PermissionsPayload, PermissionData } from '@admin/model/roles/permissions/permissions.types';
import { getSortedData } from '@admin/model/roles/permissions/permissions.helpers';
import { setCommonAppError } from '@admin/model/error-notification/error-notification.store';

export const getPermissionsFx = createEffect(async (payload: PermissionsPayload) => {
    try {
        const data = await getRequest<PermissionData[]>('permissions', payload);

        return getSortedData(data);
    } catch (error) {
        logger(error);

        setCommonAppError({ error });

        throw null;
    }
});
