import { getRequest, pollRequest, postRequest } from '@admin/model/api';
import { RequestWithPollingResponse } from '@admin/model/model.types';
import { ClientData, setCommonAppError } from '@admin/model';
import { logger } from '@admin/common/helpers/logger';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { createEffect } from 'effector';
import {
    ClientDisableAccessPayload,
    ClientDisableAccessValidationErrorCode,
} from '@admin/model/clients-and-profiles/client-disable-access/client-disable-access.types';

export const clientDisableAccessFx = createEffect(
    async ({ clientId, ...payload }: ClientDisableAccessPayload & { clientId: string }) => {
        try {
            const response = await postRequest<RequestWithPollingResponse, ClientDisableAccessPayload>(
                `clients/${clientId}/disableAccess`,
                payload,
            );
            await pollRequest<ClientData>(() => getRequest(`clients/processing/${response.processId}`));

            return true;
        } catch (error) {
            logger(error);

            if (isKnownValidationErrorResponse(error, ClientDisableAccessValidationErrorCode)) {
                throw error.data;
            } else {
                setCommonAppError({ error });

                throw null;
            }
        }
    },
);
