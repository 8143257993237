import { sample } from 'effector';
import { deleteCharacteristicFx } from '@admin/model/characteristics/delete-characteristic/delete-characteristic.fx';
import { getSuccessMessage } from '@admin/model/characteristics/delete-characteristic/delete-characteristic.helpers';
import { showNoticeEvent } from '@admin/model/notices';
import {
    $deleteCharacteristicDone,
    resetDeleteCharacteristicDoneStatusEvent,
} from '@admin/model/characteristics/delete-characteristic/delete-characteristic.store';
import { reset } from 'patronum';

sample({
    source: deleteCharacteristicFx.doneData,
    fn: (payload) => getSuccessMessage(payload),
    target: showNoticeEvent,
});

sample({
    source: deleteCharacteristicFx.done,
    fn: () => true,
    target: $deleteCharacteristicDone,
});

reset({ clock: resetDeleteCharacteristicDoneStatusEvent, target: [$deleteCharacteristicDone] });
