import { combine, createEvent, createStore } from 'effector';
import { getProductCharacteristicsFx } from '@admin/model/products/product-characteristics/get-product-characteristics.fx';
import { OfferCharacteristic } from '@admin/model/offers/offers.types';

export const resetProductCharacteristicsEvent = createEvent();

export const $productCharacteristicsData = createStore<OfferCharacteristic[]>([]);
export const $productCharacteristicsIds = createStore<Set<string>>(new Set());
export const $productCharacteristicsError = createStore<Nullable<unknown>>(null);

export const $productCharacteristics = combine({
    data: $productCharacteristicsData,
    productCharacteristicsIds: $productCharacteristicsIds,
    error: $productCharacteristicsError,
    isLoading: getProductCharacteristicsFx.pending,
});
