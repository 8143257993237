import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { DictionaryData } from '@admin/model/model.types';
import { parseData } from '@admin/model/notifications/notifications-groups-dictionary/notifications-groups-dictionary.helpers';

export const getNotificationsGroupsDictionaryFx = createEffect(async () => {
    try {
        const data = await getRequest<DictionaryData>('dictionaries/NOTIFICATION_GROUPS');

        return parseData(data);
    } catch (error) {
        logger(error);

        throw error;
    }
});
