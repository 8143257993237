import type { ValidationError } from '@admin/common/validation';

export interface UploadCouponCodeData {
    uploadId: string;
}

export enum UploadCouponCodesValidationErrorCode {
    ListFormatNotValid = 'LIST_FORMAT_NOT_VALID',
    ListFileSizeLimit = 'LIST_FILE_SIZE_LIMIT',
}

export type UploadCouponCodesValidationError = ValidationError<UploadCouponCodesValidationErrorCode>;
