import { logger } from '@admin/common/helpers/logger';
import { putRequest } from '@admin/model/api';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { setCommonAppError } from '@admin/model/error-notification';
import { createEffect } from 'effector';
import { EditRolePayload, RoleValidationErrorCode } from '../roles.types';

export const editRoleFx = createEffect(async (payload: EditRolePayload) => {
    try {
        await putRequest(`roles`, payload);
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, RoleValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
