import { normalizeDate } from '@admin/model/helpers';
import { User, UserData } from '@admin/model/users/user.types';

export const normalizeUserData = (data: UserData): User => ({
    ...data,
    roles: data.roles.map((role) => ({
        ...role,
        createdAt: normalizeDate(role.createdAt),
    })),
    registrationDate: new Date(data.registrationDate),
    reports: data.reports.map((report) => report.reportCode),
});
