import { combine, createStore } from 'effector';
import { getUsersListFx } from '@admin/model/users/users-list/get-users-list.fx';
import { UserState } from '@admin/model/users/users-list/users-list.types';

const initialState = { data: [], totalCount: 0 };

export const $usersListData = createStore<UserState>(initialState);
export const $usersListError = createStore<Nullable<unknown>>(null);

export const $usersList = combine({
    data: $usersListData,
    error: $usersListError,
    isLoading: getUsersListFx.pending,
});
