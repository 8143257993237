import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { Category, CategoryType } from '@admin/model/categories/category/category.types';
import { CollectionsPayload } from '@admin/model/collections/collections-list/collections-list.types';

export const getCollectionsListFx = createEffect(async (payload: CollectionsPayload) => {
    try {
        return await getRequest<Category[]>('catalog/categories', {
            ...payload,
            calculateProductAmount: true,
            categoryType: [CategoryType.CollectionCategory],
        });
    } catch (error) {
        logger(error);

        throw error;
    }
});
