import { logger } from '@admin/common/helpers/logger';
import { putRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { CategoryValidationErrorCode } from '@admin/model/categories/category/category.types';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { EditCategoryPayload } from '@admin/model/categories/edit-category/edit-category.types';
import { createEffect } from 'effector';

export const editCategoryFx = createEffect(async ({ formData, editableCategoryId }: EditCategoryPayload) => {
    try {
        await putRequest(`catalog/categories/${editableCategoryId}`, formData);
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, CategoryValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
