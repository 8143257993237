import { sample } from 'effector';
import { reset } from 'patronum';
import { $supplierData, $supplierError, resetSupplierEvent } from '@admin/model/suppliers/supplier/supplier.store';
import { getSupplierFx } from '@admin/model/suppliers/supplier/get-supplier.fx';
import { getNormalizedData } from '@admin/model/suppliers/supplier/supplier.helpers';

sample({
    clock: getSupplierFx.doneData,
    fn: (result) => getNormalizedData(result),
    target: $supplierData,
});

sample({
    clock: getSupplierFx.failData,
    target: $supplierError,
});

reset({ clock: resetSupplierEvent, target: [$supplierData] });
