import { normalizeImages } from '@admin/common/helpers';
import { mapCategoriesFromPathsData } from '@admin/model/helpers';
import { ProductListItemData, ProductListItem } from '@admin/model/products/products.types';

export const normalizeProductsData = (productsData: ProductListItemData[]) =>
    productsData.map((productListItemData): ProductListItem => {
        const {
            id,
            name,
            image,
            categoryPaths,
            categoryIds,
            synchronized,
            offerAmount,
            productCharacteristics,
            optionalProductCharacteristics,
            ignoreOfferCategory,
        } = productListItemData;

        return {
            id,
            name,
            images: normalizeImages([image]),
            categories: mapCategoriesFromPathsData(categoryPaths, categoryIds),
            categoryPaths,
            synchronized,
            offerAmount,
            productCharacteristics,
            optionalProductCharacteristics,
            ignoreOfferCategory,
        };
    });
