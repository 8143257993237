import { combine, createStore } from 'effector';
import { getSegmentFx } from '@admin/model/segments/segment/get-segment.fx';
import { SegmentWithValue } from '@admin/model';

export const $segmentData = createStore<Nullable<SegmentWithValue>>(null);
export const $segmentError = createStore<Nullable<unknown>>(null);

export const $segment = combine({
    data: $segmentData,
    error: $segmentError,
    isLoading: getSegmentFx.pending,
});
