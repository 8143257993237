import { combine, createEvent, createStore } from 'effector';
import { deleteSegmentFx } from '@admin/model/segments/delete-segment/delete-segment.fx';

export const resetDeleteSegmentDoneStatusEvent = createEvent();
export const $isSegmentDeleted = createStore<boolean>(false);

export const $deleteSegment = combine({
    data: $isSegmentDeleted,
    isLoading: deleteSegmentFx.pending,
});
