import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { DictionaryData } from '@admin/model/model.types';

export const getAggregatedPaymentStatusDictionaryFx = createEffect(async () => {
    try {
        const data = await getRequest<DictionaryData>('dictionaries/aggregated_payment_status');

        return data;
    } catch (error) {
        logger(error);

        throw error;
    }
});
