import { sample } from 'effector';
import {
    $clientAccountData,
    $clientAccountError,
    resetClientAccountDataEvent,
    resetClientAccountErrorEvent,
} from '@admin/model/clients-and-profiles/client-account/client-account.store';
import { getClientAccountFx } from '@admin/model/clients-and-profiles/client-account/get-client-account.fx';
import { mapClientAccount } from '@admin/model/clients-and-profiles/client-account/client-account.helpers';
import { reset } from 'patronum';

sample({
    clock: getClientAccountFx.doneData,
    fn: (result) => mapClientAccount(result),
    target: $clientAccountData,
});

sample({
    clock: getClientAccountFx.failData,
    target: $clientAccountError,
});

reset({ clock: resetClientAccountDataEvent, target: $clientAccountData });
reset({ clock: resetClientAccountErrorEvent, target: $clientAccountError });
