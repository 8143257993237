import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { getRequest } from '@admin/model/api';
import { UserData } from '@admin/model/users/user.types';

export const getUserFx = createEffect(async (id: string) => {
    try {
        return await getRequest<UserData>(`employees/${id}`);
    } catch (error) {
        logger(error);

        throw error;
    }
});
