import { normalizeImages } from '@admin/common/helpers';
import { mapCategoriesFromPathsData, normalizeDate } from '@admin/model/helpers';
import {
    AttributesData,
    Attributes,
    OfferData,
    Offer,
    Product,
    SourceCharacteristic,
} from '@admin/model/offers/offers.types';

const mapAttributesData = (attributes: AttributesData): Attributes => ({
    sendCodesWithMultibonus: attributes.send_codes_with_mb ? attributes.send_codes_with_mb === 'true' : undefined,
    deliveryInfo: attributes.delivery_info,
    priceWithoutDiscount:
        attributes.price_without_discount !== null
            ? Number(attributes.price_without_discount)
            : attributes.price_without_discount,
    forbidPriceChange: attributes.forbid_price_change === 'true',
    deliverySettings: attributes.delivery_settings,
    autoPayment: attributes.auto_payment === 'true',
    minPrice: attributes.min_price !== undefined ? Number(attributes.min_price) : attributes.min_price,
    maxPrice: attributes.max_price !== undefined ? Number(attributes.max_price) : attributes.max_price,
    priceInfo: attributes.price_info,
});

const normalizeProductsData = (productsData: Product[]) =>
    productsData.map((product) => ({
        id: product.id,
        name: product.name,
        images: normalizeImages(product.images),
        categories: mapCategoriesFromPathsData(product.categoryPaths, product.categoryIds),
        categoryPaths: product.categoryPaths,
        synchronized: product.synchronized,
        offerAmount: product.offerAmount,
        productCharacteristics: product.productCharacteristics,
        optionalProductCharacteristics: product.optionalProductCharacteristics,
        ignoreOfferCategory: product.ignoreOfferCategory,
    }));

const normalizeSourceCharacteristics = (sourceCharacteristics: { [key: string]: string }) => {
    const normalizedCharacteristics: SourceCharacteristic[] = [];

    for (const characteristic of Object.keys(sourceCharacteristics)) {
        let fields: {
            value: string;
            unit?: string;
        };

        try {
            fields = JSON.parse(sourceCharacteristics[characteristic]);
        } catch (e) {
            fields = { value: sourceCharacteristics[characteristic] };
        }

        normalizedCharacteristics.push({
            name: characteristic,
            unit: fields.unit,
            value: fields.value,
        });
    }
    return normalizedCharacteristics;
};

export const normalizeOfferData = (data: OfferData): Offer => ({
    active: data.active,
    available: data.available,
    attributes: {
        ...data.attributes,
        ...mapAttributesData(data.attributes),
    },
    categories: mapCategoriesFromPathsData(data.product.categoryPaths, data.product.categoryIds),
    offerCategories: data.categoryPath.filter((item) => item.id === data.categoryId),
    description: data.description,
    id: data.id,
    images: normalizeImages(data.images),
    manufacturer: data.manufacturer,
    moderationStatus: data.moderationStatus,
    labels: data.labels,
    name: data.name,
    price: data.price,
    offerCharacteristics: data.offerCharacteristics,
    sourceCharacteristics: normalizeSourceCharacteristics(data.sourceCharacteristics),
    supplier: data.supplier,
    targets: data.targets,
    type: data.type,
    vendorCode: data.sku,
    weight: data.weight,
    product: normalizeProductsData([data.product])[0],
    synchronized: data.synchronized,
    deactivationReason: data.deactivationReason,
    linkedProductId: data.linkedProductId,
    deleteDate: normalizeDate(data.deleteDate),
});
