import { createEffect, Effect } from 'effector';
import { DictionaryData } from '@admin/model/model.types';
import { getRequest } from '@admin/model/api';
import {
    OfferServiceParametersTypeDictionaryItem,
    OfferServiceParametersTypeDictionary,
} from '@admin/model/offers/offer-service-parameters-type/offer-service-parameters-type.types';

export const getOfferServiceParamatersTypeDictionaryFx: Effect<void, OfferServiceParametersTypeDictionary[], Error> =
    createEffect(async () => {
        const data = await getRequest<DictionaryData>('dictionaries/offer_service_parameters_type');

        return data?.dictionaryValueList.map(({ value, key }) => {
            const parsed = JSON.parse(value) as OfferServiceParametersTypeDictionaryItem;
            return { item: parsed, title: parsed?.name, code: key };
        });
    });
