export enum UploadErrorCode {
    NotFound = 'NOT_FOUND',
    OffersIdsNotUnique = 'OFFERS_IDS_NOT_UNIQUE',
    OffersFieldMissing = 'OFFERS_FIELD_MISSING',
    OfferFieldInvalid = 'OFFER_FIELD_INVALID',
    FileSizeLimit = 'FILE_SIZE_LIMIT',
    FormatNotValid = 'FORMAT_NOT_VALID',
    StructureNotValid = 'STRUCTURE_NOT_VALID',
    SizeExceeded = 'SIZE_EXCEEDED',
    CharacteristicValueOutOfRange = 'CHARACTERISTIC_VALUE_OUT_OF_RANGE',
    AmbiguousCharacteristic = 'AMBIGUOUS_CHARACTERISTIC',
    CharacteristicUnitOutOfRange = 'CHARACTERISTIC_UNIT_OUT_OF_RANGE',
    CharacteristicNotFound = 'CHARACTERISTIC_NOT_FOUND',
    ListStructureNotValid = 'LIST_STRUCTURE_NOT_VALID',
    ListOfferIdNotUnique = 'LIST_OFFER_ID_NOT_UNIQUE',
    ListOfferFieldMissing = 'LIST_OFFER_FIELD_MISSING',
    ListOfferNotFound = 'LIST_OFFER_NOT_FOUND',
    ListProductIdNotUnique = 'LIST_PRODUCT_ID_NOT_UNIQUE',
    ListProductIdFieldMissing = 'LIST_PRODUCT_FIELD_MISSING',
    ListProductNotFound = 'LIST_PRODUCT_NOT_FOUND',
}

export interface UploadError {
    errorCode: UploadErrorCode;
    sku: string;
    description: string;
    errorLineNumber?: number;
    field?: string;
    values?: Record<string, string>;
}

interface ErrorsFileData {
    name: Nullable<string>;
    path: Nullable<string>;
    process: Nullable<boolean>;
}

export interface UploadErrorData {
    totalCount: number;
    uploadErrors: UploadError[];
    errorsFile: ErrorsFileData;
}
