import { getRequest, pollRequest, postRequest } from '@admin/model/api';
import { RequestWithPollingResponse } from '@admin/model/model.types';
import { ClientData, setCommonAppError } from '@admin/model';
import { logger } from '@admin/common/helpers/logger';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { createEffect } from 'effector';
import { ClientEnableAccessValidationErrorCode } from '@admin/model/clients-and-profiles/client-enable-access/client-enable-access.types';

export const clientEnableAccessFx = createEffect(async (clientId: string) => {
    try {
        const response = await postRequest<RequestWithPollingResponse, {}>(`clients/${clientId}/enableAccess`);
        await pollRequest<ClientData>(() => getRequest(`clients/processing/${response.processId}`));

        return true;
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, ClientEnableAccessValidationErrorCode)) {
            throw error.data;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
