import { transactionsDomain } from '@admin/model/model.domains';
import { logger } from '@admin/common/helpers/logger';
import { TransactionsData, TransactionsPayload } from './transactions.types';
import { getRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';

export const loadTransactionsFx = transactionsDomain.createEffect(
    async ({ profileId, ...payload }: TransactionsPayload) => {
        try {
            const data = await getRequest<TransactionsData<string>>(`accounts/transactions/${profileId}`, payload);

            return {
                ...data,
                transactions: data.transactions.map((it) => ({
                    ...it,
                    transactionDate: new Date(it.transactionDate),
                })),
            };
        } catch (error) {
            logger(error);
            setCommonAppError({ error });
            throw error;
        }
    },
);
