import { sample } from 'effector';
import { reset } from 'patronum';
import { deleteListsFx } from '@admin/model/lists/delete-lists/delete-lists.fx';
import { getSuccessMessage } from '@admin/model/lists/delete-lists/delete-lists.helpers';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { $deleteListsData, resetDeleteListsDoneStatusEvent } from '@admin/model/lists/delete-lists/delete-lists.store';

sample({
    source: deleteListsFx.doneData,
    fn: (payload) => getSuccessMessage(payload),
    target: showNoticeEvent,
});

sample({
    clock: deleteListsFx.done,
    fn: () => true,
    target: $deleteListsData,
});

reset({
    clock: resetDeleteListsDoneStatusEvent,
    target: $deleteListsData,
});
