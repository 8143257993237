import { UploadStatus } from '@admin/model';

export enum FileUploadHistoryField {
    Id = 'id',
    UploadSuccessCount = 'uploadSuccessCount',
    UploadFailCount = 'uploadFailCount',
    CompletedAt = 'completedAt',
    UploadAt = 'uploadAt',
    StartedAt = 'startedAt',
    CreatedBy = 'createdBy',
    FileName = 'fileName',
    Status = 'status',
}

export enum UploadType {
    ProductUpload = 'PRODUCT_UPLOAD',
    ListUpload = 'LIST_UPLOAD',
}

export interface FileUploadHistoryPayload {
    supplierId?: number;
    listId?: string;
    ordering?: string;
    offset?: number;
    limit?: number;
    type?: UploadType;
}

export interface FileUploadHistoryData {
    [FileUploadHistoryField.UploadSuccessCount]: number;
    [FileUploadHistoryField.UploadFailCount]: number;
    [FileUploadHistoryField.CompletedAt]: Nullable<string>;
    [FileUploadHistoryField.UploadAt]: Nullable<string>;
    [FileUploadHistoryField.StartedAt]: Nullable<string>;
    [FileUploadHistoryField.CreatedBy]: {
        firstName: Nullable<string>;
        lastName: Nullable<string>;
        patronymicName: Nullable<string>;
        userId: Nullable<string>;
    };
    [FileUploadHistoryField.FileName]: string;
    [FileUploadHistoryField.Status]: UploadStatus;
    [FileUploadHistoryField.Id]: string;
}

export interface FileUploadHistory extends Omit<FileUploadHistoryData, 'completedAt' | 'uploadAt' | 'startedAt'> {
    completedAt?: Date;
    uploadAt?: Date;
    startedAt?: Date;
}

export interface FileUploadHistoryListData {
    catalogUploads: FileUploadHistoryData[];
    totalCount: number;
}

export interface FileUploadHistoryState {
    data: FileUploadHistory[];
    totalCount: number;
}
