import { OfferCategoryPayload } from '@admin/model/offer-categories/categories.types';

export const getFormData = (data: OfferCategoryPayload): FormData => {
    const { image, icon, ...rest } = data;

    const formData = new FormData();

    formData.append('category', JSON.stringify(rest));

    if (image) {
        formData.append('image', image, image.name);
    }
    if (icon) {
        formData.append('icon', icon, icon.name);
    }

    return formData;
};
