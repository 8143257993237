import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { deleteOfferCategoriesFx } from '@admin/model/offer-categories/delete-offer-categories/delete-offer-categories.fx';
import { getSuccessMessage } from '@admin/model/offer-categories/delete-offer-categories/delete-offer-categories.helpers';

sample({
    clock: deleteOfferCategoriesFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
