import { sample } from 'effector';
import {
    $changeOrderStatusData,
    $changeOrderStatusError,
    resetChangeOrderStatusEvent,
} from '@admin/model/orders/change-order-status/change-order-status.store';
import { changeOrderStatusFx } from '@admin/model/orders/change-order-status/change-order-status.fx';
import { reset } from 'patronum';

sample({
    clock: changeOrderStatusFx.done,
    fn: () => true,
    target: $changeOrderStatusData,
});

sample({
    clock: changeOrderStatusFx.failData,
    target: $changeOrderStatusError,
});

reset({
    clock: resetChangeOrderStatusEvent,
    target: [$changeOrderStatusError, $changeOrderStatusData],
});
