import { sample } from 'effector';
import { updateProductFx } from '@admin/model/products/update-product/update-product.fx';
import { getSuccessMessage } from '@admin/model/products/update-product/update-product.helpers';
import { showNoticeEvent } from '@admin/model/notices/notices.store';

sample({
    clock: updateProductFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
