import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { normalizeOfferData } from '@admin/model/offers/offer/offer.helpers';
import { OfferData } from '@admin/model/offers/offers.types';

export const getOfferFx = createEffect(async (offerId: string) => {
    try {
        const data = await getRequest<OfferData>(
            `offers/${offerId}`,
            {},
            {
                apiVersion: 2,
            },
        );

        return normalizeOfferData(data);
    } catch (error) {
        logger(error);

        throw error;
    }
});
