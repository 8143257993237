import { ValidationError } from '@admin/common/validation';
import { CharacteristicType } from '@admin/model/characteristics';
import { LabelItem } from '@admin/model/labels/labels.types';
import { Currency, IntegrationType, InterfaceIntegrationType } from '@admin/model/model.types';

export interface CategoryPayload {
    id: string;
    calculateProductAmount?: boolean;
}

export enum CategoryStatus {
    Activated = 'ACTIVATED',
    Deactivated = 'DEACTIVATED',
}

export enum CategoryType {
    Static = 'STATIC_CATEGORY',
    Online = 'ONLINE_CATEGORY',
    Dynamic = 'DYNAMIC_CATEGORY',
    CollectionCategory = 'COLLECTION_CATEGORY',
    Service = 'SERVICE_CATEGORY',
}

export enum CategoryValidationErrorCode {
    NameAlreadyTaken = 'NAME_ALREADY_TAKEN',
    MissingRequiredField = 'MISSING_REQUIRED_FIELD',
    SizeExceeded = 'SIZE_EXCEEDED',
    ActiveSupplierProductsExists = 'ACTIVE_SUPPLIER_PRODUCTS_EXISTS',
    UnableUpdateParentCategory = 'UNABLE_UPDATE_PARENT_CATEGORY',
    PropertyValueViolation = 'PROPERTY_VALUE_VIOLATION',
    CodeAlreadyTaken = 'CODE_ALREADY_TAKEN',
    ParentsAreNotVisible = 'PARENTS_ARE_NOT_VISIBLE',
}

export type CategoryValidationError = ValidationError<CategoryValidationErrorCode>;

export interface CategoryAttributes {
    url?: string;
    callBack?: string;
    integrationMethod?: IntegrationType;
    interfaceIntegrationMethod?: InterfaceIntegrationType;
    script?: string;
    dateToPublishTo?: string;
    dateToPublishFrom?: string;
    description?: string;
    urlTeaser?: string;
    urlBanner?: string;
    urlMobileBanner?: string;
    urlIcon?: string;
}

export interface CategorySupplier {
    id: number;
    aliases: Nullable<string[]>;
}

export enum CategoryField {
    Id = 'id',
    Name = 'name',
    Code = 'code',
    Type = 'type',
    Attributes = 'attributes',
    ParentId = 'parentId',
    Suppliers = 'suppliers',
    Labels = 'labels',
    IsHidden = 'isHidden',
    Status = 'status',
    Currencies = 'currencies',
    ProductCharacteristics = 'productCharacteristics',
    Order = 'order',
    ProductAmount = 'productAmount',
    ActiveProductAmount = 'activeProductAmount',
    HasChild = 'hasChild',
    ChildCategories = 'childCategories',
}

export interface CategoryCharacteristicValue {
    id: string;
    name: string;
    isFilter: boolean;
    unit?: Nullable<string>;
    type?: CharacteristicType;
}

export interface CategoryFormPayload {
    [CategoryField.Name]?: string;
    [CategoryField.Code]?: string;
    [CategoryField.Type]: CategoryType;
    [CategoryField.Attributes]?: CategoryAttributes;
    [CategoryField.ParentId]?: string;
    [CategoryField.Suppliers]?: CategorySupplier[];
    [CategoryField.Labels]?: string[];
    [CategoryField.IsHidden]?: boolean;
    [CategoryField.Status]?: CategoryStatus;
    [CategoryField.Currencies]?: Nullable<string[]>;
    [CategoryField.ProductCharacteristics]?: CategoryCharacteristicValue[];
}

export interface Category {
    [CategoryField.Id]: string;
    [CategoryField.Type]: CategoryType;
    [CategoryField.ParentId]: Nullable<string>;
    [CategoryField.Name]: string;
    [CategoryField.Code]?: string;
    [CategoryField.Currencies]: Currency[];
    [CategoryField.Status]: CategoryStatus;
    [CategoryField.Labels]?: LabelItem[];
    [CategoryField.Suppliers]: CategorySupplier[];
    [CategoryField.Order]: number;
    [CategoryField.ProductAmount]: Nullable<number>;
    [CategoryField.ActiveProductAmount]: number;
    [CategoryField.HasChild]: boolean;
    [CategoryField.ChildCategories]: Nullable<Category[]>;
    [CategoryField.Attributes]: Nullable<CategoryAttributes>;
    [CategoryField.IsHidden]: boolean;
    [CategoryField.ProductCharacteristics]: CategoryCharacteristicValue[];
}
