import { sample } from 'effector';
import { refreshHashFx } from '@admin/model/offers/refresh-hash/refresh-hash.fx';
import { getSuccessMessage } from '@admin/model/offers/refresh-hash/refresh-hash.helpers';
import { showNoticeEvent } from '@admin/model/notices/notices.store';

sample({
    clock: refreshHashFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
