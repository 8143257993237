import { getRequest, pollRequest, postRequest } from '@admin/model/api';
import { RequestWithPollingResponse } from '@admin/model/model.types';
import { ClientData, setCommonAppError } from '@admin/model';
import { logger } from '@admin/common/helpers/logger';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { createEffect } from 'effector';
import { ClientDisableAccessAndPhoneValidationErrorCode } from '@admin/model/clients-and-profiles/client-disable-access-and-phone/client-disable-access-and-phone.types';

export const clientDisableAccessAndPhoneFx = createEffect(async (clientId: string) => {
    try {
        const response = await postRequest<RequestWithPollingResponse, {}>(`clients/${clientId}/disablePhoneAccess`);
        await pollRequest<ClientData>(() => getRequest(`clients/processing/${response.processId}`));

        return true;
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, ClientDisableAccessAndPhoneValidationErrorCode)) {
            throw error.data;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
