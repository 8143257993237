export enum CostMatrixUploadErrorCode {
    InvalidFormat = 'INVALID_FORMAT',
    FormatNotValid = 'FORMAT_NOT_VALID',
    MissingRequiredField = 'MISSING_REQUIRED_FIELD',
    StructureNotValid = 'STRUCTURE_NOT_VALID',
    PositiveValueViolation = 'POSITIVE_VALUE_VIOLATION',
    WeightBoundsViolation = 'WEIGHT_BOUNDS_VIOLATION',
    UnrecognizedKladrValue = 'UNRECOGNIZED_KLADR_VALUE',
    WeightIntersectionViolation = 'WEIGHTS_INTERSECTION_VIOLATION',
    DuplicateKladrWeightViolation = 'DUPLICATE_KLADR_WEIGHT_VIOLATION',
}

export interface CostMatrixUploadError {
    errorCode: CostMatrixUploadErrorCode;
    rowNumber?: string;
    description: string;
    values?: { [key: string]: string | undefined };
}

interface ErrorsFileData {
    name: Nullable<string>;
    path: Nullable<string>;
    process: Nullable<boolean>;
}

export interface CostMatrixUploadErrorData {
    totalCount: number;
    uploadErrors: CostMatrixUploadError[];
    errorsFile: ErrorsFileData;
}
