import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { CategoryValidationErrorCode } from '@admin/model/categories/category/category.types';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { createEffect } from 'effector';

export const createCategoryFx = createEffect(async (payload: FormData) => {
    try {
        await postRequest('catalog/categories', payload);
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, CategoryValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
