export enum PageName {
    ACCOUNTS = 'ACCOUNTS',
    ALL_REQUESTS = 'ALL_REQUESTS',
    ALL_REQUESTS_MAIN = 'ALL_REQUESTS_MAIN',
    ALL_REQUESTS_VIEW = 'ALL_REQUESTS_VIEW',
    CATALOG = 'CATALOG',
    CATEGORIES_BINDING = 'CATEGORIES_BINDING',
    CATEGORIES_BINDING_CATEGORIES = 'CATEGORIES_BINDING_CATEGORIES',
    CATEGORIES_BINDING_CHANGE_HISTORY = 'CATEGORIES_BINDING_CHANGE_HISTORY',
    CATEGORIES_EDITOR = 'CATEGORIES_EDITOR',
    CATEGORIES_MAIN = 'CATEGORIES_MAIN',
    CATEGORY_CHANGE_HISTORY = 'CATEGORY_CHANGE_HISTORY',
    CATEGORY_CREATE = 'CATEGORY_CREATE',
    CATEGORY_EDIT = 'CATEGORY_EDIT',
    CATEGORY_INFO = 'CATEGORY_INFO',
    CATEGORY_VIEW = 'CATEGORY_VIEW',
    CHARACTERISTICS = 'CHARACTERISTICS',
    CHARACTERISTICS_MAIN = 'CHARACTERISTICS_MAIN',
    CHARACTERISTIC_CREATE = 'CHARACTERISTIC_CREATE',
    CHARACTERISTIC_EDIT = 'CHARACTERISTIC_EDIT',
    CHARACTERISTIC_VIEW = 'CHARACTERISTIC_VIEW',
    CLIENT = 'CLIENT',
    CLIENTS_AND_PROFILES = 'CLIENTS_AND_PROFILES',
    CLIENTS_AND_PROFILES_SEARCH = 'CLIENTS_AND_PROFILES_SEARCH',
    CLIENTS_SEARCH_HASH = 'CLIENTS_SEARCH_HASH',
    CLIENT_ACCOUNT = 'CLIENT_ACCOUNT',
    CLIENT_EXPIRATIONS = 'CLIENT_EXPIRATIONS',
    CLIENT_EXPIRATION = 'CLIENT_EXPIRATION',
    CLIENT_AGREEMENTS = 'CLIENT_AGREEMENTS',
    CLIENT_ORDERS = 'CLIENT_ORDERS',
    CLIENT_TRANSACTIONS = 'CLIENT_TRANSACTIONS',
    CLIENT_TRANSACTION_CREATE = 'CLIENT_TRANSACTION_CREATE',
    CLIENT_PROFILE = 'CLIENT_PROFILE',
    CLIENT_PROFILE_DETAILS = 'CLIENT_PROFILE_DETAILS',
    CLIENT_AUTH_HISTORY = 'CLIENT_AUTH_HISTORY',
    CLIENT_SUBSCRIPTIONS = 'CLIENT_SUBSCRIPTIONS',
    COLLECTIONS = 'COLLECTIONS',
    COLLECTIONS_CREATE = 'COLLECTIONS_CREATE',
    COLLECTIONS_EDIT = 'COLLECTIONS_EDIT',
    COLLECTIONS_MAIN = 'COLLECTIONS_MAIN',
    COLLECTIONS_VIEW = 'COLLECTIONS_VIEW',
    CREATE_OFFER = 'CREATE_OFFER',
    CREATE_ROLE = 'CREATE_ROLE',
    CREATE_USER = 'CREATE_USER',
    DELIVERY = 'DELIVERY',
    DELIVERY_COST_MATRIX = 'DELIVERY_COST_MATRIX',
    DELIVERY_MATRIX_CHANGE_HISTORY = 'DELIVERY_MATRIX_CHANGE_HISTORY',
    DELIVERY_MATRIX_UPLOAD_HISTORY = 'DELIVERY_MATRIX_UPLOAD_HISTORY',
    EDIT_ROLE = 'EDIT_ROLE',
    EDIT_USER = 'EDIT_USER',
    ERROR = 'ERROR',
    EVENT_SET = 'EVENT_SET',
    HOME = 'HOME',
    SUPPLIER_INFO = 'SUPPLIER_INFO',
    LISTS = 'LISTS',
    LISTS_MAIN = 'LISTS_MAIN',
    LIST_CREATE = 'LIST_CREATE',
    LIST_EDIT = 'LIST_EDIT',
    LIST_VIEW = 'LIST_VIEW',
    LIST_INFO = 'LIST_INFO',
    LIST_UPLOAD_HISTORY = 'LIST_UPLOAD_HISTORY',
    SETTINGS = 'SETTINGS',
    NOTIFICATIONS = 'NOTIFICATIONS',
    NOTIFICATIONS_MAIN = 'NOTIFICATIONS_MAIN',
    NOTIFICATION_CREATE = 'NOTIFICATION_CREATE',
    NOTIFICATION_EDIT = 'NOTIFICATION_EDIT',
    NOTIFICATION_VIEW = 'NOTIFICATION_VIEW',
    NOTIFICATION_CHANGE_HISTORY = 'NOTIFICATION_CHANGE_HISTORY',
    NOTIFICATION_INFO = 'NOTIFICATION_INFO',
    NOT_FOUND = 'NOT_FOUND',
    ORDERS_AND_PAYMENTS = 'ORDERS_AND_PAYMENTS',
    ORDERS = 'ORDERS',
    ORDERS_MAIN = 'ORDERS_MAIN',
    ORDER_INFO = 'ORDER_INFO',
    ORDER_PAYMENT = 'ORDER_PAYMENT',
    ORDER_VIEW = 'ORDER_VIEW',
    ORDER_EDIT = 'ORDER_EDIT',
    ORDER_CHANGE_HISTORY = 'ORDER_CHANGE_HISTORY',
    ORDER_TRACKING = 'ORDER_TRACKING',
    PAYMENTS = 'PAYMENTS',
    PAYMENTS_MAIN = 'PAYMENTS_MAIN',
    PRODUCTS = 'PRODUCTS',
    PRODUCTS_MAIN = 'PRODUCTS_MAIN',
    PRODUCT_CREATE = 'PRODUCT_CREATE',
    PRODUCT_DETAILS_CREATE = 'PRODUCT_DETAILS_CREATE',
    PRODUCT_OFFERS_CREATE = 'PRODUCT_OFFERS_CREATE',
    PRODUCT_VIEW = 'PRODUCT_VIEW',
    PRODUCT_DETAILS_VIEW = 'PRODUCT_DETAILS_VIEW',
    PRODUCT_OFFERS_VIEW = 'PRODUCT_OFFERS_VIEW',
    PRODUCT_EDIT = 'PRODUCT_EDIT',
    PRODUCT_DETAILS_EDIT = 'PRODUCT_DETAILS_EDIT',
    PRODUCT_OFFERS_EDIT = 'PRODUCT_OFFERS_EDIT',
    REPORTS = 'REPORTS',
    REPORTS_MAIN = 'REPORTS_MAIN',
    REQUESTS = 'REQUESTS',
    OFFERS = 'OFFERS',
    OFFER_EDIT = 'OFFER_EDIT',
    OFFERS_MAIN = 'OFFERS_MAIN',
    OFFERS_UPLOADS = 'OFFERS_UPLOADS',
    OFFERS_UPLOAD_OFFERS = 'OFFERS_UPLOAD_OFFERS',
    OFFERS_UPLOAD_COUPON_CODES = 'OFFERS_UPLOAD_COUPON_CODES',
    OFFER_VIEW = 'OFFER_VIEW',
    OFFER_INFO = 'OFFER_INFO',
    OFFER_COUPON_CODES = 'OFFER_COUPON_CODES',
    UPDATE_CHARACTERISTICS = 'UPDATE_CHARACTERISTICS',
    ROLES = 'ROLES',
    ROLES_MAIN = 'ROLES_MAIN',
    SHORT_LINKS = 'SHORT_LINKS',
    SHORT_LINKS_MAIN = 'SHORT_LINKS_MAIN',
    SHORT_LINK_CREATE = 'SHORT_LINK_CREATE',
    SHORT_LINK_VIEW = 'SHORT_LINK_VIEW',
    SHORT_LINK_EDIT = 'SHORT_LINK_EDIT',
    SUPPLIERS = 'SUPPLIERS',
    SUPPLIERS_MAIN = 'SUPPLIERS_MAIN',
    SUPPLIER_CREATE = 'SUPPLIER_CREATE',
    SUPPLIER_EDIT = 'SUPPLIER_EDIT',
    SUPPLIER_VIEW = 'SUPPLIER_VIEW',
    USERS = 'USERS',
    USERS_MAIN = 'USERS_MAIN',
    USER_CHANGE_HISTORY = 'USER_CHANGE_HISTORY',
    USER_INFO = 'USER_INFO',
    VIEW_ROLE = 'VIEW_ROLE',
    VIEW_USER = 'VIEW_USER',
    SUBSCRIPTIONS = 'SUBSCRIPTIONS',
    SUBSCRIPTIONS_MAIN = 'SUBSCRIPTIONS_MAIN',
    SUBSCRIPTION_INFO = 'SUBSCRIPTION_INFO',
    SUBSCRIPTION_VIEW = 'SUBSCRIPTION_VIEW',
    SUBSCRIPTION_CHANGE_HISTORY = 'SUBSCRIPTION_CHANGE_HISTORY',
    PARTNER_OFFERS = 'PARTNER_OFFERS',
    PARTNER_OFFERS_CATEGORIES = 'PARTNER_OFFERS_CATEGORIES',
    PARTNER_OFFERS_CATEGORIES_MAIN = 'PARTNER_OFFERS_CATEGORIES_MAIN',
    PARTNER_OFFERS_CATEGORIES_VIEW = 'PARTNER_OFFERS_CATEGORIES_VIEW',
    PARTNER_OFFERS_CATEGORIES_CREATE = 'PARTNER_OFFERS_CATEGORIES_CREATE',
    PARTNER_OFFERS_CATEGORIES_EDIT = 'PARTNER_OFFERS_CATEGORIES_EDIT',
    PARTNER_OFFERS_OFFERS = 'PARTNER_OFFERS_OFFERS',
    PARTNER_OFFERS_MAIN = 'PARTNER_OFFERS_MAIN',
    PARTNER_OFFERS_VIEW = 'PARTNER_OFFERS_VIEW',
    PARTNER_OFFERS_CREATE = 'PARTNER_OFFERS_CREATE',
    PARTNER_OFFERS_EDIT = 'PARTNER_OFFERS_EDIT',
    ACCRUALS = 'ACCRUALS',
    ACCRUALS_REQUESTS = 'ACCRUALS_REQUESTS',
    ACCRUALS_REQUESTS_MAIN = 'ACCRUALS_REQUESTS_MAIN',
    ACCRUALS_ACCRUALS = 'ACCRUALS_ACCRUALS',
    ACCRUALS_ACCRUALS_MAIN = 'ACCRUALS_ACCRUALS_MAIN',
    ACCRUALS_ACCRUALS_VIEW = 'ACCRUALS_ACCRUALS_VIEW',
    PAGE_BLOCKING = 'PAGE_BLOCKING',
    PAGE_BLOCKING_VIEW = 'PAGE_BLOCKING_VIEW',
    SEGMENTS = 'SEGMENTS',
    SEGMENTS_MAIN = 'SEGMENTS_MAIN',
    SEGMENT_CREATE = 'SEGMENT_CREATE',
    SEGMENT_EDIT = 'SEGMENT_EDIT',
    SEGMENT_VIEW = 'SEGMENT_VIEW',
    SEGMENT_INFO = 'SEGMENT_INFO',
    SEGMENT_UPLOAD_HISTORY = 'SEGMENT_UPLOAD_HISTORY',
}
