import { combine, createEvent, createStore } from 'effector';
import { updateSupplierEventSetFx } from '@admin/model/suppliers/update-suppliers-event-set/update-suppliers-event-set.fx';

export const resetUpdateSuppliersEventSetDataEvent = createEvent();

export const $updateSuppliersEventSetData = createStore<boolean>(false);

export const $updateSuppliersEventSet = combine({
    data: $updateSuppliersEventSetData,
    isLoading: updateSupplierEventSetFx.pending,
});
