import { LocalizationBackend } from '@common/localization/localization-backend';
import { LocalizationFormats } from '@common/localization/localization.types';
import i18next, { ResourceKey } from 'i18next';
import { initReactI18next } from 'react-i18next';

export async function initLocalization(defaultLng: string, loadLocale: (language: string) => Promise<ResourceKey>) {
    return i18next
        .use(LocalizationBackend)
        .use(initReactI18next)
        .init({
            backend: loadLocale,
            fallbackLng: defaultLng,
            interpolation: {
                escapeValue: false, // react already safes from xss
                format: (value: string, format?: string): string => {
                    if (format === 'withLeadingSpace') {
                        return value ? ` ${value}` : '';
                    }

                    if (format === LocalizationFormats.LowerCase) {
                        return value.toLowerCase();
                    }

                    return value;
                },
            },
            lng: defaultLng,
            react: {
                useSuspense: false,
            },
        });
}
