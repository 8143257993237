import { RolesState } from '@admin/model/roles/roles.types';
import { combine } from 'effector';
import { rolesDomain } from '@admin/model/model.domains';
import { loadRolesFx } from '@admin/model/roles/main/roles.fx';

const initialState: RolesState = { roles: [], totalCount: 0 };

export const $rolesData = rolesDomain.createStore<RolesState>(initialState);
export const $rolesError = rolesDomain.createStore<Nullable<unknown>>(null);

export const $roles = combine({
    data: $rolesData,
    error: $rolesError,
    isLoading: loadRolesFx.pending,
});
