import { sample } from 'effector';
import { getShortLinkFx } from '@admin/model/short-links/short-link/get-short-link.fx';
import { $shortLinkData, $shortLinkError } from '@admin/model/short-links/short-link/short-link.store';
import { ShortLinkField } from '@admin/model/short-links/short-links.types';
import { normalizeDate } from '@admin/model/helpers';

sample({
    clock: getShortLinkFx.doneData,
    fn: (link) => ({
        ...link,
        [ShortLinkField.UpdatedAt]: normalizeDate(link[ShortLinkField.UpdatedAt]),
    }),
    target: $shortLinkData,
});

sample({
    clock: getShortLinkFx.failData,
    target: $shortLinkError,
});
