import { createEffect } from 'effector';
import { putRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { OfferPartnerValidationErrorCode } from '@admin/model/offer-partners/offer-partners.types';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { logger } from '@admin/common/helpers/logger';

export const updateOfferPartnerFx = createEffect(async ({ formData, id }: { id: string; formData: FormData }) => {
    try {
        await putRequest(`offer-partners/${id}`, formData);
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, OfferPartnerValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
