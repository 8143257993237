import { combine, createEvent, createStore } from 'effector';
import { updateCategoriesStatusFx } from '@admin/model/categories/update-categories-status/update-categories-status.fx';

export const resetUpdateCategoriesStatusDoneStatusEvent = createEvent();
export const resetUpdateCategoriesStatusErrorEvent = createEvent();

export const $updateCategoriesStatusDoneStatus = createStore<boolean>(false);
export const $updateCategoriesStatusError = createStore<Nullable<unknown>>(null);

export const $updateCategoriesStatus = combine({
    error: $updateCategoriesStatusError,
    isStatusBatchingDone: $updateCategoriesStatusDoneStatus,
    isLoading: updateCategoriesStatusFx.pending,
});
