import { normalizeImages } from '@admin/common/helpers';
import { CategoryPath } from '@admin/model/offers';
import { ProductData, Product } from '@admin/model/products/products.types';

export const normalizeProductData = (data: ProductData): Product => ({
    category: data.category,
    categories: data.category.reduce<CategoryPath[]>((acc, i) => {
        return [...acc, { ...i.path[i.path.length - 1], productCharacteristics: i.productCharacteristics }];
    }, []),
    description: data.description,
    id: data.id,
    images: normalizeImages(data.images),
    manufacturer: data.manufacturer,
    name: data.name,
    productCharacteristics: data.productCharacteristics,
    optionalProductCharacteristics: data.optionalProductCharacteristics,
    synchronized: data.synchronized,
    isDeleted: data.isDeleted,
    offers: data.offers.map((offer) => ({ ...offer, imageUrls: offer.images })),
});
