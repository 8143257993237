import { LabelItem } from '@admin/model/labels/labels.types';
import { ProductListItemDataField, ProductListItemField, ProductListItem } from '../products/products.types';
import { ValidationError } from '@admin/common/validation/validation-error.types';
import { Characteristic, CharacteristicValue } from '@admin/model/characteristics/characteristic/characteristic.types';

export enum OfferType {
    Product = 'COMMON_PRODUCT',
    Service = 'SERVICE',
    Coupon = 'COUPON',
}

export enum OfferSynchronizationStatus {
    Synchronized = 'SYNCHRONIZED',
    notSynchronized = 'NOT_SYNCHRONIZED',
}

export enum DeliverySettings {
    NeedDelivery = 'NEED_DELIVERY',
    NotNeedDelivery = 'NOT_NEED_DELIVERY',
    DeliveryByEmail = 'DELIVERY_BY_EMAIL',
}

export enum OfferModerationStatus {
    Accepted = 'ACCEPTED',
    Declined = 'DECLINED',
    Waiting = 'WAITING',
}

export enum ServiceParameterTypes {
    String = 'string',
    Number = 'number',
}

export enum CodeStatus {
    Active = 'Active',
    Reserved = 'Reserved',
    Issued = 'Issued',
    Deactivated = 'Deactivated',
    Blocked = 'Blocked',
}

export enum CouponType {
    Internal = 'Internal',
    External = 'External',
}

export const enum OfferField {
    Id = 'id',
    Name = 'name',
    Sku = 'sku',
    Supplier = 'supplier',
    Price = 'price',
    OfferCharacteristics = 'offerCharacteristics',
    Images = 'images',
    Targets = 'targets',
    Labels = 'labels',
    VendorCode = 'vendorCode',
    Available = 'available',
    Active = 'active',
    ModerationStatus = 'moderationStatus',
    Synchronized = 'synchronized',
    Type = 'type',
    Manufacturer = 'manufacturer',
    Description = 'description',
    Categories = 'categories',
    SourceCharacteristics = 'sourceCharacteristics',
    Weight = 'weight',
    Attributes = 'attributes',
    LinkedProductId = 'linkedProductId',
    Product = 'product',
    DeactivationReason = 'deactivationReason',
    CategoryId = 'categoryId',
    CategoryPath = 'categoryPath',
    OfferCategories = 'offerCategories',
    DeleteDate = 'deleteDate',
}

export enum DeactivationReasonType {
    InactivePartner = 'INACTIVE_PARTNER',
}

export interface SourceCharacteristic {
    name: string;
    value: string;
    unit?: string;
}

export interface AttributesData {
    delivery_info?: string;
    email_delivery?: string;
    price_without_discount?: string;
    forbid_price_change?: string;
    delivery_settings?: string;
    auto_payment?: string;
    min_price?: string;
    max_price?: string;
    price_info?: string;
    send_codes_with_mb?: string;
}

export interface Attributes {
    deliveryInfo?: string;
    priceWithoutDiscount?: number;
    forbidPriceChange?: boolean;
    deliverySettings?: string;
    autoPayment?: boolean;
    minPrice?: number;
    maxPrice?: number;
    emailDelivery?: boolean;
    priceInfo?: string;
    service_parameters?: string;
    action_rules?: string;
    sendCodesWithMultibonus?: boolean;
}

export interface Product {
    [ProductListItemDataField.Id]: string;
    [ProductListItemDataField.Name]: string;
    [ProductListItemField.Images]: string[];
    [ProductListItemDataField.Synchronized]: boolean;
    [ProductListItemField.Categories]: CategoryPath[];
    [ProductListItemDataField.CategoryIds]: string[];
    [ProductListItemDataField.CategoryPaths]: CategoryPath[][];
    [ProductListItemDataField.OfferAmount]: number;
    [ProductListItemDataField.ProductCharacteristics]: OfferCharacteristic[];
    [ProductListItemDataField.OptionalProductCharacteristics]: OfferCharacteristic[];
    [ProductListItemDataField.IgnoreOfferCategory]: boolean;
}

export interface OfferData {
    [OfferField.Id]: string;
    [OfferField.Name]: string;
    [OfferField.Sku]: string;
    [OfferField.Type]: OfferType;
    [OfferField.Active]: boolean;
    [OfferField.Attributes]: AttributesData;
    [OfferField.Available]: boolean;
    [OfferField.Description]: string;
    [OfferField.Images]: string[];
    [OfferField.Targets]: string[];
    [OfferField.Categories]: CategoryPath[];
    [OfferField.OfferCategories]: CategoryPath[];
    [OfferField.OfferCharacteristics]: OfferCharacteristic[];
    [OfferField.Labels]: LabelItem[];
    [OfferField.LinkedProductId]: string;
    [OfferField.Manufacturer]: string;
    [OfferField.ModerationStatus]: OfferModerationStatus;
    [OfferField.OfferCharacteristics]: OfferCharacteristic[];
    [OfferField.Price]: number;
    [OfferField.SourceCharacteristics]: { [key: string]: string };
    [OfferField.Supplier]: ShortenedSupplier;
    [OfferField.Targets]: string[];
    [OfferField.Weight]: number;
    [OfferField.Product]: Product;
    [OfferField.Synchronized]: boolean;
    [OfferField.DeactivationReason]: DeactivationReasonType;
    [OfferField.CategoryId]: string;
    [OfferField.CategoryPath]: CategoryPath[];
    [OfferField.DeleteDate]: Nullable<string>;
}

export interface Offer
    extends Omit<
        OfferData,
        | OfferField.Sku
        | OfferField.Attributes
        | OfferField.SourceCharacteristics
        | OfferField.Product
        | OfferField.CategoryPath
        | OfferField.CategoryId
        | OfferField.DeleteDate
    > {
    [OfferField.VendorCode]: string;
    [OfferField.Categories]: CategoryPath[];
    [OfferField.SourceCharacteristics]: SourceCharacteristic[];
    [OfferField.Attributes]: Attributes;
    [OfferField.Product]: ProductListItem;
    [OfferField.OfferCategories]: CategoryPath[];
    [OfferField.DeleteDate]?: Date;
}

export interface ServiceParameter {
    code: string;
    name: string;
    mask: string;
    type: ServiceParameterTypes;
    fixedValue: string;
    hint: string;
    required: boolean;
    visible: boolean;
}

export enum OfferValidationErrorCode {
    JsonNotValid = 'JSON_NOT_VALID',
    NotFound = 'NOT_FOUND',
    NotUrl = 'NOT_URL',
    ParameterNumbersExceed = 'PARAMETER_NUMBERS_EXCEEDED',
    IllegalValue = 'ILLEGAL_VALUE',
    MissingRequiredField = 'MISSING_REQUIRED_FIELD',
    MissingRequiredFieldMinPrice = 'MISSING_REQUIRED_FIELD_MIN_PRICE',
    ForbiddenCharactersViolation = 'FORBIDDEN_CHARACTERS_VIOLATION',
    PositiveValueViolation = 'POSITIVE_VALUE_VIOLATION',
    MismatchType = 'MISMATCH_TYPE',
    SizeExceeded = 'SIZE_EXCEEDED',
    PermissionError = 'PERMISSION_ERROR',
    UniqueValueViolation = 'UNIQUE_VALUE_VIOLATION',
    InvalidDictionaryValue = 'INVALID_DICTIONARY_VALUE',
    ProductCategoryHierarchyLevelViolation = 'PRODUCT_CATEGORY_HIERARCHY_LEVEL_VIOLATION',
    PriceAndPriceWithoutDiscountViolation = 'PRICE_AND_PRICE_WITHOUT_DISCOUNT_VIOLATION',
    OfferFieldInvalid = 'OFFER_FIELD_INVALID',
    CharacteristicNotFound = 'CHARACTERISTIC_NOT_FOUND',
    ViolationOfActiveProductOfInactiveSupplier = 'VIOLATION_OF_ACTIVE_PRODUCT_OF_INACTIVE_SUPPLIER',
    SupplierToCategoryPermissionViolation = 'SUPPLIER_TO_CATEGORY_PERMISSION_VIOLATION',
    ActiveProductAndNotApprovedModerationStatusViolation = 'ACTIVE_PRODUCT_AND_NOT_APPROVED_MODERATION_STATUS_VIOLATION',
    DeliverySettingToProductTypeViolation = 'DELIVERY_SETTING_TO_PRODUCT_TYPE_VIOLATION',
    ActionRulesViolation = 'ACTION_RULES_VIOLATION',
    MinPriceAndMaxPriceViolation = 'MIN_PRICE_AND_MAX_PRICE_VIOLATION',
    MaxValue = 'MAX_VALUE',
    DisabledPartnerCouponACtivation = 'DISABLED_PARTNER_COUPON_ACTIVATION',
    NoActiveCouponCodes = 'NO_ACTIVE_COUPON_CODES',
    OfferTypeChangeViolation = 'OFFER_TYPE_CHANGE_VIOLATION',
    ManualActivationIsProhibited = 'MANUAL_ACTIVATION_IS_PROHIBITED',
}

export interface CategoryPath {
    name: string;
    id: string;
}

export interface ShortenedSupplier {
    id: number;
    name: string;
}

export interface OfferCharacteristic extends Omit<Characteristic, 'description' | 'values' | 'updatedAt' | 'key'> {
    value: Nullable<string>;
    characteristicValues: Nullable<CharacteristicValue[]>;
}

export type OfferValidationError = ValidationError<OfferValidationErrorCode>;
