import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { SubscriptionData } from '@admin/model/subscriptions/subscriptions.types';
import { normalizeSubscriptionData } from '@admin/model/subscriptions/subscription/subscription.helpers';
import { logger } from '@admin/common/helpers/logger';

export const getSubscriptionFx = createEffect(async (id: string) => {
    try {
        const data = await getRequest<SubscriptionData>(`subscriptions/${id}`);

        return normalizeSubscriptionData(data);
    } catch (error) {
        logger(error);

        throw error;
    }
});
