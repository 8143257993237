import { logger } from '@admin/common/helpers/logger';
import { deleteRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { DeleteCategoryValidationErrorCode } from '@admin/model/categories/delete-category/delete-category.types';
import { createEffect } from 'effector';

export const deleteCategoryFx = createEffect(async (categoryId: string) => {
    try {
        await deleteRequest(`catalog/categories/${categoryId}?deleteNotEmpty=true`);
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, DeleteCategoryValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
