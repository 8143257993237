import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import {
    ManufacturersListPayload,
    ManufacturersListData,
} from '@admin/model/manufacturers/manufacturers-list/manufacturers-list.types';

export const getManufacturersListFx = createEffect(async (payload: ManufacturersListPayload) => {
    try {
        const result = await getRequest<ManufacturersListData>('offers/manufacturers', payload, {
            apiVersion: 2,
        });

        const normalizeData = result.data.map((item) => ({ name: item }));

        return {
            data: normalizeData,
            totalCount: result.totalCount,
        };
    } catch (error) {
        logger(error);

        throw error;
    }
});
