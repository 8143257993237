import { sample } from 'effector';
import { updateOfferPartnersBatchingFx } from '@admin/model/offer-partners/update-offer-partners-batching/update-offer-partners-batching.fx';
import { getSuccessMessage } from '@admin/model/offer-partners/update-offer-partners-batching/update-offer-partners-batching.helpers';
import { showNoticeEvent } from '@admin/model/notices/notices.store';

sample({
    source: updateOfferPartnersBatchingFx.doneData,
    fn: (result) => getSuccessMessage(result),
    target: showNoticeEvent,
});
