import { ValidationError } from '@admin/common/validation/validation-error.types';
import { OrderStatus } from '@admin/model/model.types';

export interface ChageOrderStatusItem {
    orderId: Number;
    status: OrderStatus;
}

export interface ChageOrderStatusPayload {
    orders: ChageOrderStatusItem[];
}

export enum ChangeOrderStatusValidationErrorCode {
    NotFound = 'NOT_FOUND',
    InvalidOrderStatus = 'INVALID_ORDER_STATUS',
    ProhibitedStatusChange = 'PROHIBITED_STATUS_CHANGE',
    ClientNotMb = 'CLIENT_NOT_MB',
    ProhibitedStatusChangeFromRegistered = 'PROHIBITED_STATUS_CHANGE_FROM_REGISTERED',
}

export type ChageOrderStatusValidationError = ValidationError<ChangeOrderStatusValidationErrorCode>;
