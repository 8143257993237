import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { normalizeProductData } from '@admin/model/products/product/product.helpers';
import { ProductData } from '@admin/model/products/products.types';

export const getProductFx = createEffect(async (productId: string) => {
    try {
        const data = await getRequest<ProductData>(
            `products/${productId}`,
            {},
            {
                apiVersion: 2,
            },
        );

        return normalizeProductData(data);
    } catch (error) {
        logger(error);

        throw error;
    }
});
