import { Characteristic, CharacteristicType } from '@admin/model/characteristics/characteristic/characteristic.types';
import { OfferCharacteristic } from '@admin/model/offers/offers.types';

const getInitialCharacteristicValue = (type: CharacteristicType) => {
    switch (type) {
        case CharacteristicType.Boolean:
        case CharacteristicType.Colour:
        case CharacteristicType.List: {
            return 'notset';
        }
        case CharacteristicType.Numeric:
        case CharacteristicType.String: {
            return '';
        }
        default: {
            return '';
        }
    }
};

export const normalizeCharacteristicsData = (data: Characteristic[]): OfferCharacteristic[] =>
    data.map(({ id, name, unit, type, values }) => ({
        id,
        name,
        unit,
        type,
        value: getInitialCharacteristicValue(type),
        characteristicValues: values,
    }));
