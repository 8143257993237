import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';

import {
    ProductCharacteristicsPayload,
    CharacteristicsState,
} from '@admin/model/products/product-characteristics/product-characteristics.types';
import { normalizeCharacteristicsData } from '@admin/model/products/product-characteristics/product-characteristics.helpers';

export const getProductCharacteristicsFx = createEffect(async (payload: ProductCharacteristicsPayload) => {
    try {
        const { data } = await postRequest<CharacteristicsState, ProductCharacteristicsPayload>(
            'catalog/productCharacteristics/search',
            payload,
        );

        return normalizeCharacteristicsData(data);
    } catch (error) {
        logger(error);

        throw error;
    }
});
