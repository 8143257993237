import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import {
    NotificationsData,
    NotificationsPayload,
} from '@admin/model/notifications/notifications-list/notifications-list.types';

export const getNotificationsListFx = createEffect(async (payload: NotificationsPayload) => {
    try {
        return await postRequest<NotificationsData, NotificationsPayload>(
            'notification/configurations/search',
            payload,
        );
    } catch (error) {
        logger(error);

        throw error;
    }
});
