import { combine, createEvent, createStore } from 'effector';
import { deactivateProgramLoyaltyFx } from '@admin/model/clients-and-profiles/deactivate-program-loyalty/deactivate-program-loyalty.fx';

export const $deactivateProgramLoyaltyError = createStore<Nullable<unknown>>(null);
export const $deactivateProgramLoyaltyDone = createStore<boolean>(false);

export const resetDeactivateProgramLoyaltyErrorEvent = createEvent();

export const $deactivateProgramLoyalty = combine({
    data: $deactivateProgramLoyaltyDone,
    error: $deactivateProgramLoyaltyError,
    isLoading: deactivateProgramLoyaltyFx.pending,
});
