import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { updateOfferPartnerFx } from '@admin/model/offer-partners/update-offer-partner/update-offer-partner.fx';
import { getSuccessMessage } from '@admin/model/offer-partners/update-offer-partner/update-offer-partner.helpers';

sample({
    clock: updateOfferPartnerFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
