import { sample } from 'effector';
import { normalizeListData } from '@admin/model/lists/list/list.helpers';
import { getListFx } from '@admin/model/lists/list/get-list.fx';
import { $listData, $listError } from '@admin/model/lists/list/list.store';

sample({
    clock: getListFx.doneData,
    fn: (result) => normalizeListData(result),
    target: $listData,
});

sample({
    clock: getListFx.failData,
    target: $listError,
});
