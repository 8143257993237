import { createEffect } from 'effector';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { setCommonAppError } from '@admin/model/error-notification';
import { putRequest } from '@admin/model/api';
import type { UpdateCouponCodesStatusPayload } from '@admin/model/offers/update-coupon-codes-status/update-coupon-codes-status.types';
import { UpdateCouponCodesStatusValidationErrorCode } from '@admin/model/offers/update-coupon-codes-status/update-coupon-codes-status.types';

export const updateCouponCodesStatusFx = createEffect(async (payload: UpdateCouponCodesStatusPayload) => {
    try {
        await putRequest('coupons', payload);
    } catch (error) {
        if (isKnownValidationErrorResponse(error, UpdateCouponCodesStatusValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
