import { PageName } from '@admin/pages/page-name';

export const PagePath: { [key in PageName]: string } = {
    [PageName.HOME]: '/',
    [PageName.ERROR]: '404',
    [PageName.NOT_FOUND]: '*',

    // CATALOG_AND_ORDERS
    [PageName.CATALOG]: '/catalog',

    // ./CATEGORIES_EDITOR
    [PageName.CATEGORIES_EDITOR]: '/catalog/categories-editor',
    [PageName.CATEGORIES_MAIN]: '/catalog/categories-editor/main',
    [PageName.CATEGORY_CREATE]: '/catalog/categories-editor/create',
    [PageName.CATEGORY_EDIT]: '/catalog/categories-editor/edit/:id',
    [PageName.CATEGORY_INFO]: '/catalog/categories-editor/:id',
    [PageName.CATEGORY_VIEW]: '/catalog/categories-editor/:id/view',
    [PageName.CATEGORY_CHANGE_HISTORY]: '/catalog/categories-editor/:id/change-history',
    [PageName.CATEGORIES_BINDING]: '/catalog/categories-binding',
    [PageName.CATEGORIES_BINDING_CATEGORIES]: '/catalog/categories-binding/categories',
    [PageName.CATEGORIES_BINDING_CHANGE_HISTORY]: '/catalog/categories-binding/change-history',

    // ./OFFERS
    [PageName.OFFERS]: '/catalog/offers',
    [PageName.OFFERS_MAIN]: '/catalog/offers/main',
    [PageName.UPDATE_CHARACTERISTICS]: '/catalog/offers/update',
    [PageName.OFFER_EDIT]: '/catalog/offers/edit/:id',
    [PageName.CREATE_OFFER]: '/catalog/offers/create',
    [PageName.OFFERS_UPLOADS]: '/catalog/offers/uploads',
    [PageName.OFFERS_UPLOAD_OFFERS]: '/catalog/offers/uploads/upload-offers',
    [PageName.OFFERS_UPLOAD_COUPON_CODES]: '/catalog/offers/uploads/upload-coupon-codes',
    [PageName.OFFER_INFO]: '/catalog/offers/:id',
    [PageName.OFFER_VIEW]: '/catalog/offers/:id/view',
    [PageName.OFFER_COUPON_CODES]: '/catalog/offers/:id/coupon-codes',

    // ./PRODUCTS
    [PageName.PRODUCTS]: '/catalog/products',
    [PageName.PRODUCTS_MAIN]: '/catalog/products/main',
    [PageName.PRODUCT_CREATE]: '/catalog/products/create',
    [PageName.PRODUCT_DETAILS_CREATE]: '/catalog/products/create/details',
    [PageName.PRODUCT_OFFERS_CREATE]: '/catalog/products/create/offer-linking',
    [PageName.PRODUCT_VIEW]: '/catalog/products/view/:id',
    [PageName.PRODUCT_DETAILS_VIEW]: '/catalog/products/view/:id/details',
    [PageName.PRODUCT_OFFERS_VIEW]: '/catalog/products/view/:id/offer-linking',
    [PageName.PRODUCT_EDIT]: '/catalog/products/edit/:id',
    [PageName.PRODUCT_DETAILS_EDIT]: '/catalog/products/edit/:id/details',
    [PageName.PRODUCT_OFFERS_EDIT]: '/catalog/products/edit/:id/offer-linking',

    // ./CHARACTERISTICS
    [PageName.CHARACTERISTICS]: '/catalog/characteristics',
    [PageName.CHARACTERISTICS_MAIN]: '/catalog/characteristics/main',
    [PageName.CHARACTERISTIC_CREATE]: '/catalog/characteristics/create',
    [PageName.CHARACTERISTIC_EDIT]: '/catalog/characteristics/edit/:id',
    [PageName.CHARACTERISTIC_VIEW]: '/catalog/characteristics/view/:id',

    // ./COLLECTIONS
    [PageName.COLLECTIONS]: '/catalog/collections',
    [PageName.COLLECTIONS_CREATE]: '/catalog/collections/create',
    [PageName.COLLECTIONS_EDIT]: '/catalog/collections/edit/:id',
    [PageName.COLLECTIONS_MAIN]: '/catalog/collections/main',
    [PageName.COLLECTIONS_VIEW]: '/catalog/collections/view/:id',

    // ./SUPPLIERS
    [PageName.SUPPLIERS]: '/catalog/suppliers',
    [PageName.SUPPLIERS_MAIN]: '/catalog/suppliers/main',
    [PageName.SUPPLIER_CREATE]: '/catalog/suppliers/create',
    [PageName.SUPPLIER_INFO]: '/catalog/suppliers/:id',
    [PageName.SUPPLIER_EDIT]: '/catalog/suppliers/:id/edit',
    [PageName.SUPPLIER_VIEW]: '/catalog/suppliers/:id/view',
    [PageName.EVENT_SET]: '/catalog/suppliers/:id/event-set',
    [PageName.DELIVERY]: '/catalog/suppliers/:id/delivery',
    [PageName.DELIVERY_COST_MATRIX]: '/catalog/suppliers/:id/delivery/cost-matrix',
    [PageName.DELIVERY_MATRIX_CHANGE_HISTORY]: '/catalog/suppliers/:id/delivery/change-history',
    [PageName.DELIVERY_MATRIX_UPLOAD_HISTORY]: '/catalog/suppliers/:id/delivery/upload-history',

    // ./SUBSCRIPTIONS
    [PageName.SUBSCRIPTIONS]: '/catalog/subscriptions',
    [PageName.SUBSCRIPTIONS_MAIN]: '/catalog/subscriptions/main',
    [PageName.SUBSCRIPTION_INFO]: '/catalog/subscriptions/:id',
    [PageName.SUBSCRIPTION_VIEW]: '/catalog/subscriptions/:id/view',
    [PageName.SUBSCRIPTION_CHANGE_HISTORY]: '/catalog/subscriptions/:id/change-history',

    // ORDERS_AND_PAYMENTS
    [PageName.ORDERS_AND_PAYMENTS]: '/orders-and-payments',

    // ./ORDERS
    [PageName.ORDERS]: '/orders-and-payments/orders',
    [PageName.ORDERS_MAIN]: '/orders-and-payments/orders/main',
    [PageName.ORDER_INFO]: '/orders-and-payments/orders/:id',
    [PageName.ORDER_PAYMENT]: '/orders-and-payments/orders/:id/payment',
    [PageName.ORDER_VIEW]: '/orders-and-payments/orders/:id/details',
    [PageName.ORDER_EDIT]: '/orders-and-payments/orders/:id/edit',
    [PageName.ORDER_CHANGE_HISTORY]: '/orders-and-payments/orders/:id/change-history',
    [PageName.ORDER_TRACKING]: '/orders-and-payments/orders/:id/order-tracking',

    // ./PAYMENTS
    [PageName.PAYMENTS]: '/orders-and-payments/payments',
    [PageName.PAYMENTS_MAIN]: '/orders-and-payments/payments/main',

    // CLIENTS_AND_PROFILES
    [PageName.CLIENTS_AND_PROFILES]: '/clients-and-profiles',
    [PageName.CLIENTS_AND_PROFILES_SEARCH]: '/clients-and-profiles/search',
    [PageName.CLIENTS_SEARCH_HASH]: '/clients-and-profiles/search-hash/:id',

    // ./CLIENT
    [PageName.CLIENT]: '/clients-and-profiles/client',
    [PageName.CLIENT_ACCOUNT]: '/clients-and-profiles/client/account/:id',
    [PageName.CLIENT_EXPIRATIONS]: '/clients-and-profiles/client/expirations/:id',
    [PageName.CLIENT_EXPIRATION]: '/clients-and-profiles/client/expiration/:id/:expirationId',
    [PageName.CLIENT_AGREEMENTS]: '/clients-and-profiles/client/agreements/:id',
    [PageName.CLIENT_AUTH_HISTORY]: '/clients-and-profiles/client/auth-history/:id',
    [PageName.CLIENT_TRANSACTIONS]: '/clients-and-profiles/client/transactions/:id',
    [PageName.CLIENT_SUBSCRIPTIONS]: '/clients-and-profiles/client/subscriptions/:id',
    [PageName.CLIENT_TRANSACTION_CREATE]: '/clients-and-profiles/client/:id/create-transaction',
    [PageName.CLIENT_ORDERS]: '/clients-and-profiles/client/orders/:id',
    [PageName.CLIENT_PROFILE]: '/clients-and-profiles/client/profile/:id',
    [PageName.CLIENT_PROFILE_DETAILS]: '/clients-and-profiles/client/profile/:id/view',

    // ACCOUNTS
    [PageName.ACCOUNTS]: '/accounts',

    // ./ROLES
    [PageName.ROLES]: '/accounts/roles',
    [PageName.ROLES_MAIN]: '/accounts/roles/main',
    [PageName.CREATE_ROLE]: '/accounts/roles/create-role',
    [PageName.VIEW_ROLE]: '/accounts/roles/view-role/:id',
    [PageName.EDIT_ROLE]: '/accounts/roles/edit-role/:id',

    // ./USERS
    [PageName.USERS]: '/accounts/users',
    [PageName.USERS_MAIN]: '/accounts/users/main',
    [PageName.CREATE_USER]: '/accounts/users/create-user',
    [PageName.EDIT_USER]: '/accounts/users/edit-user/:id',
    [PageName.USER_INFO]: '/accounts/users/:id',
    [PageName.VIEW_USER]: '/accounts/users/:id/view-user',
    [PageName.USER_CHANGE_HISTORY]: '/accounts/users/:id/change-history',

    // REPORTS
    [PageName.REPORTS]: '/reports',
    [PageName.REPORTS_MAIN]: '/reports/main',

    // REQUESTS
    [PageName.REQUESTS]: '/requests',
    [PageName.ALL_REQUESTS]: '/requests/all-requests',
    [PageName.ALL_REQUESTS_MAIN]: '/requests/all-requests/main',
    [PageName.ALL_REQUESTS_VIEW]: '/requests/all-requests/view/:id',

    // SETTINGS
    [PageName.SETTINGS]: '/settings',

    // ./NOTIFICATIONS
    [PageName.NOTIFICATIONS]: '/settings/notifications',
    [PageName.NOTIFICATIONS_MAIN]: '/settings/notifications/main',
    [PageName.NOTIFICATION_CREATE]: '/settings/notifications/create',
    [PageName.NOTIFICATION_EDIT]: '/settings/notifications/edit/:id',
    [PageName.NOTIFICATION_INFO]: '/settings/notifications/:id',
    [PageName.NOTIFICATION_CHANGE_HISTORY]: '/settings/notifications/:id/change-history',
    [PageName.NOTIFICATION_VIEW]: '/settings/notifications/:id/view',

    // ./SHORT_LINKS
    [PageName.SHORT_LINKS]: '/settings/short-links',
    [PageName.SHORT_LINKS_MAIN]: '/settings/short-links/main',
    [PageName.SHORT_LINK_CREATE]: '/settings/short-links/create',
    [PageName.SHORT_LINK_VIEW]: '/settings/short-links/view/:id',
    [PageName.SHORT_LINK_EDIT]: '/settings/short-links/edit/:id',

    // ./LISTS
    [PageName.LISTS]: '/settings/lists',
    [PageName.LISTS_MAIN]: '/settings/lists/main',
    [PageName.LIST_CREATE]: '/settings/lists/create',
    [PageName.LIST_EDIT]: '/settings/lists/edit/:id',
    [PageName.LIST_INFO]: '/settings/lists/:id',
    [PageName.LIST_VIEW]: '/settings/lists/:id/view',
    [PageName.LIST_UPLOAD_HISTORY]: '/settings/lists/:id/upload',

    // ./SEGMENTS
    [PageName.SEGMENTS]: '/settings/segments',
    [PageName.SEGMENTS_MAIN]: '/settings/segments/main',
    [PageName.SEGMENT_CREATE]: '/settings/segments/create',
    [PageName.SEGMENT_EDIT]: '/settings/segments/edit/:id',
    [PageName.SEGMENT_INFO]: '/settings/segments/:id',
    [PageName.SEGMENT_VIEW]: '/settings/segments/:id/view',
    [PageName.SEGMENT_UPLOAD_HISTORY]: '/settings/segments/:id/upload',

    // ./PAGE_BLOCKING
    [PageName.PAGE_BLOCKING]: '/settings/page-blocking',
    [PageName.PAGE_BLOCKING_VIEW]: '/settings/page-blocking/view',

    // PARTNER_OFFERS
    [PageName.PARTNER_OFFERS]: '/partner-offers',

    // ./PARTNER_OFFERS_OFFERS
    [PageName.PARTNER_OFFERS_OFFERS]: '/partner-offers/offers',
    [PageName.PARTNER_OFFERS_MAIN]: '/partner-offers/offers/main',
    [PageName.PARTNER_OFFERS_CREATE]: '/partner-offers/offers/create',
    [PageName.PARTNER_OFFERS_VIEW]: '/partner-offers/offers/view/:id',
    [PageName.PARTNER_OFFERS_EDIT]: '/partner-offers/offers/edit/:id',

    // ./PARTNER_OFFERS_CATEGORIES
    [PageName.PARTNER_OFFERS_CATEGORIES]: '/partner-offers/categories',
    [PageName.PARTNER_OFFERS_CATEGORIES_MAIN]: '/partner-offers/categories/main',
    [PageName.PARTNER_OFFERS_CATEGORIES_CREATE]: '/partner-offers/categories/create',
    [PageName.PARTNER_OFFERS_CATEGORIES_VIEW]: '/partner-offers/categories/view/:id',
    [PageName.PARTNER_OFFERS_CATEGORIES_EDIT]: '/partner-offers/categories/edit/:id',

    // ACCRUALS
    [PageName.ACCRUALS]: '/accruals',
    [PageName.ACCRUALS_REQUESTS]: '/accruals/requests',
    [PageName.ACCRUALS_REQUESTS_MAIN]: '/accruals/requests/main',
    [PageName.ACCRUALS_ACCRUALS]: '/accruals/accruals',
    [PageName.ACCRUALS_ACCRUALS_MAIN]: '/accruals/accruals/main',
    [PageName.ACCRUALS_ACCRUALS_VIEW]: '/accruals/accruals/view/:id',
};
