import { combine, createStore } from 'effector';
import { RefundStatusDictionary } from '@admin/model/orders/refund-status-dictionary/refund-status-dictionary.types';
import { getRefundStatusDictionaryFx } from '@admin/model/orders/refund-status-dictionary/get-refund-status-dictionary.fx';

export const $refundStatusDictionaryData = createStore<Nullable<RefundStatusDictionary>>(null);
export const $refundStatusDictionaryError = createStore<Nullable<unknown>>(null);

export const $refundStatusDictionary = combine({
    data: $refundStatusDictionaryData,
    error: $refundStatusDictionaryError,
    isLoading: getRefundStatusDictionaryFx.pending,
});
