import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { getSuccessMessage } from '@admin/model/products/change-product-characteristics/change-product-characteristics.helpers';
import { changeProductCharacteristicsFx } from '@admin/model/products/change-product-characteristics/change-product-characteristics.fx';

sample({
    clock: changeProductCharacteristicsFx.doneData,
    fn: (payload) => getSuccessMessage(payload),
    target: showNoticeEvent,
});
