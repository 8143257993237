import styled from 'styled-components';
import { Button, Paper, Typography } from 'ui-kit';

export const StyledModalWrapper = styled(Paper)`
    width: 470px;
`;

export const StyledModalBody = styled.div`
    padding: 65px 0 40px;
`;

export const StyledButton = styled(Button)`
    margin-top: 25px;
`;

export const StyledTypography = styled(Typography)`
    white-space: pre-wrap;
    word-break: break-word;
`;
