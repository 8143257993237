export enum ClientAgreementField {
    Id = 'id',
    AgreementCode = 'agreementCode',
    CreatedAt = 'createdAt',
    Hash = 'hash',
    AgreementName = 'agreementName',
}

export interface ClientAgreementData {
    [ClientAgreementField.Id]: string;
    [ClientAgreementField.AgreementCode]: string;
    [ClientAgreementField.CreatedAt]: string;
    [ClientAgreementField.Hash]: string;
}

export interface ClientAgreementPayload {
    profileId?: string;
    limit?: number;
    offset?: number;
}

export interface ClientAgreement extends Omit<ClientAgreementData, ClientAgreementField.CreatedAt> {
    [ClientAgreementField.CreatedAt]: Date;
    [ClientAgreementField.AgreementName]?: string;
}

export interface ClientAgreementsState {
    totalCount: number;
    data: ClientAgreement[];
}
