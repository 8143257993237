import {
    OfferPartnerListItem,
    OfferPartnerListItemData,
} from '@admin/model/offer-partners/offer-partners-list/offer-partners-list.types';

export function normalizeOfferPartnersData(offerPartnersData: OfferPartnerListItemData[]): OfferPartnerListItem[] {
    return offerPartnersData.map((offerPartner) => ({
        ...offerPartner,
        dateToPublishFrom: offerPartner.dateToPublishFrom ? new Date(offerPartner.dateToPublishFrom) : undefined,
        dateToPublishTo: offerPartner.dateToPublishFrom ? new Date(offerPartner.dateToPublishTo) : undefined,
        discountDateFrom: offerPartner.dateToPublishFrom ? new Date(offerPartner.discountDateFrom) : undefined,
        discountDateTo: offerPartner.dateToPublishFrom ? new Date(offerPartner.discountDateTo) : undefined,
        categories: offerPartner.categories.map(({ categoryId, categoryName }) => ({
            id: categoryId,
            name: categoryName,
        })),
    }));
}
