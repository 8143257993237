import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { createEffect } from 'effector';
import { UpdateNotificationsActivityPayload } from '@admin/model/notifications/update-notification-batching/update-notification-batching.types';

export const updateNotificationBatchingFx = createEffect(async (payload: UpdateNotificationsActivityPayload) => {
    try {
        await postRequest('notification/configurations/batching', payload);

        return payload.configurationIds;
    } catch (error) {
        logger(error);

        setCommonAppError({ error });

        throw null;
    }
});
