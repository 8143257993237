import { createEffect } from 'effector';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { postRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { isLargeEntityError } from '@admin/model/api/api.resource';
import { logger } from '@admin/common/helpers/logger';
import {
    CatalogPayload,
    UploadCatalogValidationErrorCode,
} from '@admin/model/offers/upload-catalog/upload-catalog.types';

export const uploadCatalogFx = createEffect(async ({ values, supplierId }: CatalogPayload) => {
    try {
        await postRequest(`catalog/uploads/${supplierId}`, values);
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, UploadCatalogValidationErrorCode)) {
            throw error.data.validationErrors[0];
        } else if (isLargeEntityError(error)) {
            throw {
                code: UploadCatalogValidationErrorCode.FileSizeLimit,
            };
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
