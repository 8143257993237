import { OrderStatusDictionary } from '@admin/model/orders/orders.types';
import { DictionaryData } from '@admin/model/model.types';

export function parseData<T>(data: DictionaryData): OrderStatusDictionary<T> {
    return data.dictionaryValueList.reduce<OrderStatusDictionary<T>>((acc, eventData) => {
        acc[eventData.key] = {
            status: eventData.key,
            ...JSON.parse(eventData.value),
        };

        return acc;
    }, {});
}
