import { sample } from 'effector';
import { normalizeDate } from '@admin/model/helpers';
import {
    $notificationsListData,
    $notificationsListError,
} from '@admin/model/notifications/notifications-list/notifications-list.store';
import { getNotificationsListFx } from '@admin/model/notifications/notifications-list/get-notifications-list.fx';

sample({
    clock: getNotificationsListFx.doneData,
    fn: (result) => ({
        ...result,
        data: result.data.map((config) => ({
            ...config,
            updatedAt: normalizeDate(config.updatedAt),
        })),
    }),
    target: $notificationsListData,
});

sample({
    clock: getNotificationsListFx.failData,
    target: $notificationsListError,
});
