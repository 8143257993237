import { DictionaryData } from '@admin/model';
import { isJson } from '@admin/common/helpers/is-json';

export type SupplierOption = {
    value: string;
    title: string;
};

export type SupplierOptionDictionary = {
    array: SupplierOption[];
    dictionary: Record<string, SupplierOption | undefined>;
};

export function parseData(supplierFormConfigsData: DictionaryData): SupplierOptionDictionary {
    return supplierFormConfigsData.dictionaryValueList.reduce<SupplierOptionDictionary>(
        (acc, optionDictionary) => {
            const title = isJson(optionDictionary.value)
                ? (JSON.parse(optionDictionary.value) as { description?: string })?.description
                : optionDictionary.value;

            const option: SupplierOption = {
                value: optionDictionary.key,
                title: title ?? '',
            };
            acc.dictionary[optionDictionary.key] = option;
            acc.array.push(option);

            return acc;
        },
        {
            array: [],
            dictionary: {},
        },
    );
}
