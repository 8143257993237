import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { DictionaryData } from '@admin/model/model.types';
import { parseData } from '@admin/model/reports/notifications-statuses-dictionary/notifications-statuses-dictionary.helpers';

export const getNotificationsStatusesDictionaryFx = createEffect(async () => {
    try {
        const data = await getRequest<DictionaryData>('dictionaries/NOTIFICATION_STATUSES');

        return parseData(data);
    } catch (error) {
        logger(error);

        throw error;
    }
});
