import { DictionaryBaseItem, DictionaryData } from '@admin/model/model.types';
import { CharacteristicTypesDictionary } from '@admin/model/characteristics/characteristics-types-dictionary/characteristics-types-dictionary.types';

export const parseData = (CharacteristicTypesDictData: DictionaryData): CharacteristicTypesDictionary =>
    CharacteristicTypesDictData.dictionaryValueList.reduce<CharacteristicTypesDictionary>(
        (acc, CharacteristicTypesData) => {
            const parsed = JSON.parse(CharacteristicTypesData.value) as DictionaryBaseItem;
            acc.array.push(parsed);
            acc.dictionary[parsed.code] = parsed;
            return acc;
        },
        {
            array: [],
            dictionary: {},
        },
    );
