import { combine, createStore } from 'effector';
import { getListTypesDictionaryFx } from '@admin/model/lists/list-types-dictionary/get-list-types-dictionary.fx';
import { ListTypesDictionary } from '@admin/model/lists/list-types-dictionary/list-types-dictionary.types';

export const $listTypesDictionaryData = createStore<Nullable<ListTypesDictionary>>(null);
export const $listTypesDictionaryError = createStore<Nullable<unknown>>(null);

export const $listTypesDictionary = combine({
    data: $listTypesDictionaryData,
    error: $listTypesDictionaryError,
    isLoading: getListTypesDictionaryFx.pending,
});
