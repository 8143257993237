import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { getRequest } from '@admin/model/api';
import { DictionaryData } from '@admin/model/model.types';

export const getTargetAudienceFx = createEffect(async () => {
    try {
        return await getRequest<DictionaryData>('dictionaries/CLIENT_TARGETS');
    } catch (error) {
        logger(error);

        throw error;
    }
});
