import { OrderState } from '@admin/model/orders/orders-list/orders-list.types';
import { combine, createEvent, createStore } from 'effector';
import { getOrdersListFx } from '@admin/model/orders/orders-list/get-orders-list.fx';

const ordersListInitialState: OrderState = {
    orders: [],
    totalCount: null,
};

export const resetOrdersListEvent = createEvent();
export const mapOrdersListEvent = createEvent<OrderState>();

export const $ordersListData = createStore<OrderState>(ordersListInitialState);
export const $ordersListError = createStore<Nullable<unknown>>(null);

export const $ordersList = combine({
    data: $ordersListData,
    error: $ordersListError,
    isLoading: getOrdersListFx.pending,
});
