import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { getRequest } from '@admin/model/api';
import { ClientAgreementPayload } from '@admin/model/clients-and-profiles/client-agreement-list/client-agreement-list.types';
import { ClientAgreementData } from '@admin/model/clients-and-profiles/client-agreement-list/client-agreement-list.types';

export const getClientAgreementListFx = createEffect(async ({ profileId, ...payload }: ClientAgreementPayload) => {
    try {
        return await getRequest<ClientAgreementData[]>(`clients/${profileId}/agreements`, payload);
    } catch (error) {
        logger(error);

        throw error;
    }
});
