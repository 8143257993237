export const HEADER_PORTAL_ANCHOR = 'header-portal-anchor';
export const NAVIGATION_TABS_PORTAL_ANCHOR = 'navigation-tabs-portal-anchor';
export const SUB_NAVIGATION_TABS_PORTAL_ANCHOR = 'sub-navigation-tabs-portal-anchor';

export const NAVIGATION_ACTIONS_PORTAL_ANCHOR = 'navigation-actions-portal-anchor';
export const SIDEBAR_PORTAL_ANCHOR = 'sidebar-portal-anchor';
export const HEADER_HEIGHT = 84;
export const PAGE_TABS_HEIGHT = 40;
export const HEIGHT_MAIN_INDENT = 16;
export const FILTERS_PANEL_HEIGHT = 60;
export const NAVIGATION_PANEL_WIDTH = 246;
export const STICKY_HEADER_OFFSET = HEADER_HEIGHT + PAGE_TABS_HEIGHT + FILTERS_PANEL_HEIGHT;
export const FOLDED_NAVIGATION_PANEL_WIDTH = 80;
export const MAX_CONTENT_WIDTH = 1920;
export const MIN_CONTENT_WIDTH = 1280;
export const SCROLLBAR_RESERVE_WIDTH = 20;
export const ITEMS_PER_PAGE = 50;
export const SIDEBAR_WIDTH = 620;
export const TABLE_SIDEBAR_WIDTH = 700;
export const NAVIGATION_PANEL_BREAKPOINT = 1439;
export enum ElementLayer {
    CellPopover = 0,
    FormPopover = 1000,
    Navigation = 2000,
    NavigationMenu = 3000,
    Overlay = 4000,
    QuickActions = 5000,
    Uploading = 6000,
}
export const NAVIGATION_PANEL_BREAKPOINT_MEDIA_QUERY = `(max-width: ${NAVIGATION_PANEL_BREAKPOINT}px)`;
export const DEFAULT_PERIOD = 60;
export const YEAR_PERIOD = 365;
