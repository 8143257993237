import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { createEffect } from 'effector';
import {
    DownloadCouponCodesPayload,
    DownloadCouponCodesData,
} from '@admin/model/offers/download-coupon-codes/download-coupon-codes.types';

export const downloadCouponCodesFx = createEffect(async (payload: DownloadCouponCodesPayload) => {
    try {
        return await postRequest<DownloadCouponCodesData, DownloadCouponCodesPayload>('reports', payload);
    } catch (error) {
        logger(error);
        setCommonAppError({ error });
        throw null;
    }
});
