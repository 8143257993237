import { normalizeDate } from '@admin/model/helpers';
import { ChangeHistory, ChangeHistoryData, ChangeHistoryItemData } from '@admin/model/model.types';
import { UserChangeHistoryState } from '@admin/model/users/user-change-history/user-change-history.types';

export const normalizeUserChangeHistoryData = (
    response: ChangeHistoryData<ChangeHistoryItemData<UserChangeHistoryState>>,
): ChangeHistory<UserChangeHistoryState> => ({
    ...response,
    createdAt: normalizeDate(response.createdAt) as Date,
    data: response.data.map((dataItem) => ({ ...dataItem, changedAt: normalizeDate(dataItem.changedAt) as Date })),
});
