import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { createEffect } from 'effector';
import { AccrualsRequestsErrorsResponse } from '@admin/model/accruals/accruals.types';

export const getAccrualsRequestsErrorFx = createEffect(async (id: string): Promise<AccrualsRequestsErrorsResponse> => {
    try {
        return await getRequest(`accruals/uploads/${id}/errors`);
    } catch (error) {
        logger(error);

        throw error;
    }
});
