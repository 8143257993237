import { ValidationError } from '@admin/common/validation';

export enum DeleteOfferCategoriesValidationErrorCode {
    SomeError = 'SOME_ERROR',
    OfferPartnerWithOnlyThisCategory = 'OFFER_PARTNER_WITH_ONLY_THIS_CATEGORY',
}

export type DeleteOfferCategoriesValidationError = ValidationError<DeleteOfferCategoriesValidationErrorCode>;

export interface DeleteOfferCategoriesPayload {
    categoryIds: string[];
}
