import { StyledChildrenOverlay, StyledGlobalOverlay } from '@admin/components/overlay/overlay.styles';
import React, { memo, PropsWithChildren } from 'react';
import { StyledSpinner } from '@admin/components/overlay/overlay.styles';
import { RingSpinnerVariant } from 'ui-kit';

export interface OverlayProps {
    isActive: boolean;
    hasSpinner?: boolean;
    variant?: RingSpinnerVariant;
}

const OverlayComponent = ({
    children,
    isActive,
    hasSpinner = false,
    variant = RingSpinnerVariant.PrimaryLarge,
}: PropsWithChildren<OverlayProps>) => {
    // eslint-disable-next-line
    const Component = Boolean(children) ? StyledChildrenOverlay : StyledGlobalOverlay;

    return (
        <Component $isActive={isActive}>
            {hasSpinner && isActive && <StyledSpinner variant={variant} />}
            {children}
        </Component>
    );
};

export const Overlay = memo(OverlayComponent);
