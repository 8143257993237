import { Role } from '@admin/model/roles';
import { UserField, UserStatus, UserSupplier } from '@admin/model/users/user.types';

export interface UserChangeHistoryPayload {
    id?: string;
    limit?: number;
    offset?: number;
    ordering?: string;
    startAt?: string;
    endAt?: string;
}

export enum UserChangeHistoryStateCustomField {
    PasswordExpiresAt = 'passwordExpiresAt',
    AccessDesc = 'accessDesc',
}

export interface UserChangeHistoryState {
    [UserField.Status]?: Nullable<UserStatus>;
    [UserField.Email]?: Nullable<string>;
    [UserField.FirstName]?: Nullable<string>;
    [UserField.LastName]?: Nullable<string>;
    [UserField.Login]?: Nullable<string>;
    [UserField.PasswordSet]?: Nullable<boolean>;
    [UserField.PatronymicName]?: Nullable<string>;
    [UserField.Phone]?: Nullable<string>;
    [UserField.Roles]?: Nullable<Role[]>;
    [UserField.Suppliers]?: Nullable<UserSupplier[]>;
    [UserChangeHistoryStateCustomField.PasswordExpiresAt]?: string;
    [UserChangeHistoryStateCustomField.AccessDesc]?: string;
}
