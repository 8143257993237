import { combine, createEvent, createStore } from 'effector';
import { updateCollectionsBatchingFx } from '@admin/model/collections/update-collections-batching/update-collections-batching.fx';

export const resetUpdateCollectionsBatchingDoneStatusEvent = createEvent();
export const $updateCollectionsBatchingDone = createStore<boolean>(false);
export const $updateCollectionsBatchingError = createStore<Nullable<unknown>>(null);

export const $updateCollectionsBatching = combine({
    data: $updateCollectionsBatchingDone,
    error: $updateCollectionsBatchingError,
    isLoading: updateCollectionsBatchingFx.pending,
});
