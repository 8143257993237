import { CalculationType, CancelOrdersAction, LevelAccessAPIPosOrderGateway, OrderStatus } from '@admin/model';
import {
    Currency,
    FiscalizationType,
    ProgramLoyalty,
    SupplierConfidence,
    SupplierStatus,
    Vat,
    OTPProvider,
    FiscalizationPaymentType,
    FiscalizationLineType,
} from '@admin/model/model.types';

export interface SupplierSettings {
    programLoyalty?: ProgramLoyalty;
    cashbackCurrency?: Currency[];
    certThumbprint?: string;
    publicKey?: string;
    retailPoints?: string[];
    publicWebsite?: string;
    logo?: string;
    offerFile?: string;
    levelAccessAPIPosOrderGateway?: LevelAccessAPIPosOrderGateway;
    SMSTemplateOTP?: string;
    ordersToNotifyInPA?: OrderStatus[];
    ordersToNotifyInPAShort?: OrderStatus[];
    ordersToNotifyByEmail?: OrderStatus[];
    ordersToNotifyByEmailShort?: OrderStatus[];
    publicEmail?: string;
    publicPhones?: string[];
    orderPersonilizedTemplate?: string;
    statusOrderToSendPersonilizedMessage?: OrderStatus;
    enableSendPersonilizedMessageEmail?: boolean;
    errorEmail?: string[];
    ordersReturnEmails?: string[];
    contactNumbers?: string[];
    ordersReportEmails?: string[];
    lowCouponEmails?: string[];
    downloadCatalogURL?: string;
    ordersCheckUrl?: string;
    ordersConfirmUrl?: string;
    setStatusAfterConfirmation?: OrderStatus;
    cancelOrderTimeLimit?: number;
    mandatoryOrderEmail?: boolean;
    enableReturn?: boolean;
    orderStatusNotificationUrl?: string;
    bonusPaymentGate?: string;
    extraPaymentGate?: string;
    bonusPaymentGateId?: number;
    cardPaymentGateId?: string;
    availablePartCardPayment?: number;
    returnURL?: string;
    fiscalizationType?: FiscalizationType;
    vat?: Vat;
    fiscalizationGateId?: string;
    supplierINN?: string;
    supplierName?: string;
    otpProvider?: OTPProvider;
    rsSslPrivateKeyFileName?: string;
    rsSslPublicKey?: string;
    overdueOrdersAction?: CancelOrdersAction;
    alias?: string;
    enableCouponActivationURL?: boolean;
    downloadCouponsAvailable?: boolean;
    payAttribute?: FiscalizationPaymentType;
    lineAttribute?: FiscalizationLineType;
    bankPaymentType?: string;
    bonusCurrencyPayment?: string;
    showOrderPreview?: boolean;
}

export interface SupplierDeliverySettings {
    deliveryPartnerId?: string;
    name?: string;
    deliveryFixedCost?: string;
    deliveryCalculationType?: CalculationType;
    deliveryMatrixDownloadUrl?: string;
    deliveryOptionsUrl?: string;
    deliveryPartialPayment?: boolean;
    status?: SupplierStatus;
    deliveryResultsMapping?: string;
}

export interface Supplier {
    id: number;
    supplierId?: number;
    deliveryPartnerId?: string;
    name: string;
    partnerType: SupplierPartnerType;
    status: SupplierStatus;
    description: string;
    confidence: SupplierConfidence;
    fiscalization?: boolean;
    rangeCoefficient?: number;
    settings?: SupplierSettings;
    deliverySettings?: SupplierDeliverySettings | null;
    enableDeliveryPartner?: boolean;
    notificationSettings: Nullable<{ allowedEventTypes: string[] }>;
}

export interface SuppliersData {
    data: Supplier[];
    totalCount: number;
}

export interface SuppliersPayload {
    offset: number;
    limit: number;
    confidence?: string[];
    status?: string[];
    typePartner?: string[];
    ordering?: string;
    term?: string;
    fiscalization?: boolean;
}

export enum SupplierPartnerType {
    OnlinePartner = 'ONLINE_PARTNER',
    RetailPartner = 'RETAIL_PARTNER',
    TransferPartner = 'TRANSFER_PARTNER',
    PartnerInCatalog = 'PARTNER_IN_CATALOG',
}

export enum SupplierFormValidationErrorCode {
    MandatoryFieldEmpty = 'MANDATORY_FIELD_EMPTY',
    MissingRequiredField = 'MISSING_REQUIRED_FIELD',
    MissingRequiredFieldForSelectedType = 'MISSING_REQUIRED_FIELD_FOR_SELECTED_TYPE',
    MissingRequiredFieldForSelectedDeliveryCalculationType = 'MISSING_REQUIRED_FIELD_FOR_SELECTED_DELIVERY_CALCULATION_TYPE',
    SizeExceeded = 'SIZE_EXCEEDED',
    UniqueRetailIdViolation = 'UNIQUE_RETAILID_VIOLATION',
    UniqueCurriencyVioldation = 'UNIQUE_CURRIENCY_VIOLATION',
    NameAlreadyTaken = 'NAME_ALREADY_TAKEN',
    MismatchType = 'MISMATCH_TYPE',
    SupplierHasActiveProducts = 'SUPPLIER_HAS_ACTIVE_PRODUCTS',
    InvalidFileFormat = 'INVALID_FILE_FORMAT',
    SupplierAlreadyExists = 'SUPPLIER_ALREADY_EXISTS',
    NotUrl = 'NOT_URL',
    StringFieldIsNotValid = 'STRING_FIELD_IS_NOT_VALID',
}
