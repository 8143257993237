import { combine, createEvent, createStore } from 'effector';
import { getSupplierFx } from '@admin/model/suppliers/supplier/get-supplier.fx';
import { Supplier } from '@admin/model/suppliers/suppliers.types';

export const resetSupplierEvent = createEvent();

export const $supplierData = createStore<Nullable<Supplier>>(null);
export const $supplierError = createStore<Nullable<unknown>>(null);

export const $supplier = combine({
    data: $supplierData,
    error: $supplierError,
    isLoading: getSupplierFx.pending,
});
