import { createDomain } from 'effector';

export const rootDomain = createDomain();

export const routingDomain = rootDomain.createDomain();
export const categoriesDomain = rootDomain.createDomain();
export const characteristicsDomain = rootDomain.createDomain();
export const ordersDomain = rootDomain.createDomain();
export const transactionsDomain = rootDomain.createDomain();
export const rolesDomain = rootDomain.createDomain();
