import { sample } from 'effector';
import { getCollectionsListFx } from '@admin/model/collections/collections-list/get-collections-list.fx';
import {
    $collectionsListData,
    $collectionsListError,
} from '@admin/model/collections/collections-list/collections-list.store';
import { Category } from '@admin/model/categories/category/category.types';
import { deleteCollectionFx } from '@admin/model/collections/delete-collection/delete-collection.fx';
import { changeCollectionVisibilityFx } from '@admin/model/collections/collections-visibility/collections-visibility.fx';

sample({
    clock: getCollectionsListFx.doneData,
    source: $collectionsListData,
    fn: (_state, result) => result,
    target: $collectionsListData,
});

sample({
    clock: getCollectionsListFx.failData,
    target: $collectionsListError,
});

sample({
    clock: changeCollectionVisibilityFx.doneData,
    source: $collectionsListData,
    fn: (state, result) => state.map((collection: Category) => (collection.id === result.id ? result : collection)),
    target: $collectionsListData,
});

sample({
    clock: deleteCollectionFx.doneData,
    source: $collectionsListData,
    fn: (state, result) => state.filter(({ id }: Category) => id !== result),
    target: $collectionsListData,
});
