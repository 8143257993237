import { sample } from 'effector';
import { getOrdersListFx } from '@admin/model/orders/orders-list/get-orders-list.fx';
import {
    $ordersListData,
    $ordersListError,
    resetOrdersListEvent,
    mapOrdersListEvent,
} from '@admin/model/orders/orders-list/orders-list.store';
import { $orderStatusDictionaryData } from '@admin/model/orders/order-status-dictionary/order-status-dictionary.store';
import { $aggregatedPaymentStatusDictionaryData } from '@admin/model/orders/aggregated-payment-status-dictionary/aggregated-payment-status-dictionary.store';
import { normalizeDate } from '@admin/model/helpers';
import { reset } from 'patronum';

sample({
    clock: getOrdersListFx.doneData,
    source: {
        orderStatusDictionaryData: $orderStatusDictionaryData,
        aggregatedPaymentStatusDictionaryData: $aggregatedPaymentStatusDictionaryData,
    },
    fn: (state, result) => ({
        orders: result?.data?.map((item) => ({
            ...item,
            orderCreateDate: normalizeDate(item.createdAt),
            status: state.orderStatusDictionaryData
                ? state.orderStatusDictionaryData?.[item.status]
                : { status: item.status, description: '' },
            aggregatedPaymentStatus: state.aggregatedPaymentStatusDictionaryData
                ? state.aggregatedPaymentStatusDictionaryData?.[item.aggregatedPaymentStatus]
                : { status: item.aggregatedPaymentStatus, description: '' },
        })),
        totalCount: result.totalCount,
    }),
    target: mapOrdersListEvent,
});

sample({
    clock: mapOrdersListEvent,
    source: $ordersListData,
    fn: (state, result) => ({
        orders: result.orders ? [...state.orders, ...result.orders] : state.orders,
        totalCount: result.totalCount,
    }),
    target: $ordersListData,
});

sample({
    clock: getOrdersListFx.failData,
    target: $ordersListError,
});

reset({ clock: resetOrdersListEvent, target: [$ordersListData] });
