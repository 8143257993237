import { ValidationError } from '@admin/common/validation';
import { Role, RoleData } from '@admin/model/roles/roles.types';

export enum UsersQueryParam {
    Ordering = 'ordering',
    Page = 'page',
    SupplierId = 'supplierId',
    Role = 'role',
    Status = 'status',
    Term = 'term',
}

export enum UserStatus {
    Enabled = 'ENABLED',
    DisabledByAdmin = 'DISABLED_BY_ADMIN',
    DisabledBySystem = 'DISABLED_BY_SYSTEM ',
}

export enum UserField {
    Login = 'login',
    FirstName = 'firstName',
    LastName = 'lastName',
    PatronymicName = 'patronymicName',
    Email = 'email',
    Phone = 'phone',
    Roles = 'roles',
    Suppliers = 'suppliers',
    Status = 'status',
    PasswordSet = 'passwordSet',
    Reports = 'reports',
}

export interface UserSupplier {
    id: number;
    name: string;
}

export interface UserData {
    status: UserStatus;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    login: string;
    passwordSet: boolean;
    patronymicName: string;
    is2faEnabled?: boolean;
    phone: Nullable<string>;
    registrationDate: string;
    roles: RoleData[];
    suppliers: UserSupplier[];
    reports: {
        reportCode: string;
    }[];
}

export interface User {
    status: UserStatus;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    login: string;
    passwordSet: boolean;
    is2faEnabled?: boolean;
    patronymicName?: string;
    phone: Nullable<string>;
    registrationDate?: Date;
    roles: Role[];
    suppliers: UserSupplier[];
    reports: string[];
}

export interface UsersData {
    data: UserData[];
    totalCount: number;
}

export interface ShortenedUser {
    firstName: Nullable<string>;
    lastName: Nullable<string>;
    patronymicName: Nullable<string>;
    userId: Nullable<string>;
    userLogin?: Nullable<string>;
}

export interface UsersPayload {
    offset: number;
    limit: number;
    ordering?: string;
    supplierId?: string[];
    role?: string[];
    status?: string[];
    term?: string;
}

export interface UserPayload {
    [UserField.FirstName]?: string;
    [UserField.LastName]?: string;
    [UserField.PatronymicName]?: string;
    [UserField.Email]?: string;
    [UserField.Phone]?: string;
    [UserField.Roles]?: string[];
    [UserField.Suppliers]?: UserSupplier[];
    [UserField.Reports]?: string[];
}

export enum UsersValidationErrorCode {
    LoginNull = 'LOGIN_NULL',
    FirstNameNull = 'FIRST_NAME_NULL',
    LastNameNull = 'LAST_NAME_NULL',
    ContactsNull = 'CONTACTS_NULL',
    LoginInvalid = 'LOGIN_INVALID',
    EmailInvalid = 'EMAIL_INVALID',
    PhoneInvalid = 'PHONE_INVALID',
    SizeExceeded = 'SIZE_EXCEEDED',
    NameAlreadyTaken = 'NAME_ALREADY_TAKEN',
}

export type UsersValidationError = ValidationError<UsersValidationErrorCode>;

export enum Permission {
    AllPartnersObjectAccess = 'ALL_PARTNERS_OBJECT_ACCESS',
    AllRequestsAccess = 'ALL_REQUESTS_ACCESS',
    CategoryCreateDelete = 'CATEGORY_CREATE_DELETE',
    CategoryEdit = 'CATEGORY_EDIT',
    ClientActivationAccess = 'CLIENT_ACTIVATION_ACCESS',
    ClientChangeContactsAccess = 'CLIENT_CHANGE_CONTACTS_ACCESS',
    ClientDeleteAccess = 'CLIENT_DELETE_ACCESS',
    ClientReadAccess = 'CLIENT_READ_ACCESS',
    ClientReadPersonalDataAccess = 'CLIENT_READ_PERSONAL_DATA_ACCESS',
    ClientResetPasswordAccess = 'CLIENT_RESET_PASSWORD_ACCESS',
    ClientSetLimitAccess = 'CLIENT_SET_LIMIT_ACCESS',
    ClientSubscriptionsAccess = 'CLIENT_SUBSCRIPTIONS_ACCESS',
    ClientSubscriptionsRead = 'CLIENT_SUBSCRIPTIONS_READ',
    ClientDisableAccessWithResetPhoneNumber = 'CLIENT_DISABLE_ACCESS_WITH_RESET_PHONE_NUMBER',
    ClientTransactionsRead = 'CLIENT_TRANSACTIONS_READ',
    ClientSubscriptionsReadCl = 'CLIENT_SUBSCRIPTIONS_READ_CL',
    ClientSubscriptionsAccessCl = 'CLIENT_SUBSCRIPTIONS_ACCESS_CL',
    ClientTransactionsEdit = 'CLIENT_TRANSACTIONS_EDIT',
    CollectionsCategoryCreateDelete = 'COLLECTIONS_CATEGORY_CREATE_DELETE',
    CollectionsCategoryEdit = 'COLLECTIONS_CATEGORY_EDIT',
    CollectionsCategoryRead = 'COLLECTIONS_CATEGORY_READ',
    DiscountProgramAccess = 'DISCOUNT_PROGRAM_ACCESS',
    DwhAllReportsAccess = 'DWH_ALL_REPORTS_ACCESS',
    DwhReportsAccess = 'DWH_REPORTS_ACCESS',
    IndividualRequestsAccess = 'INDIVIDUAL_REQUESTS_ACCESS',
    LimitsRead = 'LIMITS_READ',
    NotificationConfigEdit = 'NOTIFICATION_CONFIG_EDIT',
    NotificationConfigView = 'NOTIFICATION_CONFIG_VIEW',
    PartnersCreateEdit = 'PARTNERS_CREATE_EDIT',
    PartnersObjectsCategoryEdit = 'PARTNERS_OBJECTS_CATEGORY_EDIT',
    PartnersObjectsCollectionActivate = 'PARTNERS_OBJECTS_COLLECTION_ACTIVATE',
    PartnersObjectsCollectionEdit = 'PARTNERS_OBJECTS_COLLECTION_EDIT',
    PartnersObjectsCollectionRead = 'PARTNERS_OBJECTS_COLLECTION_READ',
    PartnersObjectsMatrixReadEdit = 'PARTNERS_OBJECTS_MATRIX_READ_EDIT',
    PartnersObjectsOrdersCommissionsEdit = 'PARTNERS_OBJECTS_ORDERS_COMMISSIONS_EDIT',
    PartnersObjectsOrdersExternalOrderIdInput = 'PARTNERS_OBJECTS_ORDERS_EXTERNAL_ORDER_ID_INPUT',
    PartnersObjectsOrdersPriceRead = 'PARTNERS_OBJECTS_ORDERS_PRICE_READ',
    PartnersObjectsOrdersRead = 'PARTNERS_OBJECTS_ORDERS_READ',
    PartnersObjectsOrdersReportRead = 'PARTNERS_OBJECTS_ORDERS_REPORT_READ',
    PartnersObjectsOrdersReturnCreate = 'PARTNERS_OBJECTS_ORDERS_RETURN_CREATE',
    PartnersObjectsOrdersStatusEdit = 'PARTNERS_OBJECTS_ORDERS_STATUS_EDIT',
    PartnersObjectsOrdersEditAdvanced = 'PARTNERS_OBJECTS_ORDERS_EDIT_ADVANCED',
    PartnersObjectsCouponsEdit = 'PARTNERS_OBJECTS_COUPONS_EDIT',
    PartnersObjectsCouponsRead = 'PARTNERS_OBJECTS_COUPONS_READ',
    PartnersObjectsProductsEdit = 'PARTNERS_OBJECTS_PRODUCTS_EDIT',
    PartnersObjectsOrdersUpdate = 'PARTNERS_OBJECTS_ORDERS_UPDATE',
    PartnersRead = 'PARTNERS_READ',
    PartnersObjectsOrdersReturnByProtestDeptCreate = 'PARTNERS_OBJECTS_ORDERS_RETURN_BY_PROTEST_DEPT_CREATE',
    ProductsCharacteristicsEdit = 'PRODUCTS_CHARACTERISTICS_EDIT',
    UsersCreateEdit = 'USERS_CREATE_EDIT',
    ViewLogHistory = 'VIEW_LOG_HISTORY',
    ListCreateEditDelete = 'LIST_CREATE_EDIT_DELETE',
    OffersRead = 'OFFERS_READ',
    OffersEdit = 'OFFERS_EDIT',
    OffersDelete = 'OFFERS_DELETE',
    OffersCatalogUpload = 'OFFERS_CATALOG_UPLOAD',
    OffersStatusChange = 'OFFERS_STATUS_CHANGE',
    OffersModerate = 'OFFERS_MODERATE',
    OrderPaymentsEdit = 'ORDER_PAYMENTS_EDIT',
    ProductRead = 'PRODUCT_READ',
    ProductCardCreateEditDelete = 'PRODUCT_CARD_CREATE_EDIT_DELETE',
    LinkWatch = 'LINK_WATCH',
    LinkEdit = 'LINK_EDIT',
    OfferPartnersRead = 'OFFER_PARTNERS_READ',
    OfferPartnersCreateEdit = 'OFFER_PARTNERS_CREATE_EDIT',
    OfferPartnersModerate = 'OFFER_PARTNERS_MODERATE',
    PartnersObjectsCouponsUpload = 'PARTNERS_OBJECTS_COUPONS_UPLOAD',
    RequestsAccrualsRead = 'REQUESTS_ACCRUALS_READ',
    RequestsAccrualsUpload = 'REQUESTS_ACCRUALS_UPLOAD',
    AccrualReadAccess = 'ACCRUAL_READ_ACCESS',
    BlockingPagesView = 'BLOCKING_PAGES_SETTINGS_CONFIG_VIEW',
    BlockingPagesEdit = 'BLOCKING_PAGES_SETTINGS_CONFIG_EDIT',
    ClientAgreementsRead = 'CLIENT_AGREEMENTS_READ',
    ClientLogInHistoryRead = 'CLIENT_LOG_IN_HISTORY_READ',
    ClientSegmentsUpload = 'CLIENT_SEGMENTS_UPLOAD',
}
