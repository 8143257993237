import { logger } from '@admin/common/helpers/logger';
import { deleteRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { createEffect } from 'effector';

export const deleteCollectionFx = createEffect(async (id: string) => {
    try {
        await deleteRequest(`catalog/categories/${id}`, undefined, undefined, {
            deleteNotEmpty: true,
        });

        return id;
    } catch (error) {
        logger(error);

        setCommonAppError({ error });

        throw null;
    }
});
