import { ValidationError } from '@admin/common/validation';

export interface UploadMatrixPayload {
    data: FormData;
    deliveryPartnerId: string;
}

export enum UploadMatrixValidationErrorCode {
    FormatNotValid = 'FORMAT_NOT_VALID',
    InvalidFormat = 'INVALID_FORMAT',
    FileNameNotValid = 'FILENAME_NOT_VALID',
}

export type UploadMatrixValidationError = ValidationError<UploadMatrixValidationErrorCode>;
