import { ClientData, ClientAccountState } from '@admin/model/clients-and-profiles/client-account/client-account.types';
import { ProfileStatus } from '@admin/model/model.types';

export const mapClientAccount = (data: ClientData): ClientAccountState => {
    return {
        hash: data.hash,
        currentCurrency: data.currentCurrency,
        balance: data.balance,
        currenciesBalance: data.accountSchemas.map((item) => ({
            profileId: item.profileId,
            currencyType: item.currency,
            isActive: Boolean(item.active),
            balance: item.balance,
            status: item.status,
        })),
        changePhoneHistory: data.changeHistory.map((item) => ({
            ...item,
            changeDate: new Date(item.changeDate),
            oldPhone: item.oldValue,
            newPhone: item.newValue,
        })),
        manualBlockingReasons: data.manualBlockingReasons.map((item) => ({
            createdAt: new Date(item.createdAt),
            createdBy: item.createdBy,
            status: item.result,
            reason: item.blockingReason,
            comment: item.blockingReasonComment,
        })),
        access: data.status,
        id: data.clientId,
        isPasswordSet: data.passwordSet,
        location: data.location,
        login: data.phoneNumber,
        profiles: data.clientProfiles
            .map((profile) => ({
                ...profile,
                registrationDate:
                    profile.registrationDate !== null && Boolean(Date.parse(profile.registrationDate))
                        ? new Date(profile.registrationDate)
                        : null,
                activationDate:
                    profile.activationDate !== null && Boolean(Date.parse(profile.activationDate))
                        ? new Date(profile.activationDate)
                        : null,
                dateOfBirth:
                    profile.dateOfBirth !== null && Boolean(Date.parse(profile.dateOfBirth))
                        ? new Date(profile.dateOfBirth)
                        : null,
                profileBlockInfo: profile.profileBlockInfo
                    ? {
                          ...profile.profileBlockInfo,
                          createdAt: profile.profileBlockInfo.createdAt
                              ? new Date(profile.profileBlockInfo.createdAt)
                              : null,
                      }
                    : null,
            }))
            .sort((a, b) => {
                return a.status === ProfileStatus.Active ? -1 : b.status === ProfileStatus.Active ? 1 : 0;
            }),
    };
};
