import { Category } from '@admin/model/categories/category/category.types';
import { UpdateChildCategoriesItem } from '@admin/model/categories/categories-list/categories-list.types';
import { Currency } from '@admin/model/model.types';

export function replaceCategory(rows: Category[], newRow: Category): Category[] {
    return rows.map((row) => {
        if (row.id === newRow.id) {
            return newRow;
        }

        return row.childCategories !== null
            ? {
                  ...row,
                  childCategories: replaceCategory(row.childCategories, newRow),
              }
            : row;
    });
}

const getUpdatedChildCategories = (childCategories: Nullable<Category[]>, newChildCategories: Category[]) => {
    if (childCategories !== null) {
        const previousChildCategoriesMap: Record<string, Nullable<Category[]>> = {};
        for (const { id, childCategories: previousChildCategories } of childCategories) {
            previousChildCategoriesMap[id] = previousChildCategories;
        }

        return newChildCategories.map((newChildCategory) => ({
            ...newChildCategory,
            childCategories: previousChildCategoriesMap[newChildCategory.id] ?? null,
        }));
    }
    return newChildCategories;
};

export const getStateWithChildCategories = (
    categories: Category[],
    updateChildCategories: UpdateChildCategoriesItem[],
) => {
    const newCategories = [...categories];
    updateChildCategories.forEach(({ data, categoryId, parentId }) => {
        for (let i = 0; i < newCategories.length; i++) {
            if (newCategories[i].id === categoryId) {
                newCategories[i].childCategories = getUpdatedChildCategories(newCategories[i].childCategories, data);
                break;
            }
            if (newCategories[i].childCategories !== null || newCategories[i].id === parentId) {
                getStateWithChildCategories(newCategories[i].childCategories as Category[], [
                    { data, categoryId, parentId },
                ]);
            }
        }
    });

    return newCategories;
};

export const getUpdatedRootCategoryState = (
    state: Category[],
    data: Category,
    supplierId?: number,
    currenciesFilterValue?: Currency[],
) => {
    const currentState = state
        .map((category) =>
            data.id === category.id ? { ...data, childCategories: category.childCategories } : category,
        )
        .filter((category) => {
            if (currenciesFilterValue !== undefined && data.id === category.id) {
                return currenciesFilterValue.every((currency) => data.currencies.includes(currency));
            }

            return true;
        });

    if (supplierId === undefined) {
        return currentState;
    }
    return currentState.filter((category) =>
        data.id === category.id ? category.suppliers.some((supplier) => supplier.id === supplierId) : true,
    );
};

export const getFilteredByCurrencyState = (
    state: Category[],
    categoryIds: string[],
    currenciesFilterValue?: Currency[],
    currenciesToDelete?: Currency[],
) => {
    if (currenciesFilterValue === undefined || currenciesFilterValue.length === 0) {
        return state;
    }

    return state.filter(
        (category) =>
            !(
                category.id === categoryIds[0] &&
                currenciesFilterValue.every((currency) => currenciesToDelete?.includes(currency))
            ),
    );
};
