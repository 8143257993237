import { ValidationError } from '@admin/common/validation/validation-error.types';

export interface UpdatePartnerCategoryAliasPayload {
    categoryId: string;
    partnerId: number;
    aliases: string[];
}

export enum UpdatePartnerCategoryAliasErrorCode {
    CategoryDoseNotExist = 'CATEGORY_DOSE_NOT_EXIST',
    PartnerDoesNotExist = 'PARTNER_DOES_NOT_EXIST',
    UnavailableCategoryForPartner = 'UNAVAILABLE_CATEGORY_FOR_PARTNER',
    DuplicateAlias = 'DUPLICATE_ALIAS',
}

export type UpdatePartnerCategoryAliasError = ValidationError<UpdatePartnerCategoryAliasErrorCode>;
