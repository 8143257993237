import { OfferCategories } from '@admin/model/offer-categories/categories.types';

export enum OfferCategoriesParam {
    Ordering = 'ordering',
    Offset = 'offset',
    Limit = 'limit',
    Term = 'term',
}
export interface OfferCategoriesPayload {
    [OfferCategoriesParam.Offset]: number;
    [OfferCategoriesParam.Limit]: number;
    [OfferCategoriesParam.Ordering]?: string;
    [OfferCategoriesParam.Term]?: string;
}

export interface OfferCategoriesList {
    totalCount: number;
    categories: OfferCategories[];
}
