import { ValidationError } from '@admin/common/validation';
import { Currency } from '@admin/model/model.types';

export interface UpdateCategoriesCurrencyPayload {
    categoryIds: string[];
    currenciesToAdd?: Currency[];
    currenciesToDelete?: Currency[];
    currenciesFilterValue?: Currency[];
}

export enum UpdateCategoriesCurrencyValidationErrorCode {
    NotLeafCategory = 'NOT_LEAF_CATEGORY',
    UnableUpdateParentCategory = 'UNABLE_UPDATE_PARENT_CATEGORY',
}

export type UpdateCategoriesCurrencyValidationError = ValidationError<UpdateCategoriesCurrencyValidationErrorCode>;
