import { normalizeDate } from '@admin/model/helpers';
import { UsersData } from '@admin/model/users/user.types';
import { UserState } from '@admin/model/users/users-in-role/users-in-role.types';

export const normalizeUsersInRoleData = (userData: UsersData): UserState => ({
    data: userData.data.map((user) => ({
        ...user,
        roles: user.roles.map((role) => ({
            ...role,
            createdAt: normalizeDate(role.createdAt),
        })),
        registrationDate: normalizeDate(user.registrationDate),
        reports: user.reports.map((report) => report.reportCode),
    })),
    totalCount: userData.totalCount,
});
