import { sample } from 'effector';
import { updateCharacteristicFx } from '@admin/model/characteristics/update-characteristic/update-characteristic.fx';
import { getSuccessMessage } from '@admin/model/characteristics/update-characteristic/update-characteristic.helpers';
import { showNoticeEvent } from '@admin/model/notices';

sample({
    clock: updateCharacteristicFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
