export enum CouponCodesErrorCode {
    ListStructureNotValid = 'LIST_STRUCTURE_NOT_VALID',
    FieldsNotValid = 'FIELDS_NOT_VALID',
    ListCodeNotUnique = 'LIST_CODE_NOT_UNIQUE',
    ListOfferFieldMissing = 'LIST_OFFER_FIELD_MISSING',
    ListOfferNotFound = 'LIST_OFFER_NOT_FOUND',
    ListPartnerIdMismatch = 'LIST_PARTNER_ID_MISMATCH',
    ListOfferNotCoupon = 'LIST_OFFER_NOT_COUPON',
}

export interface CouponCodesError {
    errorCode: CouponCodesErrorCode;
    sku: string;
    activationCode: string;
    errorDescription: string;
    rowNumber: number;
    values?: Record<string, string>;
}

interface ErrorsFileData {
    name: Nullable<string>;
    path: Nullable<string>;
    process: Nullable<boolean>;
}

export interface CouponCodesErrorsData {
    totalCount: number;
    uploadErrors: CouponCodesError[];
    errorsFile: ErrorsFileData;
}
