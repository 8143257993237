import { ValidationError } from '@admin/common/validation';
import { CategoryPath, Offer, OfferCharacteristic } from '../offers';
import { Characteristic } from '@admin/model/characteristics/characteristic/characteristic.types';

export enum ProductListItemDataField {
    Id = 'id',
    Name = 'name',
    Image = 'image',
    CategoryIds = 'categoryIds',
    CategoryPaths = 'categoryPaths',
    Synchronized = 'synchronized',
    OfferAmount = 'offerAmount',
    ProductCharacteristics = 'productCharacteristics',
    OptionalProductCharacteristics = 'optionalProductCharacteristics',
    IgnoreOfferCategory = 'ignoreOfferCategory',
}

export enum ProductListItemField {
    Images = 'images',
    Categories = 'categories',
}

export interface ProductListItemData {
    [ProductListItemDataField.Id]: string;
    [ProductListItemDataField.Name]: string;
    [ProductListItemDataField.Image]: string;
    [ProductListItemDataField.CategoryIds]: string[];
    [ProductListItemDataField.CategoryPaths]: CategoryPath[][];
    [ProductListItemDataField.Synchronized]: boolean;
    [ProductListItemDataField.OfferAmount]: number;
    [ProductListItemDataField.ProductCharacteristics]: OfferCharacteristic[];
    [ProductListItemDataField.OptionalProductCharacteristics]: OfferCharacteristic[];
    [ProductListItemDataField.IgnoreOfferCategory]: boolean;
}

export interface ProductListItem
    extends Omit<ProductListItemData, ProductListItemDataField.CategoryIds | ProductListItemDataField.Image> {
    [ProductListItemField.Categories]: CategoryPath[];
    [ProductListItemField.Images]: string[];
}

export enum ProductDataField {
    Id = 'id',
    Name = 'name',
    Images = 'images',
    Category = 'category',
    Synchronized = 'synchronized',
    Manufacturer = 'manufacturer',
    ProductCharacteristics = 'productCharacteristics',
    OptionalProductCharacteristics = 'optionalProductCharacteristics',
    Description = 'description',
    IsDeleted = 'isDeleted',
    Offers = 'offers',
}

export enum ProductField {
    Categories = 'categories',
}

export enum ProductValidationErrorCode {
    MissingRequiredField = 'MISSING_REQUIRED_FIELD',
    SizeExceeded = 'SIZE_EXCEEDED',
    NotUrl = 'NOT_URL',
}

export type ProductValidationError = ValidationError<ProductValidationErrorCode>;
export type ProductErrorState = Nullable<ProductValidationError[]>;

interface ProductCategoryData {
    id: string;
    path: CategoryPath[];
    productCharacteristics: object[];
}

export interface ProductCategory {
    name: string;
    id: string;
    productCharacteristics?: Characteristic[];
}

export interface ProductData {
    [ProductDataField.Id]: string;
    [ProductDataField.Name]: string;
    [ProductDataField.Description]: string;
    [ProductDataField.Manufacturer]: string;
    [ProductDataField.Images]: string[];
    [ProductDataField.Synchronized]: boolean;
    [ProductDataField.Category]: ProductCategoryData[];
    [ProductDataField.IsDeleted]: boolean;
    [ProductDataField.ProductCharacteristics]: OfferCharacteristic[];
    [ProductDataField.OptionalProductCharacteristics]: OfferCharacteristic[];
    [ProductDataField.Offers]: Offer[];
}

export interface Product extends ProductData {
    [ProductField.Categories]: ProductCategory[];
}

export enum ProductPayloadFields {
    Id = 'id',
    Name = 'name',
    Manufacturer = 'manufacturer',
    Description = 'description',
    Categories = 'categories',
    Offers = 'offers',
    Images = 'images',
    ImageUrls = 'imageUrls',
    ProductCharacteristics = 'productCharacteristics',
    OptionalProductCharacteristics = 'optionalProductCharacteristics',
    CategoryIds = 'categoryIds',
    OfferIdsToAdd = 'offerIdsToAdd',
    OfferIdsToDelete = 'offerIdsToDelete',
}

export enum CharacteristicSettings {
    Optional = 'OPTIONAL',
    Static = 'STATIC',
}

export type ProductCharacteristic = {
    id: string;
    value: Nullable<unknown>;
    characteristicSettings: CharacteristicSettings;
};
