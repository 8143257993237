import { getRequest } from '@admin/model/api';
import { createEffect } from 'effector';
import { OfferPartnerData } from '@admin/model/offer-partners/offer-partners.types';
import { logger } from '@admin/common/helpers/logger';
import { normalizeOfferPartnerData } from '@admin/model/offer-partners/offer-partner/offer-partner.helpers';

export const getOfferPartnerFx = createEffect(async (id: string) => {
    try {
        const data = await getRequest<OfferPartnerData>(`offer-partners/${id}`);

        return normalizeOfferPartnerData(data);
    } catch (error) {
        logger(error);

        throw error;
    }
});
