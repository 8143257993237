import { ValidationError } from '@admin/common/validation';

export enum RefundDetailsType {
    Item = 'Item',
    ItemAdvance = 'ItemAdvance',
    Delivery = 'Delivery',
    DeliveryAdvance = 'DeliveryAdvance',
}

interface RefundDetails {
    refundCost: number;
    type: RefundDetailsType;
}

export interface RefundItem {
    sku: string;
    quantity: number;
    refundCost: number;
}

export interface CreateRefundPayload {
    returnClaimId?: string;
    orderId?: number;
    refundCost?: number;
    refundDetails?: RefundDetails[];
    refundItems?: RefundItem[];
    refundDelivery?: number;
    hasProtestRefund?: boolean;
}

export enum CreateRefundValidationErrorCode {
    ReturnClaimIdNotUnique = 'RETURN_CLAIM_ID_NOT_UNIQUE',
    OrderIdNotFound = 'ORDER_ID_NOT_FOUND',
    ReturnSettingDisabled = 'RETURN_SETTING_DISABLED',
    ProhibitedOrderType = 'PROHIBITED_ORDER_TYPE',
    OrderStatusNotFinal = 'ORDER_STATUS_NOT_FINAL',
    RefundSumMoreThanAllowed = 'REFUND_SUM_MORE_THAN_ALLOWED',
    BonusRefundSumMoreThanAllowed = 'BONUS_REFUND_SUM_MORE_THAN_ALLOWED',
    RubRefundSumMoreThanAllowed = 'RUB_REFUND_SUM_MORE_THAN_ALLOWED',
    RubPaymentNotFinal = 'RUB_PAYMENT_NOT_FINAL',
    BonusPaymentNotFinal = 'BONUS_PAYMENT_NOT_FINAL',
    InalidRefundCostValue = 'INVALID_REFUND_COST_VALUE',
    ClientNotMb = 'CLIENT_NOT_MB',
    PartialDeliveryRefundNotAllowed = 'PARTIAL_DELIVERY_REFUND_NOT_ALLOWED',
    ItemRefundSumMoreThanAllowed = 'ITEM_REFUND_SUM_MORE_THAN_ALLOWED',
    DeliveryRefundSumMoreThanAllowed = 'DELIVERY_REFUND_SUM_MORE_THAN_ALLOWED',
    SkuItemRefundSumMoreThanAllowed = 'SKU_ITEM_REFUND_SUM_MORE_THAN_ALLOWED',
    ItemQuantityRefundMoreThanAllowed = 'ITEM_QUANTITY_REFUND_MORE_THAN_ALLOWED',
    RefundPeriodHasExpired = 'REFUND_PERIOD_HAS_EXPIRED',
    ItemQuantityInvalidValue = 'ITEM_QUANTITY_INVALID_VALUE',
}

export type CreateRefundValidationError = ValidationError<CreateRefundValidationErrorCode>;

export interface RefundValidationErrorOptions {
    forGoods: number;
    forDelivery: number;
}
