import { ProductListItemData, ProductListItem } from '@admin/model/products/products.types';

export enum SynchronizationStatus {
    Synchronized = 'SYNCHRONIZED',
    NotSynchronized = 'NOT_SYNCHRONIZED',
}

export enum ProductsPayloadParam {
    CategoryIds = 'categoryIds',
    SupplierIds = 'supplierIds',
    listsIds = 'listsIds',
    SynchronizationStatuses = 'synchronizationStatuses',
    CalculateOffersAmount = 'calculateOffersAmount',
    Term = 'term',
    Ordering = 'ordering',
    Limit = 'limit',
    Offset = 'offset',
}

export interface LoadProductsPayload {
    categoryIds?: string[];
    supplierIds?: string[];
    listIds?: string[];
    synchronizationStatuses?: SynchronizationStatus[];
    calculateOffersAmount?: boolean;
    term?: string;
    ordering?: string;
    limit?: number;
    offset?: number;
}

export type ProductsData = {
    products: ProductListItemData[];
    totalCount: number;
};

export type ProductsState = {
    products: ProductListItem[];
    totalCount: number;
};
