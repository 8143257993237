import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { OfferPartnerValidationErrorCode } from '@admin/model/offer-partners/offer-partners.types';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { logger } from '@admin/common/helpers/logger';
import { UpdateOfferPartnersBatchingPayload } from '@admin/model/offer-partners/update-offer-partners-batching/update-offer-partners-batching.types';

export const updateOfferPartnersBatchingFx = createEffect(async (payload: UpdateOfferPartnersBatchingPayload) => {
    try {
        await postRequest('offer-partners/batching', payload);

        return payload.offerPartnerIds;
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, OfferPartnerValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
