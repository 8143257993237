import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import {
    OrderChangeHistoryPayload,
    OrderChangeHistoryData,
} from '@admin/model/orders/change-history/change-history.types';
import { normalizeOrderChangeHistoryData } from '@admin/model/orders/change-history/change-history.mapper';
import { createEffect } from 'effector';

export const getOrderChangeHistoryFx = createEffect(async ({ id, ...rest }: OrderChangeHistoryPayload) => {
    try {
        const data = await postRequest<OrderChangeHistoryData, OrderChangeHistoryPayload>(`orders/${id}/history`, {
            ...rest,
        });

        return normalizeOrderChangeHistoryData(data);
    } catch (error) {
        logger(error);

        throw error;
    }
});
