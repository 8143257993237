import { sample } from 'effector';
import {
    $manualBlockingClientReasonsData,
    $manualBlockingClientReasonsError,
    resetManualBlockingClientReasonsErrorEvent,
    resetManualBlockingClientReasonsDataEvent,
} from '@admin/model/clients-and-profiles/manual-blocking-client-reason/manual-blocking-client-reason.store';
import { reset } from 'patronum';
import { getManualBlockingClientReasonsFx } from '@admin/model/clients-and-profiles/manual-blocking-client-reason/get-manual-blocking-client-reasons.fx';

sample({
    clock: getManualBlockingClientReasonsFx.doneData,
    fn: ({ dictionaryValueList }) => {
        return dictionaryValueList.map(({ key, value }) => {
            const item: { description: string } = JSON.parse(value);
            return { title: item?.description || '', value: key };
        });
    },
    target: $manualBlockingClientReasonsData,
});

sample({
    clock: getManualBlockingClientReasonsFx.doneData,
    target: resetManualBlockingClientReasonsErrorEvent,
});

sample({
    clock: getManualBlockingClientReasonsFx.failData,
    target: $manualBlockingClientReasonsError,
});

reset({ clock: resetManualBlockingClientReasonsDataEvent, target: [$manualBlockingClientReasonsData] });
reset({ clock: resetManualBlockingClientReasonsErrorEvent, target: [$manualBlockingClientReasonsError] });
