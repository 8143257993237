import { normalizeDate } from '@admin/model/helpers';
import { FileUploadHistoryData, FileUploadHistory } from '@admin/model/file-upload-history/file-upload-history.types';

export const normalizeFileUploadHistoryData = (fileUploadHistory: FileUploadHistoryData[]): FileUploadHistory[] =>
    fileUploadHistory.map((uploadItem: FileUploadHistoryData) => ({
        ...uploadItem,
        completedAt: normalizeDate(uploadItem.completedAt),
        startedAt: normalizeDate(uploadItem.startedAt),
        uploadAt: normalizeDate(uploadItem.uploadAt),
    }));
