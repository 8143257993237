import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { putRequest } from '@admin/model/api/api.resource';
import { setCommonAppError } from '@admin/model/error-notification/error-notification.store';
import { UpdateSuppliersEventSetPayload } from '@admin/model/suppliers/update-suppliers-event-set/update-suppliers-event-set.types';

export const updateSupplierEventSetFx = createEffect(
    async ({ supplierId, allowedEventTypes, selectedEvents }: UpdateSuppliersEventSetPayload) => {
        try {
            await putRequest(`partners/${supplierId}/notification-settings`, { allowedEventTypes });

            return selectedEvents;
        } catch (error) {
            logger(error);

            setCommonAppError({ error });

            throw null;
        }
    },
);
