import { ValidationError } from '@admin/common/validation';

export interface CategoriesVisibilityUpdatePayload {
    isHidden: boolean;
    id: string;
}

export enum CategoryVisibilityValidationErrorCode {
    ParentsAreNotVisible = 'PARENTS_ARE_NOT_VISIBLE',
}

export type CategoriesStatusValidationError = ValidationError<CategoryVisibilityValidationErrorCode>;
