import { normalizeImages } from '@admin/common/helpers';
import { Category } from '@admin/model/categories/category/category.types';

export const normalizeCategoryData = (response: Category): Category => ({
    ...response,
    attributes: {
        ...response.attributes,
        urlIcon: normalizeImages([response.attributes?.urlIcon ?? ''])[0] || response.attributes?.urlIcon,
    },
});
