import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices';
import { getSuccessMessage } from '@admin/model/collections/edit-collection/edit-collection.helpers';
import { editCollectionFx } from '@admin/model/collections/edit-collection/edit-collection.fx';

sample({
    clock: editCollectionFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
