import { createEffect } from 'effector';
import { normalizeDate } from '@admin/model/helpers';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { Permission, User, UserData } from '@admin/model/users/user.types';

interface CurrentUserState {
    user: Nullable<User>;
    permissions: { [key in Permission]?: string };
    linkedSuppliersIds: { [supplierId: string]: string };
    linkedReportsTypes: { [reportTypeName: string]: string };
}

export const getCurrentUserFx = createEffect(async (id: string) => {
    try {
        const data = await getRequest<UserData>(`employees/${id}`);

        const mappedData: CurrentUserState = {
            user: {
                ...data,
                roles: data.roles.map((role) => ({
                    ...role,
                    createdAt: normalizeDate(role.createdAt),
                })),
                registrationDate: normalizeDate(data.registrationDate),
                reports: data.reports.map((report) => report.reportCode),
            },
            // TODO: Maybe I't more convinient to transform it to a Set.
            permissions: data.roles.reduce<{ [key in Permission]?: string }>((permissions, role) => {
                for (const { name } of role.permissions) {
                    permissions[name] = name;
                }
                return permissions;
            }, {}),
            linkedSuppliersIds: data.suppliers.reduce<{ [supplierId: string]: string }>((suppliersIds, supplier) => {
                const supplierId = String(supplier.id);

                suppliersIds[supplierId] = supplierId;

                return suppliersIds;
            }, {}),
            linkedReportsTypes: data.reports
                ? data.reports.reduce<{ [name: string]: string }>((acc, report) => {
                      acc[report.reportCode] = report.reportCode;

                      return acc;
                  }, {})
                : {},
        };

        return mappedData;
    } catch (error) {
        logger(error);

        throw error;
    }
});
