import { sample } from 'effector';
import { deleteCategoryFx } from '@admin/model/categories/delete-category/delete-category.fx';
import { showNoticeEvent } from '@admin/model/notices';
import { getSuccessMessage } from '@admin/model/categories/delete-category/delete-category.helpers';

sample({
    clock: deleteCategoryFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
