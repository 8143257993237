export const enum LoggerType {
    Error = 'error',
    Info = 'info',
}

export const logger = (message: unknown, type: LoggerType = LoggerType.Error) => {
    if (process.env.NODE_ENV === 'development') {
        console[type](message);
    }
};
