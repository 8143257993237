import { createEffect } from 'effector';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { getRequest, pollRequest, postRequest } from '@admin/model/api/api.resource';
import { setCommonAppError } from '@admin/model/error-notification';
import { Supplier, SupplierFormValidationErrorCode } from '@admin/model/suppliers/suppliers.types';
import { RequestWithPollingResponse } from '@admin/model/model.types';
import { logger } from '@admin/common/helpers/logger';

export const createSupplierFx = createEffect(async (payload: FormData) => {
    try {
        const response = await postRequest<RequestWithPollingResponse, FormData>('partners', payload);

        await pollRequest<Supplier>(() => getRequest(`partners/processing/${response.processId}`));

        return;
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, SupplierFormValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
