import { sample } from 'effector';
import { deleteProductsFx } from '@admin/model/products/delete-products/delete-products.fx';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { getSuccessMessage } from '@admin/model/products/delete-products/delete-products.helpers';

sample({
    clock: deleteProductsFx.done,
    fn: ({ params }) => getSuccessMessage(params.productIds),
    target: showNoticeEvent,
});
