import { Log } from '@common/services/log/log';
import { TemporaryLogService } from '@common/services/log/temporary-log.service';
import { Inject, Service } from 'typedi';

@Service()
export class PersistentLogService implements Log {
    @Inject()
    private readonly temporaryLog: TemporaryLogService;

    public debug() {
        // TODO: should implement persistent saving
    }

    public info() {
        // TODO: should implement persistent saving
    }

    public warn(...messages: string[]) {
        this.temporaryLog.warn(...messages);
    }

    public error(...messages: string[]) {
        this.temporaryLog.error(...messages);
    }
}
