import { createEffect } from 'effector';
import { getRequest, pollRequest, postRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { UserData, UserPayload, UsersValidationErrorCode } from '@admin/model/users/user.types';
import { RequestWithPollingResponse } from '@admin/model/model.types';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { setCommonAppError } from '@admin/model/error-notification';

export const createUserFx = createEffect(async (payload: UserPayload) => {
    try {
        const response = await postRequest<RequestWithPollingResponse, UserPayload>('employees', payload);

        await pollRequest<UserData>(() => getRequest(`employees/processing/${response.processId}`));
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, UsersValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
