import { combine, createEvent, createStore } from 'effector';
import { ClientAccountState } from '@admin/model/clients-and-profiles/client-account/client-account.types';
import { getClientAccountFx } from '@admin/model/clients-and-profiles/client-account/get-client-account.fx';

export const resetClientAccountDataEvent = createEvent();
export const resetClientAccountErrorEvent = createEvent();

export const $clientAccountData = createStore<Nullable<ClientAccountState>>(null);
export const $clientAccountError = createStore<Nullable<unknown>>(null);

export const $clientAccount = combine({
    data: $clientAccountData,
    error: $clientAccountError,
    isLoading: getClientAccountFx.pending,
});
