import { DictionaryData } from '@admin/model/model.types';
import {
    CharacteristicType,
    CharacteristicUnit,
    CharacteristicUnitsDictionary,
} from '@admin/model/characteristics/characteristic/characteristic.types';

export const parseData = (CharacteristicUnitsDictData: DictionaryData): CharacteristicUnitsDictionary =>
    CharacteristicUnitsDictData.dictionaryValueList.reduce<CharacteristicUnitsDictionary>(
        (acc, CharacteristicUnitsData) => {
            const parsed = JSON.parse(CharacteristicUnitsData.value) as {
                type: CharacteristicType;
                name: string;
                unit: string;
            };
            const unitedParsedData: CharacteristicUnit = {
                ...parsed,
                name: `${parsed.name}${parsed.unit ? `, ${parsed.unit}` : ''}`,
                key: CharacteristicUnitsData.key,
            };
            acc.array.push(unitedParsedData);
            acc.dictionary[CharacteristicUnitsData.key] = unitedParsedData;
            return acc;
        },
        {
            array: [],
            dictionary: {},
        },
    );
