import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { RoleData } from '../roles.types';
import { normalizeDate } from '@admin/model/helpers';

export const loadRoleFx = createEffect(async (roleId: string) => {
    try {
        const respone = await getRequest<RoleData>(`roles/${roleId}`);
        return {
            ...respone,
            createdAt: normalizeDate(respone.createdAt),
        };
    } catch (error) {
        logger(error);

        throw error;
    }
});
