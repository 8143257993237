import { ValidationError } from '@admin/common/validation';

export enum CreateSegmentValidationErrorCode {
    SegmentAlreadyExists = 'SEGMENT_ALREADY_EXISTS',
    MissingRequiredField = 'MISSING_REQUIRED_FIELD',
    SizeExceeded = 'SIZE_EXCEEDED',
}

export type CreateSegmentValidationError = ValidationError<CreateSegmentValidationErrorCode>;

export enum CreateSegmentField {
    Segment = 'segment',
}

export interface CreateSegmentPayload {
    [CreateSegmentField.Segment]: string;
}
