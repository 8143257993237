import { Permission } from '@admin/model';
import { ValidationError } from '@admin/common/validation';

export enum RoleValidationErrorCode {
    MissingRequiredField = 'MISSING_REQUIRED_FIELD',
    DuplicateRoleName = 'DUPLICATE_ROLE_NAME',
}

export type RoleValidationError = ValidationError<RoleValidationErrorCode>;

export interface RolePermission {
    description: string;
    name: Permission;
    id: string;
}

export interface RoleCreator {
    userId: Nullable<string>;
    firstName: Nullable<string>;
    lastName: Nullable<string>;
    patronymicName: Nullable<string>;
}

export interface RoleData {
    id: string;
    name: string;
    createdAt: string;
    userInRoleCount?: number;
    permissions: RolePermission[];
    createdBy: RoleCreator;
}

export interface Role extends Omit<RoleData, 'createdAt'> {
    createdAt?: Date;
}

export interface RolesData {
    data: RoleData[];
    totalCount: number;
}

export interface RolesState {
    roles: Role[];
    totalCount: number;
}
export interface RolesPayload {
    term?: string;
    limit?: number;
    offset?: number;
    ordering?: string;
}
export interface RolePayload {
    name: string;
    permissions: Permission[];
}
export interface EditRolePayload {
    id: string;
    name: string;
    permissions: string[];
}
