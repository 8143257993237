import { SegmentData, SegmentsValue, SegmentWithValue } from '@admin/model/segments/segments.types';
import { normalizeDate } from '@admin/model/helpers';

export const parseData = (segmentsData: SegmentData): SegmentWithValue => {
    const parsed = JSON.parse(segmentsData.value) as SegmentsValue;

    return {
        ...parsed,
        key: segmentsData.key,
        createdAt: normalizeDate(parsed.createdAt ?? null),
    };
};
