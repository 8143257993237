import { createContext } from 'react';
import type { ReactNode } from 'react';

export interface UniversalModalParams<T> {
    title: string;
    content: ReactNode;
    mainActionLabel?: string;
    mainActionValue?: T;
    secondaryActionLabel?: string;
    secondaryActionValue?: T;
}

export type OpenUniversalModalCallback<T = unknown> = (params: UniversalModalParams<T>) => Promise<T>;

export const UniversalModalContext = createContext((params: UniversalModalParams<unknown>) => {
    console.error(`Обработчик не был установлен. ${JSON.stringify(params)}`);

    return Promise.resolve('No value' as unknown);
});
