import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { LoadProductsPayload, ProductsData } from '@admin/model/products/products-list/products-list.types';
import { normalizeProductsData } from '@admin/model/products/products-list/products-list.helpers';

export const getProductsListFx = createEffect(async (payload: LoadProductsPayload) => {
    try {
        const data = await postRequest<ProductsData, LoadProductsPayload>('products/search', payload, {
            apiVersion: 2,
        });

        return {
            products: normalizeProductsData(data.products),
            totalCount: data.totalCount,
        };
    } catch (error) {
        logger(error);

        throw error;
    }
});
