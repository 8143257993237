import { sample } from 'effector';
import { reset } from 'patronum';
import { updatePartnerCategoryAliasFx } from '@admin/model/categories/update-partner-category-alias/update-partner-category-alias.fx';
import {
    $updatePartnerCategoryAliasData,
    $updatePartnerCategoryAliasError,
    resetUpdatePartnerCategoryAliasStateEvent,
} from '@admin/model/categories/update-partner-category-alias/update-partner-category-alias.store';

sample({
    source: updatePartnerCategoryAliasFx.done,
    fn: () => true,
    target: $updatePartnerCategoryAliasData,
});

sample({
    clock: updatePartnerCategoryAliasFx.failData,
    target: $updatePartnerCategoryAliasError,
});

reset({
    clock: resetUpdatePartnerCategoryAliasStateEvent,
    target: [$updatePartnerCategoryAliasData, $updatePartnerCategoryAliasError],
});
