import { ValidationError } from '@admin/common/validation';

export enum NotificationField {
    Id = 'id',
    Name = 'name',
    Active = 'active',
    EventGroup = 'eventGroup',
    EventType = 'eventType',
    NotificationGroup = 'notificationGroup',
    ConfigurationPriority = 'configurationPriority',
    Origin = 'origin',
    IgnoreProfileDeliveryPreferences = 'ignoreProfileDeliveryPreferences',
    Partners = 'partners',
    ChannelConfigurations = 'channelConfigurations',
    UpdatedAt = 'updatedAt',
    DeliveryWindow = 'deliveryWindow',
}

export interface NotificationChannel {
    id?: string;
    allowFreeText?: boolean;
    allowEmptyPlaceholder?: boolean;
    deliveryPriority?: string;
    titleTemplate?: string;
    bodyTemplate?: string;
    deliveryTimeout?: string;
    channel: string;
    active?: boolean;
    recipients?: string[];
}

export interface NotificationDeliveryWindow {
    startTime: string;
    endTime: string;
}

export interface NotificationSupplier {
    id: string;
    name: string;
}

export interface NotificationData {
    [NotificationField.Id]: string;
    [NotificationField.Name]: string;
    [NotificationField.Active]: boolean;
    [NotificationField.EventGroup]: string;
    [NotificationField.EventType]: string;
    [NotificationField.NotificationGroup]: string;
    [NotificationField.ConfigurationPriority]: number;
    [NotificationField.Origin]: string;
    [NotificationField.IgnoreProfileDeliveryPreferences]: boolean;
    [NotificationField.Partners]: NotificationSupplier[];
    [NotificationField.ChannelConfigurations]: NotificationChannel[];
    [NotificationField.UpdatedAt]: string;
    [NotificationField.DeliveryWindow]?: NotificationDeliveryWindow;
}

export type PartialNotificationData = Partial<NotificationData>;

export interface Notification extends Omit<NotificationData, NotificationField.UpdatedAt> {
    [NotificationField.UpdatedAt]?: Date;
}

export enum NotificationChannels {
    Sms = 'SMS',
    SmsOSN = 'SMS_OSN',
    PushOrSms = 'PUSH_OR_SMS',
    Email = 'EMAIL',
    Chat = 'CHAT',
}

export enum NotificationActivityStatus {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
}

export enum NotificationErrorCode {
    ConfigurationShouldBeUnique = 'CONFIGURATION_SHOULD_BE_UNIQUE',
    ConfigurationNameMustBeUnique = 'CONFIGURATION_NAME_MUST_BE_UNIQUE',
    MandatoryFieldEmpty = 'MANDATORY_FIELD_EMPTY',
    SizeExceeded = 'SIZE_EXCEEDED',
    UniqueValueViolation = 'UNIQUE_VALUE_VIOLATION',
    MismatchType = 'MISMATCH_TYPE',
    IncorrectPeriod = 'INCORRECT_PERIOD',
}

export type NotificationValidationError = ValidationError<NotificationErrorCode>;
