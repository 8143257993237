import { sample } from 'effector';
import { createCategoryFx } from '@admin/model/categories/create-category/create-category.fx';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { getSuccessMessage } from '@admin/model/categories/create-category/create-category.helpers';

sample({
    clock: createCategoryFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
