import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { BlockingPage } from '@admin/model/blocking-pages/blockibg-pages.store';
import { UpdateError } from '@admin/pages/settings/page-blocking/page-blocking.types';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { setCommonAppError } from '@admin/model/error-notification';

export const addBlockingPagesFx = createEffect(async (payload: BlockingPage[] = []) => {
    try {
        const data: BlockingPage[] = await postRequest('blocking-pages', payload);

        return data;
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, UpdateError)) {
            throw error.data.validationErrors[0].code;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
