import { ValidationError } from '@admin/common/validation';

export interface UpdateCategoryPositionPayload {
    categoryId: string;
    parentId: Nullable<string>;
    predecessorId: Nullable<string>;
}

export enum UpdateCategoryPositionValidationErrorCode {
    ForbiddenCategoryTypeViolation = 'FORBIDDEN_CATEGORY_TYPE_VIOLATION',
    ProductCategoryHierarchyLevelViolation = 'PRODUCT_CATEGORY_HIERARCHY_LEVEL_VIOLATION',
    NameAlreadyTaken = 'NAME_ALREADY_TAKEN',
}

export type UpdateCategoryPositionValidationError = ValidationError<UpdateCategoryPositionValidationErrorCode>;
