import { logger } from '@admin/common/helpers/logger';
import { getRequest } from '@admin/model/api';
import { createEffect } from 'effector';
import { UploadErrorData } from '@admin/model/errors/errors.types';

export const getErrorsFx = createEffect(async (uploadId: string) => {
    try {
        return await getRequest<UploadErrorData>(`catalog/uploads/${uploadId}/errors`);
    } catch (error) {
        logger(error);

        throw error;
    }
});
