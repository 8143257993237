import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import {
    CreateRefundPayload,
    CreateRefundValidationErrorCode,
} from '@admin/model/orders/create-refund/create-refund.types';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { setCommonAppError } from '@admin/model/error-notification';
import { createEffect } from 'effector';

export const createRefundFx = createEffect(async (payload: CreateRefundPayload) => {
    try {
        await postRequest<unknown, CreateRefundPayload>('orders/return', payload);
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, CreateRefundValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
