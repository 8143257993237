import { logger } from '@admin/common/helpers/logger';
import { setCommonAppError } from '@admin/model/error-notification';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { postRequest } from '@admin/model/api';
import { createEffect } from 'effector';
import {
    UploadMatrixPayload,
    UploadMatrixValidationErrorCode,
} from '@admin/model/cost-matrix/upload-matrix/upload-matrix.types';

export const uploadMatrixFx = createEffect(async ({ data, deliveryPartnerId }: UploadMatrixPayload) => {
    try {
        await postRequest(`delivery/matrices/${deliveryPartnerId}`, data);

        return true;
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, UploadMatrixValidationErrorCode)) {
            throw error.data.validationErrors[0];
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
