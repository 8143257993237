import { ValidationError } from '@admin/common/validation';

export enum OfferCategoriesField {
    Id = 'id',
    Name = 'name',
    Code = 'code',
    Order = 'order',
    IsHidden = 'isHidden',
    IconData = 'iconData',
    ImageData = 'imageData',
}

export interface OfferCategories {
    [OfferCategoriesField.Id]: string;
    [OfferCategoriesField.Name]: string;
    [OfferCategoriesField.Code]: string;
    [OfferCategoriesField.Order]: number;
    [OfferCategoriesField.IsHidden]: boolean;
    [OfferCategoriesField.IconData]: [string | File];
    [OfferCategoriesField.ImageData]: [string | File];
}

export interface ShortenedOfferCategory {
    [OfferCategoriesField.Id]: string;
    [OfferCategoriesField.Name]: string;
}

export type OfferCategory = Partial<OfferCategories>;

export enum OfferCategoryValidationErrorCode {
    MissingRequiredField = 'MISSING_REQUIRED_FIELD',
    ValueIsNotUnique = 'VALUE_IS_NOT_UNIQUE',
    NotFound = 'NOT_FOUND',
    WrongOrder = 'WRONG_ORDER',
    OfferPartnerWithOnlyThisCategory = 'OFFER_PARTNER_WITH_ONLY_THIS_CATEGORY',
}

export type OfferCategoryValidationError = ValidationError<OfferCategoryValidationErrorCode>;

export interface OfferCategoryPayload
    extends Partial<Omit<OfferCategory, OfferCategoriesField.ImageData | OfferCategoriesField.IconData>> {
    image?: File;
    icon?: File;
    iconUrl?: string;
    imageUrl?: string;
}
