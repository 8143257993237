import { ListsState } from '@admin/model/lists/get-lists/lists.types';
import { combine, createStore } from 'effector';
import { getListsFx } from '@admin/model/lists/get-lists/get-lists.fx';

const initialState: ListsState = {
    totalCount: 0,
    data: [],
};

export const $listsData = createStore<ListsState>(initialState);
export const $listsError = createStore<Nullable<unknown>>(null);

export const $lists = combine({
    data: $listsData,
    error: $listsError,
    isLoading: getListsFx.pending,
});
