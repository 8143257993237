import { HttpMethod, HttpStatusCode, PollStatus } from '@admin/model/api/api.types';
import fetch from 'cross-fetch';
import { isJson } from '@admin/common/helpers/is-json';
import { setCommonAppError } from '@admin/model/error-notification/error-notification.store';

export class ResponseError<T = object> {
    public constructor(public statusOk: boolean, public status: number, public statusText: string, public data?: T) {}
}

export class NetworkError {
    public name = 'NetworkError';
    public constructor(public message: string) {}
}

type RequestOptions = Omit<RequestInit, 'body'> & {
    apiVersion?: number;
};

function createResponseError(statusOk: boolean, status: number, statusText: string, data: unknown) {
    return (
        (typeof data === 'string' && new ResponseError(statusOk, status, statusText, data)) ||
        (typeof data === 'object' && data && new ResponseError(statusOk, status, statusText, data)) ||
        new ResponseError(statusOk, status, statusText)
    );
}

export function isNotFound(error: unknown) {
    return error instanceof ResponseError && error.status === HttpStatusCode.NotFound;
}

export function isBadRequest(error: unknown) {
    return error instanceof ResponseError && error.status === HttpStatusCode.BadRequest;
}

export function isUnauthorized(error: unknown) {
    return error instanceof ResponseError && error.status === HttpStatusCode.Unauthorized;
}

export function isForbidden(error: unknown) {
    return error instanceof ResponseError && error.status === HttpStatusCode.Forbidden;
}

export function isInternalServerError(error: unknown) {
    return error instanceof ResponseError && error.status === HttpStatusCode.InternalServerError;
}
export function isLargeEntityError(error: unknown) {
    return error instanceof ResponseError && error.status === HttpStatusCode.LargeEntityError;
}

export const getApiBaseUrl = (version = 1) => `/api/v${version}/mp-sys-b2e-composite`;
export let authToken: string;

export function setAuthToken(token: string) {
    authToken = token;
}

function isResponseJson(response: Response) {
    const contentType = response.headers.get('content-type');

    return (
        contentType !== null &&
        (contentType.includes('application/json') || contentType.includes('application/hal+json'))
    );
}

function loadData(url: string, options?: RequestInit, apiVersion?: number): Promise<Response> {
    return fetch(`${getApiBaseUrl(apiVersion)}/${url}`, {
        ...options,
        headers: {
            ...(authToken !== undefined ? { Authorization: `Bearer ${authToken}` } : {}),
            ...options?.headers,
        },
    }).catch((error) => {
        return Promise.reject(error instanceof TypeError ? new NetworkError(error.message) : error);
    });
}

async function fetchData<T>(url: string, options?: RequestInit, apiVersion?: number): Promise<T> {
    let response;
    try {
        response = await loadData(url, options, apiVersion);
    } catch (error) {
        return Promise.reject(error);
    }

    if (!response.ok) {
        if (
            response.headers.get('content-type')?.includes('text/html') &&
            response.status === HttpStatusCode.Forbidden
        ) {
            const parser = new DOMParser();
            const htmlContent = await response.text();
            const text = parser.parseFromString(htmlContent, 'text/html');
            setCommonAppError({
                error: 'qrator',
                errorTextCode: 'ERROR.QRATOR',
                errorTextValues: { errorDescription: text?.body?.textContent ?? 'UNKNOWN ERROR' },
            });
        }

        return Promise.reject(
            createResponseError(
                response.ok,
                response.status,
                response.statusText,
                isResponseJson(response) ? await response.json() : await response.text(),
            ),
        );
    }

    return response.text().then((text) => (text !== '' ? (isJson(text) ? JSON.parse(text) : text) : null));
}

export async function fetchFile(
    url: string,
    options?: RequestInit,
): Promise<{ response: BlobPart; fileName: Nullable<string> }> {
    let response;
    try {
        response = await loadData(url, options);
    } catch (error) {
        return Promise.reject(error);
    }

    if (!response.ok) {
        return Promise.reject(
            createResponseError(response.ok, response.status, response.statusText, await response.text()),
        );
    }
    const blob = await response.blob();
    const contentDispositionHeaderValue = response.headers.get('content-disposition');
    const fileName =
        contentDispositionHeaderValue !== null
            ? contentDispositionHeaderValue.split('filename=')[1].replace(/"/g, '')
            : null;

    return {
        response: blob,
        fileName: fileName,
    };
}

async function send<T, D = object>(url: string, options: RequestOptions, data?: D) {
    return fetchData<T>(
        url,
        {
            ...options,
            body: data instanceof FormData ? data : JSON.stringify(data),
            headers: {
                ...options.headers,
                ...(!(data instanceof FormData) && {
                    'Content-Type': 'application/json',
                }),
            },
        },
        options.apiVersion,
    );
}

function withMethod(method: HttpMethod, options?: RequestOptions) {
    if (options !== undefined) {
        options.method = method;
    } else {
        return { method };
    }

    return options;
}

function getUrlWithQueryParams(url: string, queryParams: object): string {
    if (Object.keys(queryParams).length === 0) {
        return url;
    }

    return `${url}?${new URLSearchParams(Object.entries(queryParams).filter((it) => it[1] !== undefined)).toString()}`;
}

export async function postRequest<T, D extends object>(url: string, data?: D, options?: RequestOptions) {
    return send<T, D>(url, withMethod(HttpMethod.Post, options), data);
}

export async function getRequest<T>(url: string, queryParams?: object, options?: RequestOptions) {
    return send<T>(
        queryParams !== undefined ? getUrlWithQueryParams(url, queryParams) : url,
        withMethod(HttpMethod.Get, options),
    );
}

export async function putRequest<T, D extends object>(url: string, data?: D, options?: RequestOptions) {
    return send<T, D>(url, withMethod(HttpMethod.Put, options), data);
}

export async function deleteRequest<T, D extends object>(
    url: string,
    data?: D,
    options?: RequestOptions,
    queryParams?: object,
) {
    return send<T, D>(
        queryParams !== undefined ? getUrlWithQueryParams(url, queryParams) : url,
        withMethod(HttpMethod.Delete, options),
        data,
    );
}

export interface PollResponse<T> {
    status: PollStatus;
    result: T;
    processId: string;
}

const defaultPollingTimeout = 300000;
const defaultPollingInterval = 1000;

export async function pollRequest<T>(
    requestFunction: () => Promise<PollResponse<T>>,
    timeout = defaultPollingTimeout,
    interval = defaultPollingInterval,
) {
    const endTime = Number(new Date()) + timeout;

    const handleTimeout = (resolve: (value?: T | PromiseLike<T>) => void, reject: (reason?: unknown) => void) => {
        requestFunction()
            .then((response) => {
                if (response.status === PollStatus.Done) {
                    resolve(response.result);
                } else if (response.status === PollStatus.Error) {
                    reject(response.result);
                } else if (Number(new Date()) < endTime) {
                    setTimeout(handleTimeout, interval, resolve, reject);
                } else {
                    reject(new Error(`timed out for: ${response.processId} status ${response.status}`));
                }
            })
            .catch((error) => {
                reject(error);
            });
    };

    return new Promise(handleTimeout);
}

export interface arrayPollRequestResult<T> {
    result: T[];
    processesWithErrorsIds: string[];
}

export async function arrayPollRequest<T>(
    endpoint: string,
    processIds: string[],
    timeout = defaultPollingTimeout,
    interval = defaultPollingInterval,
) {
    const endTime = Number(new Date()) + timeout;
    let idsToCheck = processIds;
    const requestResult: T[] = [];
    const processesWithErrorsIds: string[] = [];

    const handleTimeout = (resolve: (value: arrayPollRequestResult<T>) => void, reject: (reason?: unknown) => void) => {
        Promise.all(idsToCheck.map((processId) => getRequest<PollResponse<T[]>>(`${endpoint}${processId}`)))
            .then((allProcessResponses) => {
                const newIdsToCheck: string[] = [];

                allProcessResponses.forEach((processResponse, index) => {
                    if (processResponse.status === PollStatus.Processing) {
                        newIdsToCheck.push(idsToCheck[index]);
                    } else if (processResponse.status === PollStatus.Done) {
                        requestResult.push(...processResponse.result);
                    } else if (processResponse.status === PollStatus.Error) {
                        processesWithErrorsIds.push(processResponse.processId);
                    }
                });

                if (newIdsToCheck.length === 0) {
                    resolve({
                        processesWithErrorsIds,
                        result: requestResult,
                    });
                } else if (Number(new Date()) < endTime) {
                    idsToCheck = newIdsToCheck;
                    setTimeout(handleTimeout, interval, resolve, reject);
                } else {
                    const notFinishedProcessesIds: string[] = [];

                    allProcessResponses.forEach(({ status, processId }) => {
                        if (status === PollStatus.Processing) {
                            notFinishedProcessesIds.push(processId);
                        }
                    });

                    resolve({
                        processesWithErrorsIds: notFinishedProcessesIds,
                        result: requestResult,
                    });
                }
            })
            .catch((error) => {
                reject(error);
            });
    };

    return new Promise(handleTimeout);
}
