import { ValidationError } from '@admin/common/validation';

export interface ChangeClientPhonePayload {
    clientId: string;
    phone: string;
    resetOtherClientPhone?: boolean;
}

export enum ClientPhoneValidationErrorCode {
    WrongPhoneFormat = 'PHONE_INVALID',
    PhoneRequired = 'PHONE_REQUIRED',
    SamePhone = 'SAME_PHONE_NUMBER',
    AnotherClientHasTheSamePhone = 'ANOTHER_CLIENT_HAS_THE_SAME_PHONE',
    InvalidPhone = 'INVALID_PHONE_NUMBER',
}

export type ClientPhoneValidationError = ValidationError<ClientPhoneValidationErrorCode>;
