import loadable from '@loadable/component';
import { PageComponents } from '@admin/pages/page.types';
import { PageName } from '@admin/pages/page-name';

export const PageComponent: PageComponents = {
    [PageName.HOME]: loadable(() => import(/* webpackChunkName: 'root' */ './root/root.component')),
    [PageName.NOT_FOUND]: loadable(
        () => import(/* webpackChunkName: 'error-page' */ './error-page/error-page.component'),
    ),
    [PageName.ERROR]: loadable(() => import(/* webpackChunkName: 'error-page' */ './error-page/error-page.component')),

    // CATALOG_AND_ORDERS
    [PageName.CATALOG]: loadable(
        () => import(/* webpackChunkName: 'catalog-and-orders' */ './catalog-and-orders/catalog-and-orders.component'),
    ),

    // ./CATEGORIES_EDITOR
    [PageName.CATEGORIES_EDITOR]: loadable(
        () =>
            import(
                /* webpackChunkName: 'category-editor' */ './catalog-and-orders/categories-editor/categories-editor.component'
            ),
    ),
    [PageName.CATEGORY_INFO]: loadable(
        () =>
            import(
                /* webpackChunkName: 'category-info' */ './catalog-and-orders/categories-editor/category-info/category-info.component'
            ),
    ),
    [PageName.CATEGORY_CHANGE_HISTORY]: loadable(
        () =>
            import(
                /* webpackChunkName: 'category-change-history' */ './catalog-and-orders/categories-editor/category-info/change-history/change-history.component'
            ),
    ),
    [PageName.CATEGORY_VIEW]: loadable(
        () =>
            import(
                /* webpackChunkName: 'category-view' */ './catalog-and-orders/categories-editor/category-info/category-view/category-view.component'
            ),
    ),
    [PageName.CATEGORY_EDIT]: loadable(
        () =>
            import(
                /* webpackChunkName: 'category-edit' */ './catalog-and-orders/categories-editor/category-edit/category-edit.component'
            ),
    ),
    [PageName.CATEGORY_CREATE]: loadable(
        () =>
            import(
                /* webpackChunkName: 'create-category' */ './catalog-and-orders/categories-editor/create-category/create-category.component'
            ),
    ),
    [PageName.CATEGORIES_MAIN]: loadable(
        () =>
            import(
                /* webpackChunkName: 'categories-main' */ './catalog-and-orders/categories-editor/main/main.component'
            ),
    ),

    // ./CATEGORIES_BINDING
    [PageName.CATEGORIES_BINDING]: loadable(
        () =>
            import(
                /* webpackChunkName: 'categories-binding' */ './catalog-and-orders/categories-binding/categories-binding.component'
            ),
    ),
    [PageName.CATEGORIES_BINDING_CHANGE_HISTORY]: loadable(
        () =>
            import(
                /* webpackChunkName: 'categories-binding-change-history' */ './catalog-and-orders/categories-binding/change-history/change-history.component'
            ),
    ),
    [PageName.CATEGORIES_BINDING_CATEGORIES]: loadable(
        () =>
            import(
                /* webpackChunkName: 'categories-binding-categories' */ './catalog-and-orders/categories-binding/categories/categories.component'
            ),
    ),

    // ./OFFERS
    [PageName.OFFERS]: loadable(
        () => import(/* webpackChunkName: 'offers' */ './catalog-and-orders/offers/offers.component'),
    ),
    [PageName.OFFER_INFO]: loadable(
        () =>
            import(/* webpackChunkName: 'offer-info' */ './catalog-and-orders/offers/offer-info/offer-info.component'),
    ),
    [PageName.OFFER_COUPON_CODES]: loadable(
        () =>
            import(
                /* webpackChunkName: 'offers-coupon-codes' */ './catalog-and-orders/offers/coupon-codes/coupon-codes.component'
            ),
    ),
    [PageName.OFFER_VIEW]: loadable(
        () => import(/* webpackChunkName: 'offers-view' */ './catalog-and-orders/offers/view/view.component'),
    ),
    [PageName.OFFERS_UPLOADS]: loadable(
        () => import(/* webpackChunkName: 'offers-uploads' */ './catalog-and-orders/offers/uploads/uploads.component'),
    ),
    [PageName.OFFERS_UPLOAD_COUPON_CODES]: loadable(
        () =>
            import(
                /* webpackChunkName: 'offers-upload-coupon-codes' */ './catalog-and-orders/offers/upload-coupon-codes/upload-coupon-codes.component'
            ),
    ),
    [PageName.OFFERS_UPLOAD_OFFERS]: loadable(
        () =>
            import(
                /* webpackChunkName: 'offers-upload' */ './catalog-and-orders/offers/upload-offers/upload-offers.component'
            ),
    ),
    [PageName.CREATE_OFFER]: loadable(
        () => import(/* webpackChunkName: 'create-offer' */ './catalog-and-orders/offers/create/create.component'),
    ),
    [PageName.OFFER_EDIT]: loadable(
        () => import(/* webpackChunkName: 'offers-edit' */ './catalog-and-orders/offers/edit/edit.component'),
    ),
    [PageName.UPDATE_CHARACTERISTICS]: loadable(
        () =>
            import(
                /* webpackChunkName: 'update-characteristics' */ './catalog-and-orders/offers/update/update.component'
            ),
    ),
    [PageName.OFFERS_MAIN]: loadable(
        () => import(/* webpackChunkName: 'offers-main' */ './catalog-and-orders/offers/main/main.component'),
    ),

    // ./PRODUCTS
    [PageName.PRODUCTS]: loadable(
        () => import(/* webpackChunkName: 'offers-main' */ './catalog-and-orders/products/products.component'),
    ),
    [PageName.PRODUCT_EDIT]: loadable(
        () =>
            import(/* webpackChunkName: 'product-edit' */ './catalog-and-orders/products/edit/edit-product.component'),
    ),
    [PageName.PRODUCT_OFFERS_EDIT]: loadable(
        () =>
            import(
                /* webpackChunkName: 'products-offers-view' */ './catalog-and-orders/products/product-form/components/offers/offers.component'
            ),
    ),
    [PageName.PRODUCT_DETAILS_EDIT]: loadable(
        () =>
            import(
                /* webpackChunkName: 'products-details-view' */ './catalog-and-orders/products/product-form/components/details/details.component'
            ),
    ),
    [PageName.PRODUCT_VIEW]: loadable(
        () =>
            import(/* webpackChunkName: 'product-view' */ './catalog-and-orders/products/view/view-product.component'),
    ),
    [PageName.PRODUCT_OFFERS_VIEW]: loadable(
        () =>
            import(
                /* webpackChunkName: 'products-offers-view' */ './catalog-and-orders/products/product-form/components/offers/offers.component'
            ),
    ),
    [PageName.PRODUCT_DETAILS_VIEW]: loadable(
        () =>
            import(
                /* webpackChunkName: 'products-details-view' */ './catalog-and-orders/products/product-form/components/details/details.component'
            ),
    ),
    [PageName.PRODUCT_CREATE]: loadable(
        () =>
            import(
                /* webpackChunkName: 'product-create' */ './catalog-and-orders/products/create/create-product.component'
            ),
    ),
    [PageName.PRODUCT_OFFERS_CREATE]: loadable(
        () =>
            import(
                /* webpackChunkName: 'products-offers-create' */ './catalog-and-orders/products/product-form/components/offers/offers.component'
            ),
    ),
    [PageName.PRODUCT_DETAILS_CREATE]: loadable(
        () =>
            import(
                /* webpackChunkName: 'products-details-create' */ './catalog-and-orders/products/product-form/components/details/details.component'
            ),
    ),
    [PageName.PRODUCTS_MAIN]: loadable(
        () => import(/* webpackChunkName: 'products-main' */ './catalog-and-orders/products/main/main.component'),
    ),

    // ./CHARACTERISTICS
    [PageName.CHARACTERISTICS]: loadable(
        () =>
            import(
                /* webpackChunkName: 'products-main' */ './catalog-and-orders/characteristics/characteristics.component'
            ),
    ),
    [PageName.CHARACTERISTIC_VIEW]: loadable(
        () =>
            import(
                /* webpackChunkName: 'characteristic-view' */ './catalog-and-orders/characteristics/view/characteristic-view.component'
            ),
    ),
    [PageName.CHARACTERISTIC_EDIT]: loadable(
        () =>
            import(
                /* webpackChunkName: 'characteristic-edit' */ './catalog-and-orders/characteristics/edit/characteristic-edit.component'
            ),
    ),
    [PageName.CHARACTERISTIC_CREATE]: loadable(
        () =>
            import(
                /* webpackChunkName: 'characteristic-create' */ './catalog-and-orders/characteristics/create/characteristic-create.component'
            ),
    ),
    [PageName.CHARACTERISTICS_MAIN]: loadable(
        () =>
            import(
                /* webpackChunkName: 'characteristic-main' */ './catalog-and-orders/characteristics/main/main.component'
            ),
    ),

    // ./COLLECTIONS
    [PageName.COLLECTIONS]: loadable(
        () =>
            import(
                /* webpackChunkName: 'characteristic-main' */ './catalog-and-orders/collections/collections.component'
            ),
    ),
    [PageName.COLLECTIONS_EDIT]: loadable(
        () =>
            import(
                /* webpackChunkName: 'collection-edit' */ './catalog-and-orders/collections/edit/edit-collection.component'
            ),
    ),
    [PageName.COLLECTIONS_VIEW]: loadable(
        () =>
            import(
                /* webpackChunkName: 'collection-view' */ './catalog-and-orders/collections/view/collection-view.component'
            ),
    ),
    [PageName.COLLECTIONS_CREATE]: loadable(
        () =>
            import(
                /* webpackChunkName: 'collections-create' */ './catalog-and-orders/collections/create/create-collection.component'
            ),
    ),
    [PageName.COLLECTIONS_MAIN]: loadable(
        () => import(/* webpackChunkName: 'collections-main' */ './catalog-and-orders/collections/main/main.component'),
    ),

    // ./SUPPLIERS
    [PageName.SUPPLIERS]: loadable(
        () => import(/* webpackChunkName: 'orders-main' */ './catalog-and-orders/suppliers/suppliers.component'),
    ),
    [PageName.SUPPLIER_INFO]: loadable(
        () =>
            import(
                /* webpackChunkName: 'upload-matrix' */ './catalog-and-orders/suppliers/supplier-info/supplier-info.component'
            ),
    ),
    [PageName.EVENT_SET]: loadable(
        () =>
            import(/* webpackChunkName: 'event-set' */ './catalog-and-orders/suppliers/event-set/event-set.component'),
    ),
    [PageName.DELIVERY]: loadable(
        () => import(/* webpackChunkName: 'delivery' */ './catalog-and-orders/suppliers/delivery/delivery.component'),
    ),
    [PageName.DELIVERY_COST_MATRIX]: loadable(
        () =>
            import(
                /* webpackChunkName: 'cost-matrix' */ './catalog-and-orders/suppliers/cost-matrix/cost-matrix.component'
            ),
    ),
    [PageName.DELIVERY_MATRIX_UPLOAD_HISTORY]: loadable(
        () =>
            import(
                /* webpackChunkName: 'upload-history' */ './catalog-and-orders/suppliers/upload-history/upload-history.component'
            ),
    ),
    [PageName.DELIVERY_MATRIX_CHANGE_HISTORY]: loadable(
        () =>
            import(
                /* webpackChunkName: 'change-history' */ './catalog-and-orders/suppliers/change-history/change-history.component'
            ),
    ),
    [PageName.SUPPLIER_VIEW]: loadable(
        () =>
            import(
                /* webpackChunkName: 'suppliers-view' */ './catalog-and-orders/suppliers/view/view-supplier.component'
            ),
    ),
    [PageName.SUPPLIER_EDIT]: loadable(
        () =>
            import(
                /* webpackChunkName: 'edit-supplier' */ './catalog-and-orders/suppliers/edit/edit-supplier.component'
            ),
    ),
    [PageName.SUPPLIER_CREATE]: loadable(
        () =>
            import(
                /* webpackChunkName: 'create-supplier' */ './catalog-and-orders/suppliers/create/create-supplier.component'
            ),
    ),
    [PageName.SUPPLIERS_MAIN]: loadable(
        () => import(/* webpackChunkName: 'main-suppliers' */ './catalog-and-orders/suppliers/main/main.component'),
    ),

    // ./SUBSCRIPTIONS
    [PageName.SUBSCRIPTIONS]: loadable(
        () =>
            import(
                /* webpackChunkName: 'subscriptions' */ './catalog-and-orders/subscriptions/subscriptions.component'
            ),
    ),
    [PageName.SUBSCRIPTION_INFO]: loadable(
        () =>
            import(
                /* webpackChunkName: 'subscription-info' */ './catalog-and-orders/subscriptions/subscription-info/subscription-info.component'
            ),
    ),
    [PageName.SUBSCRIPTION_CHANGE_HISTORY]: loadable(
        () =>
            import(
                /* webpackChunkName: 'subscription-change-history' */ './catalog-and-orders/subscriptions/change-history/change-history.component'
            ),
    ),
    [PageName.SUBSCRIPTION_VIEW]: loadable(
        () =>
            import(
                /* webpackChunkName: 'subscription-view' */ './catalog-and-orders/subscriptions/view/subscription-view.component'
            ),
    ),
    [PageName.SUBSCRIPTIONS_MAIN]: loadable(
        () =>
            import(
                /* webpackChunkName: 'main-subscriptions' */ './catalog-and-orders/subscriptions/main/main.component'
            ),
    ),

    // ORDERS_AND_PAYMENTS
    [PageName.ORDERS_AND_PAYMENTS]: loadable(
        () => import(/* webpackChunkName: 'orders-section' */ './orders/orders.component'),
    ),

    // ./ORDERS
    [PageName.ORDERS]: loadable(() => import(/* webpackChunkName: 'orders' */ './orders/orders/orders.component')),
    [PageName.ORDER_INFO]: loadable(
        () => import(/* webpackChunkName: 'orders-info' */ './orders/orders/info/info-order.component'),
    ),
    [PageName.ORDER_TRACKING]: loadable(
        () => import(/* webpackChunkName: 'order-tracking' */ './orders/orders/tracking/tracking.component'),
    ),
    [PageName.ORDER_CHANGE_HISTORY]: loadable(
        () =>
            import(
                /* webpackChunkName: 'order-change-history' */ './orders/orders/change-history/change-history.component'
            ),
    ),
    [PageName.ORDER_PAYMENT]: loadable(
        () => import(/* webpackChunkName: 'payment-order' */ './orders/orders/payment/payment-order.component'),
    ),
    [PageName.ORDER_EDIT]: loadable(
        () => import(/* webpackChunkName: 'edit-order' */ './orders/orders/edit/edit-order.component'),
    ),
    [PageName.ORDER_VIEW]: loadable(
        () => import(/* webpackChunkName: 'view-order' */ './orders/orders/view/view-order.component'),
    ),
    [PageName.ORDERS_MAIN]: loadable(
        () => import(/* webpackChunkName: 'orders-main' */ './orders/orders/main/main.component'),
    ),

    // ./PAYMENTS_MAIN
    [PageName.PAYMENTS]: loadable(
        () => import(/* webpackChunkName: 'orders' */ './orders/payments/payments.component'),
    ),
    [PageName.PAYMENTS_MAIN]: loadable(
        () => import(/* webpackChunkName: 'orders-info' */ './orders/payments/main/main.component'),
    ),

    // CLIENTS_AND_PROFILES
    [PageName.CLIENTS_AND_PROFILES]: loadable(
        () =>
            import(
                /* webpackChunkName: 'clients-and-profiles' */ './clients-and-profiles/clients-and-profiles.component'
            ),
    ),

    [PageName.CLIENTS_AND_PROFILES_SEARCH]: loadable(
        () =>
            import(
                /* webpackChunkName: 'client-search' */ './clients-and-profiles/client-search/client-search.component'
            ),
    ),

    [PageName.CLIENTS_SEARCH_HASH]: loadable(
        () =>
            import(
                /* webpackChunkName: 'clients-search-hash' */ './clients-and-profiles/client-search/client-hash-accounts/client-hash-accounts.component'
            ),
    ),

    // ./CLIENT
    [PageName.CLIENT]: loadable(
        () => import(/* webpackChunkName: 'client' */ './clients-and-profiles/client/client.component'),
    ),
    [PageName.CLIENT_PROFILE]: loadable(
        () =>
            import(
                /* webpackChunkName: 'client-profile' */ './clients-and-profiles/client/client-profile/client-profile.component'
            ),
    ),
    [PageName.CLIENT_PROFILE_DETAILS]: loadable(
        () =>
            import(
                /* webpackChunkName: 'client-profile-details' */ './clients-and-profiles/client/client-profile/client-profile-details/client-profile-details.component'
            ),
    ),
    [PageName.CLIENT_ORDERS]: loadable(
        () =>
            import(
                /* webpackChunkName: 'client-orders' */ './clients-and-profiles/client/client-orders/client-orders.component'
            ),
    ),
    [PageName.CLIENT_TRANSACTIONS]: loadable(
        () =>
            import(
                /* webpackChunkName: 'client-transactions' */ './clients-and-profiles/client/client-transactions/client-transactions.component'
            ),
    ),
    [PageName.CLIENT_SUBSCRIPTIONS]: loadable(
        () =>
            import(
                /* webpackChunkName: 'client-subscriptions' */ './clients-and-profiles/client/client-subscriptions/client-subscriptions.component'
            ),
    ),
    [PageName.CLIENT_TRANSACTION_CREATE]: loadable(
        () =>
            import(
                /* webpackChunkName: 'client-transactions-create' */ './clients-and-profiles/client/client-transactions/create/create-transaction.component'
            ),
    ),
    [PageName.CLIENT_AUTH_HISTORY]: loadable(
        () =>
            import(
                /* webpackChunkName: 'client-auth-history' */ './clients-and-profiles/client/client-auth-history/client-auth-history.component'
            ),
    ),
    [PageName.CLIENT_AGREEMENTS]: loadable(
        () =>
            import(
                /* webpackChunkName: 'client-messages' */ './clients-and-profiles/client/client-agreements/client-agreements.component'
            ),
    ),
    [PageName.CLIENT_EXPIRATION]: loadable(
        () =>
            import(
                /* webpackChunkName: 'client-messages' */ './clients-and-profiles/client/client-expiration/client-expiration.component'
            ),
    ),
    [PageName.CLIENT_EXPIRATIONS]: loadable(
        () =>
            import(
                /* webpackChunkName: 'client-messages' */ './clients-and-profiles/client/client-expirations/client-expirations.component'
            ),
    ),
    [PageName.CLIENT_ACCOUNT]: loadable(
        () =>
            import(
                /* webpackChunkName: 'client-account' */ './clients-and-profiles/client/client-account/client-account.component'
            ),
    ),

    // ACCOUNTS
    [PageName.ACCOUNTS]: loadable(() => import(/* webpackChunkName: 'accounts' */ './accounts/accounts.component')),

    // ./USERS
    [PageName.USERS]: loadable(() => import(/* webpackChunkName: 'users' */ './accounts/users/users.component')),
    [PageName.EDIT_USER]: loadable(
        () => import(/* webpackChunkName: 'edit-user' */ './accounts/users/edit-user/edit-user.component'),
    ),
    [PageName.CREATE_USER]: loadable(
        () => import(/* webpackChunkName: 'create-user' */ './accounts/users/create-user/create-user.component'),
    ),
    [PageName.USERS_MAIN]: loadable(
        () => import(/* webpackChunkName: 'users-main' */ './accounts/users/main/main.component'),
    ),
    [PageName.USER_INFO]: loadable(
        () => import(/* webpackChunkName: 'user-info' */ './accounts/users/user-info/user-info.component'),
    ),
    [PageName.USER_CHANGE_HISTORY]: loadable(
        () =>
            import(
                /* webpackChunkName: 'user-change-history' */ './accounts/users/change-history/change-history.component'
            ),
    ),
    [PageName.VIEW_USER]: loadable(
        () => import(/* webpackChunkName: 'view-user' */ './accounts/users/view-user/view-user.component'),
    ),

    // ./ROLES
    [PageName.ROLES]: loadable(() => import(/* webpackChunkName: 'roles' */ './accounts/roles/roles.component')),
    [PageName.EDIT_ROLE]: loadable(
        () => import(/* webpackChunkName: 'edit-role' */ './accounts/roles/edit-role/edit-role.component'),
    ),
    [PageName.VIEW_ROLE]: loadable(
        () => import(/* webpackChunkName: 'view-role' */ './accounts/roles/view-role/view-role.component'),
    ),
    [PageName.CREATE_ROLE]: loadable(
        () => import(/* webpackChunkName: 'create-role' */ './accounts/roles/create-role/create-role.component'),
    ),
    [PageName.ROLES_MAIN]: loadable(
        () => import(/* webpackChunkName: 'roles-main' */ './accounts/roles/main/main.component'),
    ),

    // REPORTS
    [PageName.REPORTS]: loadable(() => import(/* webpackChunkName: 'reports' */ './reports/reports.component')),
    [PageName.REPORTS_MAIN]: loadable(
        () => import(/* webpackChunkName: 'reports-main' */ './reports/reports-main/reports-main.component'),
    ),

    // REQUESTS
    [PageName.REQUESTS]: loadable(() => import(/* webpackChunkName: 'request' */ './requests/requests.component')),
    [PageName.ALL_REQUESTS]: loadable(
        () => import(/* webpackChunkName: 'all-requests' */ './requests/all-requests/all-requests.component'),
    ),
    [PageName.ALL_REQUESTS_VIEW]: loadable(
        () => import(/* webpackChunkName: 'all-requests-view' */ './requests/all-requests/view/view.component'),
    ),
    [PageName.ALL_REQUESTS_MAIN]: loadable(
        () => import(/* webpackChunkName: 'all-requests-main' */ './requests/all-requests/main/main.component'),
    ),

    // SETTINGS
    [PageName.SETTINGS]: loadable(() => import(/* webpackChunkName: 'settings' */ './settings/settings.component')),

    // ./NOTIFICATIONS
    [PageName.NOTIFICATIONS]: loadable(
        () => import(/* webpackChunkName: 'notifications' */ './settings/notifications/notifications.component'),
    ),
    [PageName.NOTIFICATION_INFO]: loadable(
        () =>
            import(
                /* webpackChunkName: 'notification-info' */ './settings/notifications/notification-info/notification-info.component'
            ),
    ),
    [PageName.NOTIFICATION_CHANGE_HISTORY]: loadable(
        () =>
            import(
                /* webpackChunkName: 'notification-change-history' */ './settings/notifications/change-history/change-history.component'
            ),
    ),
    [PageName.NOTIFICATION_VIEW]: loadable(
        () =>
            import(
                /* webpackChunkName: 'notification-view' */ './settings/notifications/view/view-notification.component'
            ),
    ),
    [PageName.NOTIFICATION_EDIT]: loadable(
        () =>
            import(
                /* webpackChunkName: 'notification-edit' */ './settings/notifications/edit/edit-notification.component'
            ),
    ),
    [PageName.NOTIFICATION_CREATE]: loadable(
        () =>
            import(
                /* webpackChunkName: 'notification-create' */ './settings/notifications/create/create-notification.component'
            ),
    ),
    [PageName.NOTIFICATIONS_MAIN]: loadable(
        () => import(/* webpackChunkName: 'notifications-main' */ './settings/notifications/main/main.component'),
    ),

    // ./LISTS
    [PageName.LISTS]: loadable(() => import(/* webpackChunkName: 'lists' */ './settings/lists/lists.component')),
    [PageName.LIST_INFO]: loadable(
        () => import(/* webpackChunkName: 'list-info' */ './settings/lists/list-info/list-info.component'),
    ),
    [PageName.LIST_UPLOAD_HISTORY]: loadable(
        () =>
            import(
                /* webpackChunkName: 'list-upload-history' */ './settings/lists/list-upload-history/list-upload-history.component'
            ),
    ),
    [PageName.LIST_VIEW]: loadable(
        () => import(/* webpackChunkName: 'list-view' */ './settings/lists/view/list-view.component'),
    ),
    [PageName.LIST_EDIT]: loadable(
        () => import(/* webpackChunkName: 'list-edit' */ './settings/lists/edit/list-edit.component'),
    ),
    [PageName.LIST_CREATE]: loadable(
        () => import(/* webpackChunkName: 'list-create' */ './settings/lists/create/list-create.component'),
    ),
    [PageName.LISTS_MAIN]: loadable(
        () => import(/* webpackChunkName: 'lists-main' */ './settings/lists/main/main.component'),
    ),

    // ./SHORT_LINKS
    [PageName.SHORT_LINKS]: loadable(
        () => import(/* webpackChunkName: 'short-links' */ './settings/short-links/short-links.component'),
    ),
    [PageName.SHORT_LINK_EDIT]: loadable(
        () => import(/* webpackChunkName: 'short-link-edit' */ './settings/short-links/edit/short-link-edit.component'),
    ),
    [PageName.SHORT_LINK_VIEW]: loadable(
        () => import(/* webpackChunkName: 'view-short-link' */ './settings/short-links/view/short-link-view.component'),
    ),
    [PageName.SHORT_LINK_CREATE]: loadable(
        () =>
            import(
                /* webpackChunkName: 'short-link-create' */ './settings/short-links/create/short-link-create.component'
            ),
    ),
    [PageName.SHORT_LINKS_MAIN]: loadable(
        () => import(/* webpackChunkName: 'short-links-main' */ './settings/short-links/main/main.component'),
    ),

    // ./SEGMENTS
    [PageName.SEGMENTS]: loadable(
        () => import(/* webpackChunkName: 'segments' */ './settings/segments/segments.component'),
    ),
    [PageName.SEGMENT_INFO]: loadable(
        () => import(/* webpackChunkName: 'segment-info' */ './settings/segments/segment-info/segment-info.component'),
    ),
    [PageName.SEGMENT_UPLOAD_HISTORY]: loadable(
        () =>
            import(
                /* webpackChunkName: 'segment-upload-history' */ './settings/segments/segment-upload-history/segment-upload-history.component'
            ),
    ),
    [PageName.SEGMENT_VIEW]: loadable(
        () => import(/* webpackChunkName: 'segment-view' */ './settings/segments/view/segment-view.component'),
    ),
    [PageName.SEGMENT_EDIT]: loadable(
        () => import(/* webpackChunkName: 'segment-edit' */ './settings/segments/edit/segment-edit.component'),
    ),
    [PageName.SEGMENT_CREATE]: loadable(
        () => import(/* webpackChunkName: 'segment-create' */ './settings/segments/create/segment-create.component'),
    ),
    [PageName.SEGMENTS_MAIN]: loadable(
        () => import(/* webpackChunkName: 'segments-main' */ './settings/segments/main/main.component'),
    ),

    // ./PAGE_BLOCKING
    [PageName.PAGE_BLOCKING]: loadable(
        () => import(/* webpackChunkName: 'page-blocking' */ './settings/page-blocking/page-blocking.component'),
    ),
    [PageName.PAGE_BLOCKING_VIEW]: loadable(
        () => import(/* webpackChunkName: 'page-blocking-view' */ './settings/page-blocking/view/view.component'),
    ),

    // PARTNERS
    [PageName.PARTNER_OFFERS]: loadable(
        () => import(/* webpackChunkName: 'partner-offers' */ './partner-offers/partner-offers.component'),
    ),

    // ./OFFERS
    [PageName.PARTNER_OFFERS_OFFERS]: loadable(
        () => import(/* webpackChunkName: 'partner-offers-categories' */ './partner-offers/offers/offers.component'),
    ),
    [PageName.PARTNER_OFFERS_EDIT]: loadable(
        () => import(/* webpackChunkName: 'partner-offers-edit' */ './partner-offers/offers/edit/edit.component'),
    ),
    [PageName.PARTNER_OFFERS_VIEW]: loadable(
        () => import(/* webpackChunkName: 'partner-offers-view' */ './partner-offers/offers/view/view.component'),
    ),
    [PageName.PARTNER_OFFERS_CREATE]: loadable(
        () => import(/* webpackChunkName: 'partner-offers-create' */ './partner-offers/offers/create/create.component'),
    ),
    [PageName.PARTNER_OFFERS_MAIN]: loadable(
        () => import(/* webpackChunkName: 'partner-offers-main' */ './partner-offers/offers/main/main.component'),
    ),

    // ./CATEGORIES
    [PageName.PARTNER_OFFERS_CATEGORIES]: loadable(
        () =>
            import(
                /* webpackChunkName: 'partner-offers-categories' */ './partner-offers/categories/categories.component'
            ),
    ),
    [PageName.PARTNER_OFFERS_CATEGORIES_EDIT]: loadable(
        () =>
            import(
                /* webpackChunkName: 'partner-offers-categories-edit' */ './partner-offers/categories/edit/edit.component'
            ),
    ),
    [PageName.PARTNER_OFFERS_CATEGORIES_VIEW]: loadable(
        () =>
            import(
                /* webpackChunkName: 'partner-offers-categories-view' */ './partner-offers/categories/view/view.component'
            ),
    ),
    [PageName.PARTNER_OFFERS_CATEGORIES_CREATE]: loadable(
        () =>
            import(
                /* webpackChunkName: 'partner-offers-categories-create' */ './partner-offers/categories/create/create.component'
            ),
    ),
    [PageName.PARTNER_OFFERS_CATEGORIES_MAIN]: loadable(
        () =>
            import(
                /* webpackChunkName: 'partner-offers-categories-main' */ './partner-offers/categories/main/main.component'
            ),
    ),

    // ACCRUALS
    [PageName.ACCRUALS]: loadable(() => import(/* webpackChunkName: 'accruals' */ './accruals/accruals.component')),

    // ./REQUESTS
    [PageName.ACCRUALS_REQUESTS]: loadable(
        () => import(/* webpackChunkName: 'accruals-requests' */ './accruals/requests/requests.component'),
    ),
    [PageName.ACCRUALS_REQUESTS_MAIN]: loadable(
        () => import(/* webpackChunkName: 'accruals-requests-main' */ './accruals/requests/main/main.component'),
    ),

    // ./ACCRUALS
    [PageName.ACCRUALS_ACCRUALS]: loadable(
        () => import(/* webpackChunkName: 'accruals-accruals' */ './accruals/accruals/accruals.component'),
    ),
    [PageName.ACCRUALS_ACCRUALS_MAIN]: loadable(
        () => import(/* webpackChunkName: 'accruals-accruals-main' */ './accruals/accruals/main/main.component'),
    ),
    [PageName.ACCRUALS_ACCRUALS_VIEW]: loadable(
        () => import(/* webpackChunkName: 'accruals-accruals' */ './accruals/accruals/view/view.component'),
    ),
};
