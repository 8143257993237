import { createEffect } from 'effector';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { setCommonAppError } from '@admin/model/error-notification';
import { deleteRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import {
    DeleteOfferCategoriesPayload,
    DeleteOfferCategoriesValidationErrorCode,
} from './delete-offer-categories.types';

export const deleteOfferCategoriesFx = createEffect(async (ids: string[]) => {
    try {
        const deleteData: DeleteOfferCategoriesPayload = {
            categoryIds: ids,
        };
        await deleteRequest<unknown, DeleteOfferCategoriesPayload>('offer-partners/categories/batching', deleteData);
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, DeleteOfferCategoriesValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
