import { sample } from 'effector';
import { getSupplierFormConfigsFx } from '@admin/model/suppliers/supplier-form-configs/get-supplier-form-configs.fx';
import {
    $supplierFormConfigsData,
    $supplierFormConfigsError,
} from '@admin/model/suppliers/supplier-form-configs/supplier-form-configs.store';
import { parseData } from '@admin/model/suppliers/supplier-form-configs/supplier-form-configs.helpers';

sample({
    clock: getSupplierFormConfigsFx.doneData,
    fn: (result) => parseData(result),
    target: $supplierFormConfigsData,
});

sample({
    clock: getSupplierFormConfigsFx.failData,
    target: $supplierFormConfigsError,
});
