import { reset } from 'patronum';
import {
    $clientEnableAccessDone,
    $clientEnableAccessError,
    resetClientEnableAccessErrorEvent,
} from '@admin/model/clients-and-profiles/client-enable-access/client-enable-access.store';
import { sample } from 'effector';
import { getClientAccountFx } from '@admin/model/clients-and-profiles/client-account/get-client-account.fx';
import { clientEnableAccessFx } from '@admin/model/clients-and-profiles/client-enable-access/client-enable-access.fx';

sample({
    clock: clientEnableAccessFx.doneData,
    target: $clientEnableAccessDone,
});

sample({
    clock: getClientAccountFx.finally,
    fn: () => false,
    target: $clientEnableAccessDone,
});

sample({
    clock: clientEnableAccessFx.failData,
    target: $clientEnableAccessError,
});

reset({ clock: resetClientEnableAccessErrorEvent, target: [$clientEnableAccessError] });
