import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { normalizeDate } from '@admin/model/helpers/normalize-date';
import { ReportsPayload, ReportsData } from '@admin/model/reports/reports-list/reports-list.types';

export const getReportsListFx = createEffect(async (payload: ReportsPayload) => {
    try {
        const { data, totalCount } = await postRequest<ReportsData, ReportsPayload>('requisitions/search', payload);

        return {
            data: data.map((report) => ({
                ...report,
                createdAt: normalizeDate(report.createdAt),
                updatedAt: normalizeDate(report.updatedAt),
            })),
            totalCount: totalCount,
        };
    } catch (error) {
        logger(error);

        throw error;
    }
});
