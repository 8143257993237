import { normalizeDate } from '@admin/model/helpers';
import {
    ClientsFromFileUploads,
    SegmentFileUploadHistoryData,
    SegmentFileUploadHistoryState,
} from '@admin/model/segments/file-upload-history/segment-file-upload-history.types';

export const normalizeFileUploadHistoryData = (
    fileUploadHistory: SegmentFileUploadHistoryData,
): SegmentFileUploadHistoryState => {
    const normalized = fileUploadHistory.clientsFromFileUploads.map((uploadItem: ClientsFromFileUploads) => ({
        ...uploadItem,
        completedAt: normalizeDate(uploadItem.completedAt),
        startedAt: normalizeDate(uploadItem.startedAt),
        uploadedAt: normalizeDate(uploadItem.uploadedAt),
    }));

    return { ...fileUploadHistory, clientsFromFileUploads: normalized };
};
