export enum OrderUpdatedParameters {
    OrderStatus = 'ORDER_STATUS',
    PaymentStatus = 'PAYMENT_STATUS',
    RefundStatus = 'REFUND_STATUS',
    RefundPaymentStatus = 'REFUND_PAYMENT_STATUS',
    CouponInfo = 'COUPON_INFO',
    PaymentParams = 'PAYMENT_PARAMS',
}

export interface OrderChangeHistoryPayload {
    id?: string;
    updatedParameters?: string[];
    ordering?: string;
}

export enum OrderChangeHistoryField {
    UpdatedAt = 'updatedAt',
    CreatedAt = 'createdAt',
    UpdatedBy = 'updatedBy',
    UpdatedParameter = 'updatedParameter',
    Value = 'value',
    Comment = 'comment',
    UpdatedObject = 'updatedObject',
}

export interface OrderChangeHistoryItemData {
    [OrderChangeHistoryField.UpdatedAt]: string;
    [OrderChangeHistoryField.UpdatedBy]: string;
    [OrderChangeHistoryField.UpdatedParameter]: OrderUpdatedParameters;
    [OrderChangeHistoryField.Value]: string;
    [OrderChangeHistoryField.Comment]: string;
    [OrderChangeHistoryField.UpdatedObject]: string;
}

export interface OrderChangeHistoryData {
    items?: OrderChangeHistoryItemData[];
}

export interface OrderChangeHistoryItem extends Omit<OrderChangeHistoryItemData, 'updatedAt'> {
    [OrderChangeHistoryField.UpdatedAt]?: Date;
}

export interface OrderChangeHistory {
    items: OrderChangeHistoryItem[];
}
