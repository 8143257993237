import { combine, createStore } from 'effector';
import { User } from '@admin/model/users/user.types';
import { getUserFx } from '@admin/model/users/user/get-user.fx';

export const $userData = createStore<Nullable<User>>(null);
export const $userError = createStore<Nullable<unknown>>(null);

export const $user = combine({
    data: $userData,
    error: $userError,
    isLoading: getUserFx.pending,
});
