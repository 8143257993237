import { combine, createEvent, createStore } from 'effector';
import { resetClientPasswordFx } from '@admin/model/clients-and-profiles/client-password/reset-client-password.fx';

export const $clientPasswordError = createStore<Nullable<unknown>>(null);
export const $clientPasswordDone = createStore<boolean>(false);

export const resetClientPasswordErrorEvent = createEvent();

export const $clientPassword = combine({
    data: $clientPasswordDone,
    error: $clientPasswordError,
    isLoading: resetClientPasswordFx.pending,
});
