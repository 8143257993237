import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import { ListsPayload, ListsData } from '@admin/model/lists/get-lists/lists.types';

export const getListsFx = createEffect(async (payload: ListsPayload) => {
    try {
        return await postRequest<ListsData, ListsPayload>('lists/search', payload);
    } catch (error) {
        logger(error);

        throw error;
    }
});
