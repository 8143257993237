import { generatePath } from 'react-router';
import { PageName } from '@admin/pages/page-name';
import { PagePath } from '@admin/pages/page-path';

export type GetPageUrlFunction<PageName> = (pageName: PageName, pageParams?: PageParams) => string;

export interface PageParams {
    [paramName: string]: string | number | boolean | undefined;
}

export interface RouterParams {
    [param: string]: string | undefined;
}

function getPathWithParams(path: string, params: PageParams) {
    const pathWithoutOptional = path.replace(/\((\w+)\)\?/g, '$1');

    return generatePath(pathWithoutOptional, params);
}

export function getPageUrl(pageName: PageName, pageParams?: PageParams) {
    let path = PagePath[pageName];

    if (path === undefined) {
        throw new Error(`Path field of ${pageName} page not found`);
    }

    if (pageParams !== undefined) {
        path = getPathWithParams(path, pageParams);
    }

    return path;
}
