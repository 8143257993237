import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { getRequest } from '@admin/model/api';
import {
    SegmentFileUploadHistoryPayload,
    SegmentFileUploadHistoryData,
} from '@admin/model/segments/file-upload-history/segment-file-upload-history.types';

export const getSegmentFileUploadHistoryFx = createEffect(async (payload: SegmentFileUploadHistoryPayload) => {
    try {
        return await getRequest<SegmentFileUploadHistoryData>('segments/uploads/status', payload);
    } catch (error) {
        logger(error);

        throw error;
    }
});
