import { sample } from 'effector';
import { getCharacteristicsUnitsFilterFx } from '@admin/model/characteristics/characteristics-units-filter/get-characteristics-units-filter.fx';
import {
    $characteristicsUnitsFilterData,
    $characteristicsUnitsFilterError,
} from '@admin/model/characteristics/characteristics-units-filter/characteristics-units-filter.store';

sample({
    clock: getCharacteristicsUnitsFilterFx.doneData,
    source: $characteristicsUnitsFilterData,
    fn: (_state, result) => {
        if (!result.filter) {
            return result.data;
        }
        const regexp = new RegExp(result.filter, 'i');

        return result.data.filter((item) => regexp.test(item.name));
    },
    target: $characteristicsUnitsFilterData,
});

sample({
    clock: getCharacteristicsUnitsFilterFx.failData,
    target: $characteristicsUnitsFilterError,
});
