import { sample } from 'effector';
import { updateSupplierFx } from '@admin/model/suppliers/update-supplier/update-supplier.fx';
import { getSuccessMessage } from '@admin/model/suppliers/update-supplier/update-supplier.helpers';
import { showNoticeEvent } from '@admin/model/notices';

sample({
    clock: updateSupplierFx.done,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
