export interface AccrualsPayload {
    offset?: number;
    limit?: number;
    dateFrom?: Date | string;
    dateTo?: Date | string;
    createdAtFrom?: Date | string;
    createdAtTo?: Date | string;
    status?: string[];
    type?: string[];
    term?: string[];
}

export const enum AccrualsStatus {
    Accrued = 'ACCRUED',
    AwaitingAccrual = 'AWAITING_ACCRUAL',
    Error = 'ERROR',
}

export const enum AccrualsTypes {
    Admitad = 'ADMITAD',
    AdmitadBonus = 'ADMITAD_BONUS',
    Direct = 'DIRECT',
    Correction = 'CORRECTION',
    Advcake = 'ADVCAKE',
    Manual = 'MANUAL',
    DirectBank = 'DIRECT_BANK',
}

export interface AccrualData {
    id: string;
    type: AccrualsTypes;
    orderId?: string;
    orderDate?: string;
    createdAt?: string;
    clickDate: Nullable<string>;
    status: AccrualsStatus;
    partnerName: string;
    payment: number;
    orderSum: number;
    clientProfileId?: string;
    partnerOrderId?: string;
    description?: string;
    correctionReason?: string;
    correctionAgreed?: string;
}

export interface AccrualsData {
    totalCount: number;
    data: AccrualData[];
}

export interface Accrual {
    id: string;
    type: AccrualsTypes;
    orderId?: string;
    partnerName: string;
    orderDate?: Date;
    createdAt?: Date;
    clickDate?: Date;
    status: AccrualsStatus;
    payment: number;
    orderSum?: number;
    clientProfileId?: string;
    partnerOrderId?: string;
    description?: string;
    correctionReason?: string;
    correctionAgreed?: string;
    comment?: string;
    createdBy?: string;
}

export interface Accruals {
    totalCount: number;
    data: Accrual[];
}

export const enum AccrualsRequestStatus {
    New = 'NEW',
    Processing = 'PROCESSING',
    Done = 'DONE',
}

export interface AccrualsRequest<T> {
    id: string;
    uploadAt: T;
    startedAt: string;
    completedAt: string;
    status: AccrualsRequestStatus;
    fileName: string;
    createdBy: string;
    successCount: number;
    failCount: number;
}

export interface AccrualsRequestsResponse<T> {
    totalCount: number;
    data: AccrualsRequest<T>[];
}

export interface AccrualsRequestError {
    orderId: string;
    errorCode: string;
    errorDescription: string;
    rowNumber: number;
}

export interface AccrualsRequestsErrorsResponse {
    totalCount: number;
    uploadErrors: AccrualsRequestError[];
}

export const enum AccrualStatus {
    NEW = 'NEW',
    PROCESSING = 'PROCESSING',
    ERROR = 'ERROR',
    DONE = 'DONE',
}

export const enum AccrualType {
    ADMITAD = 'ADMITAD',
    ADMITAD_SYSTEM_ACCRUAL = 'ADMITAD_SYSTEM_ACCRUAL',
    CORRECTION = 'CORRECTION',
    ADVCAKE = 'ADVCAKE',
    MANUAL = 'MANUAL',
    DIRECT_BANK = 'DIRECT_BANK',
}

export interface AccrualDictionary {
    code: string;
    name: string;
}
