import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { getSuccessMessage } from '@admin/model/lists/create-list/create-list.helpers';
import { createListFx } from '@admin/model/lists/create-list/create-list.fx';

sample({
    clock: createListFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
