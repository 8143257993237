import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { SuppliersData, SuppliersPayload } from '@admin/model/suppliers/suppliers.types';
import { logger } from '@admin/common/helpers/logger';

export const getSuppliersListFx = createEffect(async (payload: SuppliersPayload) => {
    try {
        return await getRequest<SuppliersData>('partners', payload);
    } catch (error) {
        logger(error);

        throw error;
    }
});
