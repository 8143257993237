import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { getSuccessMessage } from '@admin/model/short-links/update-short-link/update-short-link.helpers';
import { updateShortLinkFx } from '@admin/model/short-links/update-short-link/update-short-link.fx';

sample({
    clock: updateShortLinkFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
