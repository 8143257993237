import { combine, createEvent, createStore } from 'effector';
import { pending } from 'patronum/pending';
import { getCategoriesListFx } from '@admin/model/categories/categories-list/get-categories-list.fx';
import { updateRootCategoryFx } from '@admin/model/categories/categories-list/update-root-category.fx';
import { updateChildCategoriesFx } from '@admin/model/categories/categories-list/update-child-categories.fx';
import { Category } from '@admin/model/categories/category/category.types';

export const resetCategoriesListEvent = createEvent();
export const resetRootCategoriesLoadingDataEvent = createEvent();
export const expandAllRowsEvent = createEvent();
export const collapseAllRowsEvent = createEvent();

export const $categories = createStore<Category[]>([]);
export const $flatCategories = createStore<Category[]>([]);
export const $isLoadAllCategoriesDoneData = createStore<boolean>(false);
export const $isLoadRootCategoriesDoneData = createStore<boolean>(false);
export const $categoriesListError = createStore<Nullable<unknown>>(null);
export const $areAllRowsExpanded = createStore<boolean>(false);

const $isLoading = pending({
    effects: [getCategoriesListFx, updateRootCategoryFx, updateChildCategoriesFx],
});

export const $categoriesList = combine({
    data: $categories,
    flatCategories: $flatCategories,
    error: $categoriesListError,
    isLoading: $isLoading,
    isLoadRootCategoriesDone: $isLoadRootCategoriesDoneData,
    isLoadAllCategoriesDone: $isLoadAllCategoriesDoneData,
    areAllRowsExpanded: $areAllRowsExpanded,
});
