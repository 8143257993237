import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { getSuccessMessage } from '@admin/model/segments/upload-file-segment/upload-file-segment.helpers';
import { uploadFileSegmentFx } from '@admin/model/segments/upload-file-segment/upload-file-segment.fx';

sample({
    clock: uploadFileSegmentFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
