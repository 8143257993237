import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { deleteRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';

export const deleteOffersFx = createEffect(async (ids: string[]) => {
    try {
        await deleteRequest('offers/batching', ids, {
            apiVersion: 2,
        });
    } catch (error) {
        logger(error);

        setCommonAppError({ error });

        throw error;
    }
});
