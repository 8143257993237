import { combine, createEvent, createStore } from 'effector';
import { clientDisableAccessFx } from '@admin/model/clients-and-profiles/client-disable-access/client-disable-access.fx';

export const $clientDisableAccessError = createStore<Nullable<unknown>>(null);
export const $clientDisableAccessDone = createStore<boolean>(false);

export const resetClientDisableAccessErrorEvent = createEvent();

export const $clientDisableAccess = combine({
    data: $clientDisableAccessDone,
    error: $clientDisableAccessError,
    isLoading: clientDisableAccessFx.pending,
});
