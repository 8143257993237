import { createEffect } from 'effector';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { setCommonAppError } from '@admin/model/error-notification';
import { postRequest } from '@admin/model/api';
import {
    ChageOrderStatusPayload,
    ChangeOrderStatusValidationErrorCode,
} from '@admin/model/orders/change-order-status/change-order-status.types';
import { logger } from '@admin/common/helpers/logger';

export const changeOrderStatusFx = createEffect(async (payload: ChageOrderStatusPayload) => {
    try {
        await postRequest('orders/status', payload);
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, ChangeOrderStatusValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
