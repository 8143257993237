import { restore, combine } from 'effector';
import { loadPayAttributesFx } from '@admin/model/suppliers/load-pay-attributes/load-pay-attributes.fx';

const $errorStore = restore(loadPayAttributesFx.failData, null).on(loadPayAttributesFx.doneData, () => null);

const $dataStore = restore(loadPayAttributesFx, null);

export const $payAttributesStore = combine({
    error: $errorStore,
    isLoading: loadPayAttributesFx.pending,
    data: $dataStore,
});
