import { combine, createEvent, createStore, restore } from 'effector';
import { getClientAgreementDictionaryFx } from '@admin/model/clients-and-profiles/client-agreement-dictionary/get-client-agreement-dictionary.fx';

export const resetClientAgreementDictionaryStore = createEvent();
export const $clientAgreementDictionaryData = restore(getClientAgreementDictionaryFx, null).reset(
    resetClientAgreementDictionaryStore,
);
export const $clientAgreementDictionaryError = createStore<Nullable<unknown>>(null);

export const $clientAgreementDictionary = combine({
    data: $clientAgreementDictionaryData,
    error: $clientAgreementDictionaryError,
    isLoading: getClientAgreementDictionaryFx.pending,
});
