import { AccrualData, Accrual, AccrualDictionary } from './accruals.types';
import { DictionaryData } from '@admin/model';
import { FilterGroupItem } from '@admin/components';

export const mapAccrual = (accrual: AccrualData): Accrual => ({
    ...accrual,
    orderDate: accrual?.orderDate ? new Date(accrual.orderDate) : undefined,
    createdAt: accrual?.createdAt ? new Date(accrual.createdAt) : undefined,
    clickDate: accrual?.clickDate ? new Date(accrual.clickDate) : undefined,
});

export function parseData(data: DictionaryData): FilterGroupItem<string>[] {
    return data.dictionaryValueList.reduce<FilterGroupItem<string>[]>((acc, eventData) => {
        const item: AccrualDictionary = JSON.parse(eventData.value);
        acc.push({
            checked: false,
            label: item.name,
            id: eventData.key,
        });

        return acc;
    }, []);
}
