import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { DictionaryData } from '@admin/model/model.types';
import { logger } from '@admin/common/helpers/logger';

export const getOfferPartnersAccrualCategoriesFx = createEffect(async () => {
    try {
        const { dictionaryValueList } = await getRequest<DictionaryData>(
            'dictionaries/offer_partners_accrual_category',
        );

        return dictionaryValueList.map(({ value, key }) => {
            const item = JSON.parse(value) as { name: string };
            return { value: key, title: item?.name };
        });
    } catch (error) {
        logger(error);

        throw error;
    }
});
