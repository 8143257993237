export enum SegmentField {
    CreatedAt = 'createdAt',
    Description = 'description',
    Blocked = 'blocked',
    Key = 'key',
}

export type SegmentsValue = {
    createdAt?: string;
    description: string;
    blocked?: boolean;
};

export type SegmentWithValue = Omit<SegmentsValue, 'createdAt'> & {
    key: string;
    createdAt?: Date;
};

export interface SegmentData {
    key: string;
    value: string; // JSON with object
    parent: null;
}
