import { createEvent, createStore } from 'effector';
import type { Notification } from './notices.types';
import { createNotification } from './notices.helpers';

export const showNoticeEvent = createEvent<string>();
export const hideNoticeEvent = createEvent<string>();
export const resetNoticesStoreEvent = createEvent();

export const $noticesStore = createStore<Notification[]>([])
    .on(showNoticeEvent, (state, payload) => [...state, createNotification(payload)])
    .on(hideNoticeEvent, (state, payload) => [...state.filter(({ id }) => id !== payload)])
    .reset(resetNoticesStoreEvent);
