import { combine, createEvent, createStore } from 'effector';
import { changeOrderStatusFx } from '@admin/model/orders/change-order-status/change-order-status.fx';

export const resetChangeOrderStatusEvent = createEvent();

export const $changeOrderStatusData = createStore<boolean>(false);
export const $changeOrderStatusError = createStore<Nullable<unknown>>(null);

export const $changeOrderStatus = combine({
    data: $changeOrderStatusData,
    error: $changeOrderStatusError,
    isLoading: changeOrderStatusFx.pending,
});
