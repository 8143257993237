import { combine, createEvent, createStore } from 'effector';
import { getClientAgreementListFx } from '@admin/model/clients-and-profiles/client-agreement-list/get-client-agreement-list.fx';
import { ClientAgreementsState } from '@admin/model/clients-and-profiles/client-agreement-list/client-agreement-list.types';

const initialState: ClientAgreementsState = {
    totalCount: 0,
    data: [],
};

export const $clientAgreementListData = createStore<ClientAgreementsState>(initialState);
export const $clientAgreementListError = createStore<Nullable<unknown>>(null);

export const resetClientAgreementListStore = createEvent();

export const $clientAgreementList = combine({
    data: $clientAgreementListData.reset(resetClientAgreementListStore),
    error: $clientAgreementListError,
    isLoading: getClientAgreementListFx.pending,
});
