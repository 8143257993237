import { createEffect } from 'effector';
import { DictionaryData } from '@admin/model/model.types';
import { getRequest } from '@admin/model/api';
import { parseData } from '@admin/model/offers/coupon-codes-dictionary/coupon-codes-dictionary.helpers';
import { logger } from '@admin/common/helpers/logger';
import { setCommonAppError } from '@admin/model/error-notification';

export const getCouponCodesDictionaryFx = createEffect(async () => {
    try {
        const data = await getRequest<DictionaryData>('dictionaries/coupon_status');

        return parseData(data);
    } catch (error) {
        logger(error);
        setCommonAppError({ error });
        throw null;
    }
});
