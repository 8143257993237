import { combine, createStore } from 'effector';
import { getNotificationFx } from '@admin/model/notifications/notification/get-notification.fx';
import { NotificationData } from '@admin/model/notifications/notifications.types';

export const $notificationData = createStore<Nullable<NotificationData>>(null);
export const $notificationError = createStore<Nullable<unknown>>(null);

export const $notification = combine({
    data: $notificationData,
    error: $notificationError,
    isLoading: getNotificationFx.pending,
});
