import { sample } from 'effector';
import { getSegmentListFx } from '@admin/model/segments/segment-list/get-segment-list.fx';
import { $segmentsData, $segmentsError } from '@admin/model/segments/segment-list/segment-list.store';
import { parseData } from '@admin/model/segments/segment-list/segment-list.helpers';

sample({
    clock: getSegmentListFx.doneData,
    fn: (result) => parseData(result),
    target: $segmentsData,
});

sample({
    clock: getSegmentListFx.failData,
    target: $segmentsError,
});
