import { sample } from 'effector';
import { createNotificationFx } from '@admin/model/notifications/create-notification/create-notification.fx';
import { getSuccessMessage } from '@admin/model/notifications/create-notification/create-notification.helpers';
import { showNoticeEvent } from '@admin/model/notices/notices.store';

sample({
    clock: createNotificationFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
