import { logger } from '@admin/common/helpers/logger';
import { getRequest } from '@admin/model/api';
import { createEffect } from 'effector';
import {
    FileUploadHistoryPayload,
    FileUploadHistoryListData,
} from '@admin/model/file-upload-history/file-upload-history.types';
import { normalizeFileUploadHistoryData } from '@admin/model/file-upload-history/file-upload-history.helpers';

export const getFileUploadHistoryFx = createEffect(async (payload: FileUploadHistoryPayload) => {
    try {
        const { catalogUploads, totalCount } = await getRequest<FileUploadHistoryListData>('catalog/uploads', payload);

        return {
            data: normalizeFileUploadHistoryData(catalogUploads),
            totalCount,
        };
    } catch (error) {
        logger(error);

        throw error;
    }
});
