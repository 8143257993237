import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { CollectionsUpdateBatchingPayload } from '@admin/model/collections/update-collections-batching/update-collections-batching.types';
import { setCommonAppError } from '@admin/model/error-notification/error-notification.store';

export const updateCollectionsBatchingFx = createEffect(async (payload: CollectionsUpdateBatchingPayload) => {
    try {
        await postRequest('catalog/categories/batching', payload);

        return payload.categoryIds;
    } catch (error) {
        logger(error);

        setCommonAppError({ error });

        throw null;
    }
});
