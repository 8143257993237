import { DictionaryData } from '@admin/model/model.types';
import {
    CouponCodesDictionary,
    DictionaryBaseItemExtended,
} from '@admin/model/offers/coupon-codes-dictionary/coupon-codes-dictionary.types';

export const parseData = (couponCodesDictData: DictionaryData): CouponCodesDictionary =>
    couponCodesDictData.dictionaryValueList.reduce<CouponCodesDictionary>(
        (acc, dictionaryValue) => {
            const parsed = JSON.parse(dictionaryValue.value) as DictionaryBaseItemExtended;
            acc.array.push(parsed);
            acc.dictionary[parsed.code] = parsed;
            if (parsed.additionalConfirm !== undefined) {
                acc.additionalConfirm = parsed.additionalConfirm;
            }
            return acc;
        },
        {
            array: [],
            dictionary: {},
            additionalConfirm: false,
        },
    );
