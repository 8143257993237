import { createEffect } from 'effector';
import { putRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { setCommonAppError } from '@admin/model/error-notification';
import { OfferValidationErrorCode } from '@admin/model/offers/offers.types';
import { UpdateOfferPayload } from '@admin/model/offers/update-offer/update-offer.types';

export const updateOfferFx = createEffect(async ({ id, formData }: UpdateOfferPayload) => {
    try {
        await putRequest(`offers/${id}`, formData, {
            apiVersion: 2,
        });
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, OfferValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
