import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { getSuccessMessage } from '@admin/model/categories/update-categories-accessibility/update-categories-accessibility.helpers';
import { updateCategoriesAccessibilityFx } from '@admin/model/categories/update-categories-accessibility/update-categories-accessibility.fx';
import {
    $isUpdateCategoriesAccessibilityDone,
    resetUpdateCategoriesAccessibilityDoneStatusEvent,
} from '@admin/model/categories/update-categories-accessibility/update-categories-accessibility.store';
import { reset } from 'patronum';

sample({
    source: updateCategoriesAccessibilityFx.doneData,
    fn: (payload) => getSuccessMessage(payload.categoryIds),
    target: showNoticeEvent,
});

sample({
    clock: updateCategoriesAccessibilityFx.doneData,
    fn: () => true,
    target: $isUpdateCategoriesAccessibilityDone,
});

reset({
    clock: resetUpdateCategoriesAccessibilityDoneStatusEvent,
    target: $isUpdateCategoriesAccessibilityDone,
});
