import { sample } from 'effector';
import { normalizeListsData } from '@admin/model/lists/get-lists/lists.helpers';
import { getListsFx } from '@admin/model/lists/get-lists/get-lists.fx';
import { $listsData, $listsError } from '@admin/model/lists/get-lists/lists.store';

sample({
    clock: getListsFx.doneData,
    fn: (result) => normalizeListsData(result),
    target: $listsData,
});

sample({
    clock: getListsFx.failData,
    target: $listsError,
});
