import { ChangeHistoryData } from '@admin/model/model.types';
import {
    CostMatrixChangeHistoryItemData,
    CostMatrixChangeHistory,
} from '@admin/model/cost-matrix/cost-matrix-change-history/cost-matrix-change-history.types';
import { normalizeDate } from '@admin/model/helpers';

export const normalizeCostMatrixChangeHistoryData = (
    response: ChangeHistoryData<CostMatrixChangeHistoryItemData>,
): CostMatrixChangeHistory => ({
    ...response,
    createdAt: normalizeDate(response.createdAt) as Date,
    data: response.data.map((dataItem) => ({ ...dataItem, changedAt: normalizeDate(dataItem.changedAt) as Date })),
});
