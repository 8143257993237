import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { normalizeDate } from '@admin/model/helpers';
import { RolesData, RolesPayload } from '../roles.types';
import { rolesDomain } from '@admin/model/model.domains';

export const loadRolesFx = rolesDomain.createEffect(async (payload: RolesPayload) => {
    try {
        const { data, totalCount } = await getRequest<RolesData>('roles', payload);

        return {
            roles: data.map((role) => ({
                ...role,
                createdAt: normalizeDate(role.createdAt),
            })),
            totalCount,
        };
    } catch (error) {
        logger(error);

        throw error;
    }
});
