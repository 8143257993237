import { forward, sample } from 'effector';
import {
    $clientHashAccountsData,
    $clientHashAccountsError,
    resetClientHashAccountsEvent,
    resetClientHashAccountsErrorEvent,
} from '@admin/model/clients-and-profiles/client-hash/client-hash.store';
import { reset } from 'patronum';
import { getClientHashAccountsFx } from './client-hash.fx';
import { HashAccount } from './client-hash.types';

forward({
    from: getClientHashAccountsFx.doneData,
    to: $clientHashAccountsData,
});

forward({
    from: getClientHashAccountsFx.failData,
    to: $clientHashAccountsError,
});

sample({
    clock: getClientHashAccountsFx.doneData,
    fn: (result: HashAccount[]) => (!result.length ? true : null),
    target: $clientHashAccountsError,
});

sample({
    clock: getClientHashAccountsFx.failData,
    fn: () => null,
    target: $clientHashAccountsData,
});

reset({ clock: resetClientHashAccountsEvent, target: [$clientHashAccountsData, $clientHashAccountsError] });
reset({ clock: resetClientHashAccountsErrorEvent, target: $clientHashAccountsError });
