import {
    SupplierFormConfigsData,
    SupplierFormConfigs,
    ConfigItem,
} from '@admin/model/suppliers/supplier-form-configs/supplier-form-configs.types';

export function parseData(supplierFormConfigsData: SupplierFormConfigsData): SupplierFormConfigs {
    return supplierFormConfigsData.dictionaryValueList.reduce<Record<string, ConfigItem>>((acc, supplierData) => {
        const supplierType = supplierData.key;

        acc[supplierType] = {
            config: JSON.parse(supplierData.value),
            fields: {},
        };

        acc[supplierType].config.forEach((groupConfig) => {
            groupConfig.settings.forEach((fieldName) => {
                acc[supplierType].fields[fieldName] = fieldName;
            });
        });

        return acc;
    }, {});
}
