import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { getSuccessMessage } from '@admin/model/users/update-users-roles/update-users-roles.helpers';
import { updateUsersRolesFx } from '@admin/model/users/update-users-roles/update-users-roles.fx';

sample({
    source: updateUsersRolesFx.doneData,
    fn: (payload) => getSuccessMessage(payload),
    target: showNoticeEvent,
});
