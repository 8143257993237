import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { sample } from 'effector';
import { getSuccessMessage } from '@admin/model/offers/change-coupon-codes-status/change-coupon-codes-status.helpers';
import { changeCouponCodesStatusFx } from '@admin/model/offers/change-coupon-codes-status/change-coupon-codes-status.fx';

sample({
    clock: changeCouponCodesStatusFx.done,
    fn: ({ params }) => getSuccessMessage(params.quantity),
    target: showNoticeEvent,
});
