import { logger } from '@admin/common/helpers/logger';
import { Category } from '@admin/model/categories/category/category.types';
import { getRequest } from '@admin/model/api';
import { createEffect } from 'effector';

export const getParentCategoryFx = createEffect(async (id: string) => {
    try {
        return await getRequest<Category>(`catalog/categories/${id}`);
    } catch (error) {
        logger(error);

        throw error;
    }
});
