import { createEffect } from 'effector/effector.umd';
import { postRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { setCommonAppError } from '@admin/model';

export const orderUpdateFx = createEffect(async (payload: number[]) => {
    try {
        await postRequest('orders/update', payload);
    } catch (error) {
        logger(error);
        setCommonAppError({ error });

        throw null;
    }
});
