import { DictionaryData } from '@admin/model/model.types';
import {
    NotificationEventTypeDictionaryItem,
    NotificationEventTypesDictionary,
} from '@admin/model/notifications/notifications-events-types-dictionary/notifications-events-types-dictionary.types';

export const parseData = (data: DictionaryData): NotificationEventTypesDictionary => {
    return data.dictionaryValueList.reduce<NotificationEventTypesDictionary>(
        (acc, eventData) => {
            const parsed = JSON.parse(eventData.value) as NotificationEventTypeDictionaryItem;
            acc.array.push(parsed);
            acc.dictionary[parsed.code] = parsed;

            return acc;
        },
        {
            dictionary: {},
            array: [],
        },
    );
};
