import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import {
    UserChangeHistoryPayload,
    UserChangeHistoryState,
} from '@admin/model/users/user-change-history/user-change-history.types';
import { normalizeUserChangeHistoryData } from '@admin/model/users/user-change-history/user-change-history.helpers';
import { ChangeHistoryData, ChangeHistoryItemData } from '@admin/model/model.types';
import { postRequest } from '@admin/model/api';

export const getUserChangeHistoryFx = createEffect(async ({ id, ...rest }: UserChangeHistoryPayload) => {
    try {
        const data = await postRequest<
            ChangeHistoryData<ChangeHistoryItemData<UserChangeHistoryState>>,
            UserChangeHistoryPayload
        >(`employee/${id}/history`, {
            ...rest,
        });

        return normalizeUserChangeHistoryData(data);
    } catch (error) {
        logger(error);

        throw error;
    }
});
