import { createEffect } from 'effector';
import { getRequest, pollRequest, putRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { UserData } from '@admin/model/users/user.types';
import { RequestWithPollingResponse } from '@admin/model/model.types';
import { setCommonAppError } from '@admin/model/error-notification';

export const resetUser2FAFx = createEffect(async (id: string) => {
    try {
        const response = await putRequest<RequestWithPollingResponse, {}>(`employees/${id}/two-factor-auth/reset`);

        return await pollRequest<UserData>(() => getRequest(`employees/processing/${response.processId}`));
    } catch (error) {
        logger(error);

        setCommonAppError({ error });

        throw null;
    }
});
