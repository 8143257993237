import { DictionaryData } from '@admin/model/model.types';
import {
    GroupConfig,
    ReportsGroupConfig,
    ReportsGroupsConfig,
} from '@admin/model/reports/reports-groups-configs/reports-groups-configs.types';
import { parseReportsConfigsData } from '@admin/model/reports/helpers/parse-reports-configs-data';

export function parseData(reportsConfigsData: DictionaryData, reportsGroupsData: DictionaryData): ReportsGroupsConfig {
    const parsedReports = parseReportsConfigsData(reportsConfigsData);

    return reportsGroupsData.dictionaryValueList.reduce<ReportsGroupsConfig>((acc, group) => {
        const groupKey: string = group.key;
        const groupData: GroupConfig = JSON.parse(group.value);
        const filteredReports = parsedReports.filter((report) => report.group === groupKey);

        if (filteredReports.length > 0) {
            acc[groupKey] = filteredReports.reduce<ReportsGroupConfig>(
                (acc, report) => {
                    acc.reports.push(report);
                    return acc;
                },
                {
                    name: groupData.name,
                    order: groupData.sort,
                    reports: [],
                },
            );
        }

        return acc;
    }, {});
}
