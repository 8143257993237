import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { KladrsData, KladrsPayload } from '@admin/model/kladrs/kladrs.types';

export const getKladrsFx = createEffect(async (payload: KladrsPayload) => {
    try {
        return await postRequest<KladrsData, KladrsPayload>('countries/ru/search', payload);
    } catch (error) {
        logger(error);

        throw error;
    }
});
