import { combine, createEvent, createStore } from 'effector';
import { deleteListsFx } from '@admin/model/lists/delete-lists/delete-lists.fx';

export const resetDeleteListsDoneStatusEvent = createEvent();
export const $deleteListsData = createStore<boolean>(false);

export const $deleteLists = combine({
    data: $deleteListsData,
    isLoading: deleteListsFx.pending,
});
