import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { getSuccessMessage } from '@admin/model/offer-categories/create-offer-category/create-offer-category.helpers';
import { createOfferCategoryFx } from '@admin/model/offer-categories/create-offer-category/create-offer-category.fx';

sample({
    clock: createOfferCategoryFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
