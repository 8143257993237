import { ReportType, ReportStatus } from '@admin/model/reports/report/report.types';

export interface ReportsPayload {
    offset: number;
    limit: number;
    order?: string;
    filters?: {
        userIds?: string[];
        types?: string[];
        statuses?: string[];
        term?: string;
        startAt?: string;
        endAt?: string;
    };
}

export interface Result {
    result?: string;
    description?: string;
    errorCode?: string;
    fileSize?: string;
    fileType?: string;
}

export enum RequestGroup {
    Report = 'report',
}

export interface ReportData {
    id: string;
    type: ReportType;
    status: ReportStatus;
    createdAt: string;
    updatedAt: string;
    createdBy: {
        userId: string;
        firstName: string;
        lastName: string;
        patronymicName: Nullable<string>;
    };
    group: RequestGroup;
    result: Nullable<Result>;
    parameters: { [key: string]: string };
}

export interface Report extends Omit<ReportData, 'createdAt' | 'updatedAt'> {
    createdAt?: Date;
    updatedAt?: Date;
}

export type ReportsData = {
    data: ReportData[];
    totalCount: number;
};

export type Reports = {
    data: Report[];
    totalCount: number;
};
