import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { updateCategoriesStatusFx } from '@admin/model/categories/update-categories-status/update-categories-status.fx';
import { getSuccessMessage } from '@admin/model/categories/update-categories-status/update-categories-status.helpers';
import {
    resetUpdateCategoriesStatusDoneStatusEvent,
    resetUpdateCategoriesStatusErrorEvent,
    $updateCategoriesStatusDoneStatus,
    $updateCategoriesStatusError,
} from '@admin/model/categories/update-categories-status/update-categories-status.store';
import { reset } from 'patronum';

sample({
    source: updateCategoriesStatusFx.doneData,
    fn: (payload) => getSuccessMessage(payload.categoryIds),
    target: showNoticeEvent,
});

sample({
    clock: updateCategoriesStatusFx.done,
    fn: () => true,
    target: $updateCategoriesStatusDoneStatus,
});

sample({
    clock: updateCategoriesStatusFx.failData,
    target: $updateCategoriesStatusError,
});

reset({ clock: resetUpdateCategoriesStatusDoneStatusEvent, target: $updateCategoriesStatusDoneStatus });
reset({ clock: resetUpdateCategoriesStatusErrorEvent, target: $updateCategoriesStatusError });
