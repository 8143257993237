import { logger } from '@admin/common/helpers/logger';
import { setCommonAppError } from '@admin/model/error-notification';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { putRequest } from '@admin/model/api';
import {
    UpdateCategoryPositionPayload,
    UpdateCategoryPositionValidationErrorCode,
} from '@admin/model/categories/update-category-position/update-category-position.types';
import { createEffect } from 'effector';

export const updateCategoryPositionFx = createEffect(
    async ({ categoryId, parentId, predecessorId }: UpdateCategoryPositionPayload) => {
        try {
            await putRequest(`catalog/categories/${categoryId}/position`, {
                parentId,
                predecessorId,
            });
        } catch (error) {
            logger(error);

            if (isKnownValidationErrorResponse(error, UpdateCategoryPositionValidationErrorCode)) {
                throw error.data.validationErrors;
            } else {
                setCommonAppError({ error });

                throw null;
            }
        }
    },
);
