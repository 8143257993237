import { characteristicsDomain } from '@admin/model/model.domains';

export const characteristicDomain = characteristicsDomain.createDomain();
export const characteristicsListDomain = characteristicsDomain.createDomain();
export const characteristicsTypesDictionaryDomain = characteristicsDomain.createDomain();
export const characteristicsUnitedUnitsDictionaryDomain = characteristicsDomain.createDomain();
export const characteristicsUnitsFilterDomain = characteristicsDomain.createDomain();
export const createCharacteristicDomain = characteristicsDomain.createDomain();
export const deleteCharacteristicDomain = characteristicsDomain.createDomain();
export const updateCharacteristicDomain = characteristicsDomain.createDomain();
