import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { createEffect } from 'effector';
import {
    DownloadOrdersListPayload,
    DownloadOrdersListData,
} from '@admin/model/orders/download-orders-list/download-orders-list.types';

export const downloadOrdersListFx = createEffect(async (payload: DownloadOrdersListPayload) => {
    try {
        return await postRequest<DownloadOrdersListData, DownloadOrdersListPayload>('reports', payload);
    } catch (error) {
        logger(error);
        setCommonAppError({ error });
        throw null;
    }
});
