import { restore, combine, createEvent } from 'effector';
import { Permission, User } from '@admin/model/users/user.types';
import { getCurrentUserFx } from './get-current-user.fx';

interface CurrentUserState {
    user: Nullable<User>;
    permissions: { [key in Permission]?: string };
    linkedSuppliersIds: { [supplierId: string]: string };
    linkedReportsTypes: { [reportTypeName: string]: string };
}

const initialState: CurrentUserState = {
    user: null,
    permissions: {},
    linkedSuppliersIds: {},
    linkedReportsTypes: {},
};

export const resetCurrentUserStore = createEvent();

const $errorStore = restore(getCurrentUserFx.failData, null)
    .on(getCurrentUserFx.doneData, () => null)
    .reset([resetCurrentUserStore]);

const $dataStore = restore(getCurrentUserFx, initialState).reset([resetCurrentUserStore]);

export const $currentUserStore = combine({
    error: $errorStore,
    isLoading: getCurrentUserFx.pending,
    data: $dataStore,
});
