import { LabelItem } from '@admin/model/labels/labels.types';
import {
    OfferCharacteristic,
    ShortenedSupplier,
    OfferField,
    OfferType,
    OfferModerationStatus,
    CategoryPath,
} from '@admin/model/offers/offers.types';

export enum OfferStatus {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Deleted = 'DELETED',
}

export enum OffersListPayloadParam {
    Page = 'page',
    Active = 'active',
    CategoryIds = 'categoryIds',
    ListIds = 'listIds',
    MaxPrice = 'maxPrice',
    MinPrice = 'minPrice',
    ModerationStatuses = 'moderationStatuses',
    Ordering = 'ordering',
    ShowOfferWithSyncedProduct = 'showOfferWithSyncedProduct',
    SupplierIds = 'supplierIds',
    Term = 'term',
    Types = 'types',
    SynchronizationStatuses = 'synchronizationStatuses',
    DateRange = 'dateRange',
    MinDeleteDate = 'minDeleteDate',
    MaxDeleteDate = 'maxDeleteDate',
    OfferStatuses = 'offerStatuses',
    ManufacturersList = 'manufacturersList',
}

export interface LoadOffersPayload {
    [OffersListPayloadParam.Page]?: number;
    [OffersListPayloadParam.Active]?: boolean;
    [OffersListPayloadParam.CategoryIds]?: string[];
    [OffersListPayloadParam.ListIds]?: string[];
    [OffersListPayloadParam.MaxPrice]?: number;
    [OffersListPayloadParam.MinPrice]?: number;
    [OffersListPayloadParam.ModerationStatuses]?: string[];
    [OffersListPayloadParam.Page]?: number;
    [OffersListPayloadParam.Ordering]?: string;
    [OffersListPayloadParam.ShowOfferWithSyncedProduct]?: boolean;
    [OffersListPayloadParam.SupplierIds]?: string[];
    [OffersListPayloadParam.Term]?: string;
    [OffersListPayloadParam.Types]?: string[];
    [OffersListPayloadParam.SynchronizationStatuses]?: string[];
    [OffersListPayloadParam.MinDeleteDate]?: string;
    [OffersListPayloadParam.MaxDeleteDate]?: string;
    [OffersListPayloadParam.OfferStatuses]?: string[];
    [OffersListPayloadParam.ManufacturersList]?: string[];
}

export interface OfferListItemData {
    [OfferField.Id]: string;
    [OfferField.Name]: string;
    [OfferField.Sku]: string;
    [OfferField.Supplier]: ShortenedSupplier;
    [OfferField.Price]: number;
    [OfferField.OfferCharacteristics]: OfferCharacteristic[];
    [OfferField.Images]: Nullable<string[]>;
    [OfferField.Targets]: Nullable<string[]>;
    [OfferField.Labels]: Nullable<LabelItem[]>;
    [OfferField.ModerationStatus]: OfferModerationStatus;
    [OfferField.Active]: boolean;
    [OfferField.Available]: boolean;
    [OfferField.Type]: OfferType;
    [OfferField.Synchronized]: boolean;
    [OfferField.DeleteDate]: Nullable<string>;
    categoryPaths: Nullable<CategoryPath[][]>;
}

export type OffersListData = {
    offers: OfferListItemData[];
    totalCount: number;
};

export interface OffersListItem extends Omit<OfferListItemData, OfferField.Images | OfferField.DeleteDate> {
    imageUrls: string[];
    deleteDate?: Nullable<Date>;
}
