import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { getRequest } from '@admin/model/api';
import {
    OfferCategoriesPayload,
    OfferCategoriesList,
} from '@admin/model/offer-categories/offer-categories-list/offer-categories.types';

export const getOfferCategoriesListFx = createEffect(async (payload: OfferCategoriesPayload) => {
    try {
        return await getRequest<OfferCategoriesList>('offer-partners/categories', payload);
    } catch (error) {
        logger(error);

        throw error;
    }
});
