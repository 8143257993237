import { combine, createEvent, createStore } from 'effector';
import { updatePartnerCategoryAliasFx } from '@admin/model/categories/update-partner-category-alias/update-partner-category-alias.fx';

export const resetUpdatePartnerCategoryAliasStateEvent = createEvent();

export const $updatePartnerCategoryAliasData = createStore<boolean>(false);
export const $updatePartnerCategoryAliasError = createStore<Nullable<unknown>>(null);

export const $updatePartnerCategoryAlias = combine({
    data: $updatePartnerCategoryAliasData,
    error: $updatePartnerCategoryAliasError,
    isLoading: updatePartnerCategoryAliasFx.pending,
});
