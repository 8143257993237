import { combine } from 'effector';
import { characteristicsUnitsFilterDomain } from '@admin/model/characteristics/characteristics.domains';
import { CharacteristicUnit } from '@admin/model/characteristics/characteristic/characteristic.types';
import { getCharacteristicsUnitsFilterFx } from '@admin/model/characteristics/characteristics-units-filter/get-characteristics-units-filter.fx';

export const $characteristicsUnitsFilterData =
    characteristicsUnitsFilterDomain.createStore<Nullable<CharacteristicUnit[]>>(null);
export const $characteristicsUnitsFilterError = characteristicsUnitsFilterDomain.createStore<Nullable<unknown>>(null);

export const $characteristicsUnitsFilter = combine({
    data: $characteristicsUnitsFilterData,
    error: $characteristicsUnitsFilterError,
    isLoading: getCharacteristicsUnitsFilterFx.pending,
});
