export enum ClientDisableAccessValidationErrorCode {
    ClientDisabled = 'CLIENT_DISABLED',
}

export const enum ClientDisableAccessReason {
    OTHER = 'OTHER',
}

export interface ClientDisableAccessPayload {
    reason: ClientDisableAccessReason;
    comment?: string;
}
