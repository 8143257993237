import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { updateCategoriesCurrencyFx } from '@admin/model/categories/update-categories-currency/update-categories-currency.fx';
import { getSuccessMessage } from '@admin/model/categories/update-categories-currency/update-categories-currency.helpers';
import {
    $updateCategoriesCurrencyDoneStatus,
    $updateCategoriesCurrencyError,
    resetUpdateCategoriesCurrencyDoneStatusEvent,
    resetUpdateCategoriesCurrencyErrorEvent,
} from '@admin/model/categories/update-categories-currency/update-categories-currency.store';
import { reset } from 'patronum';

sample({
    clock: updateCategoriesCurrencyFx.done,
    source: updateCategoriesCurrencyFx.doneData,
    fn: (payload) => getSuccessMessage(payload.categoryIds),
    target: showNoticeEvent,
});

sample({
    clock: updateCategoriesCurrencyFx.done,
    fn: () => true,
    target: $updateCategoriesCurrencyDoneStatus,
});

sample({
    clock: updateCategoriesCurrencyFx.failData,
    target: $updateCategoriesCurrencyError,
});

reset({ clock: resetUpdateCategoriesCurrencyDoneStatusEvent, target: $updateCategoriesCurrencyDoneStatus });
reset({ clock: resetUpdateCategoriesCurrencyErrorEvent, target: $updateCategoriesCurrencyError });
