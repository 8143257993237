import { ValidationError } from '@admin/common/validation';
import { ReportParameterType } from '@admin/model/reports/report/report.types';

export interface ReportOptionalParameters {
    [ReportParameterType.HasRefund]?: boolean;
    [ReportParameterType.Supplier]?: string[];
    [ReportParameterType.Detalization]?: string;
    [ReportParameterType.DateFilterType]?: string;
    [ReportParameterType.AuthorizationChannel]?: string;
    [ReportParameterType.NotificationGroups]?: string;
    [ReportParameterType.NotificationEventTypes]?: string[];
    [ReportParameterType.NotificationChannel]?: string[];
    [ReportParameterType.NotificationStatus]?: string[];
    [ReportParameterType.Configurations]?: string[];
    [ReportParameterType.Statuses]?: string[];
    [ReportParameterType.SingleSelectSupplier]?: string;
    [ReportParameterType.OrderStatuses]?: string[];
    [ReportParameterType.RefundStatuses]?: string[];
    [ReportParameterType.OrderDatePeriod]?: {
        startDate: Date;
        endDate: Date;
    };
    [ReportParameterType.ExpireDatePeriod]?: {
        startDate: Date;
        endDate: Date;
    };
    [ReportParameterType.PaymentStatuses]?: string[];
    [ReportParameterType.Currencies]?: string[];
    [ReportParameterType.AccrualStatuses]?: string[];
    [ReportParameterType.AccrualTypes]?: string[];
}

export interface ReportParameters extends ReportOptionalParameters {
    [ReportParameterType.StartDate]?: string;
    [ReportParameterType.EndDate]?: string;
}

export interface ReportRequestCreationPayload {
    reportCode: string;
    parameters: ReportParameters;
}

export interface ReportRequestData {
    requisitionId: number;
}

export enum CreateReportRequestErrorCode {
    ReportPeriodInvalid = 'REPORT_PERIOD_INVALID',
}

export type CreateReportRequestError = ValidationError<CreateReportRequestErrorCode>;
