import { logger } from '@admin/common/helpers/logger';
import { deleteRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { createEffect } from 'effector';

export const deleteSegmentFx = createEffect(async (segmentId: string) => {
    try {
        return await deleteRequest(`segments/${segmentId}`);
    } catch (error) {
        logger(error);

        setCommonAppError({ error });

        throw null;
    }
});
