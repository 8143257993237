import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { DictionaryData } from '@admin/model/model.types';
import { parseData } from '@admin/model/orders/orders.helpers';
import { PaymentStatus } from '@admin/model/orders/order/order.types';

export const getPaymentStatusesDictionaryFx = createEffect(async () => {
    try {
        const data = await getRequest<DictionaryData>('dictionaries/payment_status');

        return parseData<PaymentStatus>(data);
    } catch (error) {
        logger(error);

        throw error;
    }
});
