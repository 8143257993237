// validation: https://kb.epam.com/display/VTBBULPM/Generic+REST+API+responses+used+in+ULPM+project

import { isKnownValidationErrors } from '@admin/common/validation/helpers';

import { isBadRequest, isNotFound, ResponseError } from '@admin/model/api';

export class ValidationResponseError<T = string> extends ResponseError {
    public constructor(statusOk: boolean, status: number, statusText: string, public data: ValidationErrorData<T>) {
        super(statusOk, status, statusText);
    }
}

export enum ValidationErrorResponseType {
    ValidationFailed = 'VALIDATION_FAILED',
}

export enum ValidationErrorType {
    Error = 'ERROR',
    Warning = 'WARNING',
}

export interface ValidationError<T> {
    code: T;
    type: ValidationErrorType;
    detail: string;
    field: string;
    values?: { [key: string]: string | string[] };
}

export interface ValidationErrorData<T = string> {
    type: ValidationErrorResponseType.ValidationFailed;
    validationErrors: ValidationError<T>[];
    processId?: string;
}

export function isValidationErrorData(data: unknown): data is ValidationErrorData {
    return (data as ValidationErrorData)?.type === ValidationErrorResponseType.ValidationFailed;
}

export function isValidationErrorBadRequest(error: unknown): error is ValidationResponseError {
    return isValidationErrorData((error as ValidationResponseError).data) && isBadRequest(error);
}

export function isValidationErrorResponse(error: unknown): error is ValidationResponseError {
    return isValidationErrorData((error as ValidationResponseError).data) && (isNotFound(error) || isBadRequest(error));
}

export function isKnownValidationErrorResponse<T extends Record<string, string>, P extends keyof T>(
    error: unknown,
    knownValidationErrorCodes: T,
): error is ValidationResponseError<T[P]> {
    return (
        (error as ValidationResponseError).data !== undefined &&
        isValidationErrorBadRequest(error) &&
        isKnownValidationErrors(error.data, knownValidationErrorCodes)
    );
}
