import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { BlockingPage } from '@admin/model/blocking-pages/blockibg-pages.store';

export const getBlockingPagesFx = createEffect(async () => {
    try {
        const data: BlockingPage[] = await getRequest('blocking-pages');

        const mappedData: BlockingPage<Date>[] = data.map(({ dateTo, dateFrom, ...rest }) => ({
            ...rest,
            dateTo: dateTo ? new Date(dateTo) : undefined,
            dateFrom: dateFrom ? new Date(dateFrom) : undefined,
        }));

        return mappedData;
    } catch (error) {
        logger(error);

        throw error;
    }
});
