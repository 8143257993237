import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { DictionaryData } from '@admin/model/model.types';

export const getLabelsFx = createEffect(async () => {
    try {
        const { dictionaryValueList } = await getRequest<DictionaryData>('dictionaries/LABEL');

        return dictionaryValueList.map(({ key, value }) => ({ key, value }));
    } catch (error) {
        logger(error);

        throw error;
    }
});
