import { normalizeImages } from '@admin/common/helpers/normalize-images';
import { Category } from '@admin/model/categories/category/category.types';

export const normalizeCategoryImages = (data: Category): Category => {
    const teaser = data?.attributes?.urlTeaser;
    const banner = data?.attributes?.urlBanner;
    const mobileBanner = data?.attributes?.urlMobileBanner;

    const images: {
        urlTeaser?: string;
        urlBanner?: string;
        urlMobileBanner?: string;
    } = {};

    if (teaser !== undefined) {
        images.urlTeaser = normalizeImages([teaser])[0] || teaser;
    }
    if (banner !== undefined) {
        images.urlBanner = normalizeImages([banner])[0] || banner;
    }
    if (mobileBanner !== undefined) {
        images.urlMobileBanner = normalizeImages([mobileBanner])[0] || mobileBanner;
    }

    return {
        ...data,
        attributes: {
            ...data.attributes,
            ...images,
        },
    };
};
