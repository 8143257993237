import { ListTypesDictionary } from '@admin/model/lists/list-types-dictionary/list-types-dictionary.types';
import { DictionaryBaseItem, DictionaryData } from '@admin/model/model.types';

export const parseData = (ListTypesDictData: DictionaryData): ListTypesDictionary =>
    ListTypesDictData.dictionaryValueList.reduce<ListTypesDictionary>(
        (acc, ListTypesData) => {
            const parsed = JSON.parse(ListTypesData.value) as DictionaryBaseItem;
            acc.array.push(parsed);
            acc.dictionary[parsed.code] = parsed;
            return acc;
        },
        {
            array: [],
            dictionary: {},
        },
    );
