import { normalizeImages } from '@admin/common/helpers';
import { OfferPartner, OfferPartnerData } from '@admin/model/offer-partners/offer-partners.types';

export const normalizeOfferPartnerData = (data: OfferPartnerData): OfferPartner => ({
    id: data.id,
    name: data.name,
    loyaltyPrograms: data.loyaltyPrograms,
    supplier: { id: Number(data.supplier.supplierId), name: data.supplier.supplierName },
    supplierAlias: data.supplier.supplierAlias,
    supplierDescription: data.supplier.supplierDescription,
    categories: data.categories.map(({ categoryId, categoryName }) => ({ id: categoryId, name: categoryName })),
    currencies: data.currencies,
    description: data.description,
    acceptedIn: data.acceptedIn,
    awardDescription: data.awardDescription,
    integrationType: data.integrationType,
    images: normalizeImages(data.imageUrls || []),
    stickerCodes: data.stickerCodes || [],
    moderationStatus: data.moderationStatus,
    status: data.status,
    pointOfSales: data.pointOfSales,
    kladrCodes: data.kladrCodes,
    code: data.code,
    buyingConditions: data.buyingConditions,
    buyingRules: data.buyingRules,
    link: data.link,
    priority: data.priority,
    targetAudience: data.targetAudience,
    dateToPublishFrom: data.dateToPublishFrom,
    dateToPublishTo: data.dateToPublishTo,
    discountDateFrom: data.discountDateFrom,
    discountDateTo: data.discountDateTo,
    actionRules: data.actionRulesUrl,
    accrualCategory: data.accrualCategory,
});
