import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { DictionaryData } from '@admin/model/model.types';
import { parseData } from '@admin/model/notifications/notifications-events-types-dictionary/notifications-events-types-dictionary.helpers';

export const getNotificationsEventTypesDictionaryFx = createEffect(async () => {
    try {
        const data = await getRequest<DictionaryData>('dictionaries/NOTIFICATION_EVENT_TYPES');

        return parseData(data);
    } catch (error) {
        logger(error);

        throw error;
    }
});
