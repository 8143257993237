import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { getRequest } from '@admin/model/api';
import { DictionaryData } from '@admin/model/model.types';
import { FilterEventTypeSearchPayload } from '@admin/model/notifications/filter-notification-events-types/filter-notification-events-types.types';
import { getFilteredNotificationEventsTypesData } from '@admin/model/notifications/filter-notification-events-types/filter-notification-events-types.helpers';

export const getFilterNotificationEventsTypesFx = createEffect(async (payload: FilterEventTypeSearchPayload) => {
    try {
        const data = await getRequest<DictionaryData>('dictionaries/NOTIFICATION_EVENT_TYPES');

        return getFilteredNotificationEventsTypesData(data, payload.term);
    } catch (error) {
        logger(error);

        throw error;
    }
});
