import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { getSuccessMessage } from '@admin/model/users/reset-user-password/reset-user-password.helpers';
import {
    $resetUserPasswordData,
    $resetUserPasswordError,
    resetUserPasswordStateEvent,
} from '@admin/model/users/reset-user-password/reset-user-password.store';
import { reset } from 'patronum';
import { resetUserPasswordFx } from '@admin/model/users/reset-user-password/reset-user-password.fx';

sample({
    clock: resetUserPasswordFx.doneData,
    fn: () => getSuccessMessage(),
    target: showNoticeEvent,
});

sample({
    source: resetUserPasswordFx.done,
    fn: () => true,
    target: $resetUserPasswordData,
});

sample({
    clock: resetUserPasswordFx.failData,
    target: $resetUserPasswordError,
});

reset({ clock: resetUserPasswordStateEvent, target: [$resetUserPasswordError, $resetUserPasswordData] });
