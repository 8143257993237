import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { setCommonAppError } from '@admin/model/error-notification';
import { createEffect } from 'effector';
import { CreateNotificationPayload } from '@admin/model/notifications/create-notification/create-notification.types';
import { NotificationErrorCode } from '@admin/model/notifications/notifications.types';

export const createNotificationFx = createEffect(
    async ({ data, isSkipWarningValidations }: CreateNotificationPayload) => {
        try {
            await postRequest('notification/configurations', data, {
                headers: isSkipWarningValidations ? { 'X-Skip-Warning-Validations': 'true' } : undefined,
            });
        } catch (error) {
            logger(error);

            if (isKnownValidationErrorResponse(error, NotificationErrorCode)) {
                throw error.data.validationErrors;
            } else {
                setCommonAppError({ error });

                throw null;
            }
        }
    },
);
