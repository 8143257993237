import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { PaymentsListPayload, PaymentsState } from '@admin/model/payments/payments-list/payments-list.types';

export const getPaymentsListFx = createEffect(async (payload: PaymentsListPayload) => {
    try {
        return await postRequest<PaymentsState, PaymentsListPayload>('orders/unprocessedPayments', payload);
    } catch (error) {
        logger(error);

        throw error;
    }
});
