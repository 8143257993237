import { reset } from 'patronum';
import { deactivateProgramLoyaltyFx } from '@admin/model/clients-and-profiles/deactivate-program-loyalty/deactivate-program-loyalty.fx';
import {
    $deactivateProgramLoyaltyDone,
    $deactivateProgramLoyaltyError,
    resetDeactivateProgramLoyaltyErrorEvent,
} from '@admin/model/clients-and-profiles/deactivate-program-loyalty/deactivate-program-loyalty.store';
import { getClientAccountFx } from '@admin/model/clients-and-profiles/client-account/get-client-account.fx';
import { sample } from 'effector';

sample({
    clock: deactivateProgramLoyaltyFx.doneData,
    target: $deactivateProgramLoyaltyDone,
});

sample({
    clock: getClientAccountFx.finally,
    fn: () => false,
    target: $deactivateProgramLoyaltyDone,
});

sample({
    clock: deactivateProgramLoyaltyFx.failData,
    target: $deactivateProgramLoyaltyError,
});

reset({ clock: resetDeactivateProgramLoyaltyErrorEvent, target: [$deactivateProgramLoyaltyError] });
