import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { getSuccessMessage } from '@admin/model/users/update-user-quick-actions/update-user-quick-actions.helpers';
import { updateUserQuickActionsFx } from '@admin/model/users/update-user-quick-actions/update-user-quick-actions.fx';

sample({
    source: updateUserQuickActionsFx.doneData,
    fn: (payload) => getSuccessMessage(payload),
    target: showNoticeEvent,
});
