import { sample } from 'effector';
import { getClientAgreementListFx } from './get-client-agreement-list.fx';
import { $clientAgreementListData, $clientAgreementListError } from './client-agreement-list.store';
import { ClientAgreementField } from '@admin/model/clients-and-profiles/client-agreement-list/client-agreement-list.types';
import { $clientAgreementDictionaryData } from '@admin/model/clients-and-profiles/client-agreement-dictionary/client-agreement-dictionary.store';

sample({
    clock: getClientAgreementListFx.doneData,
    fn: (data) => ({
        totalCount: data.length,
        data: data.map((agreement) => ({
            ...agreement,
            [ClientAgreementField.CreatedAt]: new Date(agreement[ClientAgreementField.CreatedAt]),
        })),
    }),
    target: $clientAgreementListData,
});

sample({
    source: { $clientAgreementListData, $clientAgreementDictionaryData },
    filter: ({ $clientAgreementListData, $clientAgreementDictionaryData }) => {
        return (
            !!$clientAgreementListData.data.length &&
            !$clientAgreementListData.data.some((agreement) => !!agreement[ClientAgreementField.AgreementName]) &&
            !!$clientAgreementDictionaryData
        );
    },
    fn: ({ $clientAgreementListData, $clientAgreementDictionaryData }) => ({
        ...$clientAgreementListData,
        data: $clientAgreementListData.data.map((agreement) => ({
            ...agreement,
            [ClientAgreementField.AgreementName]:
                $clientAgreementDictionaryData?.dictionaryValueList.find((dictionary) => {
                    return agreement[ClientAgreementField.AgreementCode] === dictionary.key;
                })?.value ?? agreement[ClientAgreementField.AgreementCode],
        })),
    }),
    target: $clientAgreementListData,
});

sample({
    clock: getClientAgreementListFx.failData,
    target: $clientAgreementListError,
});
