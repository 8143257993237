import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { CouponCodesErrorsData } from '@admin/model/offers/coupon-codes-errors/coupon-codes-errors.types';
import { logger } from '@admin/common/helpers/logger';
import { setCommonAppError } from '@admin/model/error-notification';

export const getCouponCodesErrorsFx = createEffect(async (uploadId: string) => {
    try {
        return await getRequest<CouponCodesErrorsData>(`coupons/uploads/${uploadId}/errors`);
    } catch (error) {
        logger(error);
        setCommonAppError({ error });
        throw null;
    }
});
