import { postRequest } from '@admin/model/api';
import {
    CategoriesBindingChangeHistoryPayload,
    CategoriesBindingChangeHistoryItemData,
} from '@admin/model/categories/categories-binding-change-history/categories-binding-change-history.types';
import { ChangeHistoryData } from '@admin/model/model.types';
import { logger } from '@admin/common/helpers/logger';
import { normalizeCategoriesBindingChangeHistoryData } from '@admin/model/categories/categories-binding-change-history/categories-binding-change-history.mapper';
import { createEffect } from 'effector';

export const getCategoriesBindingChangeHistoryFx = createEffect(
    async ({ supplierId, ...rest }: CategoriesBindingChangeHistoryPayload) => {
        try {
            const data = await postRequest<
                ChangeHistoryData<CategoriesBindingChangeHistoryItemData>,
                CategoriesBindingChangeHistoryPayload
            >(`suppliers/${supplierId}/category/links/history`, {
                ...rest,
            });

            return normalizeCategoriesBindingChangeHistoryData(data);
        } catch (error) {
            logger(error);

            throw error;
        }
    },
);
