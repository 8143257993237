import { logger } from '@admin/common/helpers/logger';
import { Category } from '@admin/model/categories/category/category.types';
import { getRequest } from '@admin/model/api';
import { createEffect } from 'effector';
import { CategoriesPayload } from '@admin/model/categories/categories-list/categories-list.types';

export const getCategoriesListFx = createEffect(async ({ shouldExpandAll, ...rest }: CategoriesPayload) => {
    try {
        const data = await getRequest<Category[]>('catalog/categories', rest);

        return {
            categories: data,
            shouldExpandAll,
        };
    } catch (error) {
        logger(error);

        throw error;
    }
});
