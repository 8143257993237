import { Category } from '@admin/model/categories/category/category.types';
import { combine, createStore } from 'effector';
import { getCollectionsListFx } from '@admin/model/collections/collections-list/get-collections-list.fx';

export const $collectionsListData = createStore<Category[]>([]);
export const $collectionsListError = createStore<Nullable<unknown>>(null);

export const $collectionsList = combine({
    data: $collectionsListData,
    error: $collectionsListError,
    isLoading: getCollectionsListFx.pending,
});
