import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { normalizeCouponCodeData } from '@admin/model/offers/coupon-codes/coupon-codes.helpers';
import { CouponCodesData, CouponCodesPayload } from '@admin/model/offers/coupon-codes/coupon-codes.types';
import { logger } from '@admin/common/helpers/logger';
import { setCommonAppError } from '@admin/model/error-notification';

export const getCouponCodesFx = createEffect(async (payload: CouponCodesPayload) => {
    try {
        const data = await postRequest<CouponCodesData, CouponCodesPayload>('coupons/search', payload);
        return normalizeCouponCodeData(data);
    } catch (error) {
        logger(error);
        setCommonAppError({ error });
        throw null;
    }
});
