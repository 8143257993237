import { combine, createEvent, createStore } from 'effector';
import { clientDisableAccessAndPhoneFx } from '@admin/model/clients-and-profiles/client-disable-access-and-phone/client-disable-access-and-phone.fx';

export const $clientDisableAccessAndPhoneError = createStore<Nullable<unknown>>(null);
export const $clientDisableAccessAndPhoneDone = createStore<boolean>(false);

export const resetClientDisableAccessAndPhoneErrorEvent = createEvent();

export const $clientDisableAccessAndPhone = combine({
    data: $clientDisableAccessAndPhoneDone,
    error: $clientDisableAccessAndPhoneError,
    isLoading: clientDisableAccessAndPhoneFx.pending,
});
