import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { getSuccessMessage } from '@admin/model/products/delete-product-characteristics/delete-product-characteristics.helpers';
import { deleteProductCharacteristicsFx } from '@admin/model/products/delete-product-characteristics/delete-product-characteristics.fx';
import {
    $deleteProductCharacteristicsData,
    $deleteProductCharacteristicsError,
    resetDeleteProductCharacteristicsErrorEvent,
} from '@admin/model/products/delete-product-characteristics/delete-product-characteristics.store';
import { reset } from 'patronum';

sample({
    clock: deleteProductCharacteristicsFx.done,
    fn: ({ params }) => getSuccessMessage(params.productIds),
    target: showNoticeEvent,
});

sample({
    clock: deleteProductCharacteristicsFx.done,
    source: $deleteProductCharacteristicsData,
    fn: () => true,
    target: $deleteProductCharacteristicsData,
});

sample({
    clock: deleteProductCharacteristicsFx.failData,
    target: $deleteProductCharacteristicsError,
});

reset({ clock: resetDeleteProductCharacteristicsErrorEvent, target: [$deleteProductCharacteristicsError] });
