import { ValidationError } from '@admin/common/validation';
import { CodeStatus } from '@admin/model/offers/offers.types';

export enum UpdateCouponCodesStatusValidationErrorCode {
    MissingRequiredField = 'MISSING_REQUIRED_FIELD',
}

export type UpdateCouponCodesStatusValidationError = ValidationError<UpdateCouponCodesStatusValidationErrorCode>;

interface SearchFields {
    codeIdList: string[];
}

export interface UpdateCouponCodesStatusPayload {
    codeStatus?: CodeStatus;
    description?: string;
    newDisplayOrderId?: number;
    searchFields: SearchFields;
}
