export enum CollectionValidationErrorCode {
    NameAlreadyTaken = 'NAME_ALREADY_TAKEN',
    DateOrderError = 'DATE_INTERVAL_ERROR',
    MissingRequiredField = 'MISSING_REQUIRED_FIELD',
    PropertyValueViolation = 'PROPERTY_VALUE_VIOLATION',
    SizeExceeded = 'SIZE_EXCEEDED',
    IllegalValue = 'ILLEGAL_VALUE',
}

export enum CollectionField {
    Id = 'id',
    Type = 'type',
    Attributes = 'attributes',
    Name = 'name',
    DateToPublishFrom = 'dateToPublishFrom',
    DateToPublishTo = 'dateToPublishTo',
    DateRange = 'dateRange',
    Status = 'status',
    isHidden = 'isHidden',
    Description = 'description',
    Images = 'images',
    Currencies = 'currencies',
    ProductIds = 'productIds',
    Teaser = 'teaser',
    Banner = 'banner',
    Mobilebanner = 'mobilebanner',
    UrlTeaser = 'urlTeaser',
    UrlBanner = 'urlBanner',
    UrlMobileBanner = 'urlMobileBanner',
    ProductsToAdd = 'productsToAdd',
    ProductsToRemove = 'productsToRemove',
}
