export enum SegmentFileUploadHistoryField {
    Id = 'id',
    UploadSuccessCount = 'uploadSuccessCount',
    UploadFailCount = 'uploadFailCount',
    CompletedAt = 'completedAt',
    UploadedAt = 'uploadedAt',
    StartedAt = 'startedAt',
    CreatedBy = 'createdBy',
    FileName = 'fileName',
    Status = 'status',
}

export interface SegmentFileUploadHistoryPayload {
    segment?: string;
    uploadId?: string;
    uploadDateFrom?: string;
    uploadDateTo?: string;
    ordering?: string;
    offset?: number;
    limit?: number;
}

export interface ClientsFromFileUploads {
    [SegmentFileUploadHistoryField.Id]: string;
    [SegmentFileUploadHistoryField.UploadedAt]: Nullable<string>;
    [SegmentFileUploadHistoryField.StartedAt]: Nullable<string>;
    [SegmentFileUploadHistoryField.CompletedAt]: Nullable<string>;
    [SegmentFileUploadHistoryField.Status]: string;
    [SegmentFileUploadHistoryField.FileName]: string;
    [SegmentFileUploadHistoryField.UploadSuccessCount]: number;
    [SegmentFileUploadHistoryField.UploadFailCount]: number;
    [SegmentFileUploadHistoryField.CreatedBy]: string;
}

export interface SegmentFileUploadHistory
    extends Omit<ClientsFromFileUploads, 'completedAt' | 'uploadedAt' | 'startedAt'> {
    completedAt?: Date;
    uploadedAt?: Date;
    startedAt?: Date;
}

export interface SegmentFileUploadHistoryData {
    clientsFromFileUploads: ClientsFromFileUploads[];
    totalCount: number;
}

export interface SegmentFileUploadHistoryState {
    clientsFromFileUploads: SegmentFileUploadHistory[];
    totalCount: number;
}
