import { createEffect } from 'effector';
import { deleteRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { setCommonAppError } from '@admin/model/error-notification';
import {
    DeleteProductCharacteristicsPayload,
    DeleteProductCharacteristicsValidationErrorCode,
} from '@admin/model/products/delete-product-characteristics/delete-product-characteristics.types';

export const deleteProductCharacteristicsFx = createEffect(async (payload: DeleteProductCharacteristicsPayload) => {
    try {
        await deleteRequest('products/characteristics', payload, {
            apiVersion: 2,
        });

        return payload;
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, DeleteProductCharacteristicsValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
