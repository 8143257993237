import { sample } from 'effector';
import { updateMatrixItemStatusFx } from '@admin/model/cost-matrix/update-matrix-item-status/update-matrix-item-status.fx';
import { getSuccessMessage } from '@admin/model/cost-matrix/update-matrix-item-status/update-matrix-item-status.helpers';
import { showNoticeEvent } from '@admin/model/notices';

sample({
    clock: updateMatrixItemStatusFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
