export enum ReportStatus {
    New = 'NEW',
    InProcess = 'IN_PROCESS',
    Success = 'SUCCESS',
    Failed = 'FAILED',
}

export enum ReportType {
    ProductsDictionary = 'PRODUCT_DICTIONARY',
    VortexProductsCount = 'VORTEX_PRODUCTS_COUNT',
    VortexProductsDetails = 'VORTEX_PRODUCTS_DETAILS',
    VortexProductsPerSupplier = 'VORTEX_PRODUCTS_PER_SUPPLIER',
    VortexClientsCount = 'VORTEX_CLIENTS_COUNT',
    VortexClientsPerSupplier = 'VORTEX_CLIENTS_PER_SUPPLIER',
    ClientWay = 'CLIENT_WAY',
}

export enum ReportParameterType {
    StartDate = 'startDate',
    EndDate = 'endDate',
    UploadStartDate = 'uploadStartDate',
    UploadEndDate = 'uploadEndDate',
    Supplier = 'supplier',
    Detalization = 'detalization',
    AuthorizationChannel = 'authorization_channel',
    NotificationEventTypes = 'notification_event_types',
    NotificationGroups = 'notification_groups',
    Configurations = 'configurations',
    NotificationStatus = 'notification_status',
    NotificationChannel = 'notification_channel',
    OfferId = 'offerId',
    Statuses = 'statuses',
    SingleSelectSupplier = 'singleSelectSupplier',
    HasRefund = 'hasRefund',
    OrderStatuses = 'orderStatuses',
    RefundStatuses = 'refundStatuses',
    DateFilterType = 'dateFilterType',
    OrderDatePeriod = 'orderDatePeriod',
    ExpireDatePeriod = 'expireDatePeriod',
    PaymentStatuses = 'paymentStatuses',
    Currencies = 'currencies',
    TermsNotUseInReportsPage = 'terms',
    OrderIdsNotUseInReportsPage = 'orderIds',
    AccrualStatuses = 'accrualStatuses',
    AccrualTypes = 'accrualTypes',
}

export interface ReportParameterOption {
    code: string;
    name: string;
}

export interface ReportParameterConfig {
    code: ReportParameterType;
    name: string;
    type: string;
    enum?: ReportParameterOption[];
}

export interface ReportConfig {
    type: string;
    name: string;
    group: string;
    parameters: ReportParameterConfig[];
}
