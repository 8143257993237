export enum PageBlockingField {
    Section = 'section',
    DateFrom = 'dateFrom',
    DateTo = 'dateTo',
    BannerImageUrl = 'bannerImageUrl',
    BannerDescription = 'bannerDescription',
}

export interface PageBlockingData {
    [PageBlockingField.Section]: string;
    [PageBlockingField.DateFrom]?: string;
    [PageBlockingField.DateTo]?: string;
    [PageBlockingField.BannerImageUrl]?: string;
    [PageBlockingField.BannerDescription]?: string;
}

export enum UpdateError {
    SectionNotUnique = 'SECTION_NOT_UNIQUE',
    DateFromGrater = 'DATE_FROM_GRATER',
    DateFromEmpty = 'DATE_FROM_EMPTY',
}
