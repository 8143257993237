import { DictionaryData } from '@admin/model/model.types';
import { TargetAudienceState, TargetAudience } from '@admin/model/target-audience/target-audience.types';
import { SegmentsValue } from '@admin/model/segments/segments.types';

export const parseData = (data: DictionaryData): TargetAudienceState => {
    const targetAudience: TargetAudience[] = [];
    const targetAudienceMap: Record<string, string> = {};

    for (const { key, value } of data.dictionaryValueList) {
        const parsedValue = JSON.parse(value) as SegmentsValue;

        if (parsedValue.blocked) {
            continue;
        }

        targetAudience.push({ key, value: parsedValue.description });
        targetAudienceMap[key] = parsedValue.description;
    }

    return {
        targetAudience,
        targetAudienceMap,
        hasData: true,
    };
};
