import { DictionaryData } from '@admin/model/model.types';
import { CharacteristicUnit } from '@admin/model/characteristics/characteristic/characteristic.types';

export const parseData = (CharacteristicUnitsDictData: DictionaryData): CharacteristicUnit[] =>
    CharacteristicUnitsDictData.dictionaryValueList.reduce<CharacteristicUnit[]>((acc, CharacteristicUnitsData) => {
        const parsed = JSON.parse(CharacteristicUnitsData.value) as CharacteristicUnit;
        const unitedParsedData = {
            ...parsed,
            name: `${parsed.name}${parsed.unit ? `, ${parsed.unit}` : ''}`,
            key: CharacteristicUnitsData.key,
        };
        acc.push(unitedParsedData);
        return acc;
    }, []);
