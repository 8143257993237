import { createEffect } from 'effector';
import { putRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { setCommonAppError } from '@admin/model/error-notification';
import { ShortLinkPayload, ShortLinkValidationErrorCode } from '@admin/model/short-links/short-links.types';

export const updateShortLinkFx = createEffect(async ({ data, id }: { data: ShortLinkPayload; id: string }) => {
    try {
        await putRequest(`links/${id}`, data);
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, ShortLinkValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
