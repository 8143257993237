import { sample } from 'effector';
import { normalizeDate } from '@admin/model/helpers';
import {
    $shortLinkListData,
    $shortLinkListError,
} from '@admin/model/short-links/short-link-list/short-link-list.store';
import { getShortLinkListFx } from '@admin/model/short-links/short-link-list/get-short-link-list.fx';
import { ShortLinkField } from '@admin/model/short-links/short-links.types';

sample({
    clock: getShortLinkListFx.doneData,
    fn: (data) => ({
        totalCount: data.length,
        data: data.map((link) => ({
            ...link,
            [ShortLinkField.UpdatedAt]: normalizeDate(link[ShortLinkField.UpdatedAt]),
        })),
    }),
    target: $shortLinkListData,
});

sample({
    clock: getShortLinkListFx.failData,
    target: $shortLinkListError,
});
