import { sample } from 'effector';
import { $segmentData, $segmentError } from '@admin/model/segments/segment/segment.store';
import { getSegmentFx } from '@admin/model/segments/segment/get-segment.fx';
import { parseData } from '@admin/model/segments/segment/segment.helpers';

sample({
    clock: getSegmentFx.doneData,
    fn: (result) => parseData(result),
    target: $segmentData,
});

sample({
    clock: getSegmentFx.failData,
    target: $segmentError,
});
