import { ValidationError } from '@admin/common/validation';
import { TrackNumber } from '@admin/model/orders/orders.types';

export interface CreateTrackNumberPayload extends TrackNumber {
    orderId: string;
}

export enum CreateTrackNumberValidationErrorCode {
    ClientNotMb = 'CLIENT_NOT_MB',
}

export type CreateTrackNumberValidationError = ValidationError<CreateTrackNumberValidationErrorCode>;
