import { combine, createEvent, createStore } from 'effector';
import { getPaymentsListFx } from '@admin/model/payments/payments-list/get-payments-list.fx';
import { PaymentsState } from '@admin/model/payments/payments-list/payments-list.types';

const paymentsListInitialState: PaymentsState = {
    payments: [],
    totalCount: null,
};

export const resetPaymentsListEvent = createEvent();
export const mapPaymentsListEvent = createEvent<PaymentsState>();
export const $paymentsListData = createStore<PaymentsState>(paymentsListInitialState);

export const $paymentsListError = createStore<Nullable<unknown>>(null);

export const $paymentsList = combine({
    data: $paymentsListData,
    error: $paymentsListError,
    isLoading: getPaymentsListFx.pending,
});
