import { ImageObject } from '@admin/model';
import { logger } from '@admin/common/helpers/logger';
import { isJson } from '@admin/common/helpers/is-json';

// Field name is different because of BE inconsistency. It's likely that this mechanism will be changed soon
// So I leave it for now.
export const normalizeImages = (images: string[], fieldName: 'image' | 'logo' = 'image') => {
    const normalizedImages: string[] = [];

    for (const item of images) {
        if (!isJson(item) && typeof item === 'string') {
            normalizedImages.push(item);
        } else {
            try {
                const imageObject: ImageObject = JSON.parse(item);
                const imageUrl = imageObject[fieldName]?.url ?? '';
                normalizedImages.push(imageUrl);
            } catch (e) {
                logger(`Unsupported image object: ${item}`);
            }
        }
    }

    return normalizedImages;
};
