import { createEffect } from 'effector';
import { ITEMS_PER_PAGE } from '@admin/common';
import { normalizeImages } from '@admin/common/helpers';
import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import {
    LoadOffersPayload,
    OffersListPayloadParam,
    OffersListData,
} from '@admin/model/offers/offers-list/offers-list.types';
import { normalizeDate } from '@admin/model/helpers';

interface Payload extends Omit<LoadOffersPayload, 'page'> {
    limit: number;
    offset: number;
}

export const getOffersListFx = createEffect(async (payload: LoadOffersPayload) => {
    try {
        const data = await postRequest<OffersListData, Payload>(
            'offers/search',
            {
                limit: ITEMS_PER_PAGE,
                offset: ((payload[OffersListPayloadParam.Page] || 1) - 1) * ITEMS_PER_PAGE,
                ...payload,
            },
            {
                apiVersion: 2,
            },
        );

        return {
            offers: data.offers.map((offer) => ({
                ...offer,
                imageUrls: offer.images ? normalizeImages(offer.images) : [],
                deleteDate: offer.deleteDate ? normalizeDate(offer.deleteDate) : null,
            })),
            totalCount: data.totalCount,
        };
    } catch (error) {
        logger(error);

        throw error;
    }
});
