import { logService } from '@common/services';
import { BackendModule, CallbackError, ResourceKey, Services } from 'i18next';

type LoadResource = (language: string) => Promise<ResourceKey>;

export class LocalizationBackend implements BackendModule {
    public static type = 'backend' as const;
    public type: typeof LocalizationBackend.type;
    public loadResource: LoadResource;

    public init(_services: Services, loadResource: LoadResource) {
        this.loadResource = loadResource;
    }

    public async read(
        language: string,
        _namespace: string,
        callback: (error: CallbackError, data: ResourceKey) => void,
    ) {
        try {
            callback(null, await this.loadResource(language));
        } catch (error) {
            if (error instanceof Error) {
                logService.error(error.message);
                callback(error, {});
            }
        }
    }

    public create() {
        // it should be implemented if you need to dynamically save the locale on the server
    }
}
