import { ValidationError } from '@admin/common/validation';

export interface UpdateCategoriesAccessibilityPayload {
    categoryIds: string[];
    suppliersToAdd?: number[];
    suppliersToDelete?: number[];
}

export enum UpdateCategoriesAccessibilityValidationErrorCode {
    ActiveSupplierProductsExists = 'ACTIVE_SUPPLIER_PRODUCTS_EXISTS',
    UnableUpdateParentCategory = 'UNABLE_UPDATE_PARENT_CATEGORY',
}

export type UpdateCategoriesAccessibilityValidationError =
    ValidationError<UpdateCategoriesAccessibilityValidationErrorCode>;
