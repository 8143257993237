import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { Category } from '@admin/model/categories/category/category.types';
import { normalizeCategoryImages } from '@admin/model/collections/collection/collection.helpers';

export const getCollectionFx = createEffect(async (id: string) => {
    try {
        const data = await getRequest<Category>(`catalog/categories/${id}`);

        return normalizeCategoryImages(data);
    } catch (error) {
        logger(error);

        throw error;
    }
});
