import { sample } from 'effector';
import { reset } from 'patronum';
import {
    $productCharacteristicsData,
    $productCharacteristicsIds,
    $productCharacteristicsError,
    resetProductCharacteristicsEvent,
} from '@admin/model/products/product-characteristics/product-characteristics.store';
import { getProductCharacteristicsFx } from '@admin/model/products/product-characteristics/get-product-characteristics.fx';

sample({
    clock: getProductCharacteristicsFx.doneData,
    source: $productCharacteristicsData,
    fn: (_state, result) => result,
    target: $productCharacteristicsData,
});

sample({
    clock: getProductCharacteristicsFx.doneData,
    source: $productCharacteristicsIds,
    fn: (_state, result) => new Set(result.map((item) => item.id)),
    target: $productCharacteristicsIds,
});

sample({
    clock: getProductCharacteristicsFx.failData,
    target: $productCharacteristicsError,
});

reset({
    clock: resetProductCharacteristicsEvent,
    target: [$productCharacteristicsData, $productCharacteristicsError, $productCharacteristicsIds],
});
