import { App } from '@admin';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import '@admin/common/styles/main.css';
import '@admin/model/model.flow';
import { createRoot } from 'react-dom/client';

const REACT_ROOT_ID = 'root';

const root = createRoot(document.getElementById(REACT_ROOT_ID) as Element);
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
);
