import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { NotificationData } from '@admin/model/notifications/notifications.types';

export const getNotificationFx = createEffect(async (id: string) => {
    try {
        return await getRequest<NotificationData>(`notification/configurations/${id}`);
    } catch (error) {
        logger(error);

        throw error;
    }
});
