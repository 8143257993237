import { logger } from '@admin/common/helpers/logger';
import { setCommonAppError } from '@admin/model/error-notification';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import {
    UpdateCategoriesAccessibilityPayload,
    UpdateCategoriesAccessibilityValidationErrorCode,
} from '@admin/model/categories/update-categories-accessibility/update-categories-accessibility.types';
import { postRequest } from '@admin/model/api';
import { createEffect } from 'effector';

export const updateCategoriesAccessibilityFx = createEffect(async (payload: UpdateCategoriesAccessibilityPayload) => {
    try {
        await postRequest('catalog/categories/batching', payload);

        return payload;
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, UpdateCategoriesAccessibilityValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
