import { generateId } from '@admin/common/helpers';
import { normalizeDate } from '@admin/model/helpers/normalize-date';
import { ChangeHistory, ChangeHistoryData, ChangeHistoryItemData } from '@admin/model/model.types';
import { SubscriptionChangeHistoryState } from '@admin/model/subscriptions/subscription-change-history/subscription-change-history.types';

export const normalizeChangeHistoryData = (
    response: ChangeHistoryData<ChangeHistoryItemData<SubscriptionChangeHistoryState>>,
): ChangeHistory<SubscriptionChangeHistoryState> => ({
    ...response,
    createdAt: normalizeDate(response.createdAt) as Date,
    data: response.data.map((dataItem) => ({
        ...dataItem,
        changedAt: normalizeDate(dataItem.changedAt) as Date,
        id: generateId(),
    })),
});
