import { DictionaryData } from '@admin/model/model.types';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { setCommonAppError } from '@admin/model/error-notification';
import { parseData } from '@admin/model/characteristics/characteristics-united-units-dictionary/characteristics-united-units-dictionary.helpers';
import { characteristicsUnitedUnitsDictionaryDomain } from '@admin/model/characteristics/characteristics.domains';

export const getCharacteristicsUnitedUnitsDictionaryFx = characteristicsUnitedUnitsDictionaryDomain.createEffect(
    async () => {
        try {
            const data = await getRequest<DictionaryData>('dictionaries/CHARACTERISTIC_UNITS');

            return parseData(data);
        } catch (error) {
            logger(error);
            setCommonAppError({ error });
            throw null;
        }
    },
);
