export enum CostMatrixLevel {
    Region = 'REGION',
    Area = 'AREA',
    City = 'CITY',
    Settlement = 'SETTLEMENT',
}

export enum CostMatrixStatus {
    Linked = 'LINKED',
    Unlinked = 'UNLINKED',
}

export enum CostMatrixActivity {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
}

export enum CostMatrixField {
    MatrixItemId = 'matrixItemId',
    DeliveryPartnerId = 'deliveryPartnerId',
    DestinationCodeKladr = 'destinationCodeKladr',
    DestinationAddress = 'destinationAddress',
    Level = 'level',
    MinimumWeight = 'minimumWeight',
    MaximumWeight = 'maximumWeight',
    Cost = 'cost',
    Period = 'period',
    Status = 'status',
    Active = 'active',
    Deleted = 'deleted',
}

export interface MatrixData {
    [CostMatrixField.MatrixItemId]: string;
    [CostMatrixField.DeliveryPartnerId]: string;
    [CostMatrixField.DestinationCodeKladr]: string;
    [CostMatrixField.DestinationAddress]: string;
    [CostMatrixField.Level]: CostMatrixLevel;
    [CostMatrixField.MinimumWeight]: number;
    [CostMatrixField.MaximumWeight]: number;
    [CostMatrixField.Cost]: number;
    [CostMatrixField.Period]: number;
    [CostMatrixField.Status]: CostMatrixStatus;
    [CostMatrixField.Active]: boolean;
}
