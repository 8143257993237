import { ListData, List } from '@admin/model/lists/lists.types';

export interface ListsData {
    totalCount: number;
    data: ListData[];
}

export interface ListsState {
    totalCount: number;
    data: List[];
}

export enum ListsParam {
    Page = 'page',
    Offset = 'offset',
    Limit = 'limit',
    Ordering = 'ordering',
    Types = 'types',
    Term = 'term',
}

export enum ListTypes {
    Offer = 'offer',
    Product = 'product',
}

export interface ListsPayload {
    [ListsParam.Offset]: number;
    [ListsParam.Limit]: number;
    [ListsParam.Ordering]?: string;
    [ListsParam.Types]?: string[];
    [ListsParam.Term]?: string;
}
