import { DictionaryData } from '@admin/model';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { createEffect } from 'effector';
import { parseData } from '@admin/model/suppliers/suppliers.helper';

export const loadPayAttributesFx = createEffect(async () => {
    try {
        const data = await getRequest<DictionaryData>(`dictionaries/fiscalization_payment_type`);

        return parseData(data);
    } catch (error) {
        logger(error);

        throw error;
    }
});
