import { ValidationError } from '@admin/common/validation';
import { CategoryStatus } from '@admin/model/categories/category/category.types';

export interface UpdateCategoriesStatusPayload {
    categoryIds: string[];
    status: CategoryStatus;
    showInactive?: boolean;
}

export enum UpdateCategoriesStatusValidationErrorCode {
    UnableUpdateParentCategory = 'UNABLE_UPDATE_PARENT_CATEGORY',
}

export type UpdateCategoriesStatusValidationError = ValidationError<UpdateCategoriesStatusValidationErrorCode>;
