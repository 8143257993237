import { OrderPaymentsData, OrderPayments } from '@admin/model';

export const normalizeOrderPaymentsData = ({
    refundClaims,
    payments,
    chequeId,
    ...rest
}: OrderPaymentsData): OrderPayments => ({
    ...rest,
    chequeId,
    refundClaims: refundClaims?.map(({ refundPayments, refundDetails, ...rest }) => ({
        ...rest,
        refundChequeId: refundDetails?.[0]?.refundChequeId,
        subRows: refundPayments ?? [],
    })),
    payments: [
        {
            ...payments[0],
            cost: payments.reduce((acc, it) => acc + it.cost, 0),
            subRows: payments,
            chequeId,
        },
    ],
});
