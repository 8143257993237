import { logger } from '@admin/common/helpers/logger';
import { setCommonAppError } from '@admin/model/error-notification';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import {
    UpdateCategoriesCurrencyPayload,
    UpdateCategoriesCurrencyValidationErrorCode,
} from '@admin/model/categories/update-categories-currency/update-categories-currency.types';
import { postRequest } from '@admin/model/api';
import { createEffect } from 'effector';

export const updateCategoriesCurrencyFx = createEffect(async (payload: UpdateCategoriesCurrencyPayload) => {
    try {
        await postRequest('catalog/categories/batching', payload);

        return payload;
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, UpdateCategoriesCurrencyValidationErrorCode)) {
            throw error.data.validationErrors[0];
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
