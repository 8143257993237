import { combine, createStore } from 'effector';
import { getShortLinkListFx } from '@admin/model/short-links/short-link-list/get-short-link-list.fx';
import { ShortLinksState } from '@admin/model/short-links/short-links.types';

const initialState: ShortLinksState = {
    totalCount: 0,
    data: [],
};

export const $shortLinkListData = createStore<ShortLinksState>(initialState);
export const $shortLinkListError = createStore<Nullable<unknown>>(null);

export const $shortLinkList = combine({
    data: $shortLinkListData,
    error: $shortLinkListError,
    isLoading: getShortLinkListFx.pending,
});
