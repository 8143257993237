import { DictionaryData } from '@admin/model/model.types';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { setCommonAppError } from '@admin/model/error-notification';
import { parseData } from '@admin/model/characteristics/characteristics-units-filter/characteristics-units-filter.helpers';
import { characteristicsUnitsFilterDomain } from '@admin/model/characteristics/characteristics.domains';
import { CharacteristicUnitsDictionarySearchPayload } from '@admin/model/characteristics/characteristics-units-filter/characteristics-units-filter.types';

export const getCharacteristicsUnitsFilterFx = characteristicsUnitsFilterDomain.createEffect(
    async (payload: CharacteristicUnitsDictionarySearchPayload) => {
        try {
            const data = await getRequest<DictionaryData>('dictionaries/CHARACTERISTIC_UNITS');

            return {
                data: parseData(data),
                filter: payload.term,
            };
        } catch (error) {
            logger(error);
            setCommonAppError({ error });
            throw null;
        }
    },
);
