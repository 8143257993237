import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { sample } from 'effector';
import { updateCouponCodesStatusFx } from '@admin/model/offers/update-coupon-codes-status/update-coupon-codes-status.fx';
import { getSuccessMessage } from '@admin/model/offers/update-coupon-codes-status/update-coupon-codes-status.helpers';

sample({
    clock: updateCouponCodesStatusFx.done,
    fn: ({ params }) => getSuccessMessage(params.searchFields?.codeIdList),
    target: showNoticeEvent,
});
