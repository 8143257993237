import { logger } from '@admin/common/helpers/logger';
import { Category } from '@admin/model/categories/category/category.types';
import { getRequest } from '@admin/model/api';
import { CategoriesPayload } from '@admin/model/categories/categories-list/categories-list.types';
import { createEffect } from 'effector';

export const getParentCategoriesFx = createEffect(async (payload: CategoriesPayload) => {
    try {
        return await getRequest<Category[]>('catalog/categories', payload);
    } catch (error) {
        logger(error);

        throw error;
    }
});
