import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { OfferPartnerValidationErrorCode } from '@admin/model/offer-partners/offer-partners.types';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { logger } from '@admin/common/helpers/logger';
import { CreateOfferPartnerPayload } from '@admin/model/offer-partners/create-offer-partner/create-offer-partner.types';

export const createOfferPartnerFx = createEffect(
    async ({ images, actionRules, ...rest }: CreateOfferPartnerPayload) => {
        const formData = new FormData();

        formData.append('partner', JSON.stringify(rest));

        if (images) {
            for (const image of images) {
                formData.append('images', image, image.name);
            }
        }

        if (actionRules) {
            formData.append('actionRules', actionRules, actionRules.name);
        }

        try {
            await postRequest('offer-partners', formData);
        } catch (error) {
            logger(error);

            if (isKnownValidationErrorResponse(error, OfferPartnerValidationErrorCode)) {
                throw error.data.validationErrors;
            } else {
                setCommonAppError({ error });

                throw null;
            }
        }
    },
);
