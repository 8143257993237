import { logger } from '@admin/common/helpers/logger';
import { getRequest } from '@admin/model/api';
import { Supplier } from '@admin/model/suppliers/suppliers.types';
import { createEffect } from 'effector';

export const getSupplierFx = createEffect(async (id?: number) => {
    try {
        return await getRequest<Supplier>(`partners/${id}`);
    } catch (error) {
        logger(error);

        throw error;
    }
});
