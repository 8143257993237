import { normalizeDate } from '@admin/model/helpers';
import { ChangeHistory, ChangeHistoryData, ChangeHistoryItemData } from '@admin/model/model.types';
import { NotificationChangeHistoryState } from '@admin/model/notifications/notification-change-history/notification-change-history.types';

export const normalizeNotificationChangeHistoryData = (
    response: ChangeHistoryData<ChangeHistoryItemData<NotificationChangeHistoryState>>,
): ChangeHistory<NotificationChangeHistoryState> => ({
    ...response,
    createdAt: normalizeDate(response.createdAt) as Date,
    data: response.data.map((dataItem) => ({ ...dataItem, changedAt: normalizeDate(dataItem.changedAt) as Date })),
});
