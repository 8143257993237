import { ValidationError } from '@admin/common/validation';

export enum UploadFileSegmentField {
    Segment = 'segment',
    File = 'file',
}

export interface UploadFileSegmentPayload {
    [UploadFileSegmentField.Segment]: string;
    [UploadFileSegmentField.File]: File;
}

export enum UploadFileSegmentValidationErrorCode {
    SegmentStructureNotValid = 'SEGMENT_STRUCTURE_NOT_VALID',
    SegmentListFormatNotValid = 'SEGMENT_LIST_FORMAT_NOT_VALID',
    SegmentNotFound = 'SEGMENT_NOT_FOUND',
    SegmentIsBlocked = 'SEGMENT_IS_BLOCKED',
    SegmentErrorOnRead = 'SEGMENT_ERROR_ON_READ',
    SegmentFileSizeLimit = 'SEGMENT_FILE_SIZE_LIMIT',
    MissingRequiredField = 'MISSING_REQUIRED_FIELD',
    SizeExceeded = 'SIZE_EXCEEDED',
}

export type UploadFileSegmentValidationError = ValidationError<UploadFileSegmentValidationErrorCode>;
