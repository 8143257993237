import { sample } from 'effector';
import { disableSubscriptionFx } from '@admin/model/subscriptions/disable-subscription/disable-subscription.fx';
import { getSuccessMessage } from '@admin/model/subscriptions/disable-subscription/disable-subscription.helpers';
import { showNoticeEvent } from '@admin/model/notices/notices.store';

sample({
    clock: disableSubscriptionFx.done,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
