import { sample } from 'effector';
import { getUsersListFx } from '@admin/model/users/users-list/get-users-list.fx';
import { $usersListData, $usersListError } from '@admin/model/users/users-list/users-list.store';
import { normalizeUsersData } from '@admin/model/users/users-list/users-list.helpers';

sample({
    clock: getUsersListFx.doneData,
    fn: (result) => ({
        data: normalizeUsersData(result.data),
        totalCount: result.totalCount,
    }),
    target: $usersListData,
});

sample({
    clock: getUsersListFx.failData,
    target: $usersListError,
});
