import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import {
    UploadAccrualsRequestsData,
    UploadAccrualsRequestsError,
    UploadValidationError,
} from '@admin/model/accruals/upload-accruals-requests/upload-accruals-requests.types';
import { isLargeEntityError } from '@admin/model/api/api.resource';

export const uploadAccrualsRequestsFx = createEffect(async (payload: FormData) => {
    try {
        const { uploadId } = await postRequest<UploadAccrualsRequestsData, FormData>('accruals/uploads', payload);

        return uploadId;
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, UploadAccrualsRequestsError)) {
            throw error.data.validationErrors[0];
        } else if (isLargeEntityError(error)) {
            throw {
                code: UploadAccrualsRequestsError.FileSizeLimit,
            } as UploadValidationError;
        } else {
            throw {
                code: UploadAccrualsRequestsError.Unknown,
            } as UploadValidationError;
        }
    }
});
