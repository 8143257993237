import { CategoryPath } from '@admin/model/offers/offers.types';

export const mapCategoriesFromPathsData = (categoriesPaths: CategoryPath[][], categoryIds: string[]) => {
    const ids = new Set(categoryIds);

    return categoriesPaths.flat().reduce<CategoryPath[]>((acc, categoryPath) => {
        if (ids.has(categoryPath.id)) {
            // TODO: figure out why there is duplication of elements
            acc.includes(categoryPath) ? null : acc.push(categoryPath);
        }
        return acc;
    }, []);
};
