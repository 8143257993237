import { combine, createEvent, createStore } from 'effector';
import { deleteProductCharacteristicsFx } from '@admin/model/products/delete-product-characteristics/delete-product-characteristics.fx';

export const resetDeleteProductCharacteristicsErrorEvent = createEvent();

export const $deleteProductCharacteristicsData = createStore(false);
export const $deleteProductCharacteristicsError = createStore<Nullable<unknown>>(null);

export const $deleteProductCharacteristics = combine({
    data: $deleteProductCharacteristicsData,
    error: $deleteProductCharacteristicsError,
    isLoading: deleteProductCharacteristicsFx.pending,
});
