import { createEffect } from 'effector';
import { DictionaryData } from '@admin/model';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';

export const getManualBlockingClientReasonsFx = createEffect(async () => {
    try {
        return await getRequest<DictionaryData>(`dictionaries/MANUAL_BLOCKING_CLIENT_REASON`);
    } catch (error) {
        logger(error);

        throw error;
    }
});
