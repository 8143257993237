import { logger } from '@admin/common/helpers/logger';
import { putRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { updateCharacteristicDomain } from '@admin/model/characteristics/characteristics.domains';
import {
    CharacteristicPayload,
    CharacteristicValidationErrorCode,
} from '@admin/model/characteristics/characteristic/characteristic.types';
import { isKnownValidationErrorResponse } from '@admin/common/validation';

export const updateCharacteristicFx = updateCharacteristicDomain.createEffect(
    async (payload: CharacteristicPayload) => {
        try {
            await putRequest(`catalog/productCharacteristics/${payload.id}`, payload);
        } catch (error) {
            logger(error);

            if (isKnownValidationErrorResponse(error, CharacteristicValidationErrorCode)) {
                throw error.data.validationErrors;
            } else {
                setCommonAppError({ error });

                throw null;
            }
        }
    },
);
