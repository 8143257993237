import { combine, createEvent, createStore } from 'effector';
import { ReportRequestData } from '@admin/model/reports/create-report-request/create-report-request.types';
import { createReportRequestFx } from '@admin/model/reports/create-report-request/create-report-request.fx';

export const resetCreateReportRequestErrorEvent = createEvent();

export const $createReportRequestData = createStore<Nullable<ReportRequestData>>(null);
export const $createReportRequestError = createStore<Nullable<unknown>>(null);

export const $createReportRequest = combine({
    data: $createReportRequestData,
    error: $createReportRequestError,
    isLoading: createReportRequestFx.pending,
});
