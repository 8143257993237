import { restore, combine } from 'effector';
import { loadLineAttributesFx } from '@admin/model/suppliers/load-line-attributes/load-line-attributes.fx';

const $errorStore = restore(loadLineAttributesFx.failData, null).on(loadLineAttributesFx.doneData, () => null);

const $dataStore = restore(loadLineAttributesFx, null);

export const $lineAttributesStore = combine({
    error: $errorStore,
    isLoading: loadLineAttributesFx.pending,
    data: $dataStore,
});
