import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { updateOfferCategoryFx } from '@admin/model/offer-categories/update-offer-category/update-offer-category.fx';
import { getSuccessMessage } from '@admin/model/offer-categories/update-offer-category/update-offer-category.helpers';

sample({
    clock: updateOfferCategoryFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
