import { logger } from '@admin/common/helpers/logger';
import { deleteRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { createEffect } from 'effector';

export const deleteListsFx = createEffect(async (ids: string[]) => {
    try {
        await deleteRequest(`lists/batching`, ids);

        return ids;
    } catch (error) {
        logger(error);

        setCommonAppError({ error });

        throw null;
    }
});
