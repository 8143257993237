import { combine, createEvent, createStore } from 'effector';
import { changeClientPhoneFx } from '@admin/model/clients-and-profiles/client-phone/change-client-phone.fx';

export const $clientPhoneError = createStore<Nullable<unknown>>(null);
export const $clientPhoneDone = createStore<boolean>(false);

export const resetClientPhoneErrorEvent = createEvent();

export const $clientPhone = combine({
    data: $clientPhoneDone,
    error: $clientPhoneError,
    isLoading: changeClientPhoneFx.pending,
});
