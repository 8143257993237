import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { CreateCharacteristicPayload } from '@admin/model/characteristics/create-characteristic/create-characteristic.types';
import { CharacteristicValidationErrorCode } from '@admin/model/characteristics/characteristic/characteristic.types';
import { createCharacteristicDomain } from '@admin/model/characteristics/characteristics.domains';
import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { getSuccessMessage } from '@admin/model/characteristics/create-characteristic/create-characteristic.helpers';

export const createCharacteristicFx = createCharacteristicDomain.createEffect(
    async (payload: CreateCharacteristicPayload) => {
        try {
            await postRequest('catalog/productCharacteristics', payload);
        } catch (error) {
            logger(error);

            if (isKnownValidationErrorResponse(error, CharacteristicValidationErrorCode)) {
                throw error.data.validationErrors;
            } else {
                setCommonAppError({ error });

                throw null;
            }
        }
    },
);

sample({
    clock: createCharacteristicFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
