import { createEffect } from 'effector';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { logger } from '@admin/common/helpers/logger';
import { putRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import type { OffersUpdateBatchingPayload } from '@admin/model/offers/offers-batch-update/offers-batch-update.types';
import { OffersBatchingValidationErrorCode } from '@admin/model/offers/offers-batch-update/offers-batch-update.types';

export const offersBatchUpdateFx = createEffect(async (payload: OffersUpdateBatchingPayload) => {
    const labels = payload.labels;
    try {
        await putRequest(
            'offers/batching',
            {
                ...payload,
                ...(labels
                    ? {
                          labels: labels.map(({ key }) => key),
                      }
                    : {}),
            },
            {
                apiVersion: 2,
            },
        );

        return payload;
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, OffersBatchingValidationErrorCode)) {
            throw error.data.validationErrors[0];
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
