import { sample } from 'effector';
import { getDeactivateProgramLoyaltyReasonsFx } from '@admin/model/clients-and-profiles/deactivate-program-loyalty-reasons/get-deactivate-program-loyalty-reasons.fx';
import {
    $deactivateProgramLoyaltyReasonsData,
    $deactivateProgramLoyaltyReasonsError,
    resetDeactivateProgramLoyaltyReasonsErrorEvent,
    resetDeactivateProgramLoyaltyReasonsDataEvent,
} from '@admin/model/clients-and-profiles/deactivate-program-loyalty-reasons/deactivate-program-loyalty-reasons.store';
import { reset } from 'patronum';
import { DictionaryData } from '@admin/model';

const mapDictionaryValueList = (data: DictionaryData) =>
    data.dictionaryValueList?.map(({ value }) => {
        const item = JSON.parse(value);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return { value: String(item?.code || ''), title: String(item?.name || '') };
    });

sample({
    clock: getDeactivateProgramLoyaltyReasonsFx.doneData,
    filter: (results) => results.some((result) => result.status === 'fulfilled'),
    fn: ([b2e, b2c]) => {
        return {
            b2e: b2e.status === 'fulfilled' ? mapDictionaryValueList(b2e.value) : [],
            b2c: b2c.status === 'fulfilled' ? mapDictionaryValueList(b2c.value) : [],
        };
    },
    target: $deactivateProgramLoyaltyReasonsData,
});

sample({
    clock: getDeactivateProgramLoyaltyReasonsFx.doneData,
    filter: (results) => results.some((result) => result.status === 'rejected'),
    fn: ([b2e, b2c]) => {
        return {
            b2e: b2e.status === 'rejected' ? b2e.reason : null,
            b2c: b2c.status === 'rejected' ? b2c.reason : null,
        };
    },
    target: $deactivateProgramLoyaltyReasonsError,
});

sample({
    clock: getDeactivateProgramLoyaltyReasonsFx.doneData,
    filter: (results) => results.every((result) => result.status === 'fulfilled'),
    target: resetDeactivateProgramLoyaltyReasonsErrorEvent,
});

reset({ clock: resetDeactivateProgramLoyaltyReasonsDataEvent, target: [$deactivateProgramLoyaltyReasonsData] });
reset({ clock: resetDeactivateProgramLoyaltyReasonsErrorEvent, target: [$deactivateProgramLoyaltyReasonsError] });
