import { sample } from 'effector';
import { createReportRequestFx } from '@admin/model/reports/create-report-request/create-report-request.fx';
import { getSuccessMessage } from '@admin/model/reports/create-report-request/create-report-request.helpers';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import {
    $createReportRequestData,
    $createReportRequestError,
    resetCreateReportRequestErrorEvent,
} from '@admin/model/reports/create-report-request/create-report-request.store';
import { reset } from 'patronum';

sample({
    clock: createReportRequestFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});

sample({
    clock: createReportRequestFx.doneData,
    target: $createReportRequestData,
});

sample({
    clock: createReportRequestFx.failData,
    target: $createReportRequestError,
});

reset({ clock: resetCreateReportRequestErrorEvent, target: [$createReportRequestError] });
