import {
    OfferFields,
    OfferAttributesFields,
    OfferAttributesData,
    OfferAttributes,
    OfferData,
    Offer,
    SubscriptionData,
    Subscription,
    ActionRules,
} from '@admin/model/subscriptions/subscriptions.types';
import { normalizeDate } from '@admin/model/helpers';
import { generateId } from '@admin/common/helpers';

export const transformOfferAttributes = (attributes: OfferAttributesData): OfferAttributes => {
    let serviceParameters = undefined;

    try {
        serviceParameters = JSON.parse(attributes[OfferAttributesFields.ServiceParameters]);
    } catch (err) {
        console.error('Не удается обработать сервисные параметры.');
    }

    let actionRules = '/404';

    if (typeof attributes[OfferAttributesFields.ActionRules] === 'string') {
        try {
            const data = JSON.parse(attributes[OfferAttributesFields.ActionRules]) as ActionRules;

            actionRules = data.image.url;
        } catch (err) {
            actionRules = attributes[OfferAttributesFields.ActionRules];
        }
    } else {
        console.error('Не удается обработать параметры правил акции.');
    }

    return {
        ...attributes,
        [OfferAttributesFields.ServiceParameters]: serviceParameters,
        [OfferAttributesFields.ActionRules]: actionRules,
    };
};

export const mapOffer = (data: OfferData): Offer => ({
    ...data,
    [OfferFields.OfferAttributes]: transformOfferAttributes(data[OfferFields.OfferAttributes]),
});

export const normalizeSubscriptionData = (response: SubscriptionData): Subscription => ({
    ...response,
    createdAt: normalizeDate(response.createdAt),
    updatedAt: normalizeDate(response.updatedAt),
    nextPaymentDateTime: normalizeDate(response.nextPaymentDateTime),
    offer: response.offer !== null ? mapOffer(response.offer) : null,
    payments: response.payments.map((payment) => ({
        ...payment,
        createdAt: normalizeDate(payment.createdAt),
        id: generateId(),
    })),
});
