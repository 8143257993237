import { sample } from 'effector';
import { getNotificationFx } from '@admin/model/notifications/notification/get-notification.fx';
import { $notificationData, $notificationError } from '@admin/model/notifications/notification/notification.store';

sample({
    clock: getNotificationFx.doneData,
    fn: (result) => result,
    target: $notificationData,
});

sample({
    clock: getNotificationFx.failData,
    target: $notificationError,
});
