import { NotificationData, Notification } from '@admin/model/notifications/notifications.types';

export interface NotificationsData {
    totalCount: number;
    data: NotificationData[];
}

export interface NotificationsState {
    totalCount: number;
    data: Notification[];
}

export enum NotificationsParam {
    Offset = 'offset',
    Limit = 'limit',
    Ordering = 'ordering',
    Statuses = 'statuses',
    EventTypes = 'eventTypes',
    Origin = 'origin',
    PartnerIds = 'partnerIds',
    Channels = 'channels',
    Term = 'term',
}

export interface NotificationsPayload {
    [NotificationsParam.Offset]: number;
    [NotificationsParam.Limit]: number;
    [NotificationsParam.Ordering]?: string;
    filter: {
        [NotificationsParam.Statuses]?: string[];
        [NotificationsParam.EventTypes]?: string[];
        [NotificationsParam.Origin]?: string;
        [NotificationsParam.PartnerIds]?: string[];
        [NotificationsParam.Channels]?: string[];
        [NotificationsParam.Term]?: string;
    };
}
