import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { normalizeDate } from '@admin/model/helpers/normalize-date';
import { ReportData } from '@admin/model/reports/reports-list/reports-list.types';

export const getReportFx = createEffect(async (id: string) => {
    try {
        const data = await getRequest<ReportData>(`reports/${id}`);

        return {
            ...data,
            createdAt: normalizeDate(data.createdAt),
            updatedAt: normalizeDate(data.updatedAt),
        };
    } catch (error) {
        logger(error);

        throw error;
    }
});
