import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { CollectionValidationErrorCode } from '@admin/model/collections/collections.types';
import { isKnownValidationErrorResponse } from '@admin/common/validation/validation-error.types';
import { setCommonAppError } from '@admin/model/error-notification/error-notification.store';

export const createCollectionFx = createEffect(async (payload: FormData) => {
    try {
        await postRequest('catalog/categories', payload);
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, CollectionValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
