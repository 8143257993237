import { sample } from 'effector';
import {
    $refundStatusDictionaryData,
    $refundStatusDictionaryError,
} from '@admin/model/orders/refund-status-dictionary/refund-status-dictionary.store';
import { parseData } from '@admin/model/orders/refund-status-dictionary/refund-status-dictionary.helpers';
import { getRefundStatusDictionaryFx } from '@admin/model/orders/refund-status-dictionary/get-refund-status-dictionary.fx';

sample({
    clock: getRefundStatusDictionaryFx.doneData,
    fn: (result) => parseData(result),
    target: $refundStatusDictionaryData,
});

sample({
    clock: getRefundStatusDictionaryFx.failData,
    target: $refundStatusDictionaryError,
});
