import { sample } from 'effector';
import { deleteOffersFx } from '@admin/model/offers/delete-offers/delete-offers.fx';
import { getSuccessMessage } from '@admin/model/offers/delete-offers/delete-offers.helpers';
import { showNoticeEvent } from '@admin/model/notices';

sample({
    clock: deleteOffersFx.done,
    fn: ({ params }) => getSuccessMessage(params),
    target: showNoticeEvent,
});
