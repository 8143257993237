import { normalizeImages } from '@admin/common/helpers/normalize-images';
import { Supplier } from '@admin/model/suppliers/suppliers.types';

export const getNormalizedData = (data: Supplier) => ({
    ...data,
    settings: {
        ...data.settings,
        ...(data.settings?.logo
            ? {
                  logo: normalizeImages([data.settings.logo], 'logo')[0],
              }
            : {}),
    },
});
