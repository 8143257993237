import { LabelItem } from '@admin/model/labels';
import { OfferModerationStatus } from '@admin/model/offers/offers.types';

export interface OffersUpdateBatchingPayload {
    offerIds: string[];
    active?: boolean;
    moderationStatus?: OfferModerationStatus;
    available?: boolean;
    targets?: string[];
    labels?: LabelItem[];
}

export enum OffersBatchingValidationErrorCode {
    RewardWasNotApproved = 'REWARD_WAS_NOT_APPROVED',
    SupplierBlocked = 'SUPPLIER_BLOCKED',
    ViolationOfActiveProductOfInactiveSupplier = 'VIOLATION_OF_ACTIVE_PRODUCT_OF_INACTIVE_SUPPLIER',
    ActiveProductAndNotApprovedModerationStatusViolation = 'ACTIVE_PRODUCT_AND_NOT_APPROVED_MODERATION_STATUS_VIOLATION',
    DisabledPartnerCouponActivation = 'DISABLED_PARTNER_COUPON_ACTIVATION',
    NoActiveCouponCodes = 'NO_ACTIVE_COUPON_CODES',
    ManualActivationIsProhibited = 'MANUAL_ACTIVATION_IS_PROHIBITED',
}
