import { sample } from 'effector';
import { getListTypesDictionaryFx } from '@admin/model/lists/list-types-dictionary/get-list-types-dictionary.fx';
import {
    $listTypesDictionaryData,
    $listTypesDictionaryError,
} from '@admin/model/lists/list-types-dictionary/list-types-dictionary.store';
import { parseData } from '@admin/model/lists/list-types-dictionary/list-types-dictionary.helpers';

sample({
    clock: getListTypesDictionaryFx.doneData,
    fn: (result) => parseData(result),
    target: $listTypesDictionaryData,
});

sample({
    clock: getListTypesDictionaryFx.failData,
    target: $listTypesDictionaryError,
});
