import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { SupplierFormConfigsData } from '@admin/model/suppliers/supplier-form-configs/supplier-form-configs.types';

export const getSupplierFormConfigsFx = createEffect(async () => {
    try {
        return await getRequest<SupplierFormConfigsData>('dictionaries/supplier_type_properties');
    } catch (error) {
        logger(error);

        throw error;
    }
});
