import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { ExpirationPayload, ExpirationResponse } from '@admin/model/clients-and-profiles/expiration/expiration.types';
import { parseData } from '@admin/model/clients-and-profiles/expiration/expiration.helpers';
import { ExpirationTransactionType } from '@admin/model/clients-and-profiles/expirations/expirations.types';
import { DictionaryData } from '@admin/model/model.types';
import { OrderStatusDictionary } from '@admin/model/orders/orders.types';

export const getExpirationFx = createEffect(async ({ expirationId, ...payload }: ExpirationPayload) => {
    try {
        const transactionTypes = await getRequest<DictionaryData>('dictionaries/account_transaction_type');
        const types: OrderStatusDictionary<ExpirationTransactionType> =
            parseData<ExpirationTransactionType>(transactionTypes);

        const data = await getRequest<ExpirationResponse<string, ExpirationTransactionType>>(
            `accounts/expirations/transactions/${expirationId}`,
            payload,
        );

        return {
            ...data,
            expirationTransactions: data.expirationTransactions.map((item) => ({
                ...item,
                createdAt: new Date(item.createdAt),
                transactionType: types[item.transactionType]
                    ? types[item.transactionType].description
                    : item.transactionType,
            })),
        };
    } catch (error) {
        logger(error);

        throw error;
    }
});
