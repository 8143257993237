import { DictionaryBaseItem, DictionaryData } from '@admin/model/model.types';
import { NotificationsChannelsDictionary } from '@admin/model/notifications/notifications-channels-dictionary/notifications-channels-dictionary.types';

export const parseData = (notificationsChannelsDictData: DictionaryData): NotificationsChannelsDictionary =>
    notificationsChannelsDictData.dictionaryValueList.reduce<NotificationsChannelsDictionary>(
        (acc, notificationsChannelsData) => {
            const parsed = JSON.parse(notificationsChannelsData.value) as DictionaryBaseItem;
            acc.array.push(parsed);
            acc.dictionary[parsed.code] = parsed;
            return acc;
        },
        {
            array: [],
            dictionary: {},
        },
    );
