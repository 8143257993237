import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { getRequest } from '@admin/model/api';
import { ShortLinkData, ShortLinkListPayload } from '@admin/model/short-links/short-links.types';

export const getShortLinkListFx = createEffect(async (payload: ShortLinkListPayload) => {
    try {
        return await getRequest<ShortLinkData[]>('links', {
            ...payload,
        });
    } catch (error) {
        logger(error);

        throw error;
    }
});
