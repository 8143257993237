import { combine, createStore } from 'effector';
import { List } from '@admin/model/lists/lists.types';
import { getListFx } from '@admin/model/lists/list/get-list.fx';

export const $listData = createStore<Nullable<List>>(null);
export const $listError = createStore<Nullable<unknown>>(null);

export const $list = combine({
    data: $listData,
    error: $listError,
    isLoading: getListFx.pending,
});
