import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices';
import { getSuccessMessage } from '@admin/model/collections/delete-collection/delete-collection.helpers';
import { deleteCollectionFx } from '@admin/model/collections/delete-collection/delete-collection.fx';

sample({
    clock: deleteCollectionFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
