import { ValidationError } from '@admin/common/validation';
import { ShortenedOfferCategory } from '@admin/model';
import { KladrItem } from '../kladrs';
import { ShortenedSupplier } from '../offers';

export enum OfferPartnersField {
    Id = 'id',
    Name = 'name',
    Code = 'code',
    Categories = 'categories',
    StickerCodes = 'stickerCodes',
    Supplier = 'supplier',
    SupplierAlias = 'supplierAlias',
    SupplierDescription = 'supplierDescription',
    Description = 'description',
    AwardDescription = 'awardDescription',
    Status = 'status',
    Priority = 'priority',
    DateRange = 'dateRange',
    DateToPublishFrom = 'dateToPublishFrom',
    DateToPublishTo = 'dateToPublishTo',
    DiscountDateRange = 'discountDateRange',
    DiscountDateFrom = 'discountDateFrom',
    DiscountDateTo = 'discountDateTo',
    ModerationStatus = 'moderationStatus',
    ModerationText = 'moderationText',
    IntegrationType = 'integrationType',
    Link = 'link',
    imageUrls = 'imageUrls',
    Images = 'images',
    LoyaltyPrograms = 'loyaltyPrograms',
    Currencies = 'currencies',
    WithLocation = 'WithLocation',
    KladrCodes = 'kladrCodes',
    AcceptedIn = 'acceptedIn',
    ActionRules = 'actionRules',
    ActionRulesUrl = 'actionRulesUrl',
    BuyingRules = 'buyingRules',
    BuyingConditions = 'buyingConditions',
    TargetAudience = 'targetAudience',
    PointOfSales = 'pointOfSales',
    AccrualCategory = 'accrualCategory',
}

export enum OfferPartnersCategoryField {
    CategoryId = 'categoryId',
    CategoryName = 'categoryName',
}

export enum OfferPartnersParam {
    Order = 'order',
    Offset = 'offset',
    Limit = 'limit',
    Term = 'term',
    Page = 'page',
    SupplierIds = 'supplierIds',
    CategoryIds = 'categoryIds',
    Statuses = 'statuses',
}

export enum OfferPartnerStatus {
    Activated = 'ACTIVATED',
    Deactivated = 'DEACTIVATED',
}

export enum OfferPartnerAcceptedIn {
    Online = 'ONLINE',
    Offline = 'OFFLINE',
    Online_and_offline = 'ONLINE_AND_OFFLINE',
}

export enum OfferPartnerIntegrationType {
    Direct = 'direct',
    Admitad = 'admitad',
    Leclick = 'leclick',
    Advcake = 'advcake',
}

export enum OfferPartnersModerationStatus {
    Accepted = 'APPROVED',
    Declined = 'DECLINED',
    Waiting = 'WAITING',
}

export interface OfferPartnersCategory {
    [OfferPartnersCategoryField.CategoryId]: string;
    [OfferPartnersCategoryField.CategoryName]: string;
}

export interface OfferPartnerData {
    [OfferPartnersField.Id]: string;
    [OfferPartnersField.Categories]: OfferPartnersCategory[];
    [OfferPartnersField.StickerCodes]: string[];
    [OfferPartnersField.LoyaltyPrograms]: string[];
    [OfferPartnersField.Supplier]: OfferPartnerSupplier;
    [OfferPartnersField.Code]?: string;
    [OfferPartnersField.AcceptedIn]?: string;
    [OfferPartnersField.ModerationStatus]: OfferPartnersModerationStatus;
    [OfferPartnersField.ModerationText]?: string;
    [OfferPartnersField.imageUrls]?: string[];
    [OfferPartnersField.AwardDescription]?: string;
    [OfferPartnersField.BuyingConditions]?: string;
    [OfferPartnersField.BuyingRules]?: string;
    [OfferPartnersField.Currencies]?: string[];
    [OfferPartnersField.Description]?: string;
    [OfferPartnersField.DateToPublishFrom]?: string;
    [OfferPartnersField.DateToPublishTo]?: string;
    [OfferPartnersField.DiscountDateFrom]?: string;
    [OfferPartnersField.DiscountDateTo]?: string;
    [OfferPartnersField.IntegrationType]?: string;
    [OfferPartnersField.Name]?: string;
    [OfferPartnersField.Priority]?: number;
    [OfferPartnersField.Status]?: string;
    [OfferPartnersField.TargetAudience]?: string[];
    [OfferPartnersField.PointOfSales]?: PointOfSale[];
    [OfferPartnersField.KladrCodes]?: KladrItem[];
    [OfferPartnersField.Link]?: string;
    [OfferPartnersField.ActionRulesUrl]?: string;
    [OfferPartnersField.AccrualCategory]?: string;
}

export type PartialOfferPartnerData = Partial<OfferPartnerData>;

export interface OfferPartner
    extends Omit<OfferPartnerData, OfferPartnersField.Categories | OfferPartnersField.Supplier> {
    [OfferPartnersField.Images]: (string | File)[];
    [OfferPartnersField.ActionRules]?: string | File;
    [OfferPartnersField.Categories]: ShortenedOfferCategory[];
    [OfferPartnersField.Supplier]: ShortenedSupplier;
    [OfferPartnersField.SupplierAlias]: string;
    [OfferPartnersField.SupplierDescription]: string;
}

export interface PointOfSale {
    addressText: string;
    id?: number;
}

export interface OfferPartnerSupplier {
    supplierId: string;
    supplierName: string;
    supplierAlias: string;
    supplierDescription: string;
}

export enum OfferPartnerValidationErrorCode {
    MissingRequiredField = 'MISSING_REQUIRED_FIELD',
    ValueIsNotUnique = 'VALUE_IS_NOT_UNIQUE',
    MismatchType = 'MISMATCH_TYPE',
    NotFound = 'NOT_FOUND',
    WrongOrder = 'WRONG_ORDER',
    WrongOrderDates = 'WRONG_ORDER_DATES',
    ErrorDateTo = 'ERROR_DATE_TO',
    InvalidDate = 'INVALID_DATES',
    ActiveProductAndNotApprovedModerationStatusViolation = 'ACTIVE_PRODUCT_AND_NOT_APPROVED_MODERATION_STATUS_VIOLATION',
    FileSizeLimit = 'FILE_SIZE_LIMIT',
}

export type OfferPartnerValidationError = ValidationError<OfferPartnerValidationErrorCode>;
