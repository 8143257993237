import { Characteristic } from '@admin/model/characteristics/characteristic/characteristic.types';

export interface CharacteristicsState {
    totalCount: number;
    data: Characteristic[];
}

export enum CharacteristicParam {
    Offset = 'offset',
    Limit = 'limit',
    Ordering = 'ordering',
    Term = 'term',
}

export interface CharacteristicsPayload {
    [CharacteristicParam.Offset]: number;
    [CharacteristicParam.Limit]: number;
    [CharacteristicParam.Ordering]?: string;
    [CharacteristicParam.Term]?: string;
}
