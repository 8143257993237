import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { offersBatchUpdateFx } from '@admin/model/offers/offers-batch-update/offers-batch-update.fx';
import { getSuccessMessage } from '@admin/model/offers/offers-batch-update/offers-batch-update.helpers';

sample({
    clock: offersBatchUpdateFx.doneData,
    fn: (payload) => getSuccessMessage(payload.offerIds),
    target: showNoticeEvent,
});
