import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { createEffect } from 'effector';
import { ListValidationError, ListValidationErrorCode } from '@admin/model/lists/lists.types';
import { isLargeEntityError } from '@admin/model/api/api.resource';

export const createListFx = createEffect(async (payload: FormData) => {
    try {
        await postRequest('lists', payload);
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, ListValidationErrorCode)) {
            throw error.data.validationErrors;
        } else if (isLargeEntityError(error)) {
            // Supports error returned by gateway server configuration
            throw [{ code: ListValidationErrorCode.ListFileSizeLimit } as ListValidationError];
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
