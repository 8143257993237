import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { DictionaryData } from '@admin/model/model.types';
import { parseData } from '@admin/model/reports/reports-groups-configs/reports-groups-configs.helpers';

export const getReportsGroupsConfigsDictionaryFx = createEffect(async () => {
    try {
        const configsData = await getRequest<DictionaryData>(`dictionaries/reports`);
        const groupsData = await getRequest<DictionaryData>(`dictionaries/report_groups`);
        return parseData(configsData, groupsData);
    } catch (error) {
        logger(error);

        throw error;
    }
});
