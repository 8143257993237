import { sample } from 'effector';
import { updateCollectionsBatchingFx } from '@admin/model/collections/update-collections-batching/update-collections-batching.fx';
import {
    $updateCollectionsBatchingDone,
    $updateCollectionsBatchingError,
    resetUpdateCollectionsBatchingDoneStatusEvent,
} from '@admin/model/collections/update-collections-batching/update-collections-batching.store';
import { reset } from 'patronum';
import { getSuccessMessage } from '@admin/model/collections/update-collections-batching/update-collections-batching.helpers';
import { showNoticeEvent } from '@admin/model/notices';

sample({
    clock: updateCollectionsBatchingFx.doneData,
    source: $updateCollectionsBatchingDone,
    fn: () => true,
    target: $updateCollectionsBatchingDone,
});

sample({
    clock: updateCollectionsBatchingFx.failData,
    target: $updateCollectionsBatchingError,
});

sample({
    source: updateCollectionsBatchingFx.doneData,
    fn: (payload) => getSuccessMessage(payload),
    target: showNoticeEvent,
});

reset({ clock: resetUpdateCollectionsBatchingDoneStatusEvent, target: [$updateCollectionsBatchingDone] });
