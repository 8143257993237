import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { DictionaryData } from '@admin/model/model.types';
import { parseReportsConfigsData } from '@admin/model/reports/helpers/parse-reports-configs-data';

export const getReportsConfigsDictionaryFx = createEffect(async () => {
    try {
        const data = await getRequest<DictionaryData>('dictionaries/reports');

        return parseReportsConfigsData(data);
    } catch (error) {
        logger(error);

        throw error;
    }
});
