import { ValidationError } from '@admin/common/validation';
import { ShortLinksParam } from '@admin/pages/settings/short-links/short-links.types';

export enum ShortLinkField {
    Id = 'id',
    Name = 'name',
    Type = 'type',
    Status = 'status',
    ShortLink = 'shortLink',
    UpdatedAt = 'updatedAt',
    UpdatedBy = 'updatedBy',
    UniversalLink = 'universalLink',
    SiteLink = 'siteLink',
    UtmSource = 'utmSource',
    UtmMedium = 'utmMedium',
    UtmCampaign = 'utmCampaign',
    UserEditedPartLink = 'userEditedPartLink',
}

export interface ShortLinkData {
    [ShortLinkField.Id]: string;
    [ShortLinkField.Name]: string;
    [ShortLinkField.Type]: ShortLinkType;
    [ShortLinkField.Status]: ShortLinkStatus;
    [ShortLinkField.ShortLink]: string;
    [ShortLinkField.UpdatedAt]: string;
    [ShortLinkField.UpdatedBy]: string;
    [ShortLinkField.UniversalLink]?: string;
    [ShortLinkField.SiteLink]?: string;
    [ShortLinkField.UtmSource]?: string;
    [ShortLinkField.UtmMedium]?: string;
    [ShortLinkField.UtmCampaign]?: string;
}

export interface ShortLink extends Omit<ShortLinkData, ShortLinkField.UpdatedAt> {
    [ShortLinkField.UpdatedAt]?: Date;
}

export interface ShortLinksState {
    totalCount: number;
    data: ShortLink[];
}

export enum ShortLinkType {
    UniversalLink = 'UNIVERSAL_LINK',
    BrowserLink = 'BROWSER_LINK',
}

export enum ShortLinkStatus {
    Active = 'ACTIVE',
    Archive = 'ARCHIVE',
}

export enum ShortLinkValidationErrorCode {
    NotUniqueValue = 'NOT_UNIQUE_VALUE',
    InvalidValue = 'INVALID_VALUE',
    InvalidLinkId = 'INVALID_LINKID',
}

export type ShortLinkValidationError = ValidationError<ShortLinkValidationErrorCode>;

export interface ShortLinkPayload {
    [ShortLinkField.Name]?: string;
    [ShortLinkField.Type]?: ShortLinkType;
    [ShortLinkField.Status]?: ShortLinkStatus;
    [ShortLinkField.ShortLink]?: string;
    [ShortLinkField.SiteLink]?: string;
    [ShortLinkField.UtmSource]?: string;
    [ShortLinkField.UtmMedium]?: string;
    [ShortLinkField.UtmCampaign]?: string;
}

export interface ShortLinkListPayload {
    [ShortLinksParam.Term]?: string;
    [ShortLinksParam.Type]?: string;
    [ShortLinksParam.Status]?: string;
    [ShortLinksParam.PeriodFrom]?: string;
    [ShortLinksParam.PeriodTo]?: string;
    [ShortLinksParam.Page]?: number;
    [ShortLinksParam.Offset]?: number;
    [ShortLinksParam.Limit]?: number;
    [ShortLinksParam.Ordering]?: string;
}
