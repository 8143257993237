import { sample } from 'effector';
import { getSuccessMessage } from '@admin/model/orders/create-track-number/create-track-number.helpers';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { createTrackNumberFx } from '@admin/model/orders/create-track-number/create-track-number.fx';

sample({
    clock: createTrackNumberFx.done,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
