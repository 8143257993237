import { logger } from '@admin/common/helpers/logger';
import { Category } from '@admin/model/categories/category/category.types';
import { getRequest } from '@admin/model/api';
import { createEffect } from 'effector';
import { ChildCategoriesPayload } from '@admin/model/categories/categories-list/categories-list.types';

export const updateRootCategoryFx = createEffect(
    async ({ categoryIds, currenciesFilterValue, supplierId }: ChildCategoriesPayload) => {
        try {
            const data = await getRequest<Category>(`catalog/categories/${categoryIds[0]}`);
            return { data, supplierId, currenciesFilterValue };
        } catch (error) {
            logger(error);

            throw error;
        }
    },
);
