import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { getSuccessMessage } from '@admin/model/short-links/create-short-link/create-short-link.helpers';
import { createShortLinkFx } from '@admin/model/short-links/create-short-link/create-short-link.fx';

sample({
    clock: createShortLinkFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
