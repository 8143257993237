import { forward, sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { getSuccessMessage } from '@admin/model/segments/create-segment/create-segment.helpers';
import { createSegmentFx } from '@admin/model/segments/create-segment/create-segment.fx';
import { getSegmentListFx } from '@admin/model';

sample({
    clock: createSegmentFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});

forward({
    from: createSegmentFx.doneData,
    to: getSegmentListFx,
});
