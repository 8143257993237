import { SupplierStatus } from '@admin/model/model.types';
import { ValidationError } from '@admin/common/validation';

export interface UpdateSupplierActivityPayload {
    status: SupplierStatus;
    suppliersIds: number[];
}

export enum SuppliersQuickActionsValidationErrorCode {
    SupplierHasActiveProducts = 'SUPPLIER_HAS_ACTIVE_PRODUCTS',
    MissingRequiredField = 'MISSING_REQUIRED_FIELD',
    MissingRequiredFieldForSelectedType = 'MISSING_REQUIRED_FIELD_FOR_SELECTED_TYPE',
    MandatoryFieldEmpty = 'MANDATORY_FIELD_EMPTY',
}

export type SuppliersQuickActionsValidationError = ValidationError<SuppliersQuickActionsValidationErrorCode>;
