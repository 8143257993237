import { normalizeDate } from '@admin/model/helpers';
import { ListsData, ListsState } from '@admin/model/lists/get-lists/lists.types';

export const normalizeListsData = (data: ListsData): ListsState => ({
    ...data,
    data: data.data.map((config) => ({
        ...config,
        updatedAt: normalizeDate(config.updatedAt),
    })),
});
