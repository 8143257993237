import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import { OrdersData } from '@admin/model/orders/orders.types';
import { OrdersPayload } from '@admin/model/orders/orders-list/orders-list.types';

export const getOrdersListFx = createEffect(async (payload: OrdersPayload) => {
    try {
        const data = await postRequest<OrdersData, OrdersPayload>('orders/search', payload);

        return data;
    } catch (error) {
        logger(error);

        throw error;
    }
});
