import { reset } from 'patronum';
import {
    $clientDisableAccessDone,
    $clientDisableAccessError,
    resetClientDisableAccessErrorEvent,
} from '@admin/model/clients-and-profiles/client-disable-access/client-disable-access.store';
import { sample } from 'effector';
import { getClientAccountFx } from '@admin/model/clients-and-profiles/client-account/get-client-account.fx';
import { clientDisableAccessFx } from '@admin/model/clients-and-profiles/client-disable-access/client-disable-access.fx';

sample({
    clock: clientDisableAccessFx.doneData,
    target: $clientDisableAccessDone,
});

sample({
    clock: getClientAccountFx.finally,
    fn: () => false,
    target: $clientDisableAccessDone,
});

sample({
    clock: clientDisableAccessFx.failData,
    target: $clientDisableAccessError,
});

reset({ clock: resetClientDisableAccessErrorEvent, target: [$clientDisableAccessError] });
