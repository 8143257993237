import { logger } from '@admin/common/helpers/logger';
import { getRequest } from '@admin/model/api';
import { createEffect } from 'effector';
import {
    ClientAccountPayload,
    ClientData,
} from '@admin/model/clients-and-profiles/client-account/client-account.types';

export const getClientAccountFx = createEffect(async (payload: ClientAccountPayload) => {
    try {
        return await getRequest<ClientData>('clients', payload);
    } catch (error) {
        logger(error);

        throw error;
    }
});
