import { OrderStatus, AggregatedPaymentStatus } from '@admin/model';

export enum OrderChannel {
    Mobile = 'MOBILE',
    OnlinePartner = 'ONLINE_PARTNER',
    RetailPartner = 'RETAIL_PARTNER',
    Site = 'SITE',
}

export interface OrderPartner {
    id: string | number;
    name: string;
    settings: {
        downloadCouponsAvailable?: boolean;
    };
    contacts?: {
        phones?: Nullable<string[]>;
        emails?: Nullable<string[]>;
    };
}

interface OrderItemData {
    quantity: number;
    rawOfferName: string;
    sku: string;
}

export interface OrderData {
    id: string;
    channel: OrderChannel;
    needDelivery: boolean;
    createdAt: string;
    totalCost: number;
    status: OrderStatus;
    aggregatedPaymentStatus: AggregatedPaymentStatus;
    partner: OrderPartner;
    externalOrderId: Nullable<string>;
    items: OrderItemData[];
}

export interface OrdersData {
    data: OrderData[];
    totalCount: Nullable<number>;
}

export interface OrderStatusWithDescription<status> {
    status: status;
    description: string;
}

export type OrderStatusDictionary<status> = {
    [key: string]: OrderStatusWithDescription<status>;
};

export interface Order extends Omit<OrderData, 'createdAt' | 'status' | 'aggregatedPaymentStatus'> {
    orderCreateDate?: Date;
    status: OrderStatusWithDescription<OrderStatus>;
    aggregatedPaymentStatus: OrderStatusWithDescription<AggregatedPaymentStatus>;
}

export enum TrackNumberFormFields {
    Id = 'id',
    TrackNumber = 'trackNumber',
    TrackUrl = 'trackUrl',
}

export interface TrackNumber {
    [TrackNumberFormFields.Id]?: string;
    [TrackNumberFormFields.TrackNumber]?: Nullable<string>;
    [TrackNumberFormFields.TrackUrl]?: Nullable<string>;
}
