import { sample } from 'effector';
import { editCategoryFx } from '@admin/model/categories/edit-category/edit-category.fx';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import {
    $isEditCategoryDone,
    resetEditCategoryDoneStatusEvent,
} from '@admin/model/categories/edit-category/edit-category.store';
import { reset } from 'patronum';
import { getSuccessMessage } from '@admin/model/categories/edit-category/edit-category.helpers';

sample({
    clock: editCategoryFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});

sample({
    clock: editCategoryFx.doneData,
    fn: () => true,
    target: $isEditCategoryDone,
});

reset({
    clock: resetEditCategoryDoneStatusEvent,
    target: $isEditCategoryDone,
});
