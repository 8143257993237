import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import {
    LoadOfferPartnersPayload,
    OfferPartnersData,
} from '@admin/model/offer-partners/offer-partners-list/offer-partners-list.types';
import { normalizeOfferPartnersData } from '@admin/model/offer-partners/offer-partners-list/offer-partners-list.helpers';

export const getOfferPartnersListFx = createEffect(async (payload: LoadOfferPartnersPayload) => {
    try {
        const { data, totalCount } = await postRequest<OfferPartnersData, LoadOfferPartnersPayload>(
            'offer-partners/search',
            payload,
        );

        return {
            data: normalizeOfferPartnersData(data),
            totalCount,
        };
    } catch (error) {
        logger(error);

        throw error;
    }
});
