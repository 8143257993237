import { combine, createEvent, createStore } from 'effector';
import { HashAccount } from './client-hash.types';
import { getClientHashAccountsFx } from './client-hash.fx';

export const resetClientHashAccountsEvent = createEvent();
export const resetClientHashAccountsErrorEvent = createEvent();

export const $clientHashAccountsData = createStore<Nullable<HashAccount[]>>(null);
export const $clientHashAccountsError = createStore<Nullable<unknown>>(null);

export const $clientHashAccounts = combine({
    data: $clientHashAccountsData,
    error: $clientHashAccountsError,
    isLoading: getClientHashAccountsFx.pending,
});
