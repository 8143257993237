import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { getSuccessMessage } from '@admin/model/offers/create-offer/create-offer.helpers';
import { createOfferFx } from '@admin/model/offers/create-offer/create-offer.fx';

sample({
    clock: createOfferFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
