import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';

export const refreshHashFx = createEffect(async (supplierId: number) => {
    try {
        await postRequest('catalog/products/offerHash/delete', { supplierId });
    } catch (error) {
        logger(error);

        setCommonAppError({ error });

        throw error;
    }
});
