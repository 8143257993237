import { normalizeDate } from '@admin/model/helpers';
import {
    UploadCouponCodesHistoryData,
    UploadCouponCodesHistory,
} from '@admin/model/offers/upload-coupon-codes-history/upload-coupon-codes-history.types';

export const normalizeUploadCouponCodesHistoryData = (
    codesFromFileUploads: UploadCouponCodesHistoryData[],
): UploadCouponCodesHistory[] =>
    codesFromFileUploads.map((codesFromFileUpload) => ({
        ...codesFromFileUpload,
        completedAt: normalizeDate(codesFromFileUpload.completedAt),
        startedAt: normalizeDate(codesFromFileUpload.startedAt),
        uploadedAt: normalizeDate(codesFromFileUpload.uploadedAt),
    }));
