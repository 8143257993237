import type { ValidationError } from '@admin/common/validation';

export interface CatalogPayload {
    values: FormData;
    supplierId: number;
}

export enum UploadCatalogValidationErrorCode {
    FormatNotValid = 'FORMAT_NOT_VALID',
    FileSizeLimit = 'FILE_SIZE_LIMIT',
    FileNameNotValid = 'FILENAME_NOT_VALID',
}

export type UploadCatalogValidationError = ValidationError<UploadCatalogValidationErrorCode>;
