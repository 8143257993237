import { sample } from 'effector';
import { getSuccessMessage } from '@admin/model/short-links/delete-short-link/delete-short-link.helpers';
import { showNoticeEvent } from '@admin/model/notices';
import { deleteShortLinkFx } from '@admin/model/short-links/delete-short-link/delete-short-link.fx';

sample({
    clock: deleteShortLinkFx.done,
    fn: () => getSuccessMessage(),
    target: showNoticeEvent,
});
