import { sample } from 'effector';
import { getTargetAudienceFx } from '@admin/model/target-audience/get-target-audience.fx';
import { $targetAudienceData, $targetAudienceError } from '@admin/model/target-audience/target-audience.store';
import { parseData } from '@admin/model/target-audience/target-audience.helpers';

sample({
    clock: getTargetAudienceFx.doneData,
    fn: (result) => parseData(result),
    target: $targetAudienceData,
});

sample({
    clock: getTargetAudienceFx.failData,
    target: $targetAudienceError,
});
