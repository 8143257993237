import { logger } from '@admin/common/helpers/logger';
import { CategoryPayload, Category } from '@admin/model/categories/category/category.types';
import { getRequest } from '@admin/model/api';
import { normalizeCategoryData } from '@admin/model/categories/category/category.mapper';
import { createEffect } from 'effector';

export const getCategoryFx = createEffect(async ({ id, calculateProductAmount = false }: CategoryPayload) => {
    try {
        const data = await getRequest<Category>(`catalog/categories/${id}`, {
            description: calculateProductAmount,
        });

        return normalizeCategoryData(data);
    } catch (error) {
        logger(error);

        throw error;
    }
});
