import { logger } from '@admin/common/helpers/logger';
import { setCommonAppError } from '@admin/model/error-notification';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { postRequest } from '@admin/model/api';
import { createEffect } from 'effector';
import {
    UpdatePartnerCategoryAliasPayload,
    UpdatePartnerCategoryAliasErrorCode,
} from '@admin/model/categories/update-partner-category-alias/update-partner-category-alias.types';

export const updatePartnerCategoryAliasFx = createEffect(
    async ({ categoryId, ...rest }: UpdatePartnerCategoryAliasPayload) => {
        try {
            await postRequest(`catalog/categories/${categoryId}/partnerCategoryAlias`, { ...rest });
        } catch (error) {
            logger(error);

            if (isKnownValidationErrorResponse(error, UpdatePartnerCategoryAliasErrorCode)) {
                throw error.data.validationErrors[0];
            } else {
                setCommonAppError({ error });

                throw null;
            }
        }
    },
);
