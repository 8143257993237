import { reset } from 'patronum';
import {
    $clientPhoneDone,
    $clientPhoneError,
    resetClientPhoneErrorEvent,
} from '@admin/model/clients-and-profiles/client-phone/client-phone.store';
import { sample } from 'effector';
import { changeClientPhoneFx } from '@admin/model/clients-and-profiles/client-phone/change-client-phone.fx';
import { getClientAccountFx } from '@admin/model/clients-and-profiles/client-account/get-client-account.fx';
import { getSuccessMessage } from '@admin/model/clients-and-profiles/client-phone/client-phone.helpers';
import { showNoticeEvent } from '@admin/model/notices';

sample({
    clock: changeClientPhoneFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});

sample({
    clock: changeClientPhoneFx.doneData,
    target: $clientPhoneDone,
});

sample({
    clock: getClientAccountFx.finally,
    fn: () => false,
    target: $clientPhoneDone,
});

sample({
    clock: changeClientPhoneFx.failData,
    target: $clientPhoneError,
});

reset({ clock: resetClientPhoneErrorEvent, target: [$clientPhoneError] });
