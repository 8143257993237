import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { disableSyncingProductsFx } from '@admin/model/products/disable-syncing-products/disable-syncing-products.fx';
import { getSuccessMessage } from '@admin/model/products/disable-syncing-products/disable-syncing-products.helpers';

sample({
    clock: disableSyncingProductsFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
