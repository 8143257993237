import { reset } from 'patronum';
import {
    $clientPasswordDone,
    $clientPasswordError,
    resetClientPasswordErrorEvent,
} from '@admin/model/clients-and-profiles/client-password/client-password.store';
import { sample } from 'effector';
import { resetClientPasswordFx } from '@admin/model/clients-and-profiles/client-password/reset-client-password.fx';
import { getClientAccountFx } from '@admin/model/clients-and-profiles/client-account/get-client-account.fx';
import { getSuccessMessage } from '@admin/model/clients-and-profiles/client-password/client-password.helpers';
import { showNoticeEvent } from '@admin/model/notices';

sample({
    clock: resetClientPasswordFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});

sample({
    clock: resetClientPasswordFx.doneData,
    target: $clientPasswordDone,
});

sample({
    clock: getClientAccountFx.finally,
    fn: () => false,
    target: $clientPasswordDone,
});

sample({
    clock: resetClientPasswordFx.failData,
    target: $clientPasswordError,
});

reset({ clock: resetClientPasswordErrorEvent, target: [$clientPasswordError] });
