import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { OfferCategory } from '@admin/model/offer-categories/categories.types';
import { logger } from '@admin/common/helpers/logger';

export const getOfferCategoryFx = createEffect(async (id: string) => {
    try {
        return await getRequest<OfferCategory>(`offer-partners/categories/${id}`);
    } catch (error) {
        logger(error);

        throw error;
    }
});
