import { CodeStatus, OrderPartner, OrderStatus } from '@admin/model';
import { CouponType } from '@admin/model/offers/offers.types';

export enum DeliveryType {
    Delivery = 'Delivery',
    Pickup = 'Pickup',
    Email = 'Email',
    Post = 'Post',
}

interface Tracking {
    trackNumber: string;
    trackUrl: string;
}

interface PickupPoints {
    externalPickupPointId: string;
    name: string;
    address: string;
    description: string;
    phones: string[];
    operatingHours: string[];
}

interface DeliveryInfo {
    type: DeliveryType;
    deliveryName: Nullable<string>;
    deliveryPeriod: Nullable<string>;
    deliveryDescription: Nullable<string>;
    comment?: Nullable<string>;
    externalDeliveryVariantId?: Nullable<string>;
    externalLocationId?: Nullable<string>;
    pickUpPoint?: PickupPoints;
    address?: {
        addressText?: string;
        city?: string;
        district?: string;
        flat?: string;
        house?: string;
        postCode?: string;
        region?: string;
        street?: string;
        town?: string;
        floor?: string;
        block?: string;
        entrance?: string;
    };
    contact?: {
        firstName?: string;
        lastName?: string;
        middleName?: string;
        phone?: string;
        email?: string;
    };
}

export const enum OrderType {
    Coupon = 'COUPON',
    CommonProduct = 'COMMON_PRODUCT',
    Cashback = 'CASHBACK',
    PBCashback = 'PB_CASHBACK',
}

export interface ProductCharacteristic {
    id: string;
    name: string;
    value: string;
    key: string;
}

export interface OrderItem {
    id: string;
    name: string;
    quantity: number;
    price: number;
    resultPrice: number;
    sku: string;
    offerType: OrderType;
    images?: string[];
    productCharacteristics: Nullable<ProductCharacteristic[]>;
    contacts?: {
        phones?: Nullable<string[]>;
        emails?: Nullable<string[]>;
    };
}

export interface CashbackItem extends OrderOptions {
    total: number;
}

export interface OrderItemWithCodeList extends OrderItem {
    codeList: CodeList[];
    downloadCouponsAvailable?: boolean;
}

export interface CodeList {
    id: string;
    code: string;
    activationCode: string;
    codeStatus: CodeStatus;
    downloadUrl: Nullable<string>;
    partnerUrl: Nullable<string>;
    couponType: CouponType;
    createdDate: string;
    expireDate: string;
}

export interface CouponItem {
    offerId: string;
    codeList: CodeList[];
}

export interface OrderOptions {
    card?: Nullable<string>;
    cashbackSumRub?: Nullable<number>;
    cashbackCurrencyRate?: Nullable<number>;
}

export interface OrderDetails<createAt> {
    id: number;
    clientId: string;
    createdAt: createAt;
    partner: OrderPartner;
    status: OrderStatus;
    externalId: Nullable<string>;
    rsId: number;
    needDelivery: boolean;
    deliveryInfo?: DeliveryInfo;
    deliveryPostings: Nullable<Tracking>;
    items?: OrderItem[];
    offerCoupons: Nullable<CouponItem[]>;
    deliveryCost: Nullable<number>;
    totalCost: number;
    orderOptions?: OrderOptions;
    type?: OrderType;
}

export type OrderDetailsResponse = OrderDetails<Nullable<string>>;
export type OrderDetailsData = OrderDetails<Date | undefined>;

export enum OrderFormFields {
    PartnerName = 'partnerName',
    Status = 'status',
    ExternalId = 'externalId',
    DeliveryType = 'deliveryType',
    DeliveryName = 'deliveryName',
    DeliveryPeriod = 'deliveryPeriod',
    DeliveryDescription = 'deliveryDescription',
    Address = 'address',
    Tracking = 'tracking',
    RsId = 'rsId',
    Items = 'items',
    OfferCoupons = 'offerCoupons',
    ClientComment = 'clientComment',
    ExternalDeliveryVariantId = 'externalDeliveryVariantId',
    ExternalLocationId = 'externalLocationId',
    PickupAddress = 'pickupAddress',
    PickupName = 'pickupName',
    PickupDescription = 'pickupDescription',
    ExternalPickupPointId = 'externalPickupPointId',
    OperatingHours = 'operatingHours',
    PickupPhones = 'pickupPhones',
    ClientId = 'clientId',
    FullName = 'fullName',
    Phone = 'phone',
    Email = 'email',
    StatusChangeComment = 'statusChangeComment',
}

export interface OrderFormValues {
    [OrderFormFields.PartnerName]?: string;
    [OrderFormFields.Status]?: string;
    [OrderFormFields.ExternalId]?: Nullable<string>;
    [OrderFormFields.RsId]?: number;
    [OrderFormFields.DeliveryType]?: string;
    [OrderFormFields.DeliveryName]?: Nullable<string>;
    [OrderFormFields.DeliveryPeriod]?: Nullable<string>;
    [OrderFormFields.DeliveryDescription]?: Nullable<string>;
    [OrderFormFields.Address]?: string;
    [OrderFormFields.Tracking]?: JSX.Element;
    [OrderFormFields.Items]?: OrderItem[];
    [OrderFormFields.OfferCoupons]?: Nullable<CouponItem[]>;
    [OrderFormFields.Email]?: Nullable<string>;
    [OrderFormFields.ClientComment]?: Nullable<string>;
    [OrderFormFields.ExternalDeliveryVariantId]?: Nullable<string>;
    [OrderFormFields.ExternalLocationId]?: Nullable<string>;
    [OrderFormFields.PickupAddress]?: string;
    [OrderFormFields.PickupName]?: string;
    [OrderFormFields.PickupDescription]?: string;
    [OrderFormFields.ExternalPickupPointId]?: string;
    [OrderFormFields.OperatingHours]?: JSX.Element;
    [OrderFormFields.PickupPhones]?: JSX.Element;
    [OrderFormFields.StatusChangeComment]?: string;
}

export enum PaymentStatus {
    REGISTERED = 'REGISTERED',
    ON_HOLD = 'ON_HOLD',
    IN_PROCESSING = 'IN_PROCESSING',
    CREDITED = 'CREDITED',
    PAID = 'PAID',
    CANCELED = 'CANCELED',
    SENT_TO_BANK = 'SENT_TO_BANK',
    READY_TO_PAY = 'READY_TO_PAY',
    ERROR = 'ERROR',
    READY_TO_APPROVE = 'READY_TO_APPROVE',
    TO_PAY = 'TO_PAY',
    MANUAL_PAY_PROCESSING = 'MANUAL_PAY_PROCESSING',
    RETRY = 'RETRY',
}

export interface PaymentOptions {
    paymentSystem: string;
    paymentGateId: string;
}

export interface OrderPayment {
    cost: number;
    bonusCost: number;
    createdAt: string;
    loyaltyProgram: Nullable<string>;
    paymentId: string;
    paymentOptions: Nullable<PaymentOptions>;
    status: PaymentStatus;
    updatedAt: Nullable<string>;
    errorDescription?: string;
}

export enum LoyaltyProgram {
    Vtb = 'VTB',
    PostBank = 'POST_BANK',
}

interface AvailableRefundItem {
    loyaltyProgram: Nullable<LoyaltyProgram>;
    availableCost: number;
    availableCostRub: number;
}

export enum RefundClaimStatus {
    IN_PROCESSING = 'IN_PROCESSING',
    DONE = 'DONE',
    PARTIALLY_DONE = 'PARTIALLY_DONE',
    CANCELED = 'CANCELED',
    OVERDUE = 'OVERDUE',
    SUSPENDED = 'SUSPENDED',
}

export interface RefundPaymentsItem {
    cost: number;
    createdAt: string;
    loyaltyProgram: Nullable<string>;
    paymentId: string;
    status: PaymentStatus;
    updatedAt: Nullable<string>;
    paymentOptions: Nullable<PaymentOptions>;
}

export interface RefundDetailsItem {
    refundChequeId?: string;
}

export interface RefundClaimsItemData {
    cost: number;
    bonusCost: number;
    createdAt: string;
    externalRefundClaimId: string;
    id: string;
    status: RefundClaimStatus;
    updatedAt: string;
    refundPayments: RefundPaymentsItem[];
    refundDetails: RefundDetailsItem[];
    loyaltyProgram?: Nullable<string>;
    hasProtestRefund?: boolean;
    refundChequeId?: string;
    errorDescription?: string;
}

export interface RefundClaimsItem extends Omit<RefundClaimsItemData, 'refundPayments' | 'refundDetails'> {
    subRows: RefundPaymentsItem[];
}

export interface PaymentsClaimsItem extends OrderPayment {
    chequeId: Nullable<string>;
    subRows: OrderPayment[];
}

export interface AvailableRefundByItem {
    sku: string;
    name: string;
    originalQuantity: number;
    availableQuantity: number;
    originalCost: number;
    availableCostRub: number;
}

export interface OrderPaymentsData {
    availableRefunds: AvailableRefundItem[];
    availableRefundByItem: AvailableRefundByItem[];
    payments: OrderPayment[];
    chequeId: Nullable<string>;
    refundClaims: RefundClaimsItemData[];
}

export interface OrderPayments extends Omit<OrderPaymentsData, 'refundClaims' | 'payments'> {
    refundClaims: RefundClaimsItem[];
    payments: PaymentsClaimsItem[];
}
