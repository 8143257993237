import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import {
    ExpirationsPayload,
    ExpirationsResponse,
} from '@admin/model/clients-and-profiles/expirations/expirations.types';

export const getExpirationsFx = createEffect(async ({ profileId, ...payload }: ExpirationsPayload) => {
    try {
        const data = await getRequest<ExpirationsResponse<string>>(
            `accounts/expirations/schedule/${profileId}`,
            payload,
        );

        return {
            ...data,
            expirations: data.expirations.map((it) => ({
                ...it,
                expirationViewDate: it.expirationViewDate ? new Date(it.expirationViewDate) : undefined,
                expirationDate: new Date(it.expirationDate),
                createdAt: new Date(it.createdAt),
            })),
        };
    } catch (error) {
        logger(error);

        throw error;
    }
});
