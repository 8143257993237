import { combine, createStore } from 'effector';
import { OrderStatusDictionary } from '@admin/model/orders/orders.types';
import { OrderStatus } from '@admin/model/model.types';
import { getOrderStatusDictionaryFx } from '@admin/model/orders/order-status-dictionary/get-order-status-dictionary.fx';

export const $orderStatusDictionaryData = createStore<Nullable<OrderStatusDictionary<OrderStatus>>>(null);
export const $orderStatusDictionaryError = createStore<Nullable<unknown>>(null);

export const $orderStatusDictionary = combine({
    data: $orderStatusDictionaryData,
    error: $orderStatusDictionaryError,
    isLoading: getOrderStatusDictionaryFx.pending,
});
