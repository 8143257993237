import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices';
import { getSuccessMessage } from '@admin/model/collections/create-collection/create-collection.helpers';
import { createCollectionFx } from '@admin/model/collections/create-collection/create-collection.fx';

sample({
    clock: createCollectionFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
