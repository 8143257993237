import { reset } from 'patronum';
import {
    $paymentsListData,
    $paymentsListError,
    mapPaymentsListEvent,
    resetPaymentsListEvent,
} from '@admin/model/payments/payments-list/payments-list.store';
import { sample } from 'effector';
import { getPaymentsListFx } from '@admin/model/payments/payments-list/get-payments-list.fx';

sample({
    clock: getPaymentsListFx.doneData,
    fn: (result) => ({
        payments: result.payments.map((payment) => ({
            ...payment,
            orderCreatedAt: new Date(payment.orderCreatedAt),
            loyaltyProgram: payment.loyaltyProgram ?? 'RUB',
            updatedAt: new Date(payment.updatedAt),
        })),
        totalCount: result.totalCount,
    }),
    target: mapPaymentsListEvent,
});

sample({
    clock: mapPaymentsListEvent,
    source: $paymentsListData,
    fn: (state, result) => ({
        payments: result.payments ? [...state.payments, ...result.payments] : state.payments,
        totalCount: result.totalCount,
    }),
    target: $paymentsListData,
});

sample({
    clock: getPaymentsListFx.failData,
    target: $paymentsListError,
});

reset({ clock: resetPaymentsListEvent, target: [$paymentsListData, $paymentsListError] });
