import { combine, createEvent, createStore } from 'effector';
import { resetUserPasswordFx } from '@admin/model/users/reset-user-password/reset-user-password.fx';

export const resetUserPasswordStateEvent = createEvent();
export const $resetUserPasswordData = createStore<Nullable<boolean>>(null);
export const $resetUserPasswordError = createStore<Nullable<unknown>>(null);

export const $resetUserPassword = combine({
    data: $resetUserPasswordData,
    error: $resetUserPasswordError,
    isLoading: resetUserPasswordFx.pending,
});
