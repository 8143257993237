import { combine, createEvent, createStore } from 'effector';
import { ManualBlockingClientReason } from '@admin/model/clients-and-profiles/manual-blocking-client-reason/manual-blocking-client-reason.types';
import { getManualBlockingClientReasonsFx } from '@admin/model/clients-and-profiles/manual-blocking-client-reason/get-manual-blocking-client-reasons.fx';

export const $manualBlockingClientReasonsData = createStore<ManualBlockingClientReason[]>([]);
export const $manualBlockingClientReasonsError = createStore<Nullable<unknown>>(null);

export const resetManualBlockingClientReasonsDataEvent = createEvent();
export const resetManualBlockingClientReasonsErrorEvent = createEvent();

export const $manualBlockingClientReasons = combine({
    data: $manualBlockingClientReasonsData,
    error: $manualBlockingClientReasonsError,
    isLoading: getManualBlockingClientReasonsFx.pending,
});
