import { forward } from 'effector';
import { loadRoleFx } from './role.fx';
import { $roleData, $roleError } from './role.store';

forward({
    from: loadRoleFx.doneData,
    to: $roleData,
});

forward({
    from: loadRoleFx.failData,
    to: $roleError,
});
