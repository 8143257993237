import React, { memo, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import type { ResourceKey } from 'i18next';
import { useLocation } from 'react-router';
import { useStore, useUnit } from 'effector-react';
import { UniversalModal } from '@admin/components/universal-modal/universal-modal.component';
import { UserActivityTracker } from '@admin/components/user-activity-tracker/user-activity-tracker.component';
import { Overlay } from '@admin/components/overlay/overlay.component';
import { AppBlockingError } from '@admin/components/app-blocking-error/app-blocking-error.component';
import { $currentRoute, $currentUserStore, getCurrentUserFx } from '@admin/model';
import { setAuthToken } from '@admin/model/api';
import { initLocalization } from '@common/localization';
import { PageRouter } from '@admin/common/pages';
import { PKCE_METHOD, keycloak } from '@admin/common/services';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager, WebTarget } from 'styled-components';
import { Helmet } from 'react-helmet';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const defaultLang = 'ru';
const USER_INACTIVITY_TIMEOUT = 1800;

initLocalization(
    defaultLang,
    async (language: string) => (await import(`./common/locales/${language}.json`)) as ResourceKey,
);

const AppComponent = () => {
    const { ready, t } = useTranslation();
    const { search: locationSearch } = useLocation();
    const {
        data: { user, permissions },
        error: userLoadingError,
    } = useStore($currentUserStore);

    const currentRoute = useUnit($currentRoute);

    const currentDocumentTitle = document.title;
    const helmetRouteTitle = currentRoute?.title !== undefined ? currentRoute.title : currentDocumentTitle;

    useEffect(() => {
        const redirectUri = window.location.origin + window.location.pathname;
        keycloak
            .init({
                checkLoginIframe: false,
                pkceMethod: PKCE_METHOD,
            })
            .then((authentificated) => {
                if (!authentificated) {
                    keycloak.login({
                        redirectUri: `${redirectUri}${locationSearch}`,
                        locale: defaultLang,
                    });
                } else if (keycloak.token !== undefined) {
                    setAuthToken(keycloak.token);
                }
            })
            .catch((error) => {
                throw error;
            });

        keycloak.onTokenExpired = () => {
            keycloak
                .updateToken(5)
                .then((isTokenRefreshed) => {
                    const refreshRedirectUri =
                        window.location.origin + window.location.pathname + window.location.search;

                    if (isTokenRefreshed) {
                        if (keycloak.token !== undefined) {
                            setAuthToken(keycloak.token);
                        }
                    } else {
                        keycloak.login({
                            redirectUri: refreshRedirectUri,
                        });
                    }
                })
                .catch((error) => {
                    keycloak.clearToken();
                    throw error;
                });
        };

        keycloak.onAuthSuccess = () => {
            if (keycloak.subject !== undefined && keycloak.token !== undefined) {
                setAuthToken(keycloak.token);
                getCurrentUserFx(keycloak.subject);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (userLoadingError !== null) {
        return (
            <AppBlockingError
                title={t('ERROR.USER_LOADING_ERROR.TITLE')}
                description={t('ERROR.USER_LOADING_ERROR.DESCRIPTION')}
            />
        );
    }

    if (!ready || user === null) {
        return <Overlay isActive={true} hasSpinner={true} />;
    }

    //for valid styled-components transient props ($prefix) from v5
    const shouldForwardProp = (propName: string, target: WebTarget) => {
        if (typeof target === 'string') {
            return isPropValid(propName);
        }
        return true;
    };

    return (
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <UniversalModal>
                <Helmet title={helmetRouteTitle} />
                <PageRouter permissions={permissions} />
            </UniversalModal>
            <UserActivityTracker timeoutSeconds={USER_INACTIVITY_TIMEOUT} onUserInactivity={keycloak.logout} />
        </StyleSheetManager>
    );
};

const QueryClientProviderWrapper = () => (
    <QueryClientProvider client={queryClient}>
        <AppComponent />
    </QueryClientProvider>
);

export const App = memo(QueryClientProviderWrapper);
