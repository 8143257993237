import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import { characteristicsListDomain } from '@admin/model/characteristics/characteristics.domains';
import {
    CharacteristicsState,
    CharacteristicsPayload,
} from '@admin/model/characteristics/characteristics/characteristics.types';

export const getCharacteristicsFx = characteristicsListDomain.createEffect(async (payload: CharacteristicsPayload) => {
    try {
        return await postRequest<CharacteristicsState, CharacteristicsPayload>(
            'catalog/productCharacteristics/search',
            payload,
        );
    } catch (error) {
        logger(error);

        throw error;
    }
});
