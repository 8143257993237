import { DictionaryData } from '@admin/model/model.types';
import { NotificationEventTypeDictionaryItem } from '@admin/model/notifications/notifications-events-types-dictionary/notifications-events-types-dictionary.types';

function parseData(data: DictionaryData): NotificationEventTypeDictionaryItem[] {
    return data.dictionaryValueList.reduce<NotificationEventTypeDictionaryItem[]>((acc, eventData) => {
        acc.push(JSON.parse(eventData.value) as NotificationEventTypeDictionaryItem);

        return acc;
    }, []);
}

export const getFilteredNotificationEventsTypesData = (data: DictionaryData, term?: string) => {
    const parsedData = parseData(data);

    if (!term) {
        return parsedData;
    }

    const regexp = new RegExp(term, 'i');

    return parsedData.filter((eventTypeItem) => regexp.test(eventTypeItem.name));
};
