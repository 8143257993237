import { getCookie, setCookie } from '@admin/common/helpers/cookie';

const REALM_COOKIE_NAME = 'realm';
const CLIENT_ID_COOKIE_NAME = 'clientId';
const AUTHENTICATION_HOST_COOKIE_NAME = 'authHost';
export const COOKIE_PROCESSING_AGREEMENT = 'cookie_processing_agreement';
export const COOKIE_PROCESSING_AGREEMENT_EXPIRATION_DATE = Date.now() + 30 * 24 * 3600 * 1000;

if (process.env.NODE_ENV === 'development') {
    setCookie(`${REALM_COOKIE_NAME}=vtbb-ulpm-gateway`);
    setCookie(`${CLIENT_ID_COOKIE_NAME}=employee`);
    setCookie(`${AUTHENTICATION_HOST_COOKIE_NAME}=${AUTH_API_URL}`);
}

const getCookieByName = (name: string): string => {
    const cookieValue = getCookie(name);

    return cookieValue !== null ? cookieValue : '';
};

export const REALM = getCookieByName(REALM_COOKIE_NAME);
export const CLIENT_ID = getCookieByName(CLIENT_ID_COOKIE_NAME);
export const AUTHENTICATION_HOST = getCookieByName(AUTHENTICATION_HOST_COOKIE_NAME);
export const PKCE_METHOD = 'S256';
