import { logger } from '@admin/common/helpers/logger';
import {
    CategoryChangeHistoryPayload,
    CategoryChangeHistoryState,
} from '@admin/model/categories/category-change-history/category-change-history.types';
import { postRequest } from '@admin/model/api';
import { ChangeHistoryData, ChangeHistoryItemData } from '@admin/model/model.types';
import { normalizeCategoryChangeHistoryData } from '@admin/model/categories/category-change-history/category-change-history.mapper';
import { createEffect } from 'effector';

export const getCategoryChangeHistoryFx = createEffect(async ({ id, ...rest }: CategoryChangeHistoryPayload) => {
    try {
        const data = await postRequest<
            ChangeHistoryData<ChangeHistoryItemData<CategoryChangeHistoryState>>,
            CategoryChangeHistoryPayload
        >(`category/${id}/history`, { ...rest });

        return normalizeCategoryChangeHistoryData(data);
    } catch (error) {
        logger(error);

        throw error;
    }
});
