import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { deleteRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';

export const deleteShortLinkFx = createEffect(async (id: string) => {
    try {
        await deleteRequest(`links/${id}`);
    } catch (error) {
        logger(error);

        setCommonAppError({ error });

        throw error;
    }
});
