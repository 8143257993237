import { sample } from 'effector';
import { getSegmentFileUploadHistoryFx } from '@admin/model/segments/file-upload-history/get-segment-file-upload-history.fx';
import {
    $segmentFileUploadHistoryData,
    $segmentFileUploadHistoryError,
} from '@admin/model/segments/file-upload-history/segment-file-upload-history.store';
import { normalizeFileUploadHistoryData } from '@admin/model/segments/file-upload-history/segment-file-upload-history.helpers';

sample({
    clock: getSegmentFileUploadHistoryFx.doneData,
    fn: (result) => normalizeFileUploadHistoryData(result),
    target: $segmentFileUploadHistoryData,
});

sample({
    clock: getSegmentFileUploadHistoryFx.failData,
    target: $segmentFileUploadHistoryError,
});
