import { DictionaryData } from '@admin/model/model.types';
import {
    SupplierPaymentOptionDictionary,
    SupplierPaymentOption,
} from '@admin/model/suppliers/supplier-payment-option-dictionary/supplier-payment-option-dictionary.types';

export function parseData(supplierFormConfigsData: DictionaryData): SupplierPaymentOptionDictionary {
    return supplierFormConfigsData.dictionaryValueList.reduce<SupplierPaymentOptionDictionary>(
        (acc, paymentOption) => {
            const option: SupplierPaymentOption = {
                value: paymentOption.key,
                title: paymentOption.value,
            };
            acc.dictionary[paymentOption.key] = option;
            acc.array.push(option);

            return acc;
        },
        {
            array: [],
            dictionary: {},
        },
    );
}
