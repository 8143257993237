import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { DeleteProductsPayload } from './delete-products.types';
import { setCommonAppError } from '@admin/model/error-notification/error-notification.store';
import { deleteRequest } from '@admin/model/api/api.resource';

export const deleteProductsFx = createEffect(async (payload: DeleteProductsPayload) => {
    try {
        await deleteRequest('products/batching', payload.productIds, {
            apiVersion: 2,
        });
    } catch (error) {
        logger(error);

        setCommonAppError({ error });

        throw null;
    }
});
