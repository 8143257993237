import { combine, createStore } from 'effector';
import { getSupplierPaymentOptionDictionaryFx } from '@admin/model/suppliers/supplier-payment-option-dictionary/get-supplier-payment-option-dictionary.fx';
import { SupplierPaymentOptionDictionary } from '@admin/model/suppliers/supplier-payment-option-dictionary/supplier-payment-option-dictionary.types';

export const $supplierPaymentOptionDictionaryData = createStore<Nullable<SupplierPaymentOptionDictionary>>(null);
export const $supplierPaymentOptionDictionaryError = createStore<Nullable<unknown>>(null);

export const $supplierPaymentOptionDictionary = combine({
    data: $supplierPaymentOptionDictionaryData,
    error: $supplierPaymentOptionDictionaryError,
    isLoading: getSupplierPaymentOptionDictionaryFx.pending,
});
