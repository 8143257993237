export enum HttpMethod {
    Post = 'POST',
    Get = 'GET',
    Put = 'PUT',
    Delete = 'DELETE',
}

export enum HttpStatusCode {
    Ok = 200,
    Created = 201,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    LargeEntityError = 413,
    InternalServerError = 500,
}

export enum PollStatus {
    Done = 'DONE',
    Error = 'ERROR',
    Processing = 'PROCESSING',
}
