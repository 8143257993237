import { createEffect } from 'effector';
import { putRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { UserData } from '@admin/model/users/user.types';
import { setCommonAppError } from '@admin/model/error-notification';

export const disableUser2FAFx = createEffect(async (id: string) => {
    try {
        await putRequest<UserData, {}>(`employees/${id}/disable-2fa`);
    } catch (error) {
        logger(error);

        setCommonAppError({ error });

        throw null;
    }
});
