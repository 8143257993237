import { CodeStatus, CouponType } from '@admin/model/offers/offers.types';

export interface CouponCodesPayload {
    offerId?: string;
    orderId?: string;
    term?: string;
    status?: CodeStatus[];
    ordering?: string;
    offset?: number;
    limit?: number;
    expireDateFrom?: string;
    expireDateTo?: string;
    createDateFrom?: string;
    createDateTo?: string;
}

export enum CodeField {
    Id = 'id',
    Code = 'code',
    ActivationCode = 'activationCode',
    CodeStatus = 'codeStatus',
    CouponType = 'couponType',
    CreatedDate = 'createdDate',
    ExpireDate = 'expireDate',
    IssuedDate = 'issuedDate',
    OrderId = 'orderId',
    DisplayOrderId = 'displayOrderId',
    DownloadUrl = 'downloadUrl',
    PartnerUrl = 'partnerUrl',
}

interface CodeData {
    [CodeField.Id]: string;
    [CodeField.Code]: string;
    [CodeField.ActivationCode]: string;
    [CodeField.CodeStatus]: CodeStatus;
    [CodeField.CouponType]: CouponType;
    [CodeField.CreatedDate]: string;
    [CodeField.ExpireDate]: string;
    [CodeField.IssuedDate]: Nullable<string>;
    [CodeField.OrderId]: string;
    [CodeField.DisplayOrderId]: string;
    [CodeField.DownloadUrl]: string;
    [CodeField.PartnerUrl]: string;
}

export interface Code extends Omit<CodeData, CodeField.CreatedDate | CodeField.IssuedDate | CodeField.ExpireDate> {
    [CodeField.CreatedDate]?: Date;
    [CodeField.IssuedDate]?: Date;
    [CodeField.ExpireDate]?: Date;
}

interface CouponCodeData {
    offerId: string;
    codeList: CodeData[];
}

interface CouponCode extends Omit<CouponCodeData, 'codeList'> {
    codeList: Code[];
}

export interface CouponCodesData {
    totalQuantity: number;
    availableQuantity: number;
    issuedQuantity: number;
    couponOffers: CouponCodeData[];
}

export interface CouponCodes extends Omit<CouponCodesData, 'couponOffers'> {
    couponOffers: CouponCode[];
}
