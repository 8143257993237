import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import {
    ReportRequestCreationPayload,
    ReportRequestData,
    CreateReportRequestErrorCode,
} from '@admin/model/reports/create-report-request/create-report-request.types';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { setCommonAppError } from '@admin/model/error-notification';

export const createReportRequestFx = createEffect(async (payload: ReportRequestCreationPayload) => {
    try {
        return await postRequest<ReportRequestData, ReportRequestCreationPayload>('reports', payload);
    } catch (error) {
        logger(error);
        if (isKnownValidationErrorResponse(error, CreateReportRequestErrorCode)) {
            throw error.data.validationErrors[0];
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
