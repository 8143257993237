import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { setCommonAppError } from '@admin/model';
import { CreateTransactionPayload } from '@admin/pages/clients-and-profiles/client/client-transactions/create/create-transaction.types';

export const createTransactionFx = createEffect(async (payload: CreateTransactionPayload) => {
    try {
        await postRequest('accounts/transactions/manual', payload);
        return true;
    } catch (error) {
        logger(error);
        setCommonAppError({ error });
        return false;
    }
});
