import { normalizeDate } from '@admin/model/helpers';
import { User, UserData } from '@admin/model/users/user.types';

export const normalizeUsersData = (data: UserData[]): User[] =>
    data.map((user) => ({
        ...user,
        roles: user.roles.map((role) => ({
            ...role,
            createdAt: normalizeDate(role.createdAt),
        })),
        registrationDate: normalizeDate(user.registrationDate),
        reports: user.reports.map((report) => report.reportCode),
    }));
