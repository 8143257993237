import { sample } from 'effector';
import { updateUserFx } from '@admin/model/users/update-user/update-user.fx';
import { getSuccessMessage } from '@admin/model/users/update-user/update-user.helpers';
import { showNoticeEvent } from '@admin/model/notices';

sample({
    clock: updateUserFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
