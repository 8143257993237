import { ValidationError, ValidationErrorData } from '@admin/common/validation/validation-error.types';

const getUnknownErrorCodes = (response: ValidationErrorData, knownCodes: Record<string, string>) => {
    const knownCodesValues = Object.values(knownCodes);

    const unknownErrors = response.validationErrors.filter(
        (validationError) => !knownCodesValues.includes(validationError.code),
    );

    return unknownErrors.map((error) => error.code);
};

export const getValidationErrorCodeByField = (validationErrors: ValidationError<string>[], field: string) =>
    validationErrors.find((validationError) => validationError.field === field)?.code;

export const isKnownValidationErrors = (response: ValidationErrorData, codes: Record<string, string>) =>
    getUnknownErrorCodes(response, codes).length === 0;
