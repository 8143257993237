import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { putRequest } from '@admin/model/api/api.resource';
import { DisableSubscriptionData } from '@admin/model/subscriptions/subscriptions.types';
import { setCommonAppError } from '@admin/model/error-notification/error-notification.store';

export const disableSubscriptionFx = createEffect(async (id: string) => {
    try {
        const result = await putRequest<DisableSubscriptionData, object>(`subscriptions/${id}/disable`);

        return result;
    } catch (error) {
        logger(error);

        setCommonAppError({ error });
        throw null;
    }
});
