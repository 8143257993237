import { LogService } from '@common/services/injector/injector';
import { Container, Inject, Service } from 'typedi';

@Service()
export class InjectorService {
    @Inject()
    public logService: LogService;
}

export const { logService } = Container.get(InjectorService);
