import { normalizeDate } from '@admin/model/helpers';
import { CouponCodes, CouponCodesData, CodeField } from '@admin/model/offers/coupon-codes/coupon-codes.types';

export const normalizeCouponCodeData = (data: CouponCodesData): CouponCodes => ({
    ...data,
    couponOffers: data.couponOffers.map(({ offerId, codeList }) => ({
        offerId,
        codeList: codeList.map((code) => ({
            ...code,
            createdDate: normalizeDate(code[CodeField.CreatedDate]),
            issuedDate: normalizeDate(code[CodeField.IssuedDate]),
            expireDate: normalizeDate(code[CodeField.ExpireDate]),
        })),
    })),
});
