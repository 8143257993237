import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import type {
    UploadCouponCodesHistoryPayload,
    UploadCouponCodesHistoryListData,
} from '@admin/model/offers/upload-coupon-codes-history/upload-coupon-codes-history.types';
import { normalizeUploadCouponCodesHistoryData } from '@admin/model/offers/upload-coupon-codes-history/upload-coupon-codes-history.helpers';
import { logger } from '@admin/common/helpers/logger';
import { setCommonAppError } from '@admin/model/error-notification';

export const getUploadCouponCodesHistoryFx = createEffect(async (payload: UploadCouponCodesHistoryPayload) => {
    try {
        const { codesFromFileUploads, totalCount } = await postRequest<
            UploadCouponCodesHistoryListData,
            UploadCouponCodesHistoryPayload
        >('coupons/uploads/status', payload);

        return {
            data: normalizeUploadCouponCodesHistoryData(codesFromFileUploads),
            totalCount,
        };
    } catch (error) {
        logger(error);
        setCommonAppError({ error });
        throw null;
    }
});
