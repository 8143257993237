import { transactionsDomain } from '@admin/model/model.domains';
import { logger } from '@admin/common/helpers/logger';
import { getRequest } from '@admin/model/api';
import { IconVariant } from 'ui-kit';
import {
    TransactionsStatisticPayload,
    TransactionsStatisticResponse,
} from '@admin/model/clients-and-profiles/transactions/transactions.types';
import { setCommonAppError } from '@admin/model/error-notification';

export const loadTransactionsStatisticFx = transactionsDomain.createEffect(
    async ({ profileId, ...payload }: TransactionsStatisticPayload) => {
        try {
            const { accrualAmount, burnedAmount, spentAmount } = await getRequest<TransactionsStatisticResponse>(
                `accounts/transactions/total/${profileId}`,
                payload,
            );

            return [
                { id: 1, icon: IconVariant.GoIn, type: 'TRANSACTION.ACCRUAL', value: accrualAmount },
                { id: 2, icon: IconVariant.GoOff, type: 'TRANSACTION.SPENDING', value: spentAmount },
                { id: 3, icon: IconVariant.Burn, type: 'TRANSACTION.BURN', value: burnedAmount },
            ];
        } catch (error) {
            logger(error);
            setCommonAppError({ error });
            throw error;
        }
    },
);
