import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { postRequest } from '@admin/model/api/api.resource';
import { setCommonAppError } from '@admin/model/error-notification/error-notification.store';
import {
    UpdateSupplierActivityPayload,
    SuppliersQuickActionsValidationErrorCode,
} from '@admin/model/suppliers/update-supplier-activity/update-supplier-activity.types';

export const updateSupplierActivityFx = createEffect(async (payload: UpdateSupplierActivityPayload) => {
    try {
        await postRequest('partners/batching', payload);

        return payload;
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, SuppliersQuickActionsValidationErrorCode)) {
            throw error.data.validationErrors[0];
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
