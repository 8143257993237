import { sample } from 'effector';
import { $userData, $userError } from '@admin/model/users/user/user.store';
import { normalizeUserData } from '@admin/model/users/user/user.helpers';
import { getUserFx } from '@admin/model/users/user/get-user.fx';

sample({
    clock: getUserFx.doneData,
    fn: (result) => normalizeUserData(result),
    target: $userData,
});

sample({
    clock: getUserFx.failData,
    target: $userError,
});
