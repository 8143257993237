import { sample } from 'effector';
import { updateSupplierActivityFx } from '@admin/model/suppliers/update-supplier-activity/update-supplier-activity.fx';
import { getSuppliersListFx } from '@admin/model/suppliers/suppliers-list/get-suppliers-list.fx';
import { getSuccessMessage } from '@admin/model/suppliers/update-supplier-activity/update-supplier-activity.helpers';
import { showNoticeEvent } from '@admin/model/notices/notices.store';

sample({
    clock: updateSupplierActivityFx.done,
    source: getSuppliersListFx,
    target: getSuppliersListFx,
});

sample({
    clock: updateSupplierActivityFx.doneData,
    fn: (payload) => getSuccessMessage(payload.suppliersIds),
    target: showNoticeEvent,
});
