import { createEffect } from 'effector';
import { DictionaryData } from '@admin/model';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';

export const getDeactivateProgramLoyaltyReasonsFx = createEffect(async () => {
    try {
        return await Promise.allSettled([
            getRequest<DictionaryData>(`dictionaries/client_deactivation_reason_b2e`),
            getRequest<DictionaryData>(`dictionaries/client_deactivation_reason_b2c`),
        ]);
    } catch (error) {
        logger(error);

        throw error;
    }
});
