import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { deleteRequest } from '@admin/model/api/api.resource';
import { setCommonAppError } from '@admin/model/error-notification/error-notification.store';

export const disableSyncingProductsFx = createEffect(async (ids: string[]) => {
    try {
        await deleteRequest('products/synchronized', ids, {
            apiVersion: 2,
        });
    } catch (error) {
        logger(error);
        setCommonAppError({ error });

        throw null;
    }
});
