import { sample } from 'effector';
import { updateCategoryPositionFx } from '@admin/model/categories/update-category-position/update-category-position.fx';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { getSuccessMessage } from '@admin/model/categories/update-category-position/update-category-position.helpers';

sample({
    clock: updateCategoryPositionFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
