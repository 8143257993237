import { combine, createEvent, createStore } from 'effector';
import { DeactivateProgramLoyaltyReason } from '@admin/model/clients-and-profiles/deactivate-program-loyalty-reasons/deactivate-program-loyalty-reasons.types';
import { getDeactivateProgramLoyaltyReasonsFx } from '@admin/model/clients-and-profiles/deactivate-program-loyalty-reasons/get-deactivate-program-loyalty-reasons.fx';

export const $deactivateProgramLoyaltyReasonsData = createStore<
    Record<'b2e' | 'b2c', DeactivateProgramLoyaltyReason[]>
>({ b2e: [], b2c: [] });
export const $deactivateProgramLoyaltyReasonsError = createStore<Record<'b2e' | 'b2c', Nullable<unknown>>>({
    b2e: null,
    b2c: null,
});

export const resetDeactivateProgramLoyaltyReasonsDataEvent = createEvent();
export const resetDeactivateProgramLoyaltyReasonsErrorEvent = createEvent();

export const $deactivateProgramLoyaltyReasons = combine({
    data: $deactivateProgramLoyaltyReasonsData,
    error: $deactivateProgramLoyaltyReasonsError,
    isLoading: getDeactivateProgramLoyaltyReasonsFx.pending,
});
