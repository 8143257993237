import { Currency, ProgramLoyalty } from '@admin/model/model.types';

export const enum TransactionType {
    Accrual = 'ACCRUAL',
    Spending = 'SPENDING',
    Burn = 'BURN',
}

export const enum TransactionStatus {
    REGISTERED = 'REGISTERED',
    PENDING = 'PENDING',
    PROCESSING = 'PROCESSING',
    ERROR = 'ERROR',
    CANCELED = 'CANCELED',
}

export interface TransactionsStatisticPayload {
    profileId: string;
    dateFrom?: string;
    dateTo?: string;
    loyaltyProgram?: string[];
}

export interface TransactionsPayload {
    profileId: string;
    loyaltyProgram?: string[];
    dateFrom?: string;
    dateTo?: string;
    operationType?: TransactionType[];
    limit?: number;
    offset?: number;
    includeHistory?: boolean;
}

export interface TransactionData<T = Date> {
    transactionDate: T;
    loyaltyProgram: ProgramLoyalty;
    transactionDescription: string;
    transactionAmount: number;
    orderId: string;
    cardMask: string;
    transactionStatus: TransactionStatus;
    transactionType: TransactionType;
    transactionCurrency: Currency;
    balanceAfter: Nullable<number>;
}

export interface TransactionsData<T> {
    totalCount: number;
    transactions: TransactionData<T>[];
}

export interface TransactionsStatisticResponse {
    accrualAmount: number;
    spentAmount: number;
    burnedAmount: number;
}
