import { CostMatrixField } from '@admin/model/cost-matrix/cost-matrix.types';
import { ChangeHistoryData, ChangeHistoryItemData } from '@admin/model/model.types';

export interface CostMatrixChangeHistoryPayload {
    id?: string;
    limit?: number;
    offset?: number;
    ordering?: string;
    startAt?: string;
    endAt?: string;
}

export interface CostMatrixChangeHistoryState {
    [CostMatrixField.Active]?: Nullable<boolean>;
    [CostMatrixField.MinimumWeight]?: Nullable<number>;
    [CostMatrixField.MaximumWeight]?: Nullable<number>;
    [CostMatrixField.Cost]?: Nullable<number>;
    [CostMatrixField.Period]?: Nullable<number>;
}

export enum CostMatrixChangeHistoryField {
    DestinationAddress = 'destinationAddress',
    MaximumWeight = 'maximumWeight',
    MinimumWeight = 'minimumWeight',
}

export interface CostMatrixChangeHistoryItemData extends ChangeHistoryItemData<CostMatrixChangeHistoryState> {
    [CostMatrixChangeHistoryField.DestinationAddress]: string;
    [CostMatrixChangeHistoryField.MaximumWeight]: number;
    [CostMatrixChangeHistoryField.MinimumWeight]: number;
}

export interface CostMatrixChangeHistoryItem extends Omit<CostMatrixChangeHistoryItemData, 'changedAt'> {
    changedAt: Date;
}

export interface CostMatrixChangeHistory
    extends Omit<ChangeHistoryData<CostMatrixChangeHistoryItemData>, 'createdAt' | 'data'> {
    createdAt: Date;
    data: CostMatrixChangeHistoryItem[];
}
