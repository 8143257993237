import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { editRoleFx } from './edit-role.fx';
import { getEditSuccessMessage } from '../roles.helpers';

sample({
    clock: editRoleFx.doneData,
    fn: getEditSuccessMessage,
    target: showNoticeEvent,
});
