import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { createOfferPartnerFx } from '@admin/model/offer-partners/create-offer-partner/create-offer-partner.fx';
import { getSuccessMessage } from '@admin/model/offer-partners/create-offer-partner/create-offer-partner.helpers';

sample({
    clock: createOfferPartnerFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
