import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { ShortLinkData } from '@admin/model/short-links/short-links.types';

export const getShortLinkFx = createEffect(async (id: string) => {
    try {
        return await getRequest<ShortLinkData>(`links/${id}`);
    } catch (error) {
        logger(error);

        throw error;
    }
});
