import { combine, createEvent, createStore } from 'effector';
import { updateNotificationBatchingFx } from '@admin/model/notifications/update-notification-batching/update-notification-batching.fx';

export const resetUpdateNotificationStateEvent = createEvent();
export const $updateNotificationData = createStore<Nullable<boolean>>(null);
export const $updateNotificationError = createStore<Nullable<unknown>>(null);

export const $updateNotification = combine({
    data: $updateNotificationData,
    error: $updateNotificationError,
    isLoading: updateNotificationBatchingFx.pending,
});
