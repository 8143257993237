import { logger } from '@admin/common/helpers/logger';
import { getRequest } from '@admin/model/api';
import { createEffect } from 'effector';
import { CostMatrixPayload, MatrixesData } from '@admin/model/cost-matrix/cost-matrix-list/cost-matrix-list.types';
import { normalizeCostMatrixListData } from '@admin/model/cost-matrix/cost-matrix-list/cost-matrix-list.helpers';

export const getCostMatrixListFx = createEffect(async ({ queryParams, deliveryPartnerId }: CostMatrixPayload) => {
    try {
        const data = await getRequest<MatrixesData>(`delivery/matrices/${deliveryPartnerId}`, queryParams);

        return normalizeCostMatrixListData(data);
    } catch (error) {
        logger(error);

        throw error;
    }
});
