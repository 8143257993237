import { combine, createStore } from 'effector';
import { getSegmentListFx } from '@admin/model/segments/segment-list/get-segment-list.fx';
import { SegmentWithValue } from '@admin/model/segments/segments.types';

export const $segmentsData = createStore<Nullable<SegmentWithValue[]>>(null);
export const $segmentsError = createStore<Nullable<unknown>>(null);

export const $segments = combine({
    data: $segmentsData,
    error: $segmentsError,
    isLoading: getSegmentListFx.pending,
});
