import { sample } from 'effector';
import { editNotificationFx } from '@admin/model/notifications/edit-notification/edit-notification.fx';
import { getSuccessMessage } from '@admin/model/notifications/edit-notification/edit-notification.helpers';
import { showNoticeEvent } from '@admin/model/notices/notices.store';

sample({
    clock: editNotificationFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
