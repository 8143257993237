import { createEffect } from 'effector';
import { putRequest, deleteRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { CollectionVisibilityPayload } from '@admin/model/collections/collections-visibility/collections-visibility.types';
import { Category } from '@admin/model/categories/category/category.types';
import { setCommonAppError } from '@admin/model/error-notification/error-notification.store';

export const changeCollectionVisibilityFx = createEffect(async ({ id, isHidden }: CollectionVisibilityPayload) => {
    try {
        const method = isHidden ? putRequest : deleteRequest;
        const data = await method<Category, {}>(`catalog/categories/${id}/hidden`, {});

        return data;
    } catch (error) {
        logger(error);

        setCommonAppError({ error });

        throw null;
    }
});
