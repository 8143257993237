import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { OrderDetailsResponse } from '@admin/model/orders/order/order.types';

export const getOrderFx = createEffect(async (id: string) => {
    try {
        return await getRequest<OrderDetailsResponse>(`orders/${id}/common`);
    } catch (error) {
        logger(error);

        throw error;
    }
});
