import { combine, createStore } from 'effector';
import { getSupplierFormConfigsFx } from '@admin/model/suppliers/supplier-form-configs/get-supplier-form-configs.fx';
import { SupplierFormConfigs } from '@admin/model/suppliers/supplier-form-configs/supplier-form-configs.types';

export const $supplierFormConfigsData = createStore<Nullable<SupplierFormConfigs>>(null);
export const $supplierFormConfigsError = createStore<Nullable<unknown>>(null);

export const $supplierFormConfigs = combine({
    data: $supplierFormConfigsData,
    error: $supplierFormConfigsError,
    isLoading: getSupplierFormConfigsFx.pending,
});
