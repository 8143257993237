import { createEffect } from 'effector';
import { putRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { CollectionValidationErrorCode } from '@admin/model/collections/collections.types';
import { isKnownValidationErrorResponse } from '@admin/common/validation/validation-error.types';
import { setCommonAppError } from '@admin/model/error-notification/error-notification.store';
import { EditCollectionPayload } from '@admin/model/collections/edit-collection/edit-collection.types';

export const editCollectionFx = createEffect(async ({ collectionId, formData }: EditCollectionPayload) => {
    try {
        await putRequest(`catalog/categories/${collectionId}`, formData);
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, CollectionValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
