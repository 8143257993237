import { ValidationError } from '@admin/common/validation/validation-error.types';

export interface DeleteProductCharacteristicsPayload {
    productIds: string[];
    characteristicsIds: string[];
}

export enum DeleteProductCharacteristicsValidationErrorCode {
    InheritedCharacteristicsDeleteForbidden = 'INHERITED_CHARACTERISTIC_DELETE_FORBIDDEN',
}

export type DeleteProductCharacteristicsValidationError =
    ValidationError<DeleteProductCharacteristicsValidationErrorCode>;
