type PaymentPolicyValues = 'month1';

export enum SubscriptionActivityStatus {
    Closed = 'Closed',
    Active = 'Active',
}

export enum OfferFields {
    OfferAttributes = 'offerAttributes',
    OfferName = 'offerName',
    OfferPrice = 'offerPrice',
    Supplier = 'supplier',
}

export enum OfferAttributesFields {
    ActionRules = 'action_rules',
    AutoPayment = 'auto_payment',
    DeliverySettings = 'delivery_settings',
    MaxPrice = 'max_price',
    MinPrice = 'min_price',
    ServiceParameters = 'service_parameters',
}

export enum SubscriptionCommonField {
    Id = 'id',
    Offer = 'offer',
    ContractNumber = 'contractNumber',
    Phone = 'phone',
    Status = 'status',
    Cost = 'cost',
    PaymentPolicyCode = 'paymentPolicyCode',
    CreatedAt = 'createdAt',
}

export enum SubscriptionCardField {
    ClientId = 'clientId',
    NextPaymentDateTime = 'nextPaymentDateTime',
    UpdatedAt = 'updatedAt',
    Payments = 'payments',
}

export enum SubscriptionsPayloadField {
    Offset = 'offset',
    Limit = 'limit',
    Ordering = 'ordering',
    StartAt = 'startAt',
    EndAt = 'endAt',
    Term = 'term',
    SupplierId = 'supplierId',
    Statuses = 'statuses',
    ClientId = 'clientId',
}

export enum AutopaymentsField {
    Id = 'id',
    CreatedAt = 'createdAt',
    ExternalOrderId = 'externalOrderId',
    Status = 'status',
    Amount = 'amount',
}

export enum AutopaymentStatus {
    Create = 'Create',
    Send = 'Send',
    Success = 'Success',
    Failed = 'Failed',
}

export interface ServiceParameter {
    code: string;
    name: string;
    type: 'number' | 'string';
    hint: string;
    required: boolean;
    mask: string;
}

export interface ActionRules {
    id: number;
    image: {
        id: number;
        url: string;
    };
}

interface Supplier {
    id: number;
    name: Nullable<string>;
}

interface OfferAttributesCommon<ServiceParameters> {
    [OfferAttributesFields.ActionRules]: string;
    [OfferAttributesFields.AutoPayment]: string;
    [OfferAttributesFields.DeliverySettings]: string;
    [OfferAttributesFields.MaxPrice]: string;
    [OfferAttributesFields.MinPrice]: string;
    [OfferAttributesFields.ServiceParameters]: ServiceParameters;
}

export type OfferAttributesData = OfferAttributesCommon<string>;
export type OfferAttributes = OfferAttributesCommon<ServiceParameter[]>;
export interface OfferData {
    [OfferFields.OfferAttributes]: OfferAttributesData;
    [OfferFields.OfferName]: string;
    [OfferFields.OfferPrice]: Nullable<number>;
    [OfferFields.Supplier]: Nullable<Supplier>;
}

export interface Offer {
    [OfferFields.OfferAttributes]: OfferAttributes;
    [OfferFields.OfferName]: string;
    [OfferFields.OfferPrice]: Nullable<number>;
    [OfferFields.Supplier]: Nullable<Supplier>;
}

export interface SubscriptionsPayload {
    [SubscriptionsPayloadField.Offset]: number;
    [SubscriptionsPayloadField.Limit]: number;
    [SubscriptionsPayloadField.Ordering]?: string;
    [SubscriptionsPayloadField.StartAt]?: string;
    [SubscriptionsPayloadField.EndAt]?: string;
    [SubscriptionsPayloadField.Term]?: string;
    [SubscriptionsPayloadField.SupplierId]?: number;
    [SubscriptionsPayloadField.Statuses]?: string[];
    [SubscriptionsPayloadField.ClientId]?: string;
}

interface SubscriptionListItemCommon {
    [SubscriptionCommonField.Id]: Nullable<string>;
    [SubscriptionCommonField.Phone]: Nullable<string>;
    [SubscriptionCommonField.ContractNumber]: Nullable<string>;
    [SubscriptionCommonField.Status]: Nullable<SubscriptionActivityStatus>;
    [SubscriptionCommonField.Cost]: Nullable<number>;
    [SubscriptionCommonField.PaymentPolicyCode]: Nullable<PaymentPolicyValues>;
}

export interface SubscriptionListItemData extends SubscriptionListItemCommon {
    [SubscriptionCommonField.CreatedAt]: Nullable<string>;
    [SubscriptionCommonField.Offer]: Nullable<OfferData>;
}

export interface SubscriptionListItem extends SubscriptionListItemCommon {
    [SubscriptionCommonField.CreatedAt]?: Date;
    [SubscriptionCommonField.Offer]: Nullable<Offer>;
}

export interface SubscriptionPaymentsData {
    [AutopaymentsField.CreatedAt]: Nullable<string>;
    [AutopaymentsField.ExternalOrderId]: Nullable<number>;
    [AutopaymentsField.Status]: Nullable<AutopaymentStatus>;
    [AutopaymentsField.Amount]: Nullable<number>;
}

export interface SubscriptionPayments extends Omit<SubscriptionPaymentsData, AutopaymentsField.CreatedAt> {
    [AutopaymentsField.CreatedAt]?: Date;
    [AutopaymentsField.Id]: string;
}

export interface SubscriptionData extends SubscriptionListItemData {
    [SubscriptionCardField.ClientId]: Nullable<string>;
    [SubscriptionCardField.NextPaymentDateTime]: Nullable<string>;
    [SubscriptionCardField.UpdatedAt]: Nullable<string>;
    [SubscriptionCardField.Payments]: SubscriptionPaymentsData[];
}

export interface Subscription extends SubscriptionListItem {
    [SubscriptionCardField.ClientId]: Nullable<string>;
    [SubscriptionCardField.NextPaymentDateTime]?: Date;
    [SubscriptionCardField.UpdatedAt]?: Date;
    [SubscriptionCardField.Payments]: SubscriptionPayments[];
}

export interface DisableSubscriptionData {
    updatedAt: Nullable<string>;
    status: Nullable<SubscriptionActivityStatus>;
}
