import React, { createElement, memo, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { PageName } from '@admin/pages/page-name';
import { getPageUrl } from '@admin/common/pages/router-config';
import { useLocation } from 'react-router';
import { $currentRoute, setCurrentRouteEvent } from '@admin/model/routing/routing.store';
import { useUnit } from 'effector-react';
import { PagePath } from '@admin/pages/page-path';

interface PageRouteComponentProps {
    name: PageName;
    path?: string;
    component?: React.ComponentType;
    hasPermission?: boolean;
    redirectTo?: PageName;
    title?: string;
}

const PageRouteComponent = (props: PageRouteComponentProps) => {
    const { name, component, redirectTo, hasPermission, path, title } = props;

    const location = useLocation();

    const currentRoute = useUnit($currentRoute);
    const setCurrentRoute = useUnit(setCurrentRouteEvent);

    const currentPath = path ?? PagePath[name];

    useEffect(() => {
        if (title && currentRoute?.path !== currentPath) {
            setCurrentRoute({
                name,
                title,
                path: currentPath,
            });
        }
    }, [setCurrentRoute, name, path, title, currentRoute?.title, currentRoute?.path, currentPath]);

    const redirectUrl = redirectTo && getPageUrl(redirectTo);

    if (redirectUrl && (location.pathname === '/' || location.pathname === getPageUrl(name))) {
        return <Navigate to={redirectUrl} replace />;
    }

    if (hasPermission === false) {
        return <Navigate to={getPageUrl(PageName.CATEGORIES_MAIN)} replace state={{ from: name }} />;
    }

    return component ? createElement(component) : null;
};

export const PageRoute = memo(PageRouteComponent);
