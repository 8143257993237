import { logger } from '@admin/common/helpers/logger';
import { getRequest, pollRequest, putRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { createEffect } from 'effector';
import { UpdateSupplierPayload } from '@admin/model/suppliers/update-supplier/update-supplier.types';
import { RequestWithPollingResponse } from '@admin/model/model.types';
import { Supplier, SupplierFormValidationErrorCode } from '@admin/model/suppliers/suppliers.types';

export const updateSupplierFx = createEffect(async ({ supplierId, formData }: UpdateSupplierPayload) => {
    try {
        const data = await putRequest<RequestWithPollingResponse, FormData>(`partners/${supplierId}`, formData);

        return await pollRequest<Supplier>(() => getRequest(`partners/processing/${data.processId}`));
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, SupplierFormValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
