import { createEvent, createStore } from 'effector';

export interface CommonAppErrorPayload {
    error: unknown;
    errorTextCode?: string;
    errorTextValues?: Record<string, string>;
}

export const setCommonAppError = createEvent<CommonAppErrorPayload>();
export const resetCommonAppError = createEvent();

export const $commonAppErrorStore = createStore<Nullable<CommonAppErrorPayload>>(null).on(
    setCommonAppError,
    (_state, payload) => payload,
);

$commonAppErrorStore.reset([resetCommonAppError]);
