import { combine, restore } from 'effector';
import { getPaymentStatusesDictionaryFx } from '@admin/model/orders/payment-statuses-dictionary/get-payment-statuses-dictionary.fx';

export const $paymentStatusDictionaryData = restore(getPaymentStatusesDictionaryFx, null);

export const $paymentStatusDictionaryError = restore(getPaymentStatusesDictionaryFx.failData, null).on(
    getPaymentStatusesDictionaryFx.doneData,
    () => null,
);

export const $paymentStatusDictionary = combine({
    data: $paymentStatusDictionaryData,
    error: $paymentStatusDictionaryError,
    isLoading: getPaymentStatusesDictionaryFx.pending,
});
