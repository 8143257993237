import { ChangeHistory, ChangeHistoryData, ChangeHistoryItemData } from '@admin/model/model.types';
import { normalizeDate } from '@admin/model/helpers';
import { CategoryChangeHistoryState } from '@admin/model/categories/category-change-history/category-change-history.types';

export const normalizeCategoryChangeHistoryData = (
    response: ChangeHistoryData<ChangeHistoryItemData<CategoryChangeHistoryState>>,
): ChangeHistory<CategoryChangeHistoryState> => ({
    ...response,
    createdAt: normalizeDate(response.createdAt) as Date,
    data: response.data.map((dataItem) => ({ ...dataItem, changedAt: normalizeDate(dataItem.changedAt) as Date })),
});
