import { sample } from 'effector';
import { getOrderPaymentsFx } from '@admin/model/orders/order-payments/get-order-payments.fx';
import { $orderPaymentsData, $orderPaymentsError } from '@admin/model/orders/order-payments/order-payments.store';
import { normalizeOrderPaymentsData } from '@admin/model/orders/order-payments/order-payments.helpers';

sample({
    clock: getOrderPaymentsFx.doneData,
    fn: (result) => normalizeOrderPaymentsData(result),
    target: $orderPaymentsData,
});

sample({
    clock: getOrderPaymentsFx.failData,
    target: $orderPaymentsError,
});
