import { CreateProductPayload } from '@admin/model/products/create-product/create-product.types';
import i18n from 'i18next';

export const getSuccessMessage = () => i18n.t('NOTICES.CATALOG_AND_ORDERS.PRODUCTS.CREATE_PRODUCT');

export const getCreateProductPayload = ({ images, ...rest }: CreateProductPayload): FormData => {
    const formData = new FormData();
    formData.append('data', JSON.stringify(rest));

    if (images) {
        for (const image of images) {
            formData.append('images', image, image.name);
        }
    }

    return formData;
};
