import { createEffect } from 'effector';
import { putRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { setCommonAppError } from '@admin/model/error-notification';
import { UpdateUsersRolesPayload } from '@admin/model/users/update-users-roles/update-users-roles.types';
import { UserData } from '@admin/model/users/user.types';

export const updateUsersRolesFx = createEffect(async (payload: UpdateUsersRolesPayload) => {
    try {
        await putRequest<UserData[], UpdateUsersRolesPayload>('employees/batch/roles', payload);

        return payload.roleNames;
    } catch (error) {
        logger(error);

        setCommonAppError({ error });

        throw null;
    }
});
