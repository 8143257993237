import { Log } from '@common/services/log/log';
import { Service } from 'typedi';

@Service()
export class TemporaryLogService implements Log {
    public debug(...messages: string[]) {
        console.debug(...messages);
    }

    public info(...messages: string[]) {
        console.info(...messages);
    }

    public warn(...messages: string[]) {
        console.warn(...messages);
    }

    public error(...messages: string[]) {
        console.error(...messages);
    }
}
