import { sample } from 'effector';
import { getOrderFx } from '@admin/model/orders/order/get-order.fx';
import { $orderData, $orderError } from '@admin/model/orders/order/order.store';
import { normalizeDate } from '@admin/model/helpers/normalize-date';

sample({
    clock: getOrderFx.doneData,
    fn: (result) => ({
        ...result,
        createdAt: normalizeDate(result?.createdAt),
    }),
    target: $orderData,
});

sample({
    clock: getOrderFx.failData,
    target: $orderError,
});
