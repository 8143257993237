import { combine, createEvent, createStore } from 'effector';
import { getSuppliersListFx } from '@admin/model/suppliers/suppliers-list/get-suppliers-list.fx';
import { SuppliersData } from '@admin/model/suppliers/suppliers.types';

export const resetSuppliersListEvent = createEvent();

export const $suppliersListData = createStore<SuppliersData>({ data: [], totalCount: 0 });
export const $suppliersListError = createStore<Nullable<unknown>>(null);

export const $suppliesList = combine({
    data: $suppliersListData,
    error: $suppliersListError,
    isLoading: getSuppliersListFx.pending,
});
