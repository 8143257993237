import { logger } from '@admin/common/helpers/logger';
import { setCommonAppError } from '@admin/model/error-notification';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import {
    UpdateCategoriesStatusPayload,
    UpdateCategoriesStatusValidationErrorCode,
} from '@admin/model/categories/update-categories-status/update-categories-status.types';
import { postRequest } from '@admin/model/api';
import { createEffect } from 'effector';

export const updateCategoriesStatusFx = createEffect(async (payload: UpdateCategoriesStatusPayload) => {
    const { status, categoryIds } = payload;

    try {
        await postRequest('catalog/categories/batching', { categoryIds, status });

        return payload;
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, UpdateCategoriesStatusValidationErrorCode)) {
            throw error.data.validationErrors[0];
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
