import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { setCommonAppError } from '@admin/model/error-notification';
import { ShortLinkPayload, ShortLinkValidationErrorCode } from '@admin/model/short-links/short-links.types';

export const createShortLinkFx = createEffect(async (data: ShortLinkPayload) => {
    try {
        await postRequest('links/link', data);
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, ShortLinkValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
