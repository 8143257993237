import { combine, createEvent, createStore } from 'effector';
import { updateCategoriesCurrencyFx } from '@admin/model/categories/update-categories-currency/update-categories-currency.fx';

export const resetUpdateCategoriesCurrencyDoneStatusEvent = createEvent();
export const resetUpdateCategoriesCurrencyErrorEvent = createEvent();

export const $updateCategoriesCurrencyDoneStatus = createStore<boolean>(false);
export const $updateCategoriesCurrencyError = createStore<Nullable<unknown>>(null);

export const $updateCategoriesCurrency = combine({
    error: $updateCategoriesCurrencyError,
    isCurrencyBatchingDone: $updateCategoriesCurrencyDoneStatus,
    isLoading: updateCategoriesCurrencyFx.pending,
});
