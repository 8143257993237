import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { createEffect } from 'effector';
import { isLargeEntityError } from '@admin/model/api/api.resource';
import {
    UploadFileSegmentValidationErrorCode,
    UploadFileSegmentValidationError,
} from '@admin/model/segments/upload-file-segment/upload-file-segments.types';

export const uploadFileSegmentFx = createEffect(async (payload: FormData) => {
    try {
        await postRequest('segments/uploads', payload);
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, UploadFileSegmentValidationErrorCode)) {
            throw error.data.validationErrors;
        } else if (isLargeEntityError(error)) {
            // Supports error returned by gateway server configuration
            throw [
                { code: UploadFileSegmentValidationErrorCode.SegmentFileSizeLimit } as UploadFileSegmentValidationError,
            ];
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
