import { combine, createStore } from 'effector';
import { getShortLinkFx } from '@admin/model/short-links/short-link/get-short-link.fx';
import { ShortLink } from '@admin/model/short-links/short-links.types';

export const $shortLinkData = createStore<Nullable<ShortLink>>(null);
export const $shortLinkError = createStore<Nullable<unknown>>(null);

export const $shortLink = combine({
    data: $shortLinkData,
    error: $shortLinkError,
    isLoading: getShortLinkFx.pending,
});
