import { combine, createEvent, createStore } from 'effector';
import { clientEnableAccessFx } from '@admin/model/clients-and-profiles/client-enable-access/client-enable-access.fx';

export const $clientEnableAccessError = createStore<Nullable<unknown>>(null);
export const $clientEnableAccessDone = createStore<boolean>(false);

export const resetClientEnableAccessErrorEvent = createEvent();

export const $clientEnableAccess = combine({
    data: $clientEnableAccessDone,
    error: $clientEnableAccessError,
    isLoading: clientEnableAccessFx.pending,
});
