import { createEffect } from 'effector';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { postRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { isLargeEntityError } from '@admin/model/api/api.resource';
import { UploadCouponCodesValidationErrorCode } from '@admin/model/offers/upload-coupon-codes/upload-coupon-codes.types';
import type {
    UploadCouponCodeData,
    UploadCouponCodesValidationError,
} from '@admin/model/offers/upload-coupon-codes/upload-coupon-codes.types';

export const uploadCouponCodesFx = createEffect(async (payload: FormData) => {
    try {
        const { uploadId } = await postRequest<UploadCouponCodeData, FormData>('coupons/uploads', payload);

        return { uploadId };
    } catch (error) {
        if (isKnownValidationErrorResponse(error, UploadCouponCodesValidationErrorCode)) {
            throw error.data.validationErrors[0];
        } else if (isLargeEntityError(error)) {
            throw {
                code: UploadCouponCodesValidationErrorCode.ListFileSizeLimit,
            } as UploadCouponCodesValidationError;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
