import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import { ChangeHistoryData, ChangeHistoryItemData } from '@admin/model/model.types';
import {
    NotificationChangeHistoryState,
    NotificationChangeHistoryPayload,
} from '@admin/model/notifications/notification-change-history/notification-change-history.types';
import { normalizeNotificationChangeHistoryData } from '@admin/model/notifications/notification-change-history/notification-change-history.helpers';

export const getNotificationChangeHistoryListFx = createEffect(
    async ({ id, ...rest }: NotificationChangeHistoryPayload) => {
        try {
            const data = await postRequest<
                ChangeHistoryData<ChangeHistoryItemData<NotificationChangeHistoryState>>,
                NotificationChangeHistoryPayload
            >(`configuration/${id}/history`, { ...rest });

            return normalizeNotificationChangeHistoryData(data);
        } catch (error) {
            logger(error);

            throw error;
        }
    },
);
