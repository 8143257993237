import { logger } from '@admin/common/helpers/logger';
import { putRequest } from '@admin/model/api';
import { createEffect } from 'effector';
import { ChangeCouponCodesStatusPayload } from './change-coupon-codes-status.types';

export const changeCouponCodesStatusFx = createEffect(async (payload: ChangeCouponCodesStatusPayload) => {
    try {
        await putRequest<unknown, ChangeCouponCodesStatusPayload>('coupons/status-change', payload);
    } catch (error) {
        logger(error);

        throw error;
    }
});
