import { ValidationError } from '@admin/common/validation';

export interface UploadAccrualsRequestsData {
    uploadId: string;
}

export enum UploadAccrualsRequestsError {
    ListFormatNotValid = 'LIST_FORMAT_NOT_VALID',
    FileSizeLimit = 'FILE_SIZE_LIMIT',
    FileNameNotValid = 'FILE_NAME_NOT_VALID',
    FileNameNotUnique = 'FILE_NAME_NOT_UNIQUE',
    Unknown = 'UNKNOWN_ERROR',
}

export type UploadValidationError = ValidationError<UploadAccrualsRequestsError>;
