import { getRequest, pollRequest, postRequest } from '@admin/model/api';
import { RequestWithPollingResponse } from '@admin/model/model.types';
import { ClientData, setCommonAppError } from '@admin/model';
import { logger } from '@admin/common/helpers/logger';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { createEffect } from 'effector';
import {
    ChangeClientPhonePayload,
    ClientPhoneValidationErrorCode,
} from '@admin/model/clients-and-profiles/client-phone/client-phone.types';

export const changeClientPhoneFx = createEffect(
    async ({ clientId, phone, resetOtherClientPhone = false }: ChangeClientPhonePayload) => {
        try {
            const response = await postRequest<RequestWithPollingResponse, Omit<ChangeClientPhonePayload, 'clientId'>>(
                `clients/${clientId}/phone`,
                {
                    phone,
                    resetOtherClientPhone,
                },
            );

            await pollRequest<ClientData>(() => getRequest(`clients/processing/${response.processId}`));

            return true;
        } catch (error) {
            logger(error);

            if (isKnownValidationErrorResponse(error, ClientPhoneValidationErrorCode)) {
                throw error.data.validationErrors[0];
            } else {
                setCommonAppError({ error });

                throw null;
            }
        }
    },
);
