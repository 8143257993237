import { logger } from '@admin/common/helpers/logger';
import { getRequest } from '@admin/model/api';
import { createEffect } from 'effector';
import { HashAccountsData } from './client-hash.types';

export const getClientHashAccountsFx = createEffect(async (payload: string) => {
    try {
        const result = await getRequest<HashAccountsData>(`clients/${payload}`);
        return result.profiles;
    } catch (error) {
        logger(error);

        throw error;
    }
});
