import { DictionaryData } from '@admin/model/model.types';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { setCommonAppError } from '@admin/model/error-notification';
import { parseData } from '@admin/model/characteristics/characteristics-types-dictionary/characteristics-types-dictionary.helpers';
import { characteristicsTypesDictionaryDomain } from '@admin/model/characteristics/characteristics.domains';

export const getCharacteristicsTypesDictionaryFx = characteristicsTypesDictionaryDomain.createEffect(async () => {
    try {
        const data = await getRequest<DictionaryData>('dictionaries/CHARACTERISTIC_TYPES');

        return parseData(data);
    } catch (error) {
        logger(error);
        setCommonAppError({ error });
        throw null;
    }
});
