import styled from 'styled-components';
import { Typography, Button, Image } from 'ui-kit';

export const StyledWrapper = styled.div`
    max-width: 400px;
`;
export const StyledTypographyDescription = styled(Typography)`
    margin-top: 12px;
`;
export const StyledTypographyTitle = styled(Typography)`
    margin-top: 30px;
`;
export const StyledButton = styled(Button)`
    margin-top: 38px;
`;
export const StyledBackground = styled.div`
    box-sizing: border-box;
    min-height: 100vh;
    background-image: url(${Image.backgroundLiftImageUrl});
    background-repeat: no-repeat;
    background-position: bottom right;
    padding: 17.22% 0 100px 11.87%;
`;
