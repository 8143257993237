import { combine, createStore } from 'effector';
import { getSegmentFileUploadHistoryFx } from '@admin/model/segments/file-upload-history/get-segment-file-upload-history.fx';
import { SegmentFileUploadHistoryState } from '@admin/model/segments/file-upload-history/segment-file-upload-history.types';

export const $segmentFileUploadHistoryData = createStore<Nullable<SegmentFileUploadHistoryState>>(null);
export const $segmentFileUploadHistoryError = createStore<Nullable<unknown>>(null);

export const $segmentFileUploadHistory = combine({
    data: $segmentFileUploadHistoryData,
    error: $segmentFileUploadHistoryError,
    isLoading: getSegmentFileUploadHistoryFx.pending,
});
