import { PollStatus } from '@admin/model/api';
import { ShortenedUser } from '@admin/model/users';

export enum ProgramLoyalty {
    Vtb = 'VTB',
    PostBank = 'POST_BANK',
    Multiprogram = 'MULTIPROGRAM',
}

export enum AccessToSite {
    Enabled = 'ENABLED',
    DisabledByAdmin = 'DISABLED_BY_ADMIN',
    DisabledBySystem = 'DISABLED_BY_SYSTEM',
}

export enum Currency {
    Bonus = 'bonus',
    Mile = 'mile',
    Ruble = 'ruble',
    RublePB = 'rublePB',
    RubleRus = 'rubleRus',
}

export const currencyKeyMapper: Record<Currency, string> = {
    [Currency.Bonus]: 'BONUS',
    [Currency.Mile]: 'MILE',
    [Currency.Ruble]: 'RUBLE',
    [Currency.RublePB]: 'RUBLE_PB',
    [Currency.RubleRus]: 'RUBLE_RUS',
};

export enum CurrencyStatus {
    Valid = 'VALID',
    Blocked = 'BLOCKED',
}

export enum ProfileStatus {
    Active = 'ACTIVE',
    Registered = 'REGISTERED',
    Blocked = 'BLOCKED',
    WaitingForDeactivated = 'AWAITING_FOR_DEACTIVATION',
    Inactive = 'INACTIVE',
}

export enum MultiCardOptions {
    Basic = 'Basic',
    Cbmass = 'CBMASS',
    Cbpriv = 'CBPRIV',
    Cbprime = 'CBPRIME',
    NoBonus = 'NoBonus',
}

export enum Gender {
    Undefined = 'UNDEFINED',
    Male = 'MALE',
    Female = 'FEMALE',
}

export enum InterfaceIntegrationType {
    SameOrigin = 'SAME_ORIGIN',
    DoNotUse = 'DO_NOT_USE',
}

export enum IntegrationType {
    Standard = 'STANDARD',
    Script = 'SCRIPT',
}

export enum Ordering {
    Asc = 'asc',
    Desc = 'desc',
}

export enum SupplierStatus {
    Active = 'ACTIVE',
    Blocked = 'BLOCKED',
}

export enum EventStatus {
    Available = 'AVAILABLE',
    Unavailable = 'UNAVAILABLE',
}

export enum SupplierConfidence {
    High = 'HIGH',
    Middle = 'MEDIUM',
    Low = 'LOW',
}

export enum UploadStatus {
    Ready = 'READY',
    Fail = 'FAIL',
    Done = 'DONE',
    InProgress = 'IN_PROGRESS',
    Unknown = 'UNKNOWN',
}

export enum OrderStatus {
    Delivered = 'DELIVERED',
    DeliveryWaiting = 'DELIVERY_WAITING',
    InDelivery = 'IN_DELIVERY',
    InProcessing = 'IN_PROCESSING',
    NotDelivered = 'NOT_DELIVERED',
    Registered = 'REGISTERED',
    Draft = 'DRAFT',
    WaitingForPayment = 'WAITING_FOR_PAYMENT',
    Canceled = 'CANCELED',
}

export enum AggregatedPaymentStatus {
    Paid = 'PAID',
    PartiallyPaid = 'PARTIALLY_PAID',
    Canceled = 'CANCELED',
    PartiallyCanceled = 'PARTIALLY_CANCELED',
    Error = 'ERROR',
    InProcessing = 'IN_PROCESSING',
}

export interface RequestWithPollingResponse {
    processId: string;
    status: PollStatus;
}

export type RequestWithArrayPollingResponse = RequestWithPollingResponse[];

export interface PollingError {
    processesIds: string[];
}

export interface DictionaryBaseItem {
    name: string;
    code: string;
}

export interface DictionaryData {
    dictionaryValueList: {
        key: string;
        value: string; // JSON with object
        parent: null;
    }[];
}

export enum CommonChangeHistoryField {
    ChangedAt = 'changedAt',
    ChangedBy = 'changedBy',
    OldState = 'oldState',
    NewState = 'newState',
}

export interface ChangeHistoryData<T> {
    createdAt: string;
    createdBy: ShortenedUser;
    data: T[];
    totalCount: number;
}

export enum UserType {
    Employee = 'EMPLOYEE',
    Client = 'CLIENT',
}

export enum FiscalizationType {
    Agent = 'AGENT',
    Seller = 'SELLER',
}

export enum Vat {
    None = '-1',
    Zero = '0',
    Vat10 = '10',
    Vat20 = '20',
    Vat110 = '110',
    VAt120 = '120',
}

export enum OTPProvider {
    Multibonus = 'MULTIBONUS',
    RapidSoft = 'RAPID_SOFT',
    NoSend = 'NO_SEND',
}

export enum FiscalizationPaymentType {
    FullPrepaid = '1',
    Prepaid = '3',
    FullPay = '4',
}

export enum FiscalizationLineType {
    Items = '1',
    Prepaid = '10',
}

export interface AdditionalShortenedUser extends ShortenedUser {
    userType: Nullable<UserType>;
}

export interface ChangeHistoryItemData<T> {
    [CommonChangeHistoryField.ChangedAt]: string;
    [CommonChangeHistoryField.ChangedBy]: AdditionalShortenedUser;
    [CommonChangeHistoryField.NewState]: T;
    [CommonChangeHistoryField.OldState]: T;
}

export interface ChangeHistoryItem<T> extends Omit<ChangeHistoryItemData<T>, 'changedAt'> {
    changedAt: Date;
}

export interface ChangeHistory<T> extends Omit<ChangeHistoryData<ChangeHistoryItemData<T>>, 'createdAt' | 'data'> {
    createdAt: Date;
    data: ChangeHistoryItem<T>[];
}

export interface CurrencyBalance {
    profileId: string;
    currencyType: Currency;
    isActive: boolean;
    balance: number;
    status: CurrencyStatus;
}
