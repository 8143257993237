import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api';
import { createEffect } from 'effector';
import {
    CostMatrixChangeHistoryItemData,
    CostMatrixChangeHistoryPayload,
} from '@admin/model/cost-matrix/cost-matrix-change-history/cost-matrix-change-history.types';
import { ChangeHistoryData } from '@admin/model/model.types';
import { normalizeCostMatrixChangeHistoryData } from '@admin/model/cost-matrix/cost-matrix-change-history/cost-matrix-change-history.helpers';

export const getCostMatrixChangeHistoryFx = createEffect(async ({ id, ...rest }: CostMatrixChangeHistoryPayload) => {
    try {
        const data = await postRequest<
            ChangeHistoryData<CostMatrixChangeHistoryItemData>,
            CostMatrixChangeHistoryPayload
        >(`partners/${id}/delivery-matrix/history`, { ...rest });

        return normalizeCostMatrixChangeHistoryData(data);
    } catch (error) {
        logger(error);

        throw error;
    }
});
