export function getCookie(name: string) {
    const cookie = document.cookie;
    const value = new RegExp(`(^|;) ?${name}=([^;]*)(;|$)`).exec(cookie);

    return value !== null ? value[2] : null;
}

export function setCookie(cookie: string) {
    document.cookie = cookie;
}
