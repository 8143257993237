import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { updateNotificationBatchingFx } from '@admin/model/notifications/update-notification-batching/update-notification-batching.fx';
import { getSuccessMessage } from '@admin/model/notifications/update-notification-batching/update-notification-batching.helpers';
import {
    $updateNotificationData,
    $updateNotificationError,
    resetUpdateNotificationStateEvent,
} from '@admin/model/notifications/update-notification-batching/update-notification-batching.store';
import { reset } from 'patronum';

sample({
    clock: updateNotificationBatchingFx.doneData,
    fn: (result) => getSuccessMessage(result),
    target: showNoticeEvent,
});

sample({
    source: updateNotificationBatchingFx.done,
    fn: () => true,
    target: $updateNotificationData,
});

sample({
    clock: updateNotificationBatchingFx.failData,
    target: $updateNotificationError,
});

reset({ clock: resetUpdateNotificationStateEvent, target: [$updateNotificationError, $updateNotificationData] });
