import { combine, createStore } from 'effector';
import { getNotificationsListFx } from '@admin/model/notifications/notifications-list/get-notifications-list.fx';
import { NotificationsState } from '@admin/model/notifications/notifications-list/notifications-list.types';

const initialState: NotificationsState = {
    totalCount: 0,
    data: [],
};

export const $notificationsListData = createStore<NotificationsState>(initialState);
export const $notificationsListError = createStore<Nullable<unknown>>(null);

export const $notificationsList = combine({
    data: $notificationsListData,
    error: $notificationsListError,
    isLoading: getNotificationsListFx.pending,
});
