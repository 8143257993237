import { sample } from 'effector';
import { createUserFx } from '@admin/model/users/create-user/create-user.fx';
import { getSuccessMessage } from '@admin/model/users/create-user/create-user.helpers';
import { showNoticeEvent } from '@admin/model/notices';

sample({
    clock: createUserFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
