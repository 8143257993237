import { sample } from 'effector';
import { getSuccessMessage } from '@admin/model/suppliers/create-supplier/create-supplier.helpers';
import { createSupplierFx } from '@admin/model/suppliers/create-supplier/create-supplier.fx';
import { showNoticeEvent } from '@admin/model/notices';

sample({
    clock: createSupplierFx.done,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
