import { ChangeHistoryData, ChangeHistoryItemData } from '@admin/model/model.types';

export interface CategoriesBindingChangeHistoryPayload {
    supplierId?: number;
    limit?: number;
    offset?: number;
    ordering?: string;
    startAt?: string;
    endAt?: string;
}

export enum CategoriesBindingChangeHistoryStateField {
    IsLinked = 'isLinked',
    Aliases = 'aliases',
}

export enum CategoriesBindingChangeHistoryField {
    Category = 'category',
}

export interface CategoriesBindingChangeHistoryItemData
    extends ChangeHistoryItemData<CategoriesBindingChangeHistoryStateField> {
    [CategoriesBindingChangeHistoryField.Category]: string;
}

export interface CategoriesBindingChangeHistoryItem extends Omit<CategoriesBindingChangeHistoryItemData, 'changedAt'> {
    changedAt: Date;
}

export interface CategoriesBindingChangeHistory
    extends Omit<ChangeHistoryData<CategoriesBindingChangeHistoryItemData>, 'createdAt' | 'data'> {
    createdAt: Date;
    data: CategoriesBindingChangeHistoryItem[];
}
