import { DictionaryData } from '@admin/model/model.types';
import { RefundStatusDictionary } from '@admin/model/orders/refund-status-dictionary/refund-status-dictionary.types';

export const parseData = (data: DictionaryData): RefundStatusDictionary => {
    return data.dictionaryValueList.reduce<RefundStatusDictionary>((acc, eventData) => {
        acc[eventData.key] = {
            status: eventData.key,
            ...JSON.parse(eventData.value),
        };

        return acc;
    }, {});
};
