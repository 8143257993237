import { ValidationError } from '@admin/common/validation';

export enum CharacteristicField {
    Id = 'id',
    Key = 'key',
    Name = 'name',
    Unit = 'unit',
    Type = 'type',
    Values = 'values',
    Description = 'description',
    UpdatedAt = 'updatedAt',
}

export enum CharacteristicType {
    Boolean = 'BOOLEAN',
    Colour = 'COLOUR',
    List = 'LIST',
    Numeric = 'NUMERIC',
    String = 'STRING',
}

export enum CharacteristicValueField {
    Key = 'key',
    Name = 'name',
}

export interface CharacteristicValue {
    [CharacteristicValueField.Key]: string;
    [CharacteristicValueField.Name]: string;
}

export interface Characteristic {
    [CharacteristicField.Id]: string;
    [CharacteristicField.Name]: string;
    [CharacteristicField.Key]: string;
    [CharacteristicField.Unit]: Nullable<string>;
    [CharacteristicField.Type]: CharacteristicType;
    [CharacteristicField.Values]: Nullable<CharacteristicValue[]>;
    [CharacteristicField.Description]: Nullable<string>;
}

export enum CharacteristicValidationErrorCode {
    MissingRequiredField = 'MISSING_REQUIRED_FIELD',
    SizeExceeded = 'SIZE_EXCEEDED',
    UniqueValueViolation = 'UNIQUE_VALUE_VIOLATION',
}

export type CharacteristicValidationError = ValidationError<CharacteristicValidationErrorCode>;

export type CharacteristicPayload = Partial<Characteristic>;

export type CharacteristicUnitsDictionary = {
    array: CharacteristicUnit[];
    dictionary: Record<string, CharacteristicUnit | undefined>;
};

export enum CharacteristicUnitField {
    Type = 'type',
    Name = 'name',
    Unit = 'unit',
    Key = 'key',
}

export interface CharacteristicUnit {
    [CharacteristicUnitField.Type]: CharacteristicType;
    [CharacteristicUnitField.Name]: string;
    [CharacteristicUnitField.Unit]: string;
    [CharacteristicUnitField.Key]: string;
}
