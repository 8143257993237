import { memo, useEffect } from 'react';

// Mechanics
// https://medium.com/tinyso/how-to-detect-inactive-user-to-auto-logout-by-using-idle-timeout-in-javascript-react-angular-and-b6279663acf2

interface UserActivityTrackerProps {
    timeoutSeconds: number;
    onUserInactivity(): void;
}

const storageKey = 'logoutTimeout';
const checkTimeout = 5000;
const throttleDelay = 500;
let interval: number;
let throttleTimeout: number;

const updateLocalStorageTimeout = (timeout: number) => {
    localStorage.setItem(storageKey, String(Date.now() + timeout * 1000));
};

const UserActivityTrackerComponent = ({ timeoutSeconds, onUserInactivity }: UserActivityTrackerProps) => {
    useEffect(() => {
        interval = window.setInterval(() => {
            const timeout = parseInt(localStorage.getItem(storageKey) ?? '0');
            const currentTime = Date.now();
            if (timeout < currentTime) {
                localStorage.removeItem(storageKey);
                onUserInactivity();
            }
        }, checkTimeout);

        return () => {
            clearInterval(interval);
        };
    }, [onUserInactivity]);

    useEffect(() => {
        const handler = () => {
            clearTimeout(throttleTimeout);
            throttleTimeout = window.setTimeout(() => {
                updateLocalStorageTimeout(timeoutSeconds);
            }, throttleDelay);
        };

        updateLocalStorageTimeout(timeoutSeconds);
        window.addEventListener('mousemove', handler);
        window.addEventListener('scroll', handler);
        window.addEventListener('keydown', handler);

        return () => {
            window.removeEventListener('mousemove', handler);
            window.removeEventListener('scroll', handler);
            window.removeEventListener('keydown', handler);
        };
    }, [timeoutSeconds]);

    return null;
};

export const UserActivityTracker = memo(UserActivityTrackerComponent);
