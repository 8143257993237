import { combine, createEvent, restore } from 'effector';
import { getBlockingPagesFx } from '@admin/model/blocking-pages/get-blocking-pages.fx';
import { addBlockingPagesFx } from '@admin/model/blocking-pages/add-blocking-page.fx';

export interface BlockingPage<T = string> {
    section: string;
    dateFrom?: T;
    dateTo?: T;
    bannerImageUrl?: string;
    bannerDescription?: string;
}

const initialState: BlockingPage<Date>[] = [];

export const resetBlockingStoreErrors = createEvent();

const $errorStore = restore(getBlockingPagesFx.failData, null).on(getBlockingPagesFx.doneData, () => null);
const $updateErrorStore = restore(addBlockingPagesFx.failData, null)
    .on(addBlockingPagesFx.doneData, () => null)
    .reset([resetBlockingStoreErrors]);

const $dataStore = restore(getBlockingPagesFx, initialState);

export const $blockingPageStore = combine({
    error: $errorStore,
    updateError: $updateErrorStore,
    isLoading: getBlockingPagesFx.pending,
    data: $dataStore,
});
