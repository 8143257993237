import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { setCommonAppError } from '@admin/model/error-notification';
import { OfferValidationErrorCode } from '@admin/model/offers/offers.types';
import type { CreateOfferPayload } from '@admin/model/offers/create-offer/create-offer.types';

export const createOfferFx = createEffect(async ({ images, actionRules, ...rest }: CreateOfferPayload) => {
    const formData = new FormData();

    formData.append('data', JSON.stringify(rest));

    if (images) {
        for (const image of images) {
            formData.append('images', image, image.name);
        }
    }

    if (actionRules) {
        formData.append('action_rules', actionRules, actionRules.name);
    }

    try {
        await postRequest('offers', formData, {
            apiVersion: 2,
        });
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, OfferValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
