import {
    StyledWrapper,
    StyledTypographyDescription,
    StyledTypographyTitle,
    StyledButton,
    StyledBackground,
} from '@admin/components/app-blocking-error/app-blocking-error.styles';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, TypographyVariant } from 'ui-kit';

interface AppBlockingErrorProps {
    code?: string;
    title?: string;
    description?: string;
}
const AppBlockingErrorComponent = ({ code, title, description }: AppBlockingErrorProps) => {
    const { t } = useTranslation();
    const handleClick = () => {
        window.location.reload();
    };

    return (
        <StyledBackground>
            <StyledWrapper>
                {code !== undefined && (
                    <Typography variant={TypographyVariant.Heading1Cyan} element="div">
                        {code}
                    </Typography>
                )}
                {title !== undefined && (
                    <StyledTypographyTitle variant={TypographyVariant.Heading7Night} element="div">
                        {title}
                    </StyledTypographyTitle>
                )}
                {description !== undefined && (
                    <StyledTypographyDescription variant={TypographyVariant.Body2Night} element="div">
                        {description}
                    </StyledTypographyDescription>
                )}
                <StyledButton primary onClick={handleClick}>
                    {t('BUTTON.RELOAD_PAGE')}
                </StyledButton>
            </StyledWrapper>
        </StyledBackground>
    );
};

export const AppBlockingError = memo(AppBlockingErrorComponent);
