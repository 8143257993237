import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { setCommonAppError } from '@admin/model/error-notification';
import { OfferCategoryValidationErrorCode, OfferCategoryPayload } from '@admin/model/offer-categories/categories.types';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { logger } from '@admin/common/helpers/logger';
import { getFormData } from '@admin/pages/partner-offers/categories/helpers/get-form-data';

export const createOfferCategoryFx = createEffect(async (data: OfferCategoryPayload) => {
    try {
        await postRequest('offer-partners/categories', getFormData(data));
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, OfferCategoryValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
