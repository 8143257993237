import React, { useState, useCallback } from 'react';
import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { UniversalModalContext } from '@admin/common/contexts';
import type { UniversalModalParams, OpenUniversalModalCallback } from '@admin/common/contexts';
import { Modal } from '@admin/components/modal/modal.component';

interface State {
    params: UniversalModalParams<unknown>;
    resolve: (value?: unknown) => void;
    reject: (reason?: unknown) => void;
}

export const UniversalModal = ({ children }: PropsWithChildren<{}>) => {
    const { t } = useTranslation();
    const [state, setState] = useState<State>();

    const handleOpenModal = useCallback<OpenUniversalModalCallback>(
        (params) =>
            new Promise((resolve, reject) => {
                setState({
                    params,
                    resolve,
                    reject,
                });
            }),
        [],
    );

    const handleMainAction = useCallback(() => {
        setState((currentState) => {
            currentState?.resolve(currentState.params.mainActionValue);

            return undefined;
        });
    }, []);

    const handleSecondaryAction = useCallback(() => {
        setState((currentState) => {
            if (currentState?.params.secondaryActionValue !== undefined) {
                currentState.resolve(currentState.params.secondaryActionValue);
            } else {
                currentState?.reject();
            }

            return undefined;
        });
    }, []);

    const handleModalClose = useCallback(() => {
        setState((currentState) => {
            currentState?.reject();

            return undefined;
        });
    }, []);

    return (
        <UniversalModalContext.Provider value={handleOpenModal}>
            {state && (
                <Modal
                    isOpen={true}
                    title={state.params.title}
                    bodyText={typeof state.params.content === 'string' ? state.params.content : undefined}
                    confirmButtonText={state.params.mainActionLabel || t('BUTTON.GOT_IT')}
                    cancelButtonText={state.params.secondaryActionLabel}
                    onConfirm={handleMainAction}
                    onCancel={state.params.secondaryActionLabel ? handleSecondaryAction : undefined}
                    onDismiss={state.params.secondaryActionValue !== undefined ? handleModalClose : undefined}
                >
                    {typeof state.params.content !== 'string' ? state.params.content : undefined}
                </Modal>
            )}
            {children}
        </UniversalModalContext.Provider>
    );
};
