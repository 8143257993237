import { ElementLayer } from '@admin/common';
import styled, { css } from 'styled-components';
import { Colors, hex2rgba, RingSpinner } from 'ui-kit';

const common = `
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${hex2rgba(Colors.Light, 0.5)};
`;

export const StyledGlobalOverlay = styled.div<{ $isActive: boolean }>`
    z-index: ${ElementLayer.Overlay};
    position: fixed;
    display: ${({ $isActive }) => ($isActive ? 'block' : 'none')};
    ${common}
`;

export const StyledChildrenOverlay = styled.div<{ $isActive: boolean }>`
    position: relative;

    ${({ $isActive }) =>
        $isActive &&
        css`
            &:after {
                content: '';
                position: absolute;
                ${common}
            }
        `}
`;

export const StyledSpinner = styled(RingSpinner)`
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: ${ElementLayer.Overlay};
`;
