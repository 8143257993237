import { sample } from 'effector';
import { getAggregatedPaymentStatusDictionaryFx } from '@admin/model/orders/aggregated-payment-status-dictionary/get-aggregated-payment-status-dictionary.fx';
import {
    $aggregatedPaymentStatusDictionaryData,
    $aggregatedPaymentStatusDictionaryError,
} from '@admin/model/orders/aggregated-payment-status-dictionary/aggregated-payment-status-dictionary.store';
import { parseData } from '@admin/model/orders/orders.helpers';
import { AggregatedPaymentStatus } from '@admin/model/model.types';

sample({
    clock: getAggregatedPaymentStatusDictionaryFx.doneData,
    fn: (result) => parseData<AggregatedPaymentStatus>(result),
    target: $aggregatedPaymentStatusDictionaryData,
});

sample({
    clock: getAggregatedPaymentStatusDictionaryFx.failData,
    target: $aggregatedPaymentStatusDictionaryError,
});
