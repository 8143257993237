import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { SubscriptionCommonField } from '@admin/model/subscriptions/subscriptions.types';
import { mapOffer } from '@admin/model/subscriptions/subscription/subscription.helpers';
import type { SubscriptionsPayload } from '@admin/model/subscriptions/subscriptions.types';
import { normalizeDate } from '@admin/model/helpers/normalize-date';
import { logger } from '@admin/common/helpers/logger';
import {
    SubscriptionsRawState,
    SubscriptionsState,
} from '@admin/model/subscriptions/subscriptions-list/subscriptions-list.types';

export const getSubscriptionsListFx = createEffect(async (payload: SubscriptionsPayload) => {
    try {
        const data = await postRequest<SubscriptionsRawState, SubscriptionsPayload>('subscriptions/search', payload);

        const mappedData: SubscriptionsState = {
            totalCount: data.totalCount,
            subscriptions: data.data.map((i) => {
                const { [SubscriptionCommonField.Offer]: offer } = i;

                return {
                    ...i,
                    [SubscriptionCommonField.CreatedAt]: normalizeDate(i[SubscriptionCommonField.CreatedAt]),
                    [SubscriptionCommonField.Offer]: offer !== null ? mapOffer(offer) : null,
                };
            }),
        };

        return mappedData;
    } catch (error) {
        logger(error);

        throw error;
    }
});
