import { sample } from 'effector';
import {
    $suppliersListData,
    $suppliersListError,
    resetSuppliersListEvent,
} from '@admin/model/suppliers/suppliers-list/suppliers-list.store';
import { getSuppliersListFx } from '@admin/model/suppliers/suppliers-list/get-suppliers-list.fx';
import { reset } from 'patronum';

sample({
    clock: getSuppliersListFx.doneData,
    target: $suppliersListData,
});

sample({
    clock: getSuppliersListFx.failData,
    target: $suppliersListError,
});

reset({ clock: resetSuppliersListEvent, target: [$suppliersListData, $suppliersListError] });
