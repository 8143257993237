import { createEffect } from 'effector';
import { arrayPollRequest, putRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { UserData } from '@admin/model/users/user.types';
import { RequestWithArrayPollingResponse } from '@admin/model/model.types';
import { setCommonAppError } from '@admin/model/error-notification';
import { UserActivityUpdatePayload } from '@admin/model/users/update-user-quick-actions/update-user-quick-actions.types';

export const updateUserQuickActionsFx = createEffect(async (payload: UserActivityUpdatePayload) => {
    try {
        const response = await putRequest<RequestWithArrayPollingResponse, UserActivityUpdatePayload>(
            'employees/batch/status',
            payload,
        );

        const processesIds = response.map(({ processId }) => processId);

        const pollingResult = await arrayPollRequest<UserData>('employees/processing/', processesIds);

        if (pollingResult.processesWithErrorsIds.length !== 0) {
            throw pollingResult.processesWithErrorsIds;
        }

        return payload.userIds;
    } catch (error) {
        logger(error);

        if (Array.isArray(error)) {
            throw {
                processesIds: error,
            };
        }

        setCommonAppError({ error });

        throw null;
    }
});
