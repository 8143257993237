import { logger } from '@admin/common/helpers/logger';
import { CollectionProductsLoadingPayload } from '@admin/model/collections/collection-products/collection-products.types';
import { getProductsListFx } from '@admin/model/products/products-list/get-products-list.fx';
import { createEffect } from 'effector';

export const getCollectionProductsFx = createEffect(
    async ({ collectionId, limit }: CollectionProductsLoadingPayload) => {
        try {
            const data = await getProductsListFx({
                limit,
                categoryIds: [collectionId],
            });

            return data.products;
        } catch (error) {
            logger(error);

            throw error;
        }
    },
);
