import { createEffect } from 'effector';
import { putRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { UpdateProductPayload } from '@admin/model/products/update-product/update-product.types';
import { ProductValidationErrorCode } from '@admin/model/products/products.types';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { setCommonAppError } from '@admin/model/error-notification';

export const updateProductFx = createEffect(async ({ productId, formData }: UpdateProductPayload) => {
    try {
        await putRequest(`products/${productId}`, formData, {
            apiVersion: 2,
        });
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, ProductValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
