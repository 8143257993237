import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { getRequest } from '@admin/model/api';
import { SegmentData } from '@admin/model/segments/segments.types';

export const getSegmentFx = createEffect(async (id: string) => {
    try {
        return await getRequest<SegmentData>(`dictionaries/CLIENT_TARGETS/keys/${id}`);
    } catch (error) {
        logger(error);

        throw error;
    }
});
