import { sample } from 'effector';
import { createProductFx } from '@admin/model/products/create-product/create-product.fx';
import { getSuccessMessage } from '@admin/model/products/create-product/create-product.helpers';
import { showNoticeEvent } from '@admin/model/notices/notices.store';

sample({
    clock: createProductFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
