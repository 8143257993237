import { getRequest, pollRequest, postRequest } from '@admin/model/api';
import { RequestWithPollingResponse } from '@admin/model/model.types';
import { ClientData } from '@admin/model';
import { logger } from '@admin/common/helpers/logger';
import { ValidationResponseError } from '@admin/common/validation';
import { createEffect } from 'effector';
import { DeactivateProgramLoyaltyPayload } from '@admin/model/clients-and-profiles/deactivate-program-loyalty/deactivate-program-loyalty.types';

export const deactivateProgramLoyaltyFx = createEffect(
    async ({ profileId, reasons, description }: DeactivateProgramLoyaltyPayload) => {
        try {
            const response = await postRequest<RequestWithPollingResponse, {}>(`clients/${profileId}/deactivation`, {
                deactivationReasons: reasons,
                descriptionReason: description,
            });

            await pollRequest<ClientData>(() => getRequest(`clients/processing/${response.processId}`));

            return true;
        } catch (error) {
            logger(error);

            throw (error as ValidationResponseError)?.data;
        }
    },
);
