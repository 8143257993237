import { combine, createStore } from 'effector';
import { OrderStatusDictionary } from '@admin/model/orders/orders.types';
import { AggregatedPaymentStatus } from '@admin/model/model.types';
import { getAggregatedPaymentStatusDictionaryFx } from '@admin/model/orders/aggregated-payment-status-dictionary/get-aggregated-payment-status-dictionary.fx';

export const $aggregatedPaymentStatusDictionaryData =
    createStore<Nullable<OrderStatusDictionary<AggregatedPaymentStatus>>>(null);
export const $aggregatedPaymentStatusDictionaryError = createStore<Nullable<unknown>>(null);

export const $aggregatedPaymentStatusDictionary = combine({
    data: $aggregatedPaymentStatusDictionaryData,
    error: $aggregatedPaymentStatusDictionaryError,
    isLoading: getAggregatedPaymentStatusDictionaryFx.pending,
});
