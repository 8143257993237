import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { createRoleFx } from './create-role.fx';
import { getCreateSuccessMessage } from '../roles.helpers';

sample({
    clock: createRoleFx.doneData,
    fn: getCreateSuccessMessage,
    target: showNoticeEvent,
});
