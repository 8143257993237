import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices';
import { getSuccessMessage } from '@admin/model/collections/collections-visibility/collections-visibility.helpers';
import { changeCollectionVisibilityFx } from '@admin/model/collections/collections-visibility/collections-visibility.fx';

sample({
    clock: changeCollectionVisibilityFx.doneData,
    fn: getSuccessMessage,
    target: showNoticeEvent,
});
