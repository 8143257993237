import { createEffect } from 'effector';
import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { DictionaryData } from '@admin/model/model.types';
import { parseReportsConfigsData } from '@admin/model/reports/helpers/parse-reports-configs-data';

export const getReportsConfigsWithSearchDictionaryFx = createEffect(async (term?: string) => {
    try {
        const data = await getRequest<DictionaryData>('dictionaries/reports');
        const parsedReportsConfigsData = parseReportsConfigsData(data);

        if (!term) {
            return parsedReportsConfigsData;
        }

        const regexp = new RegExp(term, 'i');

        return parsedReportsConfigsData.filter((item) => regexp.test(item.name));
    } catch (error) {
        logger(error);

        throw error;
    }
});
