import { Category, CategoryType } from '@admin/model/categories/category/category.types';
import { Currency } from '@admin/model/model.types';

export enum CategoriesQueryParam {
    CalculateActiveProductAmount = 'calculateActiveProductAmount',
    CalculateActiveChildAmount = 'calculateActiveChildAmount',
    CalculateProductAmount = 'calculateProductAmount',
    CalculateChildAmount = 'calculateChildAmount',
    ExpandChilds = 'expandChilds',
    ShowInactive = 'showInactive',
    ShowAccessible = 'showAccessible',
    Currencies = 'currencies',
    SupplierId = 'supplierId',
    ParentId = 'parentId',
    CategoryType = 'categoryType',
}

export enum AvailableForLink {
    ParentCategory = 'ParentCategory',
    Product = 'Product',
}

export interface CategoriesPayload {
    availableForLink?: AvailableForLink;
    calculateActiveProductAmount?: boolean;
    calculateActiveChildAmount?: boolean;
    calculateProductAmount?: boolean;
    calculateChildAmount?: boolean;
    expandChilds?: boolean;
    showInactive?: boolean;
    showAccessible?: boolean;
    currencies?: string[];
    supplierId?: number;
    parentId?: string;
    categoryType?: CategoryType[];
    shouldExpandAll?: boolean;
}

export interface ChildCategoriesPayload extends Omit<CategoriesPayload, 'parentId'> {
    parentId?: Nullable<string>;
    categoryIds: string[];
    currenciesFilterValue?: Currency[];
}

export interface UpdateChildCategoriesItem {
    data: Category[];
    categoryId: string;
    parentId?: Nullable<string>;
}
