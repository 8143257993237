import { ValidationError } from '@admin/common/validation';

export interface EditOrderPayload {
    id?: string;
    externalOrderId?: Nullable<string>;
    status?: string;
    statusChangeComment?: string;
}

export enum EditOrderValidationErrorCode {
    ClientNotMb = 'CLIENT_NOT_MB',
    ExternalIdAlreadySet = 'EXTERNAL_ID_ALREADY_SET',
    ExternalIdNotUniqueForPartner = 'EXTERNAL_ID_NOT_UNIQUE_FOR_PARTNER',
    ProhibitedStatusChange = 'PROHIBITED_STATUS_CHANGE',
    ProhibitedStatusChangeFromRegistered = 'PROHIBITED_STATUS_CHANGE_FROM_REGISTERED',
}

export type EditOrderValidationError = ValidationError<EditOrderValidationErrorCode>;
