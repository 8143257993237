import { combine, createStore } from 'effector';
import { TrackNumber } from '@admin/model/orders/orders.types';
import { getTrackNumbersFx } from '@admin/model/orders/track-numbers/get-track-numbers.fx';

export const $trackNumbersData = createStore<Nullable<TrackNumber[]>>(null);
export const $trackNumbersError = createStore<Nullable<unknown>>(null);

export const $trackNumbers = combine({
    data: $trackNumbersData,
    error: $trackNumbersError,
    isLoading: getTrackNumbersFx.pending,
});
