import { getRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { AccrualsPayload, AccrualsData } from '@admin/model/accruals/accruals.types';
import { mapAccrual } from '@admin/model/accruals/accruals.mappers';
import { getISODateRange } from '@admin/common/helpers';
import { createEffect } from 'effector';

export const getAccrualsListFx = createEffect(async (payload: AccrualsPayload) => {
    try {
        const { dateFrom, dateTo, createdAtFrom, createdAtTo } = payload;
        if (dateFrom && dateTo) {
            const { fromDate, toDate } = getISODateRange(dateFrom as Date, dateTo as Date);
            payload.dateFrom = fromDate;
            payload.dateTo = toDate;
        }
        if (createdAtFrom && createdAtTo) {
            const { fromDate, toDate } = getISODateRange(createdAtFrom as Date, createdAtTo as Date);
            payload.createdAtFrom = fromDate;
            payload.createdAtTo = toDate;
        }
        const response = await getRequest<AccrualsData>('accruals', payload);
        return {
            ...response,
            data: response.data.map(mapAccrual),
        };
    } catch (error) {
        logger(error);

        throw error;
    }
});
