import { sample } from 'effector';
import { $trackNumbersData, $trackNumbersError } from '@admin/model/orders/track-numbers/track-numbers.store';
import { getTrackNumbersFx } from '@admin/model/orders/track-numbers/get-track-numbers.fx';

sample({
    clock: getTrackNumbersFx.doneData,
    fn: (result) => result,
    target: $trackNumbersData,
});

sample({
    clock: getTrackNumbersFx.failData,
    target: $trackNumbersError,
});
