import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { CreateProductPayload } from '@admin/model/products/create-product/create-product.types';
import { getCreateProductPayload } from '@admin/model/products/create-product/create-product.helpers';
import { isKnownValidationErrorResponse } from '@admin/common/validation';
import { ProductValidationErrorCode } from '@admin/model/products/products.types';
import { setCommonAppError } from '@admin/model/error-notification';

export const createProductFx = createEffect(async (payload: CreateProductPayload) => {
    try {
        const formData = getCreateProductPayload(payload);

        await postRequest('products', formData, {
            apiVersion: 2,
        });
    } catch (error) {
        logger(error);

        if (isKnownValidationErrorResponse(error, ProductValidationErrorCode)) {
            throw error.data.validationErrors;
        } else {
            setCommonAppError({ error });

            throw null;
        }
    }
});
