import { Currency, ProgramLoyalty } from '@admin/model/model.types';

export const enum ExpirationStatus {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
}

export const enum ExpirationType {
    PB = 'PB',
    Fix = 'FIX',
    All = 'ALL',
}

export const enum ExpirationTransactionType {
    BURN = 'BURN',
    BURN_ERROR = 'BURN_ERROR',
    BURN_CANCELED_BY_MB = 'BURN_CANCELED_BY_MB',
    BURN_CANCELED_BY_BANK = 'BURN_CANCELED_BY_BANK',
}

export const enum ExpirationTransactionStatus {
    REGISTERED = 'REGISTERED',
    PENDING = 'PENDING',
    ERROR = 'ERROR',
    PROCESSING = 'PROCESSING',
}

export interface Expiration<T = Date> {
    expirationId: string;
    expirationScheduleId: string;
    expirationType: ExpirationType;
    expirationScheduleStatus: ExpirationStatus;
    expirationDate: T;
    expirationSum: number;
    loyaltyProgram: ProgramLoyalty;
    createdAt: Date;
    expirationViewDate?: Date;
    expirationCurrency: Currency;
}

export interface ExpirationsResponse<T = Date> {
    expirations: Expiration<T>[];
    totalCount: number;
}

export interface ExpirationsPayload {
    profileId: string;
    dateFrom?: string;
    dateTo?: string;
    loyaltyProgram?: string[];
    expirationScheduleStatus?: string[];
    limit?: number;
    offset?: number;
}
