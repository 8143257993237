import { createEffect } from 'effector';
import { postRequest } from '@admin/model/api';
import { logger } from '@admin/common/helpers/logger';
import { ChangeProductCharacteristicsPayload } from '@admin/model/products/change-product-characteristics/change-product-characteristics.types';
import { setCommonAppError } from '@admin/model/error-notification/error-notification.store';

export const changeProductCharacteristicsFx = createEffect(async (payload: ChangeProductCharacteristicsPayload) => {
    try {
        await postRequest('products/characteristics', payload, {
            apiVersion: 2,
        });

        return payload.productIds;
    } catch (error) {
        logger(error);

        setCommonAppError({ error });

        throw null;
    }
});
