import { logger } from '@admin/common/helpers/logger';
import { Category } from '@admin/model/categories/category/category.types';
import { getRequest } from '@admin/model/api';
import { createEffect } from 'effector';
import { ChildCategoriesPayload } from '@admin/model/categories/categories-list/categories-list.types';

export const updateChildCategoriesFx = createEffect(
    async ({ parentId, categoryIds, ...rest }: ChildCategoriesPayload) => {
        try {
            const childCategories = [];
            for (const id of categoryIds) {
                const data = await getRequest<Category[]>('catalog/categories', {
                    ...rest,
                    parentId: id,
                });

                childCategories.push({ data, parentId, categoryId: id });
            }
            return childCategories;
        } catch (error) {
            logger(error);

            throw error;
        }
    },
);
