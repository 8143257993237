import { logger } from '@admin/common/helpers/logger';
import { getRequest } from '@admin/model/api';
import { createEffect } from 'effector';
import {
    CostMatrixUploadHistoryPayload,
    CostMatrixUploadHistoryData,
} from '@admin/model/cost-matrix/cost-matrix-upload-history/cost-matrix-upload-history.types';
import { normalizeCostMatrixUploadHistoryData } from '@admin/model/cost-matrix/cost-matrix-upload-history/cost-matrix-upload-history.helpers';

export const getCostMatrixUploadHistoryFx = createEffect(
    async ({ partnerId, options }: CostMatrixUploadHistoryPayload) => {
        try {
            const data = await getRequest<CostMatrixUploadHistoryData>(
                `delivery/matrices/${partnerId}/loadHistory`,
                options,
            );
            return normalizeCostMatrixUploadHistoryData(data);
        } catch (error) {
            logger(error);

            throw error;
        }
    },
);
