import { createEffect } from 'effector';
import { logger } from '@admin/common/helpers/logger';
import { postRequest } from '@admin/model/api/api.resource';
import { ChangeHistoryData, ChangeHistoryItemData } from '@admin/model/model.types';
import {
    SubscriptionChangeHistoryPayload,
    SubscriptionChangeHistoryState,
} from '@admin/model/subscriptions/subscription-change-history/subscription-change-history.types';
import { normalizeChangeHistoryData } from '@admin/model/subscriptions/subscription-change-history/subscription-change-history.helpers';

export const getSubscriptionChangeHistoryFx = createEffect(
    async ({ id, ...rest }: SubscriptionChangeHistoryPayload) => {
        try {
            const data = await postRequest<
                ChangeHistoryData<ChangeHistoryItemData<SubscriptionChangeHistoryState>>,
                SubscriptionChangeHistoryPayload
            >(`subscriptions/${id}/history`, { ...rest });

            return normalizeChangeHistoryData(data);
        } catch (error) {
            logger(error);

            throw error;
        }
    },
);
