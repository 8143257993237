import { combine, createStore } from 'effector';
import { OrderPayments } from '@admin/model/orders/order/order.types';
import { getOrderPaymentsFx } from '@admin/model/orders/order-payments/get-order-payments.fx';

export const $orderPaymentsData = createStore<Nullable<OrderPayments>>(null);
export const $orderPaymentsError = createStore<Nullable<unknown>>(null);

export const $orderPayments = combine({
    data: $orderPaymentsData,
    error: $orderPaymentsError,
    isLoading: getOrderPaymentsFx.pending,
});
