import { sample } from 'effector';
import { getSupplierPaymentOptionDictionaryFx } from '@admin/model/suppliers/supplier-payment-option-dictionary/get-supplier-payment-option-dictionary.fx';
import {
    $supplierPaymentOptionDictionaryData,
    $supplierPaymentOptionDictionaryError,
} from '@admin/model/suppliers/supplier-payment-option-dictionary/supplier-payment-option-dictionary.store';
import { parseData } from '@admin/model/suppliers/supplier-payment-option-dictionary/supplier-payment-option-dictionary.helpers';

sample({
    clock: getSupplierPaymentOptionDictionaryFx.doneData,
    fn: (result) => parseData(result),
    target: $supplierPaymentOptionDictionaryData,
});

sample({
    clock: getSupplierPaymentOptionDictionaryFx.failData,
    target: $supplierPaymentOptionDictionaryError,
});
