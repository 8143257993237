import { sample } from 'effector';
import { showNoticeEvent } from '@admin/model/notices/notices.store';
import { getSuccessMessage } from '@admin/model/suppliers/update-suppliers-event-set/update-suppliers-event-set.helpers';
import { updateSupplierEventSetFx } from '@admin/model/suppliers/update-suppliers-event-set/update-suppliers-event-set.fx';
import {
    $updateSuppliersEventSetData,
    resetUpdateSuppliersEventSetDataEvent,
} from '@admin/model/suppliers/update-suppliers-event-set/update-suppliers-event-set.store';
import { reset } from 'patronum';

sample({
    source: updateSupplierEventSetFx.doneData,
    fn: (payload) => getSuccessMessage(payload),
    target: showNoticeEvent,
});

sample({
    clock: updateSupplierEventSetFx.done,
    fn: () => true,
    target: $updateSuppliersEventSetData,
});

reset({ clock: resetUpdateSuppliersEventSetDataEvent, target: [$updateSuppliersEventSetData] });
