import { DictionaryBaseItem, DictionaryData } from '@admin/model/model.types';
import { NotificationsStatusesDictionary } from '@admin/model/reports/notifications-statuses-dictionary/notifications-statuses-dictionary.types';

export const parseData = (notificationsStatusesDictData: DictionaryData): NotificationsStatusesDictionary =>
    notificationsStatusesDictData.dictionaryValueList.reduce<NotificationsStatusesDictionary>(
        (acc, statusData) => {
            const parsed = JSON.parse(statusData.value) as DictionaryBaseItem;
            acc.array.push(parsed);
            acc.dictionary[parsed.code] = parsed;
            return acc;
        },
        {
            array: [],
            dictionary: {},
        },
    );
